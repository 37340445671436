import { useTheme } from "@emotion/react";
import {
  Dialog,
  DialogContentText,
  TextField,
  Tooltip,
  Typography,
  Zoom,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { LabelTypography } from "../../../shared_modules/common/StyledClass";
import Textarea from "../../../shared_modules/common/Textarea";
import { showNotification } from "../../../shared_modules/notification_bar/NotificationBarSlice";
import {
  ButtonWrapper,
  CancelButton,
  CustomDialogContent,
  CustomDialogTitle,
  DialogButton,
  RequestButton,
} from "../../record/components/RecordStyle";
import {
  AddButton,
  AddIcon,
  MessageWrapper,
} from "../../video_details/components/ExpandVideoStyle";
import {
  addToLibrary,
  checkVideoIfExists,
  fetchVideoDetails,
  resetFormData,
  selectVideoDetailsState,
  updateVideoDetails,
} from "../VideoLibrarySlice";

export default function AddToLibrary({ id, slug, closeOnRequest, pageNumber }) {
  var isAdd = true;
  if (!id) {
    isAdd = false;
  }
  const { video, message } = useSelector(selectVideoDetailsState);
  const [open, setOpen] = useState(false);
  const [formData, updateFormData] = useState("");
  const [TitleError, setTitleError] = useState("");
  const [DescError, setDescError] = useState("");
  const dispatch = useDispatch();
  const theme = useTheme();
  var errBool = false;

  const handleClickOpen = () => {
    setTitleError("");
    setDescError("");
    setOpen(true);
    if (isAdd) {
      dispatch(checkVideoIfExists(id, null));
      updateFormData({
        ...formData,
        recording_uuid: id,
      });
    } else {
      dispatch(fetchVideoDetails(null, slug));
    }
  };

  const handleClose = () => {
    dispatch(resetFormData());
    setOpen(false);
    if (closeOnRequest) {
      closeOnRequest();
    }
  };

  const handleTitleChange = (e) => {
    setTitleError("");
    updateFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
    if (formData.title && formData.title.length > 250) {
      setTitleError("Title exceeds allowed max length");
      errBool = true;
    }
  };
  const handleDescChange = (e) => {
    setDescError("");
    updateFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
    if (formData.description && formData.description.length > 250) {
      setDescError("Description exceeds allowed max length");
      errBool = true;
    }
  };

  useEffect(() => {
    updateFormData(video);
  }, [video]);

  useEffect(() => {
    if (message.status && message.status === "error") {
      setTitleError(message.message);
    }
    if (message.status && message.status === "success") {
      dispatch(
        showNotification({
          message: message.message,
        })
      );
      handleClose();
    }
  }, [message]);

  const handleSubmit = (e) => {
    e.preventDefault();

    if (
      !formData.title ||
      formData.title.trim() === "" ||
      formData.title === null
    ) {
      setTitleError("Please enter title");
      errBool = true;
    }

    if (!errBool) {
      const form_data = new FormData();
      form_data.append("title", formData.title.trim());
      form_data.append("description", formData.description);
      form_data.append("recording_uuid", formData.recording_uuid);
      form_data.append("slug", formData.slug);

      if (isAdd) {
        dispatch(addToLibrary(form_data));
      } else {
        dispatch(updateVideoDetails(form_data, pageNumber));
        // setMessage(message);
      }

      // formData.reset();
    }
  };

  return (
    <div>
      <DialogButton onClick={handleClickOpen}>
        {isAdd ? (
          <Tooltip TransitionComponent={Zoom} title=" Add to library">
            <AddButton>
              <AddIcon />
              <Typography variant="boldSubhead"> Add to library</Typography>
            </AddButton>
          </Tooltip>
        ) : (
          <Typography> Edit details</Typography>
        )}
      </DialogButton>

      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          sx: {
            bgcolor: theme.palette.common.white,
            borderRadius: theme.spacing(5),
          },
        }}
      >
        <CustomDialogTitle sx={{ paddingBottom: "5px" }}>
          {isAdd ? (
            <Typography variant="boldH4">Add to Video library</Typography>
          ) : (
            <Typography variant="boldH4">Edit video details</Typography>
          )}
        </CustomDialogTitle>
        <CustomDialogContent>
          {isAdd ? (
            <DialogContentText>
              Selected video will be added to Video library
            </DialogContentText>
          ) : (
            <></>
          )}

          <form onSubmit={handleSubmit} noValidate>
            <MessageWrapper>
              <LabelTypography variant="lightFootnote">Title</LabelTypography>
              <TextField
                inputProps={{ "aria-label": "Without label" }}
                hiddenLabel
                id="filled-hidden-label-normal"
                variant="filled"
                placeholder="Enter title"
                name="title"
                value={formData.title ? formData.title : ""}
                onChange={handleTitleChange}
                error={TitleError ? true : false}
                helperText={TitleError ? TitleError : ""}
              />
            </MessageWrapper>

            <MessageWrapper>
              <LabelTypography variant="lightFootnote">
                Description (optional)
              </LabelTypography>

              <Textarea
                minRows={3}
                maxRows={3}
                placeholder="Add an optional description"
                name="description"
                value={formData.description ? formData.description : ""}
                onChange={handleDescChange}
                error={DescError ? true : false}
                helperText={DescError ? DescError : ""}
              />
            </MessageWrapper>
            <ButtonWrapper direction={{ xs: "column", sm: "row" }} spacing={3}>
              {isAdd ? (
                <RequestButton size="large" variant="contained" type="submit">
                  <Typography variant="boldBody">
                    Add to Video library
                  </Typography>
                </RequestButton>
              ) : (
                <RequestButton size="large" variant="contained" type="submit">
                  <Typography variant="boldBody">Save</Typography>
                </RequestButton>
              )}

              <CancelButton
                size="large"
                variant="contained"
                onClick={handleClose}
              >
                <Typography variant="boldBody">Cancel</Typography>
              </CancelButton>
            </ButtonWrapper>
          </form>
        </CustomDialogContent>
      </Dialog>
    </div>
  );
}
