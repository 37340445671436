import styled from '@emotion/styled';
import { TextareaAutosize } from '@mui/material';
import React from 'react';

const StyledTextareaAutosize = styled(TextareaAutosize)(({ theme }) => ({
  width: '100%',
  background: theme.palette.dark.trans5,
  border: 0,
  borderRadius: 8,
  font: 'inherit',
  fontWeight: '500',
  fontSize: '18px',
  lineHeight: '24px',
  letterSpacing: '-0.25px',
  resize: 'vertical',
  padding: theme.spacing(2, 3),

  '&:focus-visible': {
    outline: '1px solid transparent',
  },

  '&::placeholder': {
    color: theme.palette.grey.main,
    ...theme.typography.lightSubTitle,
  },
}));

const Textarea = (props) => {
  const { children } = props;

  return (
    <StyledTextareaAutosize
      aria-label="minimum height"
      minRows={props.minRows}
      placeholder={props.placeholder}
      {...props}
    >
      {children}
    </StyledTextareaAutosize>
  );
};

export default Textarea;
