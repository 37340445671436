import { connect } from 'react-redux';
import { Dashboard } from '../components/Dashboard';
import { deleteVideo, fetchNewVideos } from '../DashboardSlice';

const mapStateToProps = state => ({
    videoListPage: state.dashboard.videoListPage,
    user: state.session.user
});

const mapDispatchToProps = dispatch => ({
    fetchVideos: (pageNumber, sorttype) => {
        dispatch(fetchNewVideos(pageNumber, sorttype));
    },
    deleteVideo: (uuid, pageNumber) => {
        dispatch(deleteVideo(uuid, pageNumber));
    },
});

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
