import { createSlice } from "@reduxjs/toolkit";
import {
  STATE_FAIL,
  STATE_LOADING,
  STATE_SUCCESS,
} from "../../common/Constants";
import { showNotification } from "../../shared_modules/notification_bar/NotificationBarSlice";
import {
  deleteRecordingAPI,
  fetchVideosApi,
} from "./DashboardAPI";
// import { fetchVideos } from '../API';
const defaultState = {
  count: 0,
  pageNumber: 1,
  pages: 0,
  results: null,
};
const initialState = {
  videoListPage: {
    videoRequests: defaultState,
    status: STATE_LOADING,
  },
};

export const dashboardSlice = createSlice({
  name: "dashboard",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    fetchVideoStart: (state, action) => {
      state.videoListPage.status = STATE_LOADING;
      // state.videoListPage.videoRequests = defaultState;
      state.videoListPage.pageNumber = action.payload;
    },
    fetchVideoSuccess: (state, action) => {
      state.videoListPage.status = STATE_SUCCESS;
      state.videoListPage.videoRequests = action.payload;
    },
    fetchVideoFail: (state) => {
      state.videoListPage.status = STATE_FAIL;
      state.videoListPage.videoRequests = defaultState;
    },
  },
});

export const fetchNewVideos = (pageNumber, sorttype) => {
  // the inside "thunk function"
  return async (dispatch) => {
    try {
      dispatch(fetchVideoStart(pageNumber, sorttype));
      const response = await fetchVideosApi(pageNumber, sorttype);
      dispatch(fetchVideoSuccess(response.data));
    } catch (err) {
      // If something went wrong, handle it here
    }
  };
};
export const deleteVideo = (uuId, pageNumber) => {
  // the inside "thunk function"
  return async (dispatch) => {
    try {
      const response = await deleteRecordingAPI(uuId);
      if (response.status === 200) {
        dispatch(
          showNotification({
            message: "Successfully deleted",
          })
        );
        dispatch(fetchNewVideos(pageNumber));
      }
    } catch (err) {
      // If something went wrong, handle it here
    }
  };
};
export const { fetchVideoStart, fetchVideoSuccess, fetchVideoFail } =
  dashboardSlice.actions;

export default dashboardSlice.reducer;
