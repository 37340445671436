import { Box, CardContent, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { ReactComponent as RightArrowIconSvg } from '../../../images/Icon/arrow-right.svg';
import { ReactComponent as ComputerIconSvg } from '../../../images/Icon/computer-speaker.svg';
import { ReactComponent as LocationIconSvg } from '../../../images/Icon/location-dot.svg';
import { StyledCardMd } from '../../../shared_modules/common/StyledCard';

export const RightArrowIcon = styled(RightArrowIconSvg)(({ theme }) => ({
  width: 22,
  height: 22,
  path: {
    fill: theme.palette.secondary.main,
  },
}));

export const LocationIcon = styled(LocationIconSvg)(({ theme }) => ({
  width: 18,
  height: 24,
  margin: theme.spacing(0, 1),

  path: {
    fill: theme.palette.grey.main,
  },
}));

export const ComputerIcon = styled(ComputerIconSvg)(({ theme }) => ({
  width: 24,
  height: 16.8,
  margin: theme.spacing(0, 1),

  path: {
    fill: theme.palette.grey.main,
  },
}));

export const ContentItem = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',

  [theme.breakpoints.up('md')]: {
    justifyContent: 'space-between',
    alignItems: 'center',
    flexDirection: 'row',
  },
}));

export const ActionBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',

  [theme.breakpoints.up('md')]: {
    justifyContent: 'space-between',
    alignItems: 'center',
    flexDirection: 'row',
  },
}));

export const BrowserBox = styled(Box)(({ theme }) => ({
  ...theme.mixins.flexyCenter,
  margin: theme.spacing(1.25, 0),

  [theme.breakpoints.up('md')]: {
    margin: theme.spacing(0, 3.75),
  },
}));

export const ActivityCard = styled(StyledCardMd)(({ theme }) => ({
  marginBottom: theme.spacing(5),
}));

export const LocationBox = styled(Box)(({ theme }) => ({
  ...theme.mixins.flexyCenter,
}));

export const ActivityBox = styled(Box)(({ theme }) => ({
  margin: theme.spacing(4, 0, 2.25, 0),
}));

export const ActivityItem = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(3.5),
  ...theme.mixins.flexyCenterItem,
}));

export const ActivityTitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.common.black,
  margin: theme.spacing(0, 1),
  textTransform: 'initial'
}));

export const ActivityTimeStamp = styled(Typography)(({ theme }) => ({
  color: theme.palette.grey.dark,
}));

export const CardContentStyle = styled(CardContent)(({ theme }) => ({
  paddingBottom: '0 !important',
}));
