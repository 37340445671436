import React from "react";
import { BrowserRouter } from "react-router-dom";
import Router from "./routes";
import ScrollToTop from "./shared_modules/common/ScrollToTop";
import ThemeConfig from "./theme";
import GlobalStyles from "./theme/globalStyles";

const App = () => {
  return (
    <BrowserRouter>
      <ThemeConfig>
        <ScrollToTop />
        <GlobalStyles />
        <Router />
      </ThemeConfig>
    </BrowserRouter >
  );
}

export default App;
