import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { styled } from "@mui/system";
import { useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import { Link } from "@mui/material";

//

const CancelButton = styled(Button)(({ theme }) => ({
  background: theme.palette.dark.lighter,
  color: theme.palette.dark.main,
  // width: "130px",
  height: "50px",
  ...theme.typography.boldSubhead,
  textTransform: "initial",

  "&:hover": {
    background: theme.palette.grey.main,
  },
}));

const DeleteButton = styled(Button)(({ theme }) => ({
  background: "#ED5E68",
  // width: "130px",
  height: "50px",
  ...theme.typography.boldSubhead,
  textTransform: "initial",

  "&:hover": {
    background: theme.palette.danger.main,
  },
}));

const DialogContentStyle = styled(DialogContent)(({ theme }) => ({
  padding: theme.spacing(8, 8, 4, 8),
}));

const DialogActionsStyle = styled(DialogActions)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  padding: theme.spacing(0, 0, 4, 0),
}));

//
export default function ConfirmDialog(props) {
  const [open, setOpen] = React.useState(false);
  const theme = useTheme();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    //to close the parent popover once the dialogue is closed
    if (props.closeOnRequest) {
      props.closeOnRequest();
    }
  };

  const handleAgree = () => {
    props.onConfirm();
    setOpen(false);
  };
  return (
    <div>
      <div onClick={handleClickOpen}>{props.children}</div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{
          sx: {
            bgcolor: theme.palette.common.white,
            borderRadius: theme.spacing(2),
            textAlign: "center",
          },
        }}
      >
        <DialogTitle id="alert-dialog-title">{props.heading}</DialogTitle>

        <DialogContentStyle>
          <DialogContentText id="alert-dialog-description">
            <Typography variant="boldH4" color="common.black">
              {props.link ? (
                <>
                  {props.description}{" "}
                  <Link href="/app/contact" underline="hover">
                    contact us
                  </Link>{" "}
                  to cancel it before you can delete your account.
                </>
              ) : (
                <>{props.description} </>
              )}
            </Typography>
          </DialogContentText>
          <Typography
            style={{ whiteSpace: "pre-line" }}
            gutterBottom
            variant="lightSubTitle"
          >
            {props.additionalDesc}
          </Typography>
        </DialogContentStyle>

        <DialogActionsStyle>
          {props.isAdmin && !props.delete ? (
            <CancelButton
              size="contained"
              variant="contained"
              onClick={handleClose}
            >
              Ok
            </CancelButton>
          ) : (
            <>
              <DeleteButton
                size="contained"
                variant="contained"
                onClick={handleAgree}
                autoFocus
              >
                Delete
              </DeleteButton>
              <CancelButton
                size="contained"
                variant="contained"
                onClick={handleClose}
              >
                Cancel
              </CancelButton>
            </>
          )}
        </DialogActionsStyle>
      </Dialog>
    </div>
  );
}
