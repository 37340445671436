import { alpha, Grid, Tooltip, Typography, Zoom } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useRef, useState } from "react";
import { StyledCardMd } from "../../../shared_modules/common/StyledCard";
import {
  FlexyCenter,
  FlexyCol,
} from "../../../shared_modules/common/StyledClass";
import { showNotification } from "../../../shared_modules/notification_bar/NotificationBarSlice";
import {
  MenuItemStyled,
  MenuItemWrapper,
  DotsButton,
} from "../../../shared_modules/video_library_card/VideoLibraryStyle";
import { selectSessionState } from "../../../shared_modules/layout/SessionSlice";
import { adminDeletionCheckApi, changeUserAccountStateApi } from "../TeamAPI";
import {
  TeammateAvatar,
  TeammateMail,
  TeammateName,
  TeammatesCardWrapper,
  ChipComponent,
} from "./TeammatsStyle";
import { ReactComponent as DotsMenu } from "../../../images/Icon/dots-menu.svg";
import MenuPopover from "../../../shared_modules/common/MenuPopover";
import ConfirmDialog from "../../../shared_modules/common/ConfirmDialog";
// import Chip from "@mui/material/Chip";

export default function TeammateCard(props) {
  const dispatch = useDispatch();
  const { user } = useSelector(selectSessionState);
  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [isdelete, setDelete] = useState("");
  // const mate=props.mate;

  const handleOpen = () => {
    handleAdminCheck(props.mate.slug);
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleActivation = (slug, action) => {
    changeUserAccountStateApi(slug, action).then((res) => {
      dispatch(
        showNotification({
          message: res.data.msg,
        })
      );
    });
    handleClose();
    props.refresh();
  };
  const handleAdminCheck = (slug) => {
    adminDeletionCheckApi(slug).then((res) => {
      if (res.data.is_last_admin) {
        setMessage(res.data.msg);
      }
      setDelete(res.data.delete);
    });
    handleClose();
  };

  return (
    <Grid item xs={12} sm={6} md={4} mt={3} key={props.mate.id}>
      <StyledCardMd>
        <TeammatesCardWrapper>
          <FlexyCenter>
            <TeammateAvatar
              src={`https://ui-avatars.com/api/?size=68&color=fff&rounded=false&bold=true&background=random&name=${props.mate.name}`}
              alt=""
            />
            <FlexyCol>
              <TeammateName
                variant="boldCallout"
                className={props.mate.status === 0 ? "inactive" : ""}
              >
                {props.mate.name}
              </TeammateName>
              <Tooltip TransitionComponent={Zoom} title={props.mate.email}>
                <TeammateMail
                  variant="lightFootnote"
                  className={props.mate.status === 0 ? "inactive" : ""}
                  noWrap
                  maxWidth={"150px"}
                >
                  {props.mate.email}
                </TeammateMail>
              </Tooltip>

              {props.mate.status === 0 ? (
                <ChipComponent label="Inactive" size="small" />
              ) : (
                ""
              )}
            </FlexyCol>
          </FlexyCenter>

          {user.company_permissions === "Admin" ? (
            <FlexyCenter>
              <></>
              <DotsButton
                ref={anchorRef}
                onClick={handleOpen}
                sx={{
                  cursor: "pointer",
                  ...(open && {
                    "&:before": {
                      zIndex: 1,
                      content: "''",
                      position: "absolute",
                      bgcolor: (theme) => alpha(theme.palette.grey[300], 0.12),
                    },
                  }),
                }}
              >
                <DotsMenu style={{ paddingBottom: "3.5px" }} />
              </DotsButton>
              <MenuPopover
                open={open}
                onClose={handleClose}
                anchorEl={anchorRef.current}
                sx={{ width: 150 }}
              >
                {props.mate.is_last_admin ? (
                  <></>
                ) : props.mate.status === 1 ? (
                  <MenuItemStyled
                    onClick={() =>
                      handleActivation(props.mate.slug, "deactivate")
                    }
                  >
                    <MenuItemWrapper>
                      <Typography>Deactivate</Typography>
                    </MenuItemWrapper>
                  </MenuItemStyled>
                ) : (
                  <MenuItemStyled
                    onClick={() =>
                      handleActivation(props.mate.slug, "activate")
                    }
                  >
                    <MenuItemWrapper>
                      <Typography>Activate</Typography>
                    </MenuItemWrapper>
                  </MenuItemStyled>
                )}
                <ConfirmDialog
                  key={"delete-confirm-" + props.mate.slug}
                  description={
                    props.mate.company_permissions === "Admin"
                      ? message.title
                      : `Are you sure you want to delete ${props.mate.name}'s account?`
                  }
                  additionalDesc={
                    props.mate.company_permissions === "Admin"
                      ? message.desc
                      : `\nThis action cannot be reversed.\n Videos associated with ${props.mate.name}'s account will still be available for you.`
                  }
                  isAdmin={
                    props.mate.company_permissions === "Admin" ? true : false
                  }
                  link={message.contact_link}
                  onConfirm={() => handleActivation(props.mate.slug, "deleted")}
                  delete={isdelete}
                  closeOnRequest={handleClose}
                >
                  <MenuItemStyled
                    // onClick={() => handleAdminCheck(mate.slug)}
                    onClose={handleClose}
                  >
                    <MenuItemWrapper>
                      <Typography>Delete</Typography>
                    </MenuItemWrapper>
                  </MenuItemStyled>
                </ConfirmDialog>
              </MenuPopover>
            </FlexyCenter>
          ) : (
            <></>
          )}
        </TeammatesCardWrapper>
      </StyledCardMd>
    </Grid>
  );
}

TeammateCard.defaultProps = {
  params: false,
};
