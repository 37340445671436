import * as React from 'react';

// <script>
export function WhatsNew() {

  var s = document.createElement('script'); s.type = "text/javascript"; s.async = true;
  s.src = "https://arn.upraise.io/rest/arn/latest/widget/widget-init?widgetId=946cde25-b061-4b58-ad8a-074aacced0bf&clientKey=jira:61982134-7d94-4d15-8da0-e1937c34aba3";

  s.onload = s.onreadystatechange = function () {
    // can add logic to load widget or not
    new window.arnWidget();
    var item = document.getElementsByClassName('arn-widget-btn')[0];
    item.style.visibility = 'hidden';
    item.style.setProperty("display", "none", "important");

    //logic to remove badge notification counter icon
    var widgetBtnBadge = document.getElementsByClassName('arn-widget-btn-badge');
    if (widgetBtnBadge && widgetBtnBadge[0] !== undefined) {
      widgetBtnBadge[0].style.visibility = 'hidden';
      widgetBtnBadge[0].style.setProperty("display", "none", "important");
    }
  };
  var h = document.getElementsByTagName('head')[0]; h.appendChild(s);
  return (
    <></>
  )
}