import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  switchChecked: false,
  details:null,
  loader:true
};

export const settingsSlice = createSlice({
  name: "settings",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    changePlansInterval: (state, { payload }) => {
      state.switchChecked = payload;
    },
    companyDetails: (state, { payload }) => {
      state.details = payload;
      state.loader = false
    },
  },
});

export const { changePlansInterval, companyDetails } = settingsSlice.actions;
export default settingsSlice.reducer;
