import {
  Box,
  CircularProgress,
  Grid,
  Pagination,
  Tooltip,
  Typography,
  Zoom,
} from "@mui/material";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { StyledCardMd } from "../../../shared_modules/common/StyledCard";
import { FlexyCenter } from "../../../shared_modules/common/StyledClass";
import { teamMatesApi } from "../TeamAPI";
import {
  acceptedInvites,
  pendingInvitesPageChange,
  teammatesPageChange,
} from "../TeamSlice";

import {
  TeammateAvatar,
  TeammateMail,
  TeammatesCardWrapper,
} from "./TeammatsStyle";
import TeammateCard from "./TeammatesCard";

export const Teammates = () => {
  const dispatch = useDispatch();
  const { teammates, teammatesPage, loader } = useSelector(
    (state) => state.invites
  );

  const getTeamMates = async () => {
    const res = await teamMatesApi(teammatesPage);
    if (res.data) {
      dispatch(acceptedInvites(res.data));
    }
  };
  React.useEffect(() => {
    getTeamMates();
  }, []);

  const handleRefresh = () => {
    getTeamMates();
  };

  return !loader ? (
    <>
      {teammates.results.length > 0 ? (
        teammates.results.map((mate) => (
          <TeammateCard mate={mate} refresh={handleRefresh} />
        ))
      ) : (
        <Grid item xs={12}>
          <Box display="flex" flexDirection="column" alignItems="center" py={4}>
            <Typography variant="boldH2">No team members added</Typography>
            <Typography variant="lightSubTitle">
              Start adding team members by sending invitation
            </Typography>
          </Box>
        </Grid>
      )}
      {teammates.results.length > 0 && (
        <Box mt={4} width="100%" px={2}>
          <Pagination
            color="secondary"
            count={teammates.pages}
            page={teammatesPage}
            onChange={(e, val) => dispatch(teammatesPageChange(val))}
          />
        </Box>
      )}
    </>
  ) : (
    <Grid item xs={12}>
      <Box textAlign="center" width="100%" pt={4}>
        {/* <CircularProgressWrapper> */}
        <CircularProgress
          sx={{
            maxHeight: "70px",
            maxWidth: "70px",
          }}
        />
        {/* </CircularProgressWrapper> */}
      </Box>
    </Grid>
  );
};

export const Pending = () => {
  const dispatch = useDispatch();
  const { pendingInvites, pendingInvitePage } = useSelector(
    (state) => state.invites
  );
  return (
    <>
      {pendingInvites.results.length > 0 ? (
        pendingInvites.results.map((invite) => (
          <Grid item xs={12} sm={6} md={4} mt={3} key={invite.id}>
            <StyledCardMd>
              <TeammatesCardWrapper>
                <FlexyCenter>
                  <TeammateAvatar
                    src={`https://ui-avatars.com/api/?size=68&color=fff&rounded=false&bold=true&background=random&name=${invite?.recipient}`}
                    alt=""
                  />
                  <Box>
                    <TeammateMail variant="lightFootnote">
                      {invite.recipient}
                    </TeammateMail>
                  </Box>
                </FlexyCenter>

                <Tooltip TransitionComponent={Zoom} title=" Delete">
                  <Box>{/* <DeleteIcon /> */}</Box>
                </Tooltip>
              </TeammatesCardWrapper>
            </StyledCardMd>
          </Grid>
        ))
      ) : (
        <Grid item xs={12}>
          <Box display="flex" flexDirection="column" alignItems="center" py={4}>
            <Typography variant="boldH2">No invitations sent</Typography>
            <Typography variant="lightSubTitle">
              Start adding team members by sending invitation
            </Typography>
          </Box>
        </Grid>
      )}
      {pendingInvites.results.length > 0 && (
        <Box mt={4} width="100%" px={2}>
          <Pagination
            color="secondary"
            count={pendingInvites.pages}
            page={pendingInvitePage}
            onChange={(e, val) => dispatch(pendingInvitesPageChange(val))}
          />
        </Box>
      )}
    </>
  );
};
