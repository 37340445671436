import {
  Button,
  Stack,
  Typography,
  TextField,
  Box,
  Tooltip,
  Zoom,
  InputAdornment,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import HelpScoutLogoText from "../../../images/Icon/helpScout.svg";
import HelpScoutLogo from "../../../images/Icon/helpScoutLogo.svg";
import hs1_img from "../../../images/hs1.png";
import hs1_mp4 from "../../../images/hs1.mp4";
import hs2_img from "../../../images/hs2.png";
import hs2_mp4 from "../../../images/hs2.mp4";
import hs3_img from "../../../images/hs3.png";
import hs4_img from "../../../images/hs4.png";
import { SpaceBottom3 } from "../../../shared_modules/common/StyledClass";
import {
  HelpFullSection,
  HelpScoutImg,
  HelpScoutLogoImg,
  InstructionTitle,
  IntegrationWrapper,
  CopyIcon,
} from "./SettingStyle";

import {
  getHelpScoutIntegrationAPI,
  updateHelpScoutIntegrationAPI,
} from "../SettingsAPI";
import { useDispatch } from "react-redux";
import { showNotification } from "../../../shared_modules/notification_bar/NotificationBarSlice";
import { getMetaTag, META_TAG_BASE_URL } from "../../../common/MetaTagReader";
import { handleCopyLinkClick } from "../../record/components/RecordingUtil";

const HelpScout = () => {
  const initialData = {
    callback_url: "",
    secret_key: "",
    notifications: true,
  };
  const [data, setData] = useState(initialData);
  const dispatch = useDispatch();

  const handleSubmit = (e) => {
    e.preventDefault();

    updateHelpScoutIntegrationAPI().then((res) => {
      setData({
        ...data,
        notifications: res.data.notifications,
      });
      // dispatch(fetchSession());
    });
  };

  useEffect(() => {
    getHelpScoutIntegrationAPI().then((res) => {
      setData({
        ...data,
        callback_url: res.data.callback_url,
        secret_key: res.data.secret_key,
        notifications: res.data.notifications,
      });
    });
  }, []);

  return (
    <>
      <IntegrationWrapper>
        {/* <Stack direction={{ xs: 'column-reverse', md: 'row' }}> */}
        <HelpFullSection>
          <Stack direction="row" spacing={1.25}>
            <HelpScoutLogoImg component="img" src={HelpScoutLogo} alt="" />
            <HelpScoutImg component="img" src={HelpScoutLogoText} alt="" />
          </Stack>

          <InstructionTitle>
            <Typography variant="boldH4">Follow these instructions</Typography>
          </InstructionTitle>

          <SpaceBottom3>
            <Typography variant="lightCallout">
              To connect Screenjar to your Help Scout account. It only takes a
              minute to set up and allows you to request and view screen
              recordings right from your Help Scout Mailbox
            </Typography>
          </SpaceBottom3>

          <SpaceBottom3>
            <Typography variant="lightSubtitle" color="dark.main">
              <Typography variant="boldH4">1.</Typography> From your Help Scout
              account, click on <b>Manage</b> from the navagation bar, then{" "}
              <b>Apps</b> in the dropdown Scroll to the bottom of the page and
              select <b>Build a Custom App</b>
            </Typography>
          </SpaceBottom3>

          <video
            id="videoEmbedVideoDemo"
            playsinline
            preload="metadata"
            muted
            controls
            poster={hs1_img}
            width="auto"
          >
            <source src={hs1_mp4} type="video/mp4" />
          </video>
          <SpaceBottom3></SpaceBottom3>
          <SpaceBottom3></SpaceBottom3>
          <SpaceBottom3>
            <Typography variant="lightSubtitle" color="dark.main">
              <Typography variant="boldH4">2.</Typography> Click on the{" "}
              <b>Create App </b>button. On the form, add "Screenjar" as the{" "}
              <b>App Name</b>, then for the <b>Content Type</b> dropdown field,
              select <b>Dynamic Content</b>
            </Typography>
          </SpaceBottom3>
          <video
            id="videoEmbedVideoDemo"
            playsinline
            preload="metadata"
            muted
            controls
            poster={hs2_img}
            width="auto"
          >
            <source src={hs2_mp4} type="video/mp4" />
          </video>
          <SpaceBottom3></SpaceBottom3>
          <SpaceBottom3></SpaceBottom3>
          <SpaceBottom3>
            <Typography variant="lightSubtitle" color="dark.main">
              <br />
              <Typography variant="boldH4">3.</Typography>{" "}
              <span style={{ color: "red" }}>
                Keep the following details private
              </span>{" "}
              - treat them as you would a password (don't share them with anyone
              you don't trust)
              <br />
              <br />
              Copy this and paste it into the <b>Callback Url </b>field in the
              Help Scout form:
              <br />
              <br />
              <TextField
                id="outlined-basic-url"
                variant="outlined"
                value={getMetaTag(META_TAG_BASE_URL) + data.callback_url}
                fullWidth
                InputProps={{
                  readOnly: true,
                  endAdornment: (
                    <InputAdornment position="end">
                      <Tooltip TransitionComponent={Zoom} title="Copy">
                        <Box
                          onClick={() =>
                            handleCopyLinkClick(
                              dispatch,
                              getMetaTag(META_TAG_BASE_URL) + data.callback_url
                            )
                          }
                        >
                          <CopyIcon />
                        </Box>
                      </Tooltip>
                    </InputAdornment>
                  ),
                }}
              />
              <br />
              <br />
              Then copy this and paste it into the <b>Secret Key</b> field in
              the Help Scout form:
              <br />
              <br />
              <TextField
                id="outlined-basic-key"
                fullWidth
                variant="outlined"
                value={data.secret_key}
                InputProps={{
                  readOnly: true,
                  endAdornment: (
                    <InputAdornment position="end">
                      <Tooltip TransitionComponent={Zoom} title="Copy">
                        <Box
                          onClick={() =>
                            handleCopyLinkClick(dispatch, data.secret_key)
                          }
                        >
                          <CopyIcon />
                        </Box>
                      </Tooltip>
                    </InputAdornment>
                  ),
                }}
              />
            </Typography>
          </SpaceBottom3>
          <img src={hs3_img} alt="Helpscout integration" />
          <SpaceBottom3></SpaceBottom3>
          <SpaceBottom3></SpaceBottom3>
          <SpaceBottom3>
            <Typography variant="lightSubtitle" color="dark.main">
              <Typography variant="boldH4">4.</Typography> You should now see a
              Screenjar widget in the sidebar on a Help Scout ticket
            </Typography>
          </SpaceBottom3>
          <img src={hs4_img} alt="Helpscout integration" />
          <SpaceBottom3></SpaceBottom3>
          <SpaceBottom3></SpaceBottom3>
          <SpaceBottom3>
            When you have notifications enabled, an email will be sent to your
            Help Scout Mailbox email address (e.g.
            support@yourcompany.helpscoutapp.com) whenever a video is submitted
            using a Screenjar link that's linked to this integration.
          </SpaceBottom3>

          {data.notifications ? (
            <SpaceBottom3>
              <SpaceBottom3>Notification are currently enabled</SpaceBottom3>

              <Button
                size="large"
                type="submit"
                variant="contained"
                onClick={handleSubmit}
                style={{ backgroundColor: "grey" }}
              >
                <Typography variant="boldBody">
                  Disable notifications
                </Typography>
              </Button>
            </SpaceBottom3>
          ) : (
            <SpaceBottom3>
              <SpaceBottom3>Notification are currently disabled</SpaceBottom3>
              <Button
                size="large"
                type="submit"
                variant="contained"
                onClick={handleSubmit}
              >
                <Typography variant="boldBody">Enable notifications</Typography>
              </Button>
            </SpaceBottom3>
          )}
          <SpaceBottom3></SpaceBottom3>
          <SpaceBottom3>
            If you have any issues connecting this integration or you have any
            requests, feedback, or questions, please get in touch{" "}
            <a href="/app/contact">here</a> or by emailing me at
            screenjar@amoeboids.com
          </SpaceBottom3>
        </HelpFullSection>
      </IntegrationWrapper>
    </>
  );
};

export default HelpScout;
