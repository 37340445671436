import { Stack, Typography } from '@mui/material'
import React from 'react'
import { SpaceBottom3 } from '../../../shared_modules/common/StyledClass'
import { HelpFullSection, HelpScoutImg, InstructionTitle, IntegrationWrapper } from './SettingStyle'

import freshchat1 from '../../../images/freshchat1.png';
import freshchat2 from '../../../images/freshchat2.png';
import freshchat3 from '../../../images/freshchat3.png';
import freshchat4 from '../../../images/freshchat4.png';
import freshchat5 from '../../../images/freshchat5.png';
import freshchat6 from '../../../images/freshchat6.png';
import freshchat7 from '../../../images/freshchat7.png';
import freshchat8 from '../../../images/freshchat8.png';

import {
    SpaceBottom2,
    SpaceTop1,
    SpaceTop2,
    SpaceTop3,
} from '../../../shared_modules/common/StyledClass';

import freshchat from '../../../images/Icon/freshchat.png'

const Freshchat = () => {
    return (
        <>
            <IntegrationWrapper>
                <HelpFullSection>
                    <Stack direction="row" spacing={1.25}>
                        <HelpScoutImg component="img" src={freshchat} alt="" style={{ width: '50%' }} />
                    </Stack>

                    <InstructionTitle>
                        <Typography variant="boldH4">
                            Follow these instructions
                        </Typography>
                    </InstructionTitle>
                    <SpaceBottom3>
                        <Typography variant="lightCallout">
                            Install Screenjar from Freshchat app marketplace
                        </Typography>
                    </SpaceBottom3>

                    <SpaceBottom3>
                        <Typography variant="lightSubtitle" color="dark.main">
                            <Typography variant="boldH4">1. </Typography>
                            <b>
                                Install the Screenjar - Screen Recorder app from the marketplace.
                            </b>
                        </Typography>
                    </SpaceBottom3>
                    <SpaceBottom3>
                        <Typography variant="lightSubtitle" color="dark.main">
                            Go to <a target="_blank" href='https://www.freshworks.com/apps/freshchat/sscreenjar_-_screen_recorder/' rel="noreferrer"><b> https://www.freshworks.com/apps/freshchat/sscreenjar_-_screen_recorder/</b></a> and install screenjar.
                        </Typography>
                    </SpaceBottom3>


                    <img src={freshchat1} alt="freshchat integration" />
                    <SpaceBottom3></SpaceBottom3>
                    <SpaceBottom3></SpaceBottom3>
                    <SpaceBottom3></SpaceBottom3>

                    <SpaceBottom3>
                        <Typography variant="lightSubtitle" color="dark.main">
                            <Typography variant="boldH4">2.</Typography> <b>Configure settings</b>
                        </Typography>
                    </SpaceBottom3>
                    <SpaceBottom3>
                        <Typography variant="lightSubtitle" color="dark.main">
                            You need to provide API key and domain name. Go to your Freshchat admin settings and copy API key from the API Tokens page.
                        </Typography>
                    </SpaceBottom3>
                    <SpaceBottom3></SpaceBottom3>

                    <img src={freshchat2} alt="freshchat integration" />
                    <SpaceBottom3></SpaceBottom3>
                    <SpaceBottom3></SpaceBottom3>

                    <img src={freshchat3} alt="freshchat integration" />
                    <SpaceBottom3></SpaceBottom3>
                    <SpaceBottom3></SpaceBottom3>
                    <SpaceBottom3></SpaceBottom3>
                    <SpaceBottom3>
                        <Typography variant="lightSubtitle" color="dark.main">
                            Subdomain can be located in section Mobile app for chat. Otherwise, you can copy the subdomain from your Freshchat url as shown in the image.
                        </Typography>
                    </SpaceBottom3>
                    <SpaceBottom3></SpaceBottom3>
                    <img src={freshchat4} alt="freshchat integration" />
                    <SpaceTop3></SpaceTop3>
                    <SpaceTop3></SpaceTop3>
                    <SpaceTop2></SpaceTop2>
                    <SpaceBottom3>
                        <Typography variant="lightSubtitle" color="dark.main">
                            <Typography variant="boldH4">3.</Typography> <b>Send request for recording screen to customer</b>
                        </Typography>
                    </SpaceBottom3>
                    <SpaceBottom3>
                        <Typography variant="lightSubtitle" color="dark.main">
                            Open your Freshchat inbox and click on the "Send recording request" button in the left apps bar section of the ticket screen.
                        </Typography>
                    </SpaceBottom3>
                    <SpaceBottom3></SpaceBottom3>
                    <img src={freshchat5} alt="freshchat integration" />
                    <SpaceTop3></SpaceTop3>
                    <SpaceTop3></SpaceTop3>
                    <SpaceTop2></SpaceTop2>
                    <SpaceBottom3>
                        <Typography variant="lightSubtitle" color="dark.main">
                            <Typography variant="boldH4">4.</Typography> <b>Customer records screen and send recording</b>
                        </Typography>
                    </SpaceBottom3>
                    <SpaceBottom3>
                        <Typography variant="lightSubtitle" color="dark.main">
                            Your customer will receive a link to begin the screen recording process in the messenger.
                        </Typography>
                    </SpaceBottom3>
                    <SpaceTop2></SpaceTop2>
                    <img src={freshchat6} alt="freshchat integration" width={500} />
                    <SpaceTop3></SpaceTop3>
                    <SpaceTop3></SpaceTop3>
                    <SpaceTop2></SpaceTop2>
                    <SpaceBottom3>
                        <Typography variant="lightSubtitle" color="dark.main">
                            The customer will be prompted to give permission to record their screen and microphone (if applicable). Once the recording is complete, the customer can review it and click the "send" button to share the recording with support.
                        </Typography>
                    </SpaceBottom3>
                    <SpaceTop2></SpaceTop2>
                    <SpaceTop1></SpaceTop1>
                    <img src={freshchat7} alt="freshchat integration" />
                    <SpaceBottom3></SpaceBottom3>
                    <SpaceBottom3></SpaceBottom3>
                    <SpaceBottom3>
                        <Typography variant="lightSubtitle" color="dark.main">
                            <Typography variant="boldH4">5.</Typography> <b>You will receive the recording</b>
                        </Typography>
                    </SpaceBottom3>
                    <SpaceBottom3>
                        <Typography variant="lightSubtitle" color="dark.main">
                            You will receive the recorded video in the same ticket as highlighted below.
                        </Typography>
                    </SpaceBottom3>
                    <img src={freshchat8} alt="freshchat integration" />
                </HelpFullSection>
            </IntegrationWrapper>
        </>
    )
}

export default Freshchat