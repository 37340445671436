import axiosInstance from "../../common/axios";

export function UpdateProfileAPI(form_data) {

    return axiosInstance.post(`api/profile-update/`, form_data, {
        headers: {
            'content-type': 'multipart/form-data'
        }
    });
}
export function ProfileAPI() {
    return axiosInstance.get(`api/profile-view/`);
}

export function ResetPasswordAPI(emailid) {
    return axiosInstance.post('api/reset-password/', emailid);
}

export function ResetPasswordConfirmView(token, uid, new_password1, new_password2) {
    return axiosInstance.post(`api/pass-reset/${token}/${uid}`,
        { token, uid, new_password1, new_password2 });
}

export function ProfileLink() {
    return axiosInstance.get(`api/user-profile-link`);
}
