import { createSlice } from "@reduxjs/toolkit";
import { CompanyViewAPI } from "./CompanyAPI";

const initialState = {
  company: {
    name: "",
    website: "",
    logo: "",
    banner: false,
  },
};

export const companySlice = createSlice({
  name: "company",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    getCompanyDetailsSuccess: (state, { payload }) => {
      state.company = payload;
    },
  },
});

export const getCompanyDetails = () => {
  // the inside "thunk function"
  return async (dispatch) => {
    try {
      const response = await CompanyViewAPI();
      if (dispatch(getCompanyDetailsSuccess(response.data.company))) {
      }
    } catch (err) {
      // If something went wrong, handle it here
    }
  };
};

export const selectCompanyState = (state) => state.company;
export const { getCompanyDetailsSuccess } = companySlice.actions;

export default companySlice.reducer;
