import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { PageTitle } from "../../shared_modules/common/StyledClass";
import { Wrapper } from "../../shared_modules/common/Wrapper";
import CheckoutForm from "./components/CheckoutForm";
import { getPlanByIdAPI } from "./PaymentAPI";
import { META_TAG_STRIPE_KEY, getMetaTag } from "../../common/MetaTagReader";
// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.

const stripePromise = loadStripe(
  getMetaTag(META_TAG_STRIPE_KEY)
);

const Payment = () => {
  const [planDetails, setPlanDetails] = useState("");
  const [countries, setCountries] = useState({});
  const { planId } = useParams();

  useEffect(() => {
    const getPlanById = async () => {
      const res = await getPlanByIdAPI(planId);
      if (res.data) {
        setPlanDetails(res.data.plan);
        const countriesArray = Object.keys(res.data.countries).map((key) => (
          {
            label: res.data.countries[key],
            value: key
          }
        ))
        setCountries(countriesArray)
      }
    };
    if (planId) {
      getPlanById();
    }
  }, [planId]);
  return (
    <>
      <Wrapper>
        <PageTitle>Payment</PageTitle>
        <Elements stripe={stripePromise}>
          <CheckoutForm planDetails={planDetails} countries={countries} />
        </Elements>
      </Wrapper>
    </>
  );
};

export default Payment;
