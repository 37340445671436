import { Stack, Typography } from '@mui/material';
import React from 'react';
import Intercom3 from '../../../images/Intercom3.png';
import Intercom7 from '../../../images/Intercom7.png';
import Intercom21 from '../../../images/Intercom21.png';
import Intercom22 from '../../../images/Intercom22.png';
import Intercom23 from '../../../images/Intercom23.png';
import Intercom24 from '../../../images/Intercom24.png';
import Intercom25 from '../../../images/Intercom25.png';
import Intercom26 from '../../../images/Intercom26.png';
import {
    SpaceBottom2,
    SpaceBottom3,
    SpaceTop1,
    SpaceTop2,
    SpaceTop3,
} from '../../../shared_modules/common/StyledClass';
import {
    HelpFullSection,
    HelpScoutImg,
    InstructionTitle,
    IntegrationWrapper,
} from './SettingStyle';
import IntercomLogoWithText from '../../../images/Icon/IntercomLogoWithName.png'

export default function Zendesk() {
    return (
        <>
            <IntegrationWrapper>
                <HelpFullSection>
                    <Stack direction="row" spacing={1.25}>
                        <HelpScoutImg component="img" src={IntercomLogoWithText} alt="" style={{ width: '50%' }} />
                    </Stack>

                    <InstructionTitle>
                        <Typography variant="boldH4">
                            Follow these instructions
                        </Typography>
                    </InstructionTitle>
                    <SpaceBottom3>
                        <Typography variant="lightCallout">
                            Install Screenjar from Intercom app marketplace
                        </Typography>
                    </SpaceBottom3>

                    <SpaceBottom3>
                        <Typography variant="lightSubtitle" color="dark.main">
                            <Typography variant="boldH4">1.</Typography> <b>Install Screenjar from Intercom app marketplace</b>
                        </Typography>
                    </SpaceBottom3>
                    <SpaceBottom3>
                        <Typography variant="lightSubtitle" color="dark.main">
                            Go to the Intercom marketplace at <a target="_blank" href='https://app.intercom.com/a/apps/zegplx3p/appstore?app_package_code=screenjar-screen-recorder'><b>https://app.intercom.com/a/apps/zegplx3p/appstore?app_package_code=screenjar-screen-recorder</b></a> and install screenjar.
                        </Typography>
                    </SpaceBottom3>

                    <img src={Intercom21} alt="Helpscout integration" />
                    <SpaceBottom3></SpaceBottom3>
                    <Typography variant="lightSubtitle" color="dark.main">
                        Once installed, you will be taken to the authorization page. Please click on “Allow”
                    </Typography>
                    <SpaceBottom3></SpaceBottom3>
                    <SpaceBottom3>
                        <Typography variant="lightSubtitle" color="dark.main">
                            After authorization, a new window / tab will appear asking you to login into the Screenjar account. If you already have an account with Screenjar, enter your email and password else create a new account
                        </Typography>
                    </SpaceBottom3>
                    <img src={Intercom3} alt="Helpscout integration" />
                    <SpaceBottom3></SpaceBottom3>
                    <SpaceBottom3>
                        <Typography variant="lightSubtitle" color="dark.main">
                            After this, screenjar will appear in your installed apps section.
                        </Typography>
                    </SpaceBottom3>
                    <SpaceBottom3></SpaceBottom3>
                    <SpaceBottom3>
                        <Typography variant="lightSubtitle" color="dark.main">
                            <Typography variant="boldH4">2.</Typography> <b>Send recording request to customer</b>
                        </Typography>
                    </SpaceBottom3>

                    <SpaceBottom3>
                        <Typography variant="lightSubtitle" color="dark.main">
                            Go to your inbox. The Screenjar screen recorder will appear in the left apps bar section on the tickets screen. Click on “Send recording request” to send a recording request to the customer. You can also use shortcut button below comment box (highlighted below) to send recording request
                        </Typography>
                    </SpaceBottom3>
                    <img src={Intercom22} alt="Helpscout integration" />
                    <SpaceBottom3></SpaceBottom3>
                    <SpaceBottom3>
                        <Typography variant="lightSubtitle" color="dark.main">
                            Once you click on the "New Recording Request" button a link will appear in the
                            message box. Hit send to send it to the customer.
                        </Typography>
                    </SpaceBottom3>
                    <img src={Intercom7} alt="Helpscout integration" />
                    <SpaceBottom3></SpaceBottom3>
                    <SpaceBottom3></SpaceBottom3>
                    <SpaceBottom3></SpaceBottom3>
                    <SpaceBottom3>
                        <Typography variant="lightSubtitle" color="dark.main">
                            <Typography variant="boldH4">3.</Typography> <b>Customer records screen</b>
                        </Typography>
                    </SpaceBottom3>
                    <Typography variant="lightSubtitle" color="dark.main">
                        Customers will receive this link in messenger.
                    </Typography>
                    <SpaceBottom3></SpaceBottom3>
                    <img src={Intercom25} alt="Helpscout integration" />
                    <SpaceTop3></SpaceTop3>
                    <Typography variant="lightSubtitle" color="dark.main">
                        Recording screen will appear as customer clicks on recording link. Customer needs to give permission to record screen and use microphone if required. Once recording is complete. Customer can review the recording and click on send button to share recording with support
                    </Typography>
                    <SpaceTop1></SpaceTop1>
                    <img src={Intercom26} alt="Helpscout integration" />
                    <SpaceTop3></SpaceTop3>
                    <SpaceTop3></SpaceTop3>
                    <SpaceTop2></SpaceTop2>
                    <SpaceBottom3>
                        <Typography variant="lightSubtitle" color="dark.main">
                            <Typography variant="boldH4">4.</Typography> <b>Watch recorded video</b>
                        </Typography>
                    </SpaceBottom3>
                    <Typography variant="lightSubtitle" color="dark.main">
                        You will receive the recorded video in the same ticket as highlighted below.
                    </Typography>
                    <SpaceTop2></SpaceTop2>
                    <img src={Intercom23} alt="Helpscout integration" />
                    <SpaceTop3></SpaceTop3>
                    <SpaceTop3></SpaceTop3>
                    <SpaceTop2></SpaceTop2>
                    <SpaceBottom2>
                        <Typography variant="lightSubtitle" color="dark.main">
                            <Typography variant="boldH4">5.</Typography> <b>You can also add Screenjar app to messenger</b>
                        </Typography>
                    </SpaceBottom2>
                    <Typography variant="lightSubtitle" color="dark.main">
                        Go to Messenger &gt; Add apps to your Messenger and click on “Add an app” button as
                        highlighted below and select Screenjar. This way there is no need to share link, user can
                        directly record a video and create a new ticket.
                    </Typography>
                    <SpaceTop2></SpaceTop2>
                    <SpaceTop1></SpaceTop1>
                    <img src={Intercom24} alt="Helpscout integration" />
                </HelpFullSection>
            </IntegrationWrapper>
        </>
    )
}
