import PaginationItem from "@mui/material/PaginationItem";
import { styled } from "@mui/material/styles";
import { Box } from "@mui/material";

export const PaginationItemStyle = styled(PaginationItem)(({ theme }) => ({
  color: theme.palette.grey.main,
  ...theme.typography.boldSubhead,
}));

export const NoVideoBox = styled(Box)(({ theme }) => ({
  margin: theme.spacing(5, "auto"),
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

export const VideoBoxWrapper = styled(Box)(({ theme }) => ({
  padding: theme.spacing(5),
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  textAlign: "center",
  gap: theme.spacing(4),
}));
