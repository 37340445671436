import { AppBar, Box, IconButton, Stack, Toolbar } from "@mui/material";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import { ReactComponent as SideMenuIcon } from "../../images/Icon/SideMenu.svg";
import MyAccount from "../header/MyAccount";
import TrialExpiry from "../header/TrialExpiry";
import Notification from "../header/Notification";
// import Searchbar from '../header/Searchbar';

// ----------------------------------------------------------------------

const DRAWER_WIDTH = 256;
const APPBAR_MOBILE = 64;
const APPBAR_DESKTOP = 80;

const RootStyle = styled(AppBar)(({ theme }) => ({
  boxShadow: "none",
  backgroundColor: "#fff",
  top: "auto",
  // position: "relative",

  [theme.breakpoints.up("lg")]: {
    width: `calc(100% - ${DRAWER_WIDTH + 1}px)`,
  },
}));

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  minHeight: APPBAR_MOBILE,

  [theme.breakpoints.up("lg")]: {
    minHeight: APPBAR_DESKTOP,
    padding: theme.spacing(0, 5),
  },
}));

const SideMenu = styled(SideMenuIcon)(({ theme }) => ({
  width: 22,
  height: 22,
}));

// ----------------------------------------------------------------------

Navbar.propTypes = {
  onOpenSidebar: PropTypes.func,
};

export default function Navbar({ onOpenSidebar }) {
  return (
    <>
      <RootStyle>
        {/* <AlertHeader /> */}
        <TrialExpiry />
        <ToolbarStyle sx={{ borderBottom: "1px solid rgba(0, 0, 0, .10)" }}>
          <IconButton
            onClick={onOpenSidebar}
            sx={{
              mr: 1,
              display: { lg: "none" },
            }}
          >
            <SideMenu />
          </IconButton>

          {/* <Searchbar /> */}

          <Box sx={{ flexGrow: 1 }} />

          <Stack
            direction="row"
            alignItems="center"
            sx={{
              mr: {
                lg: 10,
              },
            }}
            spacing={{ xs: 0.5, sm: 2.5 }}
          >
            <Notification />
            <MyAccount />
          </Stack>
        </ToolbarStyle>
      </RootStyle>
    </>
  );
}
