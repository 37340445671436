import { IconButton } from '@mui/material';
import { styled } from '@mui/system';
import React from 'react';
import { ReactComponent as DeleteIconSvg } from '../../images/Icon/trash.svg';

const DeleteIconBtn = styled(DeleteIconSvg)(({ theme }) => ({
  width: 16,
  height: 16,
  path: {
    fill: theme.palette.danger.main,
  },
}));

const DeleteButton = styled(IconButton)(({ theme }) => ({
  boxShadow: 'none',
  padding: '8px',

  display: 'flex',
  borderRadius: '8px',
  lineHight: 0,
  backgroundColor: theme.palette.danger.trans5,
  '&:hover': {
    backgroundColor: theme.palette.danger.trans5,
    boxShadow: 'none',
  },
}));

const DeleteIcon = (props) => {
  return (
    <>
      <DeleteButton onClick={props.onClick}>
        <DeleteIconBtn />
      </DeleteButton>
    </>
  );
};

export default DeleteIcon;
