import { Box, Grid, Stack, Typography } from "@mui/material";
import Pagination from "@mui/material/Pagination";
import PaginationItem from "@mui/material/PaginationItem";
import { styled } from "@mui/material/styles";
import { Component } from "react";
import { Link } from "react-router-dom";
import InboxSort from "./InboxSort";
import {
  STATE_FAIL,
  STATE_LOADING,
  STATE_SUCCESS,
} from "../../../common/Constants";
import Loader from "../../../shared_modules/common/Loader";
import { StyledCardMd } from "../../../shared_modules/common/StyledCard";
import { getQueryParameters } from "../../../shared_modules/common/Utils";
import { Wrapper } from "../../../shared_modules/common/Wrapper";
import InboxCard from "../../../shared_modules/inbox_card/InboxCard";

// ----------------------------------------------------------------------

const PaginationItemStyle = styled(PaginationItem)(({ theme }) => ({
  color: theme.palette.grey.main,
  ...theme.typography.boldSubhead,
}));

const NoVideoBox = styled(Box)(({ theme }) => ({
  margin: theme.spacing(5, "auto"),
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const VideoBoxWrapper = styled(Box)(({ theme }) => ({
  padding: theme.spacing(5),
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  textAlign: "center",
  gap: theme.spacing(4),
}));

const LinkItem = styled(Link)(({ theme }) => ({
  color: theme.palette.primary.main,
  fontWeight: 700,
  cursor: "pointer",
  // textDecoration: "none",
}));

//
export class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sortType: "recent",
      SORT_OPTIONS: [
        { value: "recent", label: "Recent" },
        { value: "oldest", label: "Oldest" },
      ],
    };
    this.handleSort = this.handleSort.bind(this);
  }

  loadPageData = (pageNumber, sorttype) => {
    this.props.fetchVideos(pageNumber, sorttype);
  };
  handlePaginationChange = (event, value) => {
    this.loadPageData(value, this.state.sortType);
  };

  handleSort(e) {
    this.setState({ sortType: e.target.value });
    this.loadPageData("1", e.target.value);
  }

  componentDidMount() {
    const queryParams = getQueryParameters(window.location.search);
    const pageNumber = parseInt(queryParams["page"] || "1", 10);
    const sorttype = this.state.sortType;
    this.loadPageData(pageNumber, sorttype);
  }

  render() {
    const { status, videoRequests } = this.props.videoListPage;
    const SORT_OPTIONS = this.state.SORT_OPTIONS;

    return (
      <>
        <Wrapper>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            mb={2.25}
            gap={2}
          >
            <Grid>
              <Typography marginRight={2} variant="boldH4" gutterBottom>
                Inbox
              </Typography>
              <LinkItem to="/app/link-history">
                <Typography variant="boldCallout">View link history</Typography>
              </LinkItem>
            </Grid>
            <InboxSort options={SORT_OPTIONS} handleSort={this.handleSort} />
          </Stack>

          <Grid container spacing={3.75}>
            {status === STATE_LOADING && (
              <div>
                <Loader />
              </div>
            )}

            {status === STATE_FAIL && (
              <div>
                Something went wrong. Please refresh page or try after some time
              </div>
            )}
            {status === STATE_SUCCESS &&
              videoRequests.results.length === 0 &&
              showEmptyState()}
            {status === STATE_SUCCESS &&
              videoRequests.results.map(
                (request) =>
                  request.first_recording != null && (
                    <InboxCard
                      key={request.id}
                      item={request.first_recording}
                      videoCount={request.video_count}
                      screenshotCount={request.screenshot_count}
                      deleteVideo={this.props.deleteVideo}
                      params={true}
                      pageNumber={videoRequests.pageNumber}
                    />
                  )
              )}
          </Grid>

          <Stack
            direction="row"
            alignItems="center"
            justifyContent="center"
            style={{ marginTop: "40px" }}
          >
            {status === STATE_SUCCESS && videoRequests.pages > 1 && (
              // <Pagination count={10} defaultPage={3} hidePrevButton hideNextButton />
              <Pagination
                color="secondary"
                size="large"
                page={videoRequests.pageNumber}
                count={videoRequests.pages}
                renderItem={(item) => (
                  <PaginationItemStyle
                    component={Link}
                    to={`/app/inbox${item.page === 1 ? "" : `?page=${item.page}`
                      }`}
                    {...item}
                  />
                )}
                onChange={this.handlePaginationChange}
              />
            )}
          </Stack>

          {/*  */}
          {/* */}
        </Wrapper>
      </>
    );
  }
}

const showEmptyState = () => {
  return (
    <NoVideoBox>
      <StyledCardMd>
        <VideoBoxWrapper>
          <Typography variant="boldH2">
            No videos or images uploaded yet !!
          </Typography>
          <Typography variant="lightSubTitle">
            Click on "Record video" to generate a new screen recording request
            link and send it to customer.
          </Typography>
        </VideoBoxWrapper>
      </StyledCardMd>
    </NoVideoBox>
  );
};
