import { useTheme } from "@emotion/react";
import Dialog from "@mui/material/Dialog";
import Typography from "@mui/material/Typography";
import React, { useState } from "react";
import { FlexyCenterItem } from "../../../shared_modules/common/StyledClass";
import Textarea from "../../../shared_modules/common/Textarea";
import { generateLinkApi } from "../RecordAPI";
import {
  BackButton,
  ButtonWrapper,
  CancelButton,
  ContentSection,
  CustomDialogContent,
  CustomDialogTitle,
  DialogButton,
  LinkNoUnderline,
  RequestButton,
  SaveIcon,
  SaveItem,
  StyledNoteBox,
  TextareaWrapper,
} from "./RecordStyle";
import { selectSessionState } from "../../../shared_modules/layout/SessionSlice";
import { useSelector } from "react-redux";
import { Wrapper } from "../../../shared_modules/common/Wrapper";
import { Button, Stack } from "@mui/material";
import { Link } from "react-router-dom";

function generateLinkUi(
  handleChange,
  handleClose,
  generateLink,
  isInProgress,
  formData
) {
  const chromeUrl =
    "https://chrome.google.com/webstore/detail/screenjar/eannaomdbmcakllbmhhaiaekahjdhffa";
  return (
    <>
      <CustomDialogTitle id="responsive-dialog-title">
        <Typography variant="boldH4">
          Request a new screen recording by generating a new request link and
          sending it to customer
        </Typography>
      </CustomDialogTitle>

      <CustomDialogContent>
        <LinkNoUnderline
          href="/app/link-history"
          target="_blank"
          rel="noopener noreferrer"
          onClick={() => window.open("/app/link-history")}
        >
          <SaveItem id="modal-modal-description" variant="boldCallout">
            View past request links history
          </SaveItem>
        </LinkNoUnderline>
        <FlexyCenterItem>
          <LinkNoUnderline
            href={chromeUrl}
            target="_blank"
            rel="noopener noreferrer"
            onClick={() => window.open(chromeUrl)}
          >
            <SaveItem id="modal-modal-description" variant="boldCallout">
              Save time with the Chrome extension
            </SaveItem>
          </LinkNoUnderline>
          <span onClick={() => window.open(chromeUrl)}>
            <SaveIcon />
          </span>
        </FlexyCenterItem>
        <TextareaWrapper>
          <Textarea
            placeholder="Optional private note to keep track of where you send request links (customers don't see this)"
            onChange={handleChange}
            name="note"
            value={formData.note}
          />
        </TextareaWrapper>

        <ButtonWrapper direction={{ xs: "column", sm: "row" }} spacing={3}>
          <RequestButton
            size="large"
            variant="contained"
            type="submit"
            disabled={isInProgress}
            onClick={generateLink}
          >
            <Typography variant="boldBody">
              Request a new screen recording
            </Typography>
          </RequestButton>

          <CancelButton size="large" variant="contained" onClick={handleClose}>
            <Typography variant="boldBody">Cancel</Typography>
          </CancelButton>
        </ButtonWrapper>
      </CustomDialogContent>
    </>
  );
}

function successLinkUi(handleClose, handleOpen, linkData) {
  navigator.clipboard.writeText(linkData.record_link);
  return (
    <>
      <CustomDialogTitle id="responsive-dialog-title">
        <Typography variant="boldH4">
          Your screen recording request link has been created & copied to your
          clipboard
        </Typography>
      </CustomDialogTitle>

      <CustomDialogContent>
        Share it with someone and videos they record with it will appear in your
        inbox automatically.
        <ContentSection>
          <a
            href={linkData.record_link}
            onClick={() => window.open(linkData.preview_link)}
          >
            {linkData.record_link}
          </a>
          {/* ({' '} */}
          {/* <a href={linkData.preview_link} >preview</a> ) */}
        </ContentSection>
        {linkData.note && <StyledNoteBox>{linkData.note}</StyledNoteBox>}
        <ButtonWrapper direction={{ xs: "column", sm: "row" }} spacing={3}>
          <BackButton
            size="large"
            variant="contained"
            type="submit"
            onClick={handleOpen}
          >
            <Typography variant="boldBody">
              Go back, Request another link
            </Typography>
          </BackButton>

          <CancelButton size="large" variant="contained" onClick={handleClose}>
            <Typography variant="boldBody">Cancel</Typography>
          </CancelButton>
        </ButtonWrapper>
      </CustomDialogContent>
    </>
  );
}
export default function Record({ listItem }) {
  var isAlert = false;
  const { user } = useSelector(selectSessionState);

  const statuses = {
    link_not_generated: 0,
    link_in_progress: 1,
    link_generated: 2,
  };

  const initialFormData = Object.freeze({
    note: "",
  });
  const [open, setOpen] = React.useState(false);
  const [formData, updateFormData] = useState(initialFormData);
  const [status, setStatus] = useState(statuses.link_not_generated);
  const [linkData, setLinkData] = useState();

  const handleOpen = () => {
    setStatus(statuses.link_not_generated);
    setOpen(true);
  };
  const handleClose = () => setOpen(false);
  const theme = useTheme();

  const handleChange = (e) => {
    updateFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };
  const generateLink = (e) => {
    e.preventDefault();
    setStatus(statuses.link_in_progress);
    generateLinkApi(formData.note).then((res) => {
      setLinkData(res.data);
      setStatus(statuses.link_generated);
    });
  };
  const isInProgress = status === statuses.link_in_progress;

  if (!user.has_active_subscription) {
    if (user.trial_days_remaining < 1) {
      isAlert = true;
    }
  }

  return (
    <>
      <DialogButton onClick={handleOpen}>{listItem}</DialogButton>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
        PaperProps={{
          sx: {
            bgcolor: theme.palette.common.white,
            borderRadius: theme.spacing(5),
          },
        }}
      >
        {isAlert ? (
          <Wrapper>
            <Stack direction="column" alignItems="center">
              <Typography variant="boldH4">Free trial expired</Typography>
              <Typography alignItems="center" margin={2}>
                Please{" "}
                <strong>
                  <Link
                    to="/app/settings/billing"
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={() => window.open("/app/settings/billing")}
                  >
                    upgrade
                  </Link>
                </strong>{" "}
                to continue using Screenjar Or{" "}
                <Link
                  to="/app/contact"
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={() => window.open("/app/contact")}
                >
                  contact us
                </Link>{" "}
                for any questions.
              </Typography>
              <ButtonWrapper alignItems="center">
                <Button
                  size="large"
                  variant="contained"
                  style={{ width: "100%" }}
                  onClick={handleClose}
                >
                  <Typography variant="boldBody">Ok</Typography>
                </Button>
              </ButtonWrapper>
            </Stack>
          </Wrapper>
        ) : (
          <>
            {status !== statuses.link_generated &&
              generateLinkUi(
                handleChange,
                handleClose,
                generateLink,
                isInProgress,
                formData
              )}
            {status === statuses.link_generated &&
              successLinkUi(handleClose, handleOpen, linkData)}
          </>
        )}
      </Dialog>
    </>
  );
}
