import React, { useState } from 'react';
import axiosInstance, { axiosPlainInstance } from '../../common/axios';

import { useNavigate } from 'react-router-dom';
import { Stack, TextField, Button, Box, Typography } from '@mui/material';
import {
	LeftSectionStyle,
	ContentStyle,
	RootStyle,
	FormStyle,
	LogoBox,
	FromWrapper,
	SubTitle,
	FormInfoBox,
	StyledTypography,
	StyledLink,
	MainTitle,
} from '../sign-up/components/SignUpStyle';
import { Link as RouterLink } from 'react-router-dom';
import Logo from '../../images/LogoWhite.svg';
import { sendTokenToChromeExtension } from '../../common/chromeExtension';
import { zendeskAuth,intercomAuth,freshchatAuth } from './LoginUtils';

export function Login() {
	const search = window.location.search;
	const params = new URLSearchParams(search);

	const source = params.get('source');

	const source_data = params.get('source_z');
	let API_PATH = `api/token/`;
	let CREATE_USER_REDIRECT = `/app/signup`;
	let FORGET_USER_PASSWORD = `/app/send-pwd-link`;

	var link = params.get('redirect');
	const navigate = useNavigate();
	const initialFormData ={
		email: '',
		password: '',
	};
	let source_z = "";
	let origin = "";
	let intercom_email = "";

	const [formData, updateFormData] = useState(initialFormData);
	const [errorMessage, setErrorMessage] = useState('');
	const [emailErr, setEmailErr] = useState('');
	const [passwordErr, setPasswordErr] = useState('');

	var isExtension = false;
	if (source === 'extension') {
		isExtension = true;
		link = '/app/chrome_logged_in';
	}


	let API_PARAMS = {
		email: formData.email,
		password: formData.password,
	};

	if (source_data) {
		const source_arr = source_data.split(";");
		source_z = source_arr[0];
		origin = source_arr[1].split("=")[1];
		if (source_z === 'zendesk' || source_z === 'intercom' || source_z === 'freshchat') {
			CREATE_USER_REDIRECT = CREATE_USER_REDIRECT + `?source_z=` + source_data;
			FORGET_USER_PASSWORD =  FORGET_USER_PASSWORD + `?source_z=`+ source_data;
		}

		if (source_z === 'intercom') {
			formData.email=source_arr[2].split("=")[1];
		}
		
		API_PARAMS = {
			email: formData.email,
			password: formData.password,
			source: source_z,
			origin: origin,
		};

	}

	const handleChange = (e) => {
		setEmailErr('');
		setPasswordErr('');
		setErrorMessage('');
		updateFormData({
			...formData,
			[e.target.name]: e.target.value.trim(),
		});
	};


	const handleSubmit = (e) => {
		e.preventDefault();
		axiosPlainInstance
			.post(API_PATH, API_PARAMS)
			.then((res) => {
				if (isExtension) {
					try { sendTokenToChromeExtension({ jwt: res.data }) }
					catch (err) {
						console.log(err);
					}


				}
				localStorage.setItem('access_token', res.data.access);
				localStorage.setItem('refresh_token', res.data.refresh);
				axiosInstance.defaults.headers['Authorization'] =
					'JWT ' + localStorage.getItem('access_token');

				if (source_data) {
					if (source_z === 'freshchat') {
						freshchatAuth(origin)
					}
					if (source_z === 'intercom') {
						intercomAuth(origin)
					}
					if (source_z === 'zendesk') {
						zendeskAuth(origin)
					}
					

					
				}
				else {
					if (link) {
						navigate(link);
					} else {
						navigate('/app/inbox');
					}
				}




			})
			.catch((error) => {
				const statusCode = error.response ? error.response.status : null;
				if (statusCode === 401) {
					setErrorMessage('Please enter valid email and password');
				} else if (statusCode === 400) {
					let errorobj = error.response.data;
					if ("email" in errorobj) {
						setEmailErr('Email cannot be empty');
					}
					if ("password" in errorobj) {
						setPasswordErr('Password cannot be empty');
					}
				} else {
					error.handleGlobally && error.handleGlobally();
				}
			});
	};
	return (
		<RootStyle>
			<FromWrapper>
				<Box>
					<LogoBox component="img" alt="register" src={Logo} />
					<LeftSectionStyle>
						<MainTitle variant="boldH3">
							Skip the customer support back-and-forth
						</MainTitle>
						<SubTitle variant="lightSubtitle">
							Make it ridiculously easy for customers to send you
							video recordings of their screen. No download/install required.
						</SubTitle>
					</LeftSectionStyle>
				</Box>

				<ContentStyle>
					<FormStyle component="form" autoComplete="off" onSubmit={''}>
						<Stack spacing={3}>
							<FormInfoBox>
								<StyledTypography variant="boldH2">Sign in</StyledTypography>
								{!isExtension &&
									<StyledTypography variant="lightCallout" mt={2.5}>
										New User? &nbsp;
										<StyledLink
											underline="none"
											variant="boldCallout"
											component={RouterLink}
											to={CREATE_USER_REDIRECT}
										>
											Create an account
										</StyledLink>
									</StyledTypography>
								}
							</FormInfoBox>

							<StyledTypography variant="lightCallout" style={{ color: 'red' }} >
								{errorMessage && <p className="error" > {errorMessage} </p>}
							</StyledTypography>

							<TextField
								variant="filled"
								autoComplete="username"
								type="email"
								label="Email"
								name="email"
								value={formData.email}
								onChange={handleChange}
								error={emailErr}
								helperText={emailErr ? emailErr : ""}
							/>

							<TextField
								variant="filled"
								autoComplete="current-password"
								type="password"
								label="Password"
								name="password"
								onChange={handleChange}
								error={passwordErr}
								helperText={passwordErr ? passwordErr : ""}
							/>

							<Button
								size="large"
								type="submit"
								variant="contained"
								style={{ width: '100%' }}
								onClick={handleSubmit}
							>
								<Typography variant="boldBody" >Sign in</Typography>
							</Button>

							<StyledTypography variant="lightCallout">
								If you need to reset your password, &nbsp;
								<StyledLink
									underline="none"
									variant="boldCallout"
									component={RouterLink}
									to={FORGET_USER_PASSWORD}
								>
									you can do so here.
								</StyledLink>
							</StyledTypography>
						</Stack>
					</FormStyle>
				</ContentStyle>
			</FromWrapper>
		</RootStyle>
	);
}
