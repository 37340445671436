import {
  Avatar,
  Box,
  Button,
  CardContent,
  Typography,
  MenuItem,
  IconButton,
} from "@mui/material";
import { styled } from "@mui/material/styles";

// ----------------------------------------------------------------------

export const CardMediaStyle = styled("div")({
  padding: "8px",
  width: "100%",
  height: "100%",
  textAlign: "center",
  "&:before": {
    content: `''`,
    width: "1px",
    height: "100%",
    display: "inline-block",
    verticalAlign: "middle",
    marginLeft: "-1px",
  },
});

export const CardContentStyle = styled(CardContent)(({ theme }) => ({
  padding: theme.spacing(0.5, 1, 1, 1),
}));

export const DotsButton = styled(IconButton)(({ theme }) => ({
  borderRadius: "8px",
  textTransform: "initial",
  flex: 1,
  padding: "5px 0px 1px 0px",

  backgroundColor: "#fff",
  "&:hover": {
    backgroundColor: theme.palette.primary.trans5,
  },
}));

export const StyledVideo = styled("video")({
  width: "100%",
  height: "100%",
  display: "inline-block",
  verticalAlign: "middle",
  borderRadius: "16px",
});

export const UserAvatar = styled(Avatar)({
  width: "24px",
  height: "24px",
  marginRight: "8px",
});

export const UserName = styled(Typography)(({ theme }) => ({
  color: theme.palette.secondary.main,
  cursor: "pointer",
}));

export const MainWrapper = styled(Box)(({ theme }) => ({
  ...theme.mixins.flexyBetween,
}));

export const InboxText = styled(Typography)({
  opacity: 0.5,
});

export const CollapseBox = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  background: theme.palette.grey.lighter,
  borderRadius: 8,
  padding: 8,
  position: "relative",
  lineHeight: 1.2,
}));

export const ItemBox = styled(Box)(({ theme }) => ({
  background: "#fff",
  borderRadius: 8,
  padding: "8px 10px",
  margin: 0.5,
  position: "relative",
}));

export const MenuItemStyled = styled(MenuItem)(({ theme }) => ({
  padding: theme.spacing(1, 2.5),
}));

export const MenuItemWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItem: "center",
  gap: theme.spacing(2),
  ...theme.typography.boldSubhead,
}));
