import { useTheme } from "@emotion/react";
import { Typography } from "@mui/material";
import React from "react";
import { FlexyCenterItem } from "../../../shared_modules/common/StyledClass";
import UserDetails from "../../../shared_modules/common/UserDetails";
import {
  ActionBox,
  ActivityBox,
  ActivityCard,
  ActivityTimeStamp,
  ActivityTitle,
  BrowserBox,
  CardContentStyle,
  ComputerIcon,
  ContentItem,
  LocationBox,
  LocationIcon,
  RightArrowIcon,
  ActivityItem,
} from "./ActivityStyle";

// ----------------------------------------------------------------------

const Activity = ({ activities, userText }) => {
  const theme = useTheme();

  return (
    <>
      <ActivityCard>
        <CardContentStyle>
          <ContentItem>
            {/* User details */}
            <UserDetails text={userText} noImg />

            <ActionBox>
              <BrowserBox>
                <ComputerIcon />
                <Typography variant="boldSubhead">
                  {`${activities.activity_data?.user_agent_data?.browser}, ${activities.activity_data?.user_agent_data?.os}`}
                </Typography>
              </BrowserBox>

              {activities.activity_data?.location_data?.country && (
                <LocationBox>
                  <LocationIcon />
                  <Typography variant="boldSubhead">
                    {activities.activity_data?.location_data?.country}
                  </Typography>
                </LocationBox>
              )}
            </ActionBox>
          </ContentItem>

          {/*  */}
          <ActivityBox>
            {activities.activity.map((item) => (
              <ActivityItem id={item.id} key={item.id}>
                <RightArrowIcon />
                <ActivityTitle variant="lightCallout">
                  {item?.activity_type}
                </ActivityTitle>
                <ActivityTimeStamp variant="lightCallout">
                  {item?.date_created}
                </ActivityTimeStamp>
              </ActivityItem>
            ))}
          </ActivityBox>
        </CardContentStyle>
      </ActivityCard>
    </>
  );
};

export default Activity;
