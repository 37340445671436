import * as React from "react";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import {
  hideNotification,
  selectNotificationState,
} from "./NotificationBarSlice";
import { useDispatch, useSelector } from "react-redux";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} {...props} />; //variant="filled"
});

export default function NotificationBar(props) {
  const { open, severity, message } = useSelector(selectNotificationState);
  const dispatch = useDispatch();
  var autoHideDuration = 4000;

  if (severity && severity === "warning") {
    autoHideDuration = null;
  }

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      //don't do anything if clicked anywhere else
      return;
    }
    dispatch(hideNotification());
  };

  return (
    <Snackbar
      open={open}
      autoHideDuration={autoHideDuration}
      onClose={handleClose}
    >
      <Alert
        onClose={handleClose}
        severity={severity ? severity : "success"}
        sx={{ width: "100%" }}
        {...(severity !== "warning" && { variant: "filled" })}
      >
        {message}
      </Alert>
    </Snackbar>
    //   <Alert severity="error">This is an error message!</Alert>
    //   <Alert severity="warning">This is a warning message!</Alert>
    //   <Alert severity="info">This is an information message!</Alert>
    //   <Alert severity="success">This is a success message!</Alert>
  );
}
