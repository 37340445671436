import React from "react";
import { BrowserRouter } from "react-router-dom";
import RecordScreen from "./pages/record/components/RecordScreen";
// import Router from "./routes";
import ScrollToTop from "./shared_modules/common/ScrollToTop";
import ThemeConfig from "./theme";
import GlobalStyles from "./theme/globalStyles";

const AppRecording = (props) => {
  // const requestSlug = document.getElementById("screenjar-demo-slug").value;
  return (
    <BrowserRouter>
      <ThemeConfig>
        <ScrollToTop />
        <GlobalStyles />
        <RecordScreen requestSlug={props.requestSlug} />
      </ThemeConfig>
    </BrowserRouter >
  );
}

export default AppRecording;
