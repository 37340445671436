import axiosInstance, { axiosNoAuthInstance } from "../../common/axios";

export function ResetPasswordAPI(emailid) {
    return axiosInstance.post('api/reset-password-logged-off/', emailid);
}

export function ResetPasswordConfirmView(token, uid, new_password1, new_password2) {
    return axiosNoAuthInstance.post(`api/pass-reset/${uid}/${token}`,
        { token, uid, new_password1, new_password2 });
}
