import { Grid, Stack, Typography } from "@mui/material";
import Pagination from "@mui/material/Pagination";
import { Component } from "react";
import { Link } from "react-router-dom";
import LibrarySort from "./LibrarySort";
import {
  STATE_FAIL,
  STATE_LOADING,
  STATE_SUCCESS,
} from "../../../common/Constants";
import Loader from "../../../shared_modules/common/Loader";
import { StyledCardMd } from "../../../shared_modules/common/StyledCard";
import { getQueryParameters } from "../../../shared_modules/common/Utils";
import { Wrapper } from "../../../shared_modules/common/Wrapper";
import VideoLibraryCard from "../../../shared_modules/video_library_card/VideoLibraryCard";
import {
  PaginationItemStyle,
  NoVideoBox,
  VideoBoxWrapper,
} from "./VideoLibraryStyle";

// ----------------------------------------------------------------------

export class VideoLibrary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sortType: "recent",
      SORT_OPTIONS: [
        { value: "recent", label: "Recent" },
        { value: "oldest", label: "Oldest" },
      ],
    };
    this.handleSort = this.handleSort.bind(this);
  }

  loadPageData = (pageNumber, sorttype) => {
    this.props.fetchVideos(pageNumber, sorttype);
  };
  handlePaginationChange = (event, value) => {
    this.loadPageData(value, this.state.sortType);
  };

  handleSort(e) {
    this.setState({ sortType: e.target.value });
    this.loadPageData("1", e.target.value);
  }

  componentDidMount() {
    const queryParams = getQueryParameters(window.location.search);
    const pageNumber = parseInt(queryParams["page"] || "1", 10);
    const sorttype = this.state.sortType;
    this.loadPageData(pageNumber, sorttype);
  }

  render() {
    const { status, videoRequests } = this.props.videoListPage;
    const SORT_OPTIONS = this.state.SORT_OPTIONS;

    return (
      <>
        <Wrapper>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            mb={2.25}
            gap={2}
          >
            <Grid>
              <Typography marginRight={2} variant="boldH4" gutterBottom>
                Video Library
              </Typography>
            </Grid>
            <LibrarySort options={SORT_OPTIONS} handleSort={this.handleSort} />
          </Stack>

          <Grid container spacing={3.75}>
            {status === STATE_LOADING && (
              <div>
                <Loader />
              </div>
            )}

            {status === STATE_FAIL && (
              <div>
                Something went wrong. Please refresh page or try after some time
              </div>
            )}
            {status === STATE_SUCCESS &&
              videoRequests.results.length === 0 &&
              showEmptyState()}
            {status === STATE_SUCCESS &&
              videoRequests.results.map((request) => (
                <VideoLibraryCard
                  item={request}
                  deleteVideo={this.props.deleteVideo}
                  pageNumber={videoRequests.pageNumber}
                />
              ))}
          </Grid>

          <Stack
            direction="row"
            alignItems="center"
            justifyContent="center"
            style={{ marginTop: "40px" }}
          >
            {status === STATE_SUCCESS && videoRequests.pages > 1 && (
              <Pagination
                color="secondary"
                size="large"
                page={videoRequests.pageNumber}
                count={videoRequests.pages}
                renderItem={(item) => (
                  <PaginationItemStyle
                    component={Link}
                    to={`/app/video_library${
                      item.page === 1 ? "" : `?page=${item.page}`
                    }`}
                    {...item}
                  />
                )}
                onChange={this.handlePaginationChange}
              />
            )}
          </Stack>

          {/*  */}
          {/* */}
        </Wrapper>
      </>
    );
  }
}

const showEmptyState = () => {
  return (
    <NoVideoBox>
      <StyledCardMd>
        <VideoBoxWrapper>
          <Typography variant="boldH2">No videos uploaded yet !!</Typography>
        </VideoBoxWrapper>
      </StyledCardMd>
    </NoVideoBox>
  );
};
