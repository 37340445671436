import axiosInstance from "../../common/axios";

export function pendingInvitesApi(pageNumber = 1) {
  return axiosInstance.get(`api/list-invite?page=${pageNumber}`);
}

export function sendInviteApi(values) {
  return axiosInstance.post(`api/create-invite/`, values);
}

export function teamMatesApi(pageNumber = 1) {
  return axiosInstance.get(`api/accepted-invite?page=${pageNumber}`);
}

export function changeUserAccountStateApi(slug, action) {
  return axiosInstance.post(`api/account/activate-deactivate/${slug}`, {
    action: action,
  });
}

export function adminDeletionCheckApi(slug) {
  return axiosInstance.post(`api/account/delete-admin-checks/${slug}`);
}
