import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  InputAdornment,
  TextField,
  Tooltip,
  Typography,
  Zoom,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  LabelTypography,
  PageTitle,
} from "../../../shared_modules/common/StyledClass";
import { Wrapper } from "../../../shared_modules/common/Wrapper";
import {
  DividerStyled,
  FieldWrapper,
  ImageWrapper,
  InfoDescription,
  InfoWrapper,
  NotifiedWrapper,
  ProfileCard,
  UserImage,
  ButtonStyled,
  StyledTypography,
} from "./ProfileStyle";
import { UpdateProfileAPI, ResetPasswordAPI } from "../ProfileAPI";
import { useDispatch, useSelector } from "react-redux";
import { getUserDetails, selectProfileState } from "../ProfileSlice";
import { showNotification } from "../../../shared_modules/notification_bar/NotificationBarSlice";
import { fetchSession } from "../../../shared_modules/layout/SessionSlice";
import { CopyIcon } from "../../video_details/components/ExpandVideoStyle";
import { getMetaTag, META_TAG_BASE_URL } from "../../../common/MetaTagReader";
import { handleCopyLinkClick } from "../../record/components/RecordingUtil";

const Profile = () => {
  const dispatch = useDispatch();
  const { profile } = useSelector(selectProfileState);
  const [formData, updateFormData] = useState(profile);
  const [checked, setChecked] = useState(true);
  const [email, setEmail] = useState("");
  const [emailSent, setEmailSent] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [avatar, setAvatar] = useState("");
  const baseURL = `${getMetaTag(META_TAG_BASE_URL)}`;

  useEffect(() => {
    dispatch(getUserDetails());
  }, []);

  useEffect(() => {
    updateFormData(profile);
    setAvatar(profile.name);
    // setChecked(profile.subscribed)
  }, [profile]);

  useEffect(() => {
    //set checkbox value
    setChecked(formData.subscribed);
  }, [formData.subscribed]);

  const handleChange = (e) => {
    //triggered by onChange event handler to update the values everytime input changes
    const value =
      e.target.name === "subscribed" ? e.target.checked : e.target.value;
    updateFormData({
      ...formData,
      [e.target.name]: value,
    });
  };

  const handleSumit = (e) => {
    //submits profile setting data, triggered by onSubmit event handler
    e.preventDefault();
    const form_data = new FormData();
    if (recording_link) {
      form_data.append("is_profile_link", true);
    }

    form_data.append("name", formData.name);
    form_data.append("subscribed", formData.subscribed);

    UpdateProfileAPI(form_data)
      .then((res) => {
        dispatch(
          showNotification({
            message: `User information is updated successfully`,
          })
        );
        dispatch(fetchSession());
      })
      .catch((err) => { });
  };

  const handleEmail = (e) => {
    setEmail(e.target.value);
    // [e.target.name] = e.target.value
  };

  const handlePasswordReset = (e) => {
    //handle password-reset here
    e.preventDefault();
    if (email === "" || email === null) {
      setErrorMessage("Please enter your email");
    }
    const emailid = new FormData();
    emailid.append("email", email);
    ResetPasswordAPI(emailid)
      .then((res) => {
        if (res.data.status === "success") {
          setEmailSent(true);
        }
        if (res.data.status === "error") {
          if ("email" in res.data) {
            setErrorMessage(res.data.email);
          }
        }
      })
      .catch((err) => { });
  };

  const handleResend = (e) => {
    e.preventDefault();
    setEmailSent(false);
  };

  var recording_link = "";
  if (formData.company) {
    var name = "org";
    let company = formData.company.name
      .split(" ")[0]
      .replace(/[^a-zA-Z0-9]/g, "")
      .toLowerCase();
    if (company && company !== "") {
      name = company;
    }
    recording_link = `${baseURL}/record/${name}/${formData.slug}`;
  }

  return (
    <>
      <Wrapper>
        <PageTitle>Profile</PageTitle>

        <ProfileCard>
          <Wrapper>
            <ImageWrapper>
              <UserImage
                component="img"
                src={`https://ui-avatars.com/api/?size=68&color=fff&rounded=false&bold=true&background=random&name=${avatar}`}
                alt=""
              />
            </ImageWrapper>
            <InfoWrapper>
              <Typography marginTop={5} variant="boldH4">
                Profile link{" "}
              </Typography>

              <InfoDescription>
                <Typography variant="lightCallout">
                  This is a unique URL specific to you. You can add it to your
                  signature, share it directly with customers, and so on. Videos
                  recorded using this link will appear in your inbox.
                </Typography>
              </InfoDescription>
              <TextField
                inputProps={{
                  "aria-label": "Without label",
                  style: { fontSize: 15 },
                }}
                hiddenLabel
                fullWidth
                id="filled-hidden-label-normal"
                variant="filled"
                value={recording_link}
                InputProps={{
                  readOnly: true,
                  endAdornment: (
                    <InputAdornment position="end">
                      <Tooltip TransitionComponent={Zoom} title="Copy">
                        <Box
                          onClick={() =>
                            handleCopyLinkClick(dispatch, recording_link)
                          }
                        >
                          <CopyIcon />
                        </Box>
                      </Tooltip>
                    </InputAdornment>
                  ),
                }}
              />
            </InfoWrapper>
          </Wrapper>

          <InfoWrapper>
            <FormControl component="form"
              autoComplete="off"
              onSubmit={e => handleSumit(e)}>
              <Typography variant="boldH4">Update name </Typography>

              <InfoDescription>
                <Typography variant="lightCallout">
                  Update your name and notification settings here. Your name is
                  displayed on your screen recording request links. Email us if
                  you'd like to make any other changes to your account
                  (screenjar@amoeboids.com)
                </Typography>
              </InfoDescription>

              <FieldWrapper>
                <LabelTypography variant="lightFootnote">
                  Your first name
                </LabelTypography>
                <TextField
                  inputProps={{ "aria-label": "Without label" }}
                  hiddenLabel
                  fullWidth
                  id="filled-hidden-label-normal"
                  variant="filled"
                  placeholder="First Name"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                />
              </FieldWrapper>

              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={checked}
                      onChange={handleChange}
                      inputProps={{ "aria-label": "controlled" }}
                    />
                  }
                  label="Email notifications"
                  name="subscribed"
                  value={checked}
                />
              </FormGroup>

              <NotifiedWrapper>
                <Typography variant="lightCaption">
                  Get notified by email whenever someone records their screen with
                  one of your links.
                </Typography>
              </NotifiedWrapper>

              <Button size="large" variant="contained" onClick={handleSumit}>
                <Typography variant="boldBody">Save</Typography>
              </Button>
            </FormControl>

            <DividerStyled />

            <Typography variant="boldH4">Reset your password</Typography>
            {emailSent ? (
              <React.Fragment>
                <InfoDescription>
                  <Typography variant="lightCallout">
                    The link with reset password is sent to email address
                    provided by you. Kindly follow the instructions in the email
                    to reset your password
                  </Typography>
                </InfoDescription>

                <ButtonStyled
                  size="large"
                  variant="contained"
                  onClick={handleResend}
                >
                  <Typography variant="boldBody">Send email again</Typography>
                </ButtonStyled>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <InfoDescription>
                  <Typography variant="lightCallout">
                    Enter your email address to get started with your password
                    reset. If you don't find the reset email, please get in
                    touch and we'll get this all sorted for you.
                  </Typography>
                </InfoDescription>

                <FieldWrapper>
                  {/* <LabelTypography variant="lightFootnote">Email</LabelTypography> */}
                  <TextField
                    variant="filled"
                    placeholder="Enter your email id "
                    required
                    inputProps={{ "aria-label": "Without label" }}
                    hiddenLabel
                    fullWidth
                    id="filled-hidden-label-normal"
                    name="email"
                    onChange={handleEmail}
                    error={errorMessage}
                    helperText={errorMessage ? errorMessage : ""}
                  />
                </FieldWrapper>

                <Button
                  size="large"
                  variant="contained"
                  onClick={handlePasswordReset}
                >
                  <Typography variant="boldBody">Reset password</Typography>
                </Button>

                <StyledTypography
                  variant="lightCallout"
                  style={{ color: "red" }}
                >
                  {/* {errorMessage && <p className="error" > {errorMessage} </p>} */}
                </StyledTypography>
              </React.Fragment>
            )}
          </InfoWrapper>
        </ProfileCard>
      </Wrapper>
    </>
  );
};

export default Profile;
