import styled from '@emotion/styled';
import { Box } from '@mui/system';

export const Wrapper = styled(Box)(({ theme }) => ({
  margin: theme.spacing(0, 2),

  [theme.breakpoints.up('lg')]: {
    margin: theme.spacing(5, 8, 2, 8),
  },
}));
