import { Box, CircularProgress, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getMetaTag, META_TAG_BASE_URL } from "../../common/MetaTagReader";
import { downloadFile, getVideoType } from "../../shared_modules/common/Utils";
import { StyledImageCard } from "../../shared_modules/inbox_card/InboxStyle";
import {
  DownloadIconLarge,
  DownloadLargeButton,
} from "../record/components/RecordScreenStyle";
import {
  ShareStyledVideo,
  ShareVideoDownloadBtn,
  ShareVideoWrapper,
} from "./Style";

const ShareVideo = () => {
  const { slug } = useParams();
  const baseURL = `${getMetaTag(META_TAG_BASE_URL)}`;
  const [shareVideourl, setShareVideourl] = useState("");

  useEffect(() => {
    const fetchShareVideo = async () => {
      const url = `${baseURL}/api/share/${slug}/`;
      const options = {
        method: "GET",
        "Content-Type": "application/json",
      };
      const res = await fetch(url, options);
      if (res.status === 202) {
        window.location.replace("/page-removed.html/");
      }
      const data = await res.json();
      if (data) {
        setShareVideourl(data);
      }
    };
    fetchShareVideo();
  }, [slug]);

  return (
    <ShareVideoWrapper>
      {shareVideourl?.video_url || shareVideourl.screenshot_url ? (
        <>
          {shareVideourl.video_url ? (
            <>
              <ShareStyledVideo controls>
                <source
                  src={baseURL + shareVideourl?.video_url}
                  type={getVideoType(shareVideourl?.video_url)}
                />
              </ShareStyledVideo>
              <DownloadLargeButton
                size="large"
                variant="contained"
                style={{ margin: 10 }}
                onClick={() =>
                  downloadFile(
                    baseURL + shareVideourl?.video_url,
                    shareVideourl?.file_name
                  )
                }
              >
                <DownloadIconLarge />
                <Typography variant="boldBody">Download video</Typography>
              </DownloadLargeButton>
            </>
          ) : (
            <>
              <StyledImageCard>
                <img
                  src={baseURL + shareVideourl.screenshot_url}
                  alt={shareVideourl.file_name}
                />
              </StyledImageCard>
              <DownloadLargeButton
                size="large"
                variant="contained"
                style={{ margin: 10, width: "auto" }}
                onClick={() => {
                  downloadFile(
                    baseURL + shareVideourl?.screenshot_url,
                    shareVideourl?.file_name
                  );
                }}
              >
                <DownloadIconLarge />
                <Typography variant="boldBody">Download image</Typography>
              </DownloadLargeButton>
            </>
          )}
        </>
      ) : (
        <Box textAlign="center">
          <CircularProgress
            sx={{
              maxHeight: "70px",
              maxWidth: "70px",
            }}
          />
        </Box>
      )}
    </ShareVideoWrapper>
  );
};

export default ShareVideo;
