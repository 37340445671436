import { styled } from '@mui/system';
import { useSwitch } from '@mui/base/SwitchUnstyled';
import clsx from 'clsx';

const BasicSwitchRoot = styled('span')(({ theme }) => ({
  fontSize: 0,
  position: 'relative',
  display: 'inline-block',
  width: '40px',
  height: '20px',
  marginRight: theme.spacing(1.25),
  background: theme.palette.primary.lighter,
  borderRadius: '10px',
  cursor: 'pointer',

  '&.Switch-disabled': {
    opacity: 0.4,
    cursor: 'not-allowed',
  },

  '&.Switch-checked': {
    background: theme.palette.secondary.main,
  },
}));

const BasicSwitchInput = styled('input')({
  cursor: 'inherit',
  position: 'absolute',
  width: '100%',
  height: '100%',
  top: 0,
  left: 0,
  opacity: 0,
  zIndex: 1,
  margin: 0,
});

const BasicSwitchThumb = styled('span')(({ theme }) => ({
  display: 'block',
  width: '14px',
  height: '14px',
  top: '3px',
  left: '3px',
  borderRadius: '16px',
  backgroundColor: '#fff',
  position: 'relative',
  transition: 'all 200ms ease',

  '&.Switch-focusVisible': {
    backgroundColor: theme.palette.grey.main,
    boxShadow: ' 0 0 1px 8px rgba(0, 0, 0, 0.25)',
  },

  '&.Switch-checked': {
    left: '22px',
    top: '3px',
    backgroundColor: '#fff',
  },
}));

export default function BasicSwitch(props) {
  const { getInputProps, checked, disabled, focusVisible } = useSwitch(props);

  const stateClasses = {
    'Switch-checked': checked,
    'Switch-disabled': disabled,
    'Switch-focusVisible': focusVisible,
  };

  return (
    <BasicSwitchRoot className={clsx(stateClasses)}>
      <BasicSwitchThumb className={clsx(stateClasses)} />
      <BasicSwitchInput {...getInputProps()} aria-label="Demo switch" />
    </BasicSwitchRoot>
  );
}
