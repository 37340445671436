import {
  Box,
  CardContent,
  Grid,
  Pagination,
  Stack,
  Tooltip,
  Typography,
  Zoom,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { getMetaTag, META_TAG_BASE_URL } from "../../../common/MetaTagReader";
import ConfirmDialog from "../../../shared_modules/common/ConfirmDialog";
import Loader from "../../../shared_modules/common/Loader";
import {
  PrimaryBadge,
  SuccessBadge,
} from "../../../shared_modules/common/RoundIconBadge";
import { StyledCardMd } from "../../../shared_modules/common/StyledCard";
import {
  FlexyCenter,
  PageTitle,
} from "../../../shared_modules/common/StyledClass";
import BasicSwitch from "../../../shared_modules/common/Switch";
import Textarea from "../../../shared_modules/common/Textarea";
import UserDetails from "../../../shared_modules/common/UserDetails";
import {
  downloadFile,
  getVideoType,
} from "../../../shared_modules/common/Utils";
import { Wrapper } from "../../../shared_modules/common/Wrapper";
import DeleteIcon from "../../../shared_modules/icon_with_button/DeleteIcon";
import InboxCard from "../../../shared_modules/inbox_card/InboxCard";
import { InboxText } from "../../../shared_modules/inbox_card/InboxStyle";
import { showNotification } from "../../../shared_modules/notification_bar/NotificationBarSlice";
import {
  deleteRecordingAPI,
  fetchAllLinkVideosAPI,
  fetchRecentActivityUsers,
  fetchVideoDetailsAPI,
  toggleSharingEnabledAPI,
  updateVideoRequestAPI,
} from "../../dashboard/DashboardAPI";
import Activity from "./Activity";
import {
  ActionBox,
  BrowserBox,
  CardMediaStyle,
  CollapseBox,
  ComputerIcon,
  ContentItem,
  CopyIcon,
  DownloadButton,
  DownloadIcon,
  EditNoteButton,
  ExpandCollapse,
  ItemBox,
  LinkWrapper,
  LocationBox,
  LocationIcon,
  OtherBox,
  ShareVideo,
  SharingWrapper,
  StyledVideo,
  TitleWrapperActivity,
  TitleWrapperMoreVideos,
} from "./ExpandVideoStyle";
import { StyledImageCard } from "../../../shared_modules/inbox_card/InboxStyle";
import AddToLibrary from "../../video_library/components/AddToLibrary";
import { handleCopyLinkClick } from "../../record/components/RecordingUtil";
import { ContentSection } from "../../record/components/RecordStyle";

// ----------------------------------------------------------------------

export default function ExpandVideo({ listItem }) {
  const [switchChecked, setSwitchChecked] = useState(false);
  const [videoDetails, setVideoDetails] = useState([]);
  const [more_videos, setMoreVideos] = useState([]);
  const [recentActivity, setRecentActivity] = useState([]);
  const [loader, setLoader] = useState(true);
  const [screenVideoLink, setScreenVideoLink] = useState("");
  const [screenImageLink, setScreenImageLink] = useState("");
  const [note, setNote] = useState("");
  const [touched, setTouched] = useState(false);
  const [isDelete, setIsDelete] = useState(false);
  const { videoId, uuid } = useParams();
  const baseURL = `${getMetaTag(META_TAG_BASE_URL)}`;
  const [searchParams] = useSearchParams();
  const [noVideo, setNoVideo] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // const PaginationItemStyle = styled(PaginationItem)(({ theme }) => ({
  //   color: theme.palette.grey.main,
  //   ...theme.typography.boldSubhead,
  // }));

  const loadRecentActivity = (pageNumber) => {
    fetch_recent_activity(pageNumber);
  };
  // const handleActivityPagination = (event, value) => {
  //   loadRecentActivity(value);
  // };

  const loadPageData = (pageNumber) => {
    fetch_more_videos(pageNumber);
  };
  const handlePaginationChange = (event, value) => {
    loadPageData(value);
  };
  const helpScout = {
    isHelpScout: false,
    url: "",
    subject: "",
  };
  const [helpScoutData, setHelpScout] = useState(helpScout);

  const deleteVideo = async (uuid) => {
    const res = await deleteRecordingAPI(uuid);
    if (res.status === 200) {
      setIsDelete(!isDelete);
    }
  };

  const updateNote = async (videoId) => {
    const val = {
      note: note,
    };
    if (touched) {
      const res = await updateVideoRequestAPI(videoId, val);
      if (res.data) {
        dispatch(
          showNotification({
            message: `Note has been saved`,
          })
        );
        setTouched(false);
      }
    } else setTouched(true);
  };

  const fetch_more_videos = async (pageNumber) => {
    const res = await fetchAllLinkVideosAPI(videoId, pageNumber);
    if (res.data) {
      if (res.data) {
        setMoreVideos(res.data);
        if (res.data.results.length) {
          navigate(`/app/details/${videoId}/${res.data.results[0].uuid}`);
        }
      }
    }
  };

  const fetch_recent_activity = async (pageNumber) => {
    const res = await fetchRecentActivityUsers(videoId, pageNumber);
    if (res.data) {
      setRecentActivity(res.data);
    }
  };

  useEffect(() => {
    loadPageData("1");
    loadRecentActivity("1");
  }, [videoId, isDelete]);

  useEffect(() => {
    const fetchVideoDetails = async () => {
      const on_root = searchParams.get("on_root");
      const res = await fetchVideoDetailsAPI(videoId, uuid, on_root);
      if (res.data) {
        setScreenImageLink("");
        setScreenVideoLink("");
        if (res.data.error) {
          setNoVideo(true);
        } else {
          setNoVideo(false);
          setLoader(true);
          setVideoDetails(res.data);
          setNote(res.data.video_request.note);
          if (
            res.data.video_request?.integration &&
            res.data.video_request.integration.platform === "Help Scout"
          ) {
            setHelpScout({
              isHelpScout: true,
            });
            if (
              res.data.video_request.data.ticket.subject &&
              res.data.video_request.data.ticket.id
            ) {
              let ticketId = res.data.video_request.data.ticket.id;
              let ticketNo = res.data.video_request.data.ticket.number;
              let url = `https://secure.helpscout.net/conversation/${ticketId}/${ticketNo}`;
              let subject = res.data.video_request.data.ticket.subject;
              setHelpScout({
                ...helpScout,
                isHelpScout: true,
                url: url,
                subject: subject,
              });
            }
          }
          if (res.data.screen_video) {
            setSwitchChecked(res.data.screen_video?.sharing_enabled);
            setScreenImageLink(res.data.screen_video?.screenshot_url);
            setScreenVideoLink(res.data.screen_video?.video_url);
            setLoader(false);
          } else setLoader(false);
        }
      }
    };
    fetchVideoDetails();
  }, [isDelete, uuid, videoId, navigate]);

  let posterUrl = "";
  if (!loader) {
    if (videoDetails.screen_video) {
      posterUrl = videoDetails.screen_video.poster_url;
    } else if (more_videos?.results?.length > 0) {
      posterUrl = more_videos[0] ? more_videos[0].poster_url : "";
    }
  }

  return !loader ? (
    <>
      <Wrapper>
        {screenImageLink ||
          screenVideoLink ||
          more_videos?.results?.length > 0 ? (
          <>
            {/* Video Info */}
            <PageTitle>
              {screenImageLink ? "Screenshot Info" : "Video Info"}
            </PageTitle>

            <Grid>
              <StyledCardMd>
                <CardContent>
                  <ContentItem>
                    {/* User details */}
                    <UserDetails
                      userDetails={videoDetails?.video_request.user}
                    />

                    <ActionBox>
                      {screenImageLink ? null : (
                        <BrowserBox>
                          <ComputerIcon />
                          <Typography variant="boldSubhead">
                            {
                              videoDetails?.screen_video?.recording_data
                                ?.user_agent_data?.browser
                            }
                            ,{" "}
                            {
                              videoDetails?.screen_video?.recording_data
                                ?.user_agent_data?.os
                            }
                          </Typography>
                        </BrowserBox>
                      )}
                      <OtherBox>
                        {screenImageLink ? null : (
                          <>
                            <LocationBox>
                              <LocationIcon />
                              <Typography variant="boldSubhead">
                                {
                                  videoDetails?.screen_video?.recording_data
                                    ?.location_data?.country
                                }
                              </Typography>
                            </LocationBox>
                            <AddToLibrary id={uuid} />
                          </>
                        )}
                        <Tooltip TransitionComponent={Zoom} title=" Download">
                          <DownloadButton
                            onClick={() => {
                              if (screenImageLink) {
                                downloadFile(
                                  videoDetails?.screen_video?.screenshot_url,
                                  videoDetails?.screen_video?.file_name
                                );
                              } else {
                                downloadFile(
                                  videoDetails?.screen_video?.video_url,
                                  videoDetails?.screen_video?.file_name
                                );
                              }
                            }}
                          >
                            <DownloadIcon />
                          </DownloadButton>
                        </Tooltip>

                        {/* Delete */}
                        <ConfirmDialog
                          description={
                            !screenImageLink
                              ? "Are you sure you want to delete this video?"
                              : "Are you sure you want to delete this image?"
                          }
                          onConfirm={() => {
                            deleteVideo(uuid);
                          }}
                        >
                          <Tooltip TransitionComponent={Zoom} title="Delete">
                            <Box>
                              <DeleteIcon />
                            </Box>
                          </Tooltip>
                        </ConfirmDialog>
                      </OtherBox>
                    </ActionBox>
                  </ContentItem>
                </CardContent>

                <CardMediaStyle>
                  {screenImageLink ? (
                    <StyledImageCard>
                      <img src={baseURL + screenImageLink} />
                    </StyledImageCard>
                  ) : (
                    <StyledVideo controls poster={posterUrl ? posterUrl : ""}>
                      {screenVideoLink ? (
                        <source
                          src={baseURL + screenVideoLink}
                          type={getVideoType(screenVideoLink)}
                        />
                      ) : null}
                    </StyledVideo>
                  )}
                </CardMediaStyle>

                <CardContent>
                  <Stack direction="row" alignItems="center" spacing={2} mb={1}>
                    <FlexyCenter>
                      <PrimaryBadge />
                      <InboxText variant="lightCaption">
                        {videoDetails?.screen_video?.human_created}
                      </InboxText>
                    </FlexyCenter>

                    <FlexyCenter>
                      <SuccessBadge />
                      <InboxText variant="lightCaption">
                        {videoDetails?.screen_video?.date_created}
                      </InboxText>
                    </FlexyCenter>
                  </Stack>

                  <Grid container spacing={2}>
                    <ShareVideo item lg={6}>
                      {videoDetails?.screen_video?.note ? (
                        <ContentSection
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                          <Typography
                            variant="lightCallout"
                            mb={1}
                            style={{ fontSize: "14px" }}
                          >
                            User message
                          </Typography>
                          <Typography variant="lightCallout">
                            {videoDetails?.screen_video?.note}
                          </Typography>
                        </ContentSection>
                      ) : null}
                      <LinkWrapper>
                        <Typography variant="lightCallout" mr={2}>
                          {videoDetails?.screen_video?.record_link}
                        </Typography>
                        <Tooltip TransitionComponent={Zoom} title="Copy">
                          <Box
                            onClick={() =>
                              handleCopyLinkClick(
                                dispatch,
                                videoDetails?.screen_video?.record_link
                              )
                            }
                          >
                            <CopyIcon />
                          </Box>
                        </Tooltip>
                      </LinkWrapper>

                      <SharingWrapper>
                        <BasicSwitch
                          checked={switchChecked}
                          onChange={() => {
                            setSwitchChecked(!switchChecked);
                            toggleSharingEnabledAPI(
                              videoDetails?.screen_video?.uuid
                            );
                          }}
                        />
                        <Typography variant="lightBody">
                          {screenImageLink
                            ? "Share this image"
                            : "Share this video"}
                        </Typography>
                      </SharingWrapper>

                      <ExpandCollapse in={switchChecked}>
                        {switchChecked === true ? (
                          <>
                            <CollapseBox>
                              <Typography variant="lightSmall">
                                Anyone with this link can access this
                                {screenImageLink ? " image" : " video"}
                              </Typography>
                              <ItemBox>
                                <Typography variant="lightCaption">
                                  {videoDetails?.screen_video?.sharing_link}
                                </Typography>

                                <Tooltip
                                  TransitionComponent={Zoom}
                                  title="Copy Link"
                                >
                                  <Box
                                    onClick={() =>
                                      handleCopyLinkClick(
                                        dispatch,
                                        videoDetails?.screen_video
                                          ?.sharing_link
                                      )
                                    }
                                  >
                                    <CopyIcon />
                                  </Box>
                                </Tooltip>
                              </ItemBox>
                            </CollapseBox>
                          </>
                        ) : (
                          ""
                        )}
                      </ExpandCollapse>
                      {helpScoutData.isHelpScout ? (
                        <>
                          <Typography variant="lightCallout" mr={2}>
                            Video recorded from a Help Scout link
                          </Typography>
                          <LinkWrapper>
                            Ticket:
                            <a href={helpScoutData.url}>
                              {helpScoutData.subject}
                            </a>
                          </LinkWrapper>
                        </>
                      ) : (
                        <></>
                      )}
                    </ShareVideo>

                    {/* Private Note */}
                    <ShareVideo item md={6}>
                      <Box pl={0} pt={2}>
                        <Textarea
                          value={note}
                          placeholder="Optional private note to keep track of where you send request links (customers don't see this)"
                          onChange={(e) => setNote(e.target.value)}
                          disabled={!touched}
                          minRows={3}
                        />
                        {videoDetails.video_request.is_request_user && (
                          <Box textAlign={"right"} mt={1}>
                            <EditNoteButton
                              size="large"
                              variant="contained"
                              onClick={() => updateNote(videoId)}
                            >
                              <Typography variant="boldSubhead">
                                {touched ? "Save" : "Edit note"}
                              </Typography>
                            </EditNoteButton>
                          </Box>
                        )}
                      </Box>
                    </ShareVideo>
                  </Grid>
                </CardContent>
              </StyledCardMd>
            </Grid>

            {/* More Videos */}
            {more_videos?.results?.length > 1 && (
              <Box>
                <TitleWrapperMoreVideos>
                  <Typography variant="boldH4">
                    More videos and images
                  </Typography>
                </TitleWrapperMoreVideos>
                <Grid container spacing={3.75}>
                  {more_videos?.results?.map((items, index) => {
                    if (items.uuid !== videoDetails?.screen_video?.uuid) {
                      return (
                        <InboxCard
                          key={items.uuid}
                          item={items}
                          deleteVideo={deleteVideo}
                          params={true}
                        // pageNumber={more_videos.pageNumber}
                        />
                      );
                    }
                  })}
                </Grid>
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="center"
                  style={{ marginTop: "40px" }}
                >
                  {more_videos.pages > 1 ? (
                    <Pagination
                      color="secondary"
                      size="large"
                      page={more_videos.pageNumber}
                      count={more_videos.pages}
                      onChange={handlePaginationChange}
                    />
                  ) : (
                    <></>
                  )}
                </Stack>
              </Box>
            )}

            {/* Activity */}
            {screenImageLink ? null : (
              <>
                {videoDetails?.video_request?.recent_activity_users &&
                  videoDetails?.video_request?.recent_activity_users.length >
                  0 && (
                    <Box>
                      <TitleWrapperActivity>
                        <Typography variant="boldH4">Activity </Typography>
                      </TitleWrapperActivity>
                      {recentActivity &&
                        recentActivity.results &&
                        recentActivity.results.map((activity, index) => (
                          <Box key={index}>
                            <Activity
                              activities={activity}
                              userText={`User ${index + 1}`}
                            />
                          </Box>
                        ))}
                      <Stack
                        direction="row"
                        alignItems="center"
                        justifyContent="center"
                        style={{ marginTop: "40px" }}
                      ></Stack>
                    </Box>
                  )}
              </>
            )}
          </>
        ) : (
          <>
            <Box display={"flex"} mb={4}>
              This request was generated by
              <Box mx={1}>
                <img
                  src={`https://ui-avatars.com/api/?size=24&color=fff&rounded=true&bold=true&background=random&name=${videoDetails?.video_request.user?.name}`}
                  alt=""
                />
              </Box>
              {videoDetails?.video_request.user?.name} on{" "}
              {videoDetails?.video_request?.date_created}
            </Box>
            <Typography>
              No videos and images are uploaded using this link
            </Typography>
            <LinkWrapper>
              <Typography variant="lightCallout" mr={2}>
                {videoDetails?.video_request?.record_link}
              </Typography>
              <Tooltip TransitionComponent={Zoom} title="Copy">
                <Box
                  onClick={() =>
                    handleCopyLinkClick(
                      dispatch,
                      videoDetails?.video_request?.record_link
                    )
                  }
                >
                  <CopyIcon />
                </Box>
              </Tooltip>
            </LinkWrapper>
          </>
        )}
      </Wrapper>
    </>
  ) : (
    <>
      {noVideo ? (
        <Wrapper>
          <Box>
            <Typography>
              The video you are looking for does not exists or is removed !!
            </Typography>
          </Box>
        </Wrapper>
      ) : (
        <Loader />
      )}
    </>
  );
}
