import { createSlice } from "@reduxjs/toolkit";
import {
  STATE_FAIL,
  STATE_LOADING,
  STATE_SUCCESS,
} from "../../common/Constants";
import { showNotification } from "../../shared_modules/notification_bar/NotificationBarSlice";
import {
  addToLibraryAPI,
  deleteVideoLibraryAPI,
  fetchVideoDetailsAPI,
  fetchVideosApi,
  updateVideoDetailsAPI,
} from "./VideoLibraryAPI";

const defaultState = {
  count: 0,
  pageNumber: 1,
  pages: 0,
  results: null,
};
const initialState = {
  videoListPage: {
    videoRequests: defaultState,
    status: STATE_LOADING,
  },
  video: {
    recording_uuid: "",
    title: "",
    description: "",
    slug: "",
  },
  message: {
    message: "",
    status: "",
  },
};

export const videoLibrarySlice = createSlice({
  name: "videoLibrary",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    fetchVideoStart: (state, action) => {
      state.videoListPage.status = STATE_LOADING;
      // state.videoListPage.videoRequests = defaultState;
      state.videoListPage.pageNumber = action.payload;
    },
    fetchVideoSuccess: (state, action) => {
      state.videoListPage.status = STATE_SUCCESS;
      state.videoListPage.videoRequests = action.payload;
    },
    fetchVideoFail: (state) => {
      state.videoListPage.status = STATE_FAIL;
      state.videoListPage.videoRequests = defaultState;
    },
    fetchVideoLibraryDetailsSuccess: (state, { payload }) => {
      state.video = payload;
    },
    setSubmitResponse: (state, { payload }) => {
      state.message = payload;
    },
    resetAddUpdateForm: (state) => {
      state.video = {
        recording_uuid: "",
        title: "",
        description: "",
        slug: "",
      };
      state.message = {
        message: "",
        status: "",
      };
    },
  },
});

export const fetchNewVideos = (pageNumber, sorttype) => {
  // the inside "thunk function"
  return async (dispatch) => {
    try {
      dispatch(fetchVideoStart(pageNumber, sorttype));
      const response = await fetchVideosApi(pageNumber, sorttype);
      dispatch(fetchVideoSuccess(response.data));
    } catch (err) {
      // If something went wrong, handle it here
      console.log(err);
    }
  };
};
export const deleteVideo = (uuid, pageNumber) => {
  // the inside "thunk function"
  return async (dispatch) => {
    try {
      const response = await deleteVideoLibraryAPI(uuid);
      if (response.status === 200) {
        dispatch(
          showNotification({
            message: "Successfully deleted",
          })
        );
        dispatch(fetchNewVideos(pageNumber));
      }
    } catch (err) {
      // If something went wrong, handle it here
      console.log(err);
    }
  };
};

export const addToLibrary = (form_data) => {
  return async (dispatch) => {
    try {
      const response = await addToLibraryAPI(form_data);
      dispatch(setSubmitResponse(response.data));
      if (response.data.status === "success") {
        resetFormData();
      }
    } catch (err) {
      // If something went wrong, handle it here
      console.log(err);
    }
  };
};

export const updateVideoDetails = (form_data, pageNumber) => {
  return async (dispatch) => {
    try {
      const response = await updateVideoDetailsAPI(form_data);
      dispatch(setSubmitResponse(response.data));
      if (response.data.status === "success") {
        resetFormData();
        dispatch(fetchNewVideos(pageNumber));
      }
    } catch (err) {
      console.log(err);
      // If something went wrong, handle it here
    }
  };
};

export const checkVideoIfExists = (uuid, slug) => {
  return async (dispatch) => {
    try {
      const response = await fetchVideoDetailsAPI(uuid, slug);
      if (response.data.video_exists) {
        dispatch(
          showNotification({
            severity: "warning",
            message: `Video is already added to library, You can continue if you wish to add again`,
          })
        );
      }
    } catch (err) {
      // If something went wrong, handle it here
      console.log(err);
    }
  };
};
export const fetchVideoDetails = (uuid, slug) => {
  return async (dispatch) => {
    try {
      const response = await fetchVideoDetailsAPI(uuid, slug);
      if (response.data.video_data) {
        dispatch(fetchVideoLibraryDetailsSuccess(response.data.video_data));
      }
    } catch (err) {
      // If something went wrong, handle it here
      console.log(err);
    }
  };
};

export const resetFormData = () => {
  return async (dispatch) => {
    dispatch(resetAddUpdateForm());
  };
};

export const selectVideoDetailsState = (state) => state.videoLibrary;
export const {
  fetchVideoStart,
  fetchVideoSuccess,
  fetchVideoFail,
  fetchVideoLibraryDetails,
  fetchVideoLibraryDetailsSuccess,
  setSubmitResponse,
  resetAddUpdateForm,
} = videoLibrarySlice.actions;

export default videoLibrarySlice.reducer;
