import styled from '@emotion/styled';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { changeTab } from './TabsSlice';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Box>{children}</Box>
        </Box>
      )}
    </div>
  );
}

const StyledTabs = styled(Tabs)(({ theme }) => ({
  '& .MuiTabs-flexContainer': {
    display: 'flex',
    flexDirection: 'column',
    // flexWrap: 'wrap',
    alignItems: 'center',

    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
      alignItems: 'start',
    },
  },

  '& .MuiTabs-indicator': {
    display: 'none',
  },
}));

const StyledTab = styled(Tab)(({ theme }) => ({
  background: theme.palette.secondary.trans20,
  margin: theme.spacing(1, 0),
  borderRadius: theme.spacing(2),
  textTransform: 'initial',
  color: theme.palette.secondary.main,
  padding: theme.spacing(2, 3),
  ...theme.typography.boldSubTitle,
  width: '100%',

  [theme.breakpoints.up('md')]: {
    width: 'auto',

    margin: theme.spacing(0, 2.6, 0, 0),
  },

  '&.Mui-selected': {
    background: theme.palette.secondary.main,
    color: theme.palette.common.white,
  },
  '& .MuiTabs-indicatorSpan': {
    backgroundColor: 'red',
  },
}));

export default function BasicTabs({ tabs, selectedTab }) {
  // const dispatch = useDispatch()
  const navigate = useNavigate();
  // const selectedTab = useSelector((state) => state.tabs.selectedTab)


  const handleChange = (event, newValue) => {
    event.preventDefault();
    navigate(event.target.dataset.navpath);
    // dispatch(changeTab(newValue))
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Box>
        <StyledTabs
          value={selectedTab}
          onChange={handleChange}
        >
          {tabs.map(({ label, href, index }, i) => (
            <StyledTab label={label} key={i} href={href} index={index} data-navpath={href} />
          ))}
        </StyledTabs>
      </Box>
      {tabs.map(({ Component, index }, i) => (
        <TabPanel value={selectedTab} index={index} key={i}>
          {Component}
        </TabPanel>
      ))}
    </Box>
  );
}
