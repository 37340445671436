import { Box, Link, Typography } from "@mui/material";
import Alert from "@mui/material/Alert";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import { styled } from "@mui/material/styles";
import { useState } from "react";
import { ReactComponent as CloseIcon } from "../../images/Icon/close.svg";
import { selectSessionState } from "../layout/SessionSlice";
import { useSelector } from "react-redux";
//

const BoxWrapper = styled(Box)(({ theme }) => ({
  position: "initial",
  top: 0,
  zIndex: 1,
}));

const AlertStyle = styled(Alert)(({ theme }) => ({
  position: "sticky",
  width: "auto",
  height: "auto",
  display: "flex",
  marginBottom: "0 !important",
}));

const Close = styled(CloseIcon)(({ theme }) => ({
  width: 22,
  height: 22,
}));

//
export default function TrialExpiry() {
  const [open, setOpen] = useState(true);
  const { user } = useSelector(selectSessionState);
  var msg = null;

  if (!user.has_active_subscription) {
    if (user.trial_days_remaining < 1) {
      msg = (
        <Typography variant="lightFootnote">
          Your free trial has expired. Please{" "}
          <Link href="/app/settings/billing" underline="hover">
            upgrade
          </Link>{" "}
          to continue using Screenjar Or{" "}
          <Link href="/app/contact" underline="hover">
            contact us
          </Link>{" "}
          for any questions.
        </Typography>
      );
    } else if (user.trial_days_remaining < 8) {
      msg = (
        <Typography variant="lightFootnote">
          You have {user.trial_days_remaining} day/s remaining on your trial.{" "}
          <strong>
            <Link href="/app/settings/billing" underline="hover">
              Upgrade early here
            </Link>
          </strong>
        </Typography>
      );
    }
  }
  return (
    <>
      {msg && (
        <BoxWrapper>
          <Collapse in={open}>
            <AlertStyle
              severity="info"
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={() => {
                    setOpen(false);
                  }}
                >
                  <Close />
                </IconButton>
              }
              sx={{ mb: 2 }}
            >
              {/* {alertType && alertType === 'ended' && alertMsg} */}
              {msg}
            </AlertStyle>
          </Collapse>
        </BoxWrapper>
      )}
    </>
  );
}
