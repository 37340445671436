import { Box, Button, TextField, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { ReactComponent as RightArrowIconSvg } from "../../../images/Icon/arrow-right.svg";
import { ReactComponent as LockIconSvg } from "../../../images/Icon/lock.svg";
import { ReactComponent as RecordButtonIconSvg } from "../../../images/Icon/recordButton.svg";
import { ReactComponent as RedoIconSvg } from "../../../images/Icon/redo.svg";
import { ReactComponent as DownloadIconSvg } from "../../../images/Icon/download.svg";
import { ReactComponent as CheckIconSvg } from "../../../images/Icon/check.svg";
import { ReactComponent as EditIconSvg } from "../../../images/Icon/pencil.svg";
// RecordScreen
export const MainWrapper = styled(Box)(({ theme }) => ({
  width: "100%",
  height: "auto",
  display: "flex",
  padding: theme.spacing(0, 2.5),
  paddingTop: theme.spacing(2),
}));

export const CardDescription = styled(Box)(({ theme }) => ({
  ...theme.mixins.flexyCenterCol,
  position: "relative",
  margin: theme.spacing(-6, 0, 0, 0),
  padding: theme.spacing(0, 2, 0, 2),

  [theme.breakpoints.up("md")]: {
    padding: theme.spacing(0, 3, 0, 4),
  },
}));
export const CardScreenshotDescription = styled(CardDescription)(({ theme }) => ({
  margin: theme.spacing(0, 0, 0, 0),
}));

export const TitleWrapper = styled(Typography)(({ theme }) => ({
  color: theme.palette.secondary.main,
  textAlign: "center",
  marginBottom: theme.spacing(2),
}));

export const InfoWwrapperHead = styled(Typography)(({ theme }) => ({
  color: theme.palette.danger.main,
  textAlign: "center",
  marginBottom: theme.spacing(2),
  fontWeight: 'bold'
}));
export const InfoWwrapper = styled(Typography)(({ theme }) => ({
  color: theme.palette.grey.darker,
  textAlign: "center",
  marginBottom: theme.spacing(2),
}));

export const IssueLink = styled("a")(({ theme }) => ({
  color: theme.palette.secondary.main,
  "&:hover": {
    color: theme.palette.secondary.main,
    textDecoration: "none",
  },
  "&:visited": {
    color: theme.palette.secondary.main,
  },
}));

export const LinkButton = styled("a")(({ theme }) => ({
  color: theme.palette.secondary.main,
  cursor: "pointer",
  textDecoration: "none",
}));

export const SwitchWrapper = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(3),
  ...theme.mixins.flexyCenterItem,
}));

export const RecordButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.danger.trans20,
  color: theme.palette.danger.main,
  textTransform: "initial",
  marginTop: theme.spacing(3),

  "&:hover": {
    backgroundColor: theme.palette.danger.trans40,
  },
}));

export const RecordIcon = styled(RecordButtonIconSvg)(({ theme }) => ({
  width: 20,
  height: 20,
  marginLeft: theme.spacing(1),
  path: {
    fill: theme.palette.danger.main,
  },
}));

export const LockIcon = styled(LockIconSvg)(({ theme }) => ({
  width: 36,
  height: 36,
  marginRight: theme.spacing(3),
  path: {
    fill: theme.palette.secondary.main,
  },
}));

export const RecordDescription = styled(Box)(({ theme }) => ({
  ...theme.mixins.flexyCenter,
  margin: theme.spacing(3, 0, 0, 0),
}));

// RecordOnGoing
export const StopRecordButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.danger.trans20,
  color: theme.palette.danger.main,
  textTransform: "initial",
  width: "100%",
  marginBottom: theme.spacing(2),

  "&:hover": {
    backgroundColor: theme.palette.danger.trans40,
  },

  [theme.breakpoints.up("sm")]: {
    width: "auto",
    marginRight: theme.spacing(3),
    marginBottom: theme.spacing(0),
  },
}));

export const RedoButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.secondary.trans20,
  color: theme.palette.secondary.main,
  textTransform: "initial",
  padding: "15px",

  "&:hover": {
    backgroundColor: theme.palette.secondary.trans40,
  },
}));

export const RedoIcon = styled(RedoIconSvg)(({ theme }) => ({
  width: 24,
  height: 24,
  marginLeft: theme.spacing(2),

  path: {
    fill: theme.palette.secondary.main,
  },
}));

export const DownloadLargeButton = styled(RedoButton)(({ theme }) => ({
  marginBottom: theme.spacing(2),

  [theme.breakpoints.up("sm")]: {
    marginRight: theme.spacing(3),
    marginBottom: theme.spacing(0),
  },
}));

export const DownloadIconLarge = styled(DownloadIconSvg)(({ theme }) => ({
  width: 24,
  height: 24,
  marginRight: theme.spacing(2),
  path: {
    fill: theme.palette.secondary.main,
  },
}));

export const ActionRecordButtonWrapper = styled(Box)(({ theme }) => ({
  ...theme.mixins.flexyCenterCol,
  marginTop: theme.spacing(3),

  [theme.breakpoints.up("sm")]: {
    flexDirection: "row",
  },
}));

// SaveVideo
export const ProgressPercent = styled(Box)(({ theme }) => ({
  top: 0,
  left: 0,
  bottom: 0,
  right: 0,
  position: "absolute",
  ...theme.mixins.flexyCenterCol,
}));

export const ProgressWrapper = styled(Box)(({ theme }) => ({
  position: "relative",
  display: "inline-flex",
  marginTop: theme.spacing(3),
}));

// ReadyVideo
export const HomeButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.secondary.trans20,
  color: theme.palette.secondary.main,
  textTransform: "initial",
  width: "100%",
  marginTop: theme.spacing(3),

  "&:hover": {
    backgroundColor: theme.palette.secondary.trans40,
  },
  [theme.breakpoints.up("sm")]: {
    width: "auto",
  },
}));

export const RightArrowIcon = styled(RightArrowIconSvg)(({ theme }) => ({
  width: 22,
  height: 22,
  marginLeft: theme.spacing(2),
  path: {
    fill: theme.palette.secondary.main,
  },
}));

export const MainTitleWrapper = styled(Box)(({ theme }) => ({
  margin: theme.spacing(3, 0, 0, 0),
  textAlign: "center",
}));

export const EditIcon = styled(EditIconSvg)(({ theme }) => ({
  marginRight: 2,
  width: 20,
  height: 20,
  cursor: "pointer",
  path: {
    fill: theme.palette.secondary.grey,
  },
}));

export const SubmitIcon = styled(CheckIconSvg)(({ theme }) => ({
  width: 20,
  height: 20,
  cursor: "pointer",
  path: {
    fill: theme.palette.secondary.dark,
  },
}));

export const ActionWrapper = styled(Box)(({ theme }) => ({
  ...theme.mixins.flexyCenter,
  // marginRight: theme.spacing(2.5),
}));

export const EditField = styled(TextField)(({ theme }) => ({
  margin: 0,
  padding: 0,
}));
