import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  historyLinks: {
    results: [],
  },
  historyLinksPage: 1,
  unusedLinkCheck: false
};

export const linksHistory = createSlice({
  name: "linksHistory",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    onHistoryLinks: (state, { payload }) => {
      state.historyLinks = payload;
    },
    onHistoryPageChange: (state, { payload }) => {
      state.historyLinksPage = payload;
    },
    unusedLinkHandle: (state, { payload }) => {
      state.unusedLinkCheck = payload;
    },
  },
});


export const { onHistoryLinks, onHistoryPageChange, unusedLinkHandle } = linksHistory.actions;
export default linksHistory.reducer;
