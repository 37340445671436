import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  selectedTab: 0,
};

export const tabSlice = createSlice({
  name: "tabs",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    changeTab: (state, { payload }) => {
      state.selectedTab = payload;
    },
  },
});
export const selectSettingTabState = state => state.tabs;
export const { changeTab } = tabSlice.actions;
export default tabSlice.reducer;
