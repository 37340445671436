import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  pendingInvites: {
    results: []
  },
  teammates: {
    results: []
  },
  teammatesPage: 1,
  pendingInvitePage: 1,
  loader: true
};

export const inviteSlice = createSlice({
  name: "invites",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    pendingInvites: (state, { payload }) => {
      state.pendingInvites = payload;
      state.loader = false
    },
    acceptedInvites: (state, { payload }) => {
      state.teammates = payload;
      state.loader = false
    },
    teammatesPageChange: (state, { payload }) => {
      state.teammatesPage = payload;
      state.loader = false
    },
    pendingInvitesPageChange: (state, { payload }) => {
      state.pendingInvitePage = payload;
      state.loader = false
    },
  },
});


export const {
  pendingInvites,
  acceptedInvites,
  teammatesPageChange,
  pendingInvitesPageChange,
} = inviteSlice.actions;
export default inviteSlice.reducer;
