import axiosInstance, { axiosPlainInstance } from "../../common/axios";

export function fetchSessionApi(linkId) {
    if (linkId) {
        return axiosPlainInstance.get("api/session");
    } else {
        return axiosInstance.get("api/session");
    }
}

