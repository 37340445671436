import { Box, Button, Card, Link, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import BgImg from '../../../images/blurred_main_bg.png';

export const RootStyle = styled(Box)(({ theme }) => ({
  background: `url(${BgImg}) no-repeat center center`,
  backgroundSize: '100% 100%',
  minHeight: '100vh',
  ...theme.mixins.flexyCenter,

  [theme.breakpoints.up('md')]: {
    display: 'block',
  },
}));

export const FromWrapper = styled(Box)(({ theme }) => ({
  ...theme.mixins.flexyColBetween,
  margin: 0,

  [theme.breakpoints.up('md')]: {
    margin: theme.spacing(0, 2.5),
    flexDirection: 'row',
  },
  [theme.breakpoints.up('lg')]: {
    margin: theme.spacing(0, 17.5),
  },
}));

export const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: '530px',
  margin: 'auto',
  minHeight: '0',
  ...theme.mixins.flexyCenterCol,
  [theme.breakpoints.up('md')]: {
    margin: 0,
    minHeight: '100vh',
  },
}));

export const LogoBox = styled(Box)(({ theme }) => ({
  width: '254px',
  height: '73px',
  margin: theme.spacing(2.5, 0),

  [theme.breakpoints.up('md')]: {
    width: '324px',
    height: '83px',
    margin: theme.spacing(0),
  },
}));

export const FormStyle = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  borderRadius: theme.spacing(5),
  padding: theme.spacing(6, 3.75),
  margin: theme.spacing(0, 1.25),
  boxShadow: '10px 10px 32px 0px rgba(0,0,27,0.8)',

  [theme.breakpoints.up('md')]: {
    margin: theme.spacing(0),
  },
}));

export const LeftSectionStyle = styled(Card)(({ theme }) => ({
  maxWidth: '466px',
  width: '100%',
  backgroundColor: 'transparent',
  boxShadow: 'none',
  display: 'none',

  [theme.breakpoints.up('md')]: {
    ...theme.mixins.flexyCenterCol,
  },
}));

export const SubTitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.common.white,
  marginTop: theme.spacing(2),
}));

export const MainTitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.common.white,
  marginTop: theme.spacing(5.5),
}));

export const FormInfoBox = styled(Box)(({ theme }) => ({
  ...theme.mixins.flexyCol,
}));

export const StyledLink = styled(Link)(({ theme }) => ({
  color: theme.palette.primary.main,
}));

export const StyledTypography = styled(Typography)(({ theme }) => ({
  color: theme.palette.dark.main,
}));

export const SignUpWrapper = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(2.5),
}));

export const BtnSignIn = styled(Button)({
  width: '100%',
});
