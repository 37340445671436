import React from "react";
import {
  CoverImgStyle,
  LogoImgStyle,
  LogoStyle,
  StyledCard,
  WrapperCard,
} from "./RecordScreenCardStyle";
import Banner from "../../images/Banner.png";
import Logo from "../../images/Logo.svg";

const RecordScreenCard = (props) => {
  const { children, user, jiraLogo } = props;
  let bannerImg = null;
  let logoImg = null;
  // let jiraLogo
  if (user) {
    if (jiraLogo) {
      logoImg = jiraLogo;
    } else if (user.company.logo) {
      logoImg = user.company.logo;
    } else {
      logoImg = Logo;
    }
    if (user.company.banner) {
      bannerImg = Banner;
    }

    return (
      <>
        <WrapperCard>
          {bannerImg ? (
            <CoverImgStyle component="img" alt="" src={bannerImg} />
          ) : (
            // <></>
            <div style={{ height: "105px" }} />
          )}

          <StyledCard>
            <LogoStyle>
              <LogoImgStyle component="img" alt="logo" src={logoImg} />
            </LogoStyle>
            {children}
          </StyledCard>
        </WrapperCard>
      </>
    );
  } else {
    return (
      <>
        <WrapperCard>
          <CoverImgStyle />
          <StyledCard>
            <LogoStyle>
              <LogoImgStyle />
            </LogoStyle>
            {children}
          </StyledCard>
        </WrapperCard>
      </>
    );
  }
};

export default RecordScreenCard;
