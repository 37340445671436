import { Box, Link, Stack, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";

export const RecordLinkWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
}));

export const ActionButtonWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-start",
  margin: theme.spacing(0, 1, 0, 1),
  gap: theme.spacing(1),

  "@media (max-width:370px)": {
    margin: theme.spacing(0),
  },

}));

export const ActionLinkWrapperBox = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",

  "@media (min-width:370px)": {
    flexDirection: "row",
  },
}));

export const LinkItemWrapper = styled(Box)(({ theme }) => ({
  margin: theme.spacing(2, 0, 2, 0),
}));

export const LinkItem = styled(Box)(({ theme }) => ({
  transiton: "all 0.5s",
  display: "flex",
  flexDirection: "column",
  width: "100%",
  padding: "10px",

  [theme.breakpoints.up("sm")]: {
    alignItems: "center",
    flexDirection: "row",
    justifyContent: "space-between",
    padding: theme.spacing(2),
  },
}));

export const DividerWrapper = styled(Box)(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    width: "100%",
  },
}));

export const VideoCountWrapper = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  margin: theme.spacing(1, 0),
  [theme.breakpoints.up("sm")]: {
    width: "30%",
    alignItems: "center",
    margin: theme.spacing(0),
  },
}));

export const RecordLink = styled(Link)(({ theme }) => ({
  color: theme.palette.common.black,
  ...theme.typography.lightCallout,
  textDecoration: "none",
  padding: theme.spacing(0, 0, 1, 0),
}));

export const CounterStack = styled(Stack)(({ theme }) => ({
  alignItems: "flex-start",

  [theme.breakpoints.up("sm")]: {
    alignItems: "center",
  },
}));

export const UserHistoryWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  "@media (max-width:370px)": {
    padding: theme.spacing(1, 0, 0, 0),
  },
}));

export const CountBox = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  textDecoration: "none",
  fontWeight: 500,
}));

export const VideoCountTypography = styled(Typography)(({ theme }) => ({
  ...theme.typography.boldH4,
}));

export const UserHistoryText = styled(Typography)(({ theme }) => ({
  ...theme.typography.lightFootnote,
  color: theme.palette.common.black,
  marginLeft: theme.spacing(1),
}));

export const UserName = styled("span")(({ theme }) => ({
  fontWeight: 700,
}));
