import styled from '@emotion/styled';
import { Box, IconButton, MenuItem, Stack, Typography } from '@mui/material';
import { alpha } from '@mui/material/styles';
import { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink as RouterLink, useNavigate } from 'react-router-dom';
import { ReactComponent as ArrowDownSvg } from '../../images/Icon/caret-down.svg';
import { ReactComponent as SignOutSvg } from '../../images/Icon/power-off.svg';
import { ReactComponent as UserSvg } from '../../images/Icon/user.svg';
import { logout } from '../../pages/login/loginSlice';
import MenuPopover from '../common/MenuPopover';
import { selectSessionState } from '../layout/SessionSlice';

// ----------------------------------------------------------------------


const SignOut = styled(SignOutSvg)(({ theme }) => ({
  width: 24,
  height: 24,
  path: {
    fill: theme.palette.grey.main,
  },
}));

const User = styled(UserSvg)(({ theme }) => ({
  width: 24,
  height: 24,
  path: {
    fill: theme.palette.grey.main,
  },
}));

const MenuItemStyled = styled(MenuItem)(({ theme }) => ({
  padding: theme.spacing(1, 2.5)
}))

const MenuItemWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItem: 'center',
  gap: theme.spacing(2),
  // color: theme.palette.grey.main,
  ...theme.typography.boldSubhead
}))

const ArrowDown = styled(ArrowDownSvg)(({ theme }) => ({
  width: 20,
  height: 20,
  marginLeft: '5px !important',
  cursor: 'pointer',
  path: {
    opacity: 0.4,
    fill: theme.palette.grey.main,
  },
}));

// ----------------------------------------------------------------------

export default function MyAccount() {
  const { user } = useSelector(selectSessionState);
  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const handleSignout = (e) => {
    dispatch(logout(navigate));
  };

  return (
    <>

      <Box
        ref={anchorRef}
        onClick={handleOpen}
        sx={{
          cursor: 'pointer',
          ...(open && {
            '&:before': {
              zIndex: 1,
              content: "''",
              position: 'absolute',
              bgcolor: (theme) => alpha(theme.palette.grey[300], 0.12)
            }
          })
        }}
      >
        <Stack direction="row" px={0} spacing={2.25} alignItems="center">
          <Typography variant="boldFootnote" sx={{ color: '#000' }} style={{ marginRight: 10 }}>
            {user.name}
          </Typography>
          <Box
            component="img"
            src={`https://ui-avatars.com/api/?size=32&color=fff&rounded=true&background=6C5DD2&bold=true&name=${user.name}`}
            alt={user.name}
          />
          <ArrowDown />
        </Stack>
      </Box>

      <MenuPopover
        open={open}
        onClose={handleClose}
        anchorEl={anchorRef.current}
        sx={{ width: 220 }}
      >

        <MenuItemStyled
          onClick={handleClose}
          component={RouterLink} to="/app/profile"
        >
          <MenuItemWrapper>
            <Box>
              <User />
            </Box>
            <Typography>
              Profile
            </Typography>
          </MenuItemWrapper>
        </MenuItemStyled>

        <MenuItemStyled
          onClick={handleSignout}

        >
          <MenuItemWrapper >
            <Box>
              <SignOut />
            </Box>
            <Typography>
              Sign out
            </Typography>
          </MenuItemWrapper>
        </MenuItemStyled>


      </MenuPopover>
    </>
  );
}
