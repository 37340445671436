import { Decoder, tools, Reader } from "ts-ebml";
// import axios from 'axios';
import * as Sentry from "@sentry/browser";
import UAParser from "ua-parser-js";
import * as markerjs2 from "markerjs2";
// import 'whatwg-fetch';
import { axiosPlainInstance } from "../../../common/axios";
import {
  setBrowserNotSupported,
  setMicrophoneBlocked,
  setRecordingNotSupported,
  updateJiraUploadStatus,
  updateRecordingState,
  updateScreenshotState,
  setScreenShotImage,
  setScreenShotName,
  updateInstructions,
  updateRecordingStateWithData,
  updateRecordingUploadStuck,
  updateSaveProgress,
  setMarkerImageState,
} from "../RecordSlice";
import { showNotification } from "../../../shared_modules/notification_bar/NotificationBarSlice";

import { isjiraLink, isMondayLink } from "./RecordScreen";

export const STATE_RECORDING_TRIGGERED = "recording-triggered";
export const STATE_RECORDING_ERRORED = "recording-errored";
export const STATE_RECORDING_ERRORED_SYSTEM_PERMISSIONS_FF =
  "recording-errored-system-permissions-ff";
export const STATE_RECORDING_ERRORED_SYSTEM_PERMISSIONS_EDGE =
  "recording-errored-system-permissions-edge";
export const STATE_RECORDING_ERRORED_SYSTEM_PERMISSIONS =
  "recording-errored-system-permissions";
export const STATE_RECORDING_ERRORED_FIREFOX_PERMISSIONS =
  "recording-errored-firefox-permissions";
export const STATE_RECORDING_ERRORED_CHROME_PERMISSIONS =
  "recording-errored-chrome-permissions";
export const STATE_RECORDING_ERRORED_SAFARI_PERMISSIONS =
  "recording-errored-safari-permissions";
export const STATE_RECORDING_ERRORED_EDGE_PERMISSIONS =
  "recording-errored-edge-permissions";
export const STATE_RECORDING_ACTIVE = "recording-active";
export const STATE_RECORDING_EDIT = "recording-edit";
export const STATE_SCREENSHOT_INSTRUCTION_ACTIVE =
  "screenshot-instruction-active";
export const STATE_SCREENSHOT_CAPUTURED = "screenshot-captured";
export const STATE_SCREENSHOT_SENT = "screenshot-sent";
export const STATE_SWITCH_INSTRUCTION = "switch-instruction";
export const STATE_RECORDING_SUBMITTED = "recording-submitted";

export const RECORDING_AUDIO_STARTED = "recording-audio";
export const STATE_RECORDING_COMPLETE = "recording-complete";
export const STATE_RECORDING_SUBMITTED_ERROR = "recording-submitted-error";

export const UPLOAD_STATUS_STARTED = "started";
export const UPLOAD_STATUS_SUCCESS = "success";
export const UPLOAD_STATUS_ERROR = "error";
export const JIRA_SOURCE = "Jira app"
//zendesk
export const ZENDESK_SOURCE = "Zendesk app";

//Intercom
export const INTERCOM_SOURCE = "Intercom app";

//freshchat
export const FRESHCHAT_SOURCE = "Freshchat app";

// monday.com
export const MONDAYCOM_SOURCE = "monday.com app";

const AudioContext =
  window.AudioContext || // Default
  window.webkitAudioContext || // Safari and old versions of Chrome
  false;
let activity_url = "";
let voiceStream;
let canWebm;
let codecString;
let device;
let blobs;
let blob;
let rec;
let stream;
let desktopStream;
let audioM;
let uploadVideoUrl;
let currentLocalRecordingState = "";
export const initRecordingScreen = (dispatch, linkDetails) => {
  Sentry.init({
    dsn: "https://1ca3f76f535b452e8738382cb0d551b6@o44699.ingest.sentry.io/5200427",
  });
  const parser = new UAParser();
  parser.setUA(navigator.userAgent);
  device = parser.getResult();
  activity_url = `/api/_/activity/${linkDetails.uuid}/`;
  uploadVideoUrl = `/api/s/${linkDetails.slug}/`;
  checkRecordingSupport(dispatch);
};
const checkRecordingSupport = (dispatch) => {
  try {
    const excludeBrowsers =
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      );
    const canDispMd = !navigator.mediaDevices.getDisplayMedia;
    const canAudioConst = !AudioContext;
    const canMediaRecorder = !window.MediaRecorder;
    if (excludeBrowsers) {
      recordActivity("recording not supported");
      dispatch(setBrowserNotSupported());
    } else if (canDispMd || canAudioConst || canMediaRecorder) {
      recordActivity("recording not supported");
      dispatch(setRecordingNotSupported());
    }
  } catch (err) {
    recordActivity("recording not supported");
    dispatch(setRecordingNotSupported());
  }

  const videoOnlyCodecs = [
    'video/mp4;codecs="h264"',
    "video/mp4",
    'video/mpeg;codecs="h264"',
    "video/mpeg",
    'video/webm;codecs="vp8"',
    'video/webm;codecs="daala"',
    'video/webm;codecs="h264"',
    "video/mpeg",
    "video/webm",
  ];
  codecString = videoOnlyCodecs.find(isMimeTypeSupported);

  try {
    const testEl = document.createElement("video");
    canWebm = "" !== testEl.canPlayType(codecString);
  } catch (err) {
    recordActivity("recording not supported");
    dispatch(setRecordingNotSupported());
  }
};

function isMimeTypeSupported(mimeType) {
  if (typeof MediaRecorder.isTypeSupported !== "function") {
    return true;
  }
  return MediaRecorder.isTypeSupported(mimeType);
}

export function getCookie(name) {
  const value = "; " + document.cookie;
  const parts = value.split("; " + name + "=");
  if (parts.length === 2) return parts.pop().split(";").shift();
}

function recordActivity(activity_string) {
  if (activity_url) {
    try {
      const data = { activity_type: activity_string };

      const config = {
        headers: {
          "Content-Type": "application/json",
          "X-CSRFToken": getCookie("csrftoken"),
        },
      };
      axiosPlainInstance.post(activity_url, data, config);
    } catch (err) {
      console.log("fetch issue");
    }
  }
}

export const onchangeAudioToggle = async (dispatch, checked) => {
  if (checked) {
    voiceStream = await navigator.mediaDevices
      .getUserMedia({ video: false, audio: true })
      .catch((e) => {
        dispatch(setMicrophoneBlocked(true));
      });
  } else if (voiceStream) {
    voiceStream.getTracks().forEach((s) => s.stop());
  }
};

const updateCurrentRecordingState = (dispatch, currentState) => {
  currentLocalRecordingState = currentState;
  dispatch(updateRecordingState(currentState));
};
const setPermissionsError = (dispatch, error) => {
  const browserName = device.browser.name;
  const osName = device.os.name;
  if (
    error.includes("denied by system") ||
    error.includes("object can not be found")
  ) {
    if (osName === "Mac OS") {
      if (browserName === "Firefox") {
        updateCurrentRecordingState(
          dispatch,
          STATE_RECORDING_ERRORED_SYSTEM_PERMISSIONS_FF
        );
      } else if (browserName === "Edge") {
        updateCurrentRecordingState(
          dispatch,
          STATE_RECORDING_ERRORED_SYSTEM_PERMISSIONS_EDGE
        );
      } else {
        updateCurrentRecordingState(
          dispatch,
          STATE_RECORDING_ERRORED_SYSTEM_PERMISSIONS
        );
      }
    } else {
      updateCurrentRecordingState(dispatch, STATE_RECORDING_ERRORED);
    }
  } else if (
    error.includes("Permission denied") ||
    error.includes(
      "allowed by the user agent or the platform in the current context"
    )
  ) {
    if (browserName === "Firefox") {
      updateCurrentRecordingState(
        dispatch,
        STATE_RECORDING_ERRORED_FIREFOX_PERMISSIONS
      );
    } else if (browserName === "Chrome" || browserName === "Chromium") {
      updateCurrentRecordingState(
        dispatch,
        STATE_RECORDING_ERRORED_CHROME_PERMISSIONS
      );
    } else if (browserName === "Safari") {
      updateCurrentRecordingState(
        dispatch,
        STATE_RECORDING_ERRORED_SAFARI_PERMISSIONS
      );
    } else if (browserName === "Edge") {
      updateCurrentRecordingState(
        dispatch,
        STATE_RECORDING_ERRORED_EDGE_PERMISSIONS
      );
    } else {
      updateCurrentRecordingState(dispatch, STATE_RECORDING_ERRORED);
    }
  } else {
    updateCurrentRecordingState(dispatch, STATE_RECORDING_ERRORED);
  }
};
export const onScreenshotClick = async (dispatch, state) => {
  dispatch(
    updateScreenshotState({
      recordingState: state,
    })
  );
};
export const onSwitchInstructionClick = async (dispatch) => {
  dispatch(updateInstructions());
};
export const handlePaste = async (e, dispatch, imageName, imageLink) => {
  if (e.clipboardData.files.length) {
    const fileObject = e.clipboardData.files[0];
    let date = new Date();
    let name = `Screenshot_${date.getDate()}_${date.getMonth() + 1
      }_${date.getFullYear()}_${date.getTime()}.png`;
    dispatch(
      setMarkerImageState({
        markerImageState: null,
      })
    );
    dispatch(
      setScreenShotImage({
        imageName: name,
        imageLink: URL.createObjectURL(fileObject),
        recordingState: STATE_SCREENSHOT_CAPUTURED,
      })
    );
  }
};

export const handleCopyLinkClick = async (dispatch, url) => {
  navigator.clipboard.writeText(url);
  dispatch(
    showNotification({
      message: `url ${url} copied to clipboard`,
    })
  );
};

let sourceImage, targetRoot;
function setSourceImage(source) {
  sourceImage = source;
  targetRoot = source.parentElement;
}

export const screenshotNameHandler = async (dispatch, name, uuid, note) => {
  dispatch(
    setScreenShotName({
      imageName: name,
      uuid: uuid,
      note: note,
    })
  );
};

export const showMarkerArea = (
  dispatch,
  imgRef,
  underImgRef,
  markerImageState,
  handleOpen,
  linkDetail,
  sendScreenshot,
  isSendScreenshot
) => {
  // create a marker.js MarkerArea
  setSourceImage(underImgRef.current);
  const markerArea = new markerjs2.MarkerArea(sourceImage);
  markerArea.uiStyleSettings.toolbarBackgroundColor = "#6554be";
  markerArea.renderAtNaturalSize = true;
  markerArea.renderImageQuality = 1;
  markerArea.targetRoot = targetRoot;
  markerArea.addEventListener("beforeclose", (event) => {
    event.preventDefault();
  });
  // attach an event handler to assign annotated image back to our image element
  markerArea.addEventListener("render", (event) => {
    //check if jira link

    imgRef.current.src = event.dataUrl;
    dispatch(
      setMarkerImageState({
        markerImageState: event.state,
      })
    );
    dispatch(
      setScreenShotImage({
        editedImgLink: event.dataUrl,
        recordingState: STATE_SCREENSHOT_SENT,
      })
    );
    if (!isjiraLink(linkDetail) &&
      !linkDetail.jsm_id &&
      !isMondayLink(linkDetail)) {
      handleOpen();
    } else {
      sendScreenshot(event.dataUrl);
    }
  });
  // launch marker.js
  markerArea.show();
  if (markerImageState !== "" && markerImageState !== null) {
    markerArea.restoreState(markerImageState);
  }
};

export const onRecordClick = async (dispatch, recordAudioEnabled) => {
  const WIDTH = 500;
  const HEIGHT = 50;
  // Check to see what state it's in, if it's ready to record, start that process
  // if (recordingSection.classList.contains('not-recording')) {
  updateCurrentRecordingState(dispatch, STATE_RECORDING_TRIGGERED);
  recordActivity("clicked record");
  // Get audio recording tracks
  desktopStream = await navigator.mediaDevices
    .getDisplayMedia({ video: true, audio: false })
    .catch((e) => {
      recordActivity("permission denied");
      try {
        setPermissionsError(dispatch, e.toString());
      } catch (err) {
        updateCurrentRecordingState(dispatch, STATE_RECORDING_ERRORED);
        console.log("Screen capturing issue", e);
      }
    });

  let micNumber = 0;
  await navigator.mediaDevices
    .enumerateDevices()
    .then(function (devices) {
      devices.forEach(function (device) {
        if (device.kind === "audioinput") {
          micNumber++;
        }
      });
    })
    .catch((e) => {
      console.log("mic counting error = " + micNumber, e);
    });
  if (desktopStream) {
    let recordingAud;
    stream = desktopStream;
    if (recordAudioEnabled) {
      if (voiceStream) {
        dispatch(
          updateRecordingStateWithData({
            recordingState: STATE_RECORDING_ACTIVE,
            audioRecordingState: RECORDING_AUDIO_STARTED,
          })
        );
        voiceStream.getAudioTracks().forEach((track) => {
          stream.addTrack(track);
        });
        const audioContext = new AudioContext();
        const input = audioContext.createMediaStreamSource(voiceStream);
        audioM = createAudioMeter(audioContext);
        input.connect(audioM);
        const canvasContext = document.getElementById("meter").getContext("2d");
        function drawLoop(time) {
          // clear the background
          canvasContext.clearRect(0, 0, WIDTH, HEIGHT);

          // check if we're currently clipping
          canvasContext.fillStyle = "#ccc";

          // draw a bar based on the current volume
          canvasContext.fillRect(0, 0, audioM.volume * WIDTH * 1.4, HEIGHT);

          // set up the next visual callback
          window.requestAnimationFrame(drawLoop);
        }
        drawLoop();
      }
    }
    stream.getTracks().forEach((s) => {
      if (s.kind === "audio") {
        recordingAud = true;
      }
      s.addEventListener("ended", (e) => {
        rec.stop();
        stream.getTracks().forEach((s) => s.stop());
        stream = null;
      });
    });
    blobs = [];
    const audioCodecs = [
      'video/mp4;codecs="h264,aac"',
      "video/mp4",
      'video/mpeg;codecs="h264,aac"',
      "video/mpeg",
      'video/webm;codecs="vp8,opus"',
      'video/webm;codecs="daala,opus"',
      'video/webm;codecs="h264,opus"',
      "video/mpeg",
      "video/webm",
    ];
    if (recordingAud) {
      codecString = audioCodecs.find(isMimeTypeSupported);
    }
    let streamEnded;
    stream.getTracks().forEach((track) => {
      if (track.readyState === "ended") {
        streamEnded = true;
      }
    });
    if (streamEnded) {
      updateCurrentRecordingState(dispatch, STATE_RECORDING_ERRORED);
    } else {
      rec = new MediaRecorder(stream, { mimeType: codecString });

      rec.addEventListener("dataavailable", (e) => {
        blobs.push(e.data);
      });
      document.title = "Recording...";
      // recordBtn.querySelector('.record-button-text').innerText = "Stop recording";
      updateCurrentRecordingState(dispatch, STATE_RECORDING_ACTIVE);
      rec.onstop = async () => {
        document.title = "Screenjar";
        let setVideoType = "video/webm";
        if (codecString.startsWith("video/mp4")) {
          setVideoType = "video/mp4";
        }
        if (codecString.startsWith("video/mpeg")) {
          setVideoType = "video/mpeg";
        }
        blob = new Blob(blobs, { type: setVideoType });
        updateCurrentRecordingState(dispatch, STATE_RECORDING_EDIT);
        injectMetadata(blob, dispatch);
        if (stream) {
          stream.getTracks().forEach((s) => s.stop());
          stream = null;
        }
        if (voiceStream) {
          voiceStream = null;
        }
        if (audioM) {
          audioM.shutdown();
        }
      };
      rec.onerror = async (event) => {
        console.error(`error recording stream:${event.error}`);
        recordActivity(`error recording stream: ${event.error.name}`);
      };
      rec.start();
    }
    recordActivity("recording started");
  } else {
    if (!currentLocalRecordingStateIsError()) {
      updateCurrentRecordingState(dispatch, STATE_RECORDING_ERRORED);
    }
  }
};

const currentLocalRecordingStateIsError = () => {
  return (
    currentLocalRecordingState === STATE_RECORDING_ERRORED_SYSTEM_PERMISSIONS ||
    currentLocalRecordingState ===
    STATE_RECORDING_ERRORED_FIREFOX_PERMISSIONS ||
    currentLocalRecordingState === STATE_RECORDING_ERRORED_CHROME_PERMISSIONS ||
    currentLocalRecordingState === STATE_RECORDING_ERRORED_SAFARI_PERMISSIONS ||
    currentLocalRecordingState === STATE_RECORDING_ERRORED_EDGE_PERMISSIONS ||
    currentLocalRecordingState ===
    STATE_RECORDING_ERRORED_SYSTEM_PERMISSIONS_FF ||
    currentLocalRecordingState ===
    STATE_RECORDING_ERRORED_SYSTEM_PERMISSIONS_EDGE ||
    currentLocalRecordingState === STATE_RECORDING_ERRORED
  );
};
export const onStopRecordClick = async (dispatch, isRecordingCancelled) => {
  if (rec) {
    if (isRecordingCancelled) {
      rec.onstop = null;
    }
    rec.stop();
    stream.getTracks().forEach((s) => s.stop());
    stream = null;
  }
};
function createAudioMeter(audioContext, clipLevel, averaging, clipLag) {
  var processor = audioContext.createScriptProcessor(512);
  processor.onaudioprocess = volumeAudioProcess;
  processor.clipping = false;
  processor.lastClip = 0;
  processor.volume = 0;
  processor.clipLevel = clipLevel || 0.98;
  processor.averaging = averaging || 0.95;
  processor.clipLag = clipLag || 750;

  processor.connect(audioContext.destination);

  processor.checkClipping = function () {
    if (!this.clipping) return false;
    if (this.lastClip + this.clipLag < window.performance.now())
      this.clipping = false;
    return this.clipping;
  };

  processor.shutdown = function () {
    this.disconnect();
    this.onaudioprocess = null;
  };
  return processor;
}

function volumeAudioProcess(event) {
  var buf = event.inputBuffer.getChannelData(0);
  var bufLength = buf.length;
  var sum = 0;
  var x;
  for (var i = 0; i < bufLength; i++) {
    x = buf[i];
    if (Math.abs(x) >= this.clipLevel) {
      this.clipping = true;
      this.lastClip = window.performance.now();
    }
    sum += x * x;
  }
  var rms = Math.sqrt(sum / bufLength);
  this.volume = Math.max(rms, this.volume * this.averaging);
}

const injectMetadata = function (blob, dispatch) {
  const decoder = new Decoder();
  const reader = new Reader();
  reader.logging = false;
  reader.drop_default_duration = false;

  readAsArrayBuffer(blob)
    .then((buffer) => {
      const elms = decoder.decode(buffer);
      elms.forEach((elm) => {
        reader.read(elm);
      });
      reader.stop();

      var refinedMetadataBuf = tools.makeMetadataSeekable(
        reader.metadatas,
        reader.duration,
        reader.cues
      );
      var body = buffer.slice(reader.metadataSize);

      const result = new Blob([refinedMetadataBuf, body], { type: blob.type });
      submitVideo(result, dispatch);
    })
    .catch((e) => {
      submitVideo(blob, dispatch);
    });
};

const readAsArrayBuffer = function (blob) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsArrayBuffer(blob);
    reader.onloadend = () => {
      resolve(reader.result);
    };
    reader.onerror = (ev) => {
      reject(ev.error);
    };
  });
};
function sleep(time) {
  return new Promise((resolve) => setTimeout(resolve, time));
}

// export function setVideo(blob, dispatch) {
//   dispatch(
//     updateRecordingStateWithData({ recordingState: STATE_RECORDING_EDIT })
//   );
//   dispatch(
//     updateVideoEdit({
//       videoBlobLink: objectURL,
//       blob: blob,
//     })
//   );
// }

export function submitVideo(blob, dispatch) {
  recordActivity("recording stopped");
  dispatch(
    updateRecordingStateWithData({ recordingState: STATE_RECORDING_SUBMITTED })
  );
  const formData = new FormData();
  formData.append("video", blob);
  document.title = "Uploading video...";
  sleep(100000).then(() => {
    const objectURL = URL.createObjectURL(blob);
    dispatch(
      updateRecordingUploadStuck({
        videoBlobLink: objectURL,
        blob: blob,
      })
    );
  });
  axiosPlainInstance
    .post(uploadVideoUrl, formData, {
      method: "POST", // or 'PUT'
      credentials: "same-origin",
      processData: false,
      contentType: false,
      timeout: 500000,
      onUploadProgress: function (progressEvent) {
        const percentCompleted = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );
        dispatch(updateSaveProgress(percentCompleted));
      },
      headers: {
        "X-CSRFToken": getCookie("csrftoken"),
      },
    })
    .then((data) => {
      dispatch(
        updateRecordingStateWithData({
          recordingState: STATE_RECORDING_COMPLETE,
          videoLink: data.data.video,
          canWebm: canWebm,
          videoName: data.data.name,
          uuid: data.data.uuid,
        })
      );
      document.title = "Screenjar";
    })
    .catch((error) => {
      const objectURL = URL.createObjectURL(blob);
      if (error.response) {
        console.log("Error", error, error.response);
      } else {
        console.log("Error", error);
      }
      dispatch(
        updateRecordingStateWithData({
          recordingState: STATE_RECORDING_SUBMITTED_ERROR,
          videoBlobLink: objectURL,
          blob: blob,
        })
      );
      document.title = "Screenjar";
      recordActivity("upload error");
    });
}

export function uploadToJiraHanlder(linkDetails, dispatch, videoName) {
  dispatch(updateJiraUploadStatus({ status: UPLOAD_STATUS_STARTED }));
  const uploadVideoToJiraUrl = `/api/s/${linkDetails.slug}/jira-app/upload/`;
  axiosPlainInstance
    .post(
      uploadVideoToJiraUrl,
      { file_name: videoName },
      {
        method: "POST",
        credentials: "same-origin",
        processData: false,
        contentType: false,
        timeout: 500000,
        headers: {
          "X-CSRFToken": getCookie("csrftoken"),
        },
      }
    )
    .then((response) => {
      dispatch(updateJiraUploadStatus(response.data));
      if (response.data.status === "success") {
        dispatch(
          showNotification({
            message: response.data.message,
          })
        );
      } else {
        dispatch(
          showNotification({
            severity: "error",
            message: response.data.message,
          })
        );
        if (response.data.exception) {
          console.log(response.data.exception);
        }
      }
    })
    .catch((error) => {
      dispatch(
        updateJiraUploadStatus({
          status: UPLOAD_STATUS_ERROR,
          message: "Error occured while uploading. please try again",
        })
      );
      dispatch(
        showNotification({
          severity: "error",
          message: "Error occured while uploading. please try again",
        })
      );
    });
}

export function uploadToVideo(
  linkDetails,
  dispatch,
  integration,
  updatestatusfunction,
  uploadstatuserror
) {
  const uploadVideo = `/${integration}/${linkDetails.slug}/upload/`;

  dispatch(updatestatusfunction({ status: UPLOAD_STATUS_STARTED }));
  axiosPlainInstance
    .post(
      uploadVideo,
      {},
      {
        method: "POST",
        credentials: "same-origin",
        processData: false,
        contentType: false,
        timeout: 500000,
        headers: {
          "X-CSRFToken": getCookie("csrftoken"),
        },
      }
    )
    .then((response) => {
      dispatch(updatestatusfunction(response.data));

      if (response.data.status === "success") {
        dispatch(
          showNotification({
            message: response.data.message,
          })
        );
      } else {
        dispatch(
          showNotification({
            severity: "error",
            message: response.data.message,
          })
        );
        if (response.data.exception) {
          console.log(response.data.exception);
        }
      }
    })
    .catch((error) => {
      dispatch(
        updatestatusfunction({
          status: uploadstatuserror,
          message: "Error occured while uploading. please try again",
        })
      );
      dispatch(
        showNotification({
          severity: "error",
          message: "Error occured while uploading. please try again",
        })
      );
    });
}
