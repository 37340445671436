import { Avatar, Box, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import React from "react";
import { FlexyCol } from "./StyledClass";

const UserBox = styled(Box)(({ theme }) => ({
  ...theme.mixins.flexyBetween,
}));

const UserAvatar = styled(Avatar)({
  width: "54px",
  height: "54px",
});

const UserName = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.main,
  cursor: "pointer",
}));

const UserMail = styled(Typography)(({ theme }) => ({
  color: theme.palette.dark.main,
}));

const UserDetails = ({ userDetails, noImg, text }) => {
  return (
    <>
      <UserBox>
        {noImg ? (
          <Typography variant="boldH5">{text}</Typography>
        ) : (
          <UserAvatar
            src={`https://ui-avatars.com/api/?size=68&color=fff&rounded=false&bold=true&background=random&name=${userDetails?.name}`}
          />
        )}
        <FlexyCol ml={1}>
          <UserName variant="boldH6">{userDetails?.name}</UserName>
          <UserMail variant="lightFootnote">{userDetails?.email}</UserMail>
        </FlexyCol>
      </UserBox>
    </>
  );
};

export default UserDetails;

UserDetails.defaultProps = {
  noImg: false,
};
