import { IconButton } from '@mui/material';
import { styled } from '@mui/system';
import React from 'react';
import { ReactComponent as CopyIconSvg } from '../../images/Icon/link.svg';

const CopyIconBtn = styled(CopyIconSvg)(({ theme }) => ({
  width: 16,
  height: 16,
  path: {
    fill: theme.palette.secondary.main,
  },
}));

const CopyButton = styled(IconButton)(({ theme }) => ({
  boxShadow: 'none',
  padding: '8px',
  display: 'flex',
  borderRadius: '8px',
  lineHight: 0,
  backgroundColor: theme.palette.secondary.trans5,
  '&:hover': {
    backgroundColor: theme.palette.secondary.trans5,
    boxShadow: 'none',
  },
}));

const CopyIcon = (props) => {
  return (
    <>
      <CopyButton onClick={props.onClick}>
        <CopyIconBtn />
      </CopyButton>
    </>
  );
};

export default CopyIcon;
