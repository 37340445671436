import { styled } from "@mui/material/styles";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Outlet, useParams } from "react-router-dom";
import Navbar from "../navbar/Navbar";
import Sidebar from "../navbar/Sidebar";
import { fetchSession, selectSessionState } from "./SessionSlice";
import NotificationBar from "../../shared_modules/notification_bar/NotificationBar";
import { WhatsNew } from "../notification_bar/WhatsNew";
import { AddLibrary } from "../common/Utils";
// ----------------------------------------------------------------------

const RootStyle = styled("div")({
  display: "flex",
  minHeight: "100%",
  overflow: "hidden",
});

const MainStyle = styled("div")(({ theme }) => ({
  flexGrow: 1,
  overflow: "auto",
  minHeight: "100%",
  paddingTop: "64px",
  paddingBottom: theme.spacing(10),
  [theme.breakpoints.up("lg")]: {
    paddingTop: "92px",
  },
}));

// ----------------------------------------------------------------------

export function HomeLayout() {
  const [open, setOpen] = useState(false);
  const { user, isGuest } = useSelector(selectSessionState);
  let { linkId } = useParams();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchSession(linkId));
  }, [dispatch, linkId]);

  return (
    <>
      {user !== null && !isGuest && (
        <>
          {/* Alert Mini header */}

          {/* Main */}
          <RootStyle>
            <Navbar onOpenSidebar={() => setOpen(true)} />
            <Sidebar
              isOpenSidebar={open}
              onCloseSidebar={() => setOpen(false)}
            />
            <MainStyle>
              <Outlet />
            </MainStyle>
          </RootStyle>
          <WhatsNew />
          {AddLibrary()}
        </>
      )}
      {user === null && isGuest && linkId !== null && linkId !== undefined && (
        <>
          <Outlet />
        </>
      )}
      <NotificationBar />
    </>
  );
}
