import { Box, Button, Divider, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { ReactComponent as EditIconSvg } from '../../../images/Icon/edit.svg';
import { StyledCardLg } from '../../../shared_modules/common/StyledCard';

export const ProfileCard = styled(StyledCardLg)(({ theme }) => ({
  borderRadius: theme.spacing(3),
  padding: theme.spacing(3),
  ...theme.mixins.flexyItemCenterCol,

  [theme.breakpoints.up('md')]: {
    padding: theme.spacing(6),
    borderRadius: theme.spacing(5),
    ...theme.mixins.flexyItemStart,
  },
}));


//following comments can be restored after adding profile picture feature
export const ImageWrapper = styled(Box)(({ theme }) => ({
  background: theme.palette.grey.lighter,
  borderRadius: theme.spacing(2),
  padding: theme.spacing(2, 2, 2, 2), //theme.spacing(0, 2, 2, 2),
  margin: theme.spacing(0),
  width: '250px',
  height: '250px', //'300px',
  ...theme.mixins.flexyColBetween,

  [theme.breakpoints.up('md')]: {
    margin: theme.spacing(0, 10, 0, 0),
    width: '292px',
    height: '292px', //'344px',
  },
}));

export const ImageTitle = styled(Box)(({ theme }) => ({
  ...theme.mixins.flexyBetween,
  margin: theme.spacing(2, 0, 2.5, 0),
}));

export const EditIcon = styled(EditIconSvg)(({ theme }) => ({
  cursor: 'pointer',
  path: {
    fill: theme.palette.dark.main,
  },
}));

export const UserImage = styled(Box)(({ theme }) => ({
  borderRadius: theme.spacing(3),
  width: '220px',
  height: '200px',

  [theme.breakpoints.up('md')]: {
    width: '260px',
    height: '260px',
  },
}));

export const InfoWrapper = styled(Box)(({ theme }) => ({
  ...theme.mixins.flexyCol,
  flex: 1,
  margin: theme.spacing(3, 0, 0, 0),

  [theme.breakpoints.up('md')]: {
    margin: theme.spacing(0, 10, 0, 0),
  },

  [theme.breakpoints.up('xl')]: {
    margin: theme.spacing(0, 20, 0, 0),
  },
}));

export const InfoDescription = styled(Box)(({ theme }) => ({
  margin: theme.spacing(2, 0, 0, 0),
}));

export const FieldWrapper = styled(Box)(({ theme }) => ({
  margin: theme.spacing(3, 0, 2, 0),
}));

export const NotifiedWrapper = styled(Box)(({ theme }) => ({
  margin: theme.spacing(3, 0),
}));

export const DividerStyled = styled(Divider)(({ theme }) => ({
  margin: theme.spacing(3, 0),
}));

export const ButtonStyled = styled(Button)(({ theme }) => ({
  marginTop: theme.spacing(3),
}));

export const StyledTypography = styled(Typography)(({ theme }) => ({
  color: theme.palette.dark.main,
}));