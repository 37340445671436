import styled from '@emotion/styled';
import { Card } from '@mui/material';

const styledCard = styled(Card)(({ theme }) => ({
  position: 'relative',
  background: theme.palette.common.white,
  boxShadow: '0px 0px 43px 1px rgb(240,243,246)',
}));

export const StyledCardMd = styled(styledCard)(({ theme }) => ({
  borderRadius: theme.spacing(3),
}));

export const StyledCardLg = styled(styledCard)(({ theme }) => ({
  borderRadius: theme.spacing(5),
}));
