import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

export const WrapperCard = styled(Box)(({ theme }) => ({
  borderRadius: theme.spacing(3),
  width: '100%',
  maxWidth: '470px',
  backgroundColor: 'transparent',
  position: 'relative',
  margin: 'auto',
  boxShadow: '0px 0px 43px 1px rgb(240,243,246)',
}));

export const StyledCard = styled(Box)(({ theme }) => ({
  position: 'relative',
  background: theme.palette.common.white,
  borderRadius: theme.spacing(3),
  maxWidth: '470px',
  transform: 'translateY(-5%)',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
}));

export const CoverImgStyle = styled(Box)({
  borderRadius: '24px 24px 0 0',
  maxHeight: '170px',
  width: '100%',
});

export const LogoStyle = styled(Box)(({ theme }) => ({
  marginLeft: 'auto',
  marginRight: 'auto',
  transform: 'translateY(-50%)',
  position: 'relative',
  zIndex: 10,
  height: 80,
  width: 80,

  [theme.breakpoints.up('sm')]: {
    height: 120,
    width: 120,
  },
}));

export const LogoImgStyle = styled(Box)(({ theme }) => ({
  display: 'block',
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  border: '5px solid #fff',
  borderRadius: theme.spacing(3),
}));
