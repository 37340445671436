import {
  Box,
  Grid,
  Tooltip,
  Typography,
  Zoom,
  Modal,
  IconButton,
} from "@mui/material";
// import Collapse from '@mui/material/Collapse';
// import { useState } from 'react';
import { useDispatch } from "react-redux";
import { NavLink as RouterLink } from "react-router-dom";
import { getMetaTag, META_TAG_BASE_URL } from "../../common/MetaTagReader";
import ConfirmDialog from "../common/ConfirmDialog";
import {
  //PrimaryBadge,
  SuccessBadge,
} from "../common/RoundIconBadge";
import { StyledCardMd } from "../common/StyledCard";
import { FlexyCenter, FlexyCenterItem } from "../common/StyledClass";
import { downloadFile, getVideoType } from "../common/Utils";
import CopyIcon from "../icon_with_button/CopyIcon";
import DeleteIcon from "../icon_with_button/DeleteIcon";
import DownloadIcon from "../icon_with_button/DownloadIcon";
import CropFreeIcon from "@mui/icons-material/CropFree";
import "./InboxCard.css";
import CloseIcon from "@mui/icons-material/Close";
import {
  CardContentStyle,
  CardMediaStyle,
  // CollapseBox,
  DetailsButton,
  // ItemBox,
  MainWrapper,
  StyledVideo,
  InboxText,
  StyledImageCard,
} from "./InboxStyle";
import { useState, useRef } from "react";
import { handleCopyLinkClick } from "../../pages/record/components/RecordingUtil";
import {
  TransformWrapper,
  TransformComponent,
} from "@pronestor/react-zoom-pan-pinch";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import CropRotateIcon from "@mui/icons-material/CropRotate";

// ----------------------------------------------------------------------

export default function InboxCard({
  item,
  deleteVideo,
  params,
  pageNumber,
  videoCount,
  screenshotCount,
}) {
  // const { sources } = item;
  const baseURL = `${getMetaTag(META_TAG_BASE_URL)}`;
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);
  const [rotation, setRotation] = useState(0);

  const handleOpen = () => setShowModal(true);
  const handleClose = () => setShowModal(false);
  const modalImageRef = useRef();
  const rotate = () => {
    let newRotation = rotation;
    if (rotation >= 360) {
      newRotation -= 360;
    } else {
      newRotation = newRotation + 90;
    }
    setRotation(newRotation);
  };
  let rotateCondition = rotation === 0 || rotation === 180 || rotation === 360;
  // const poster = item.poster_url ? item.poster_url : "";
  // const theme = useTheme();
  // const [switchChecked, setSwitchChecked] = useState(false);
  // console.log(item);

  return (
    <Grid item xs={12} sm={6} md={4}>
      <StyledCardMd>
        <CardMediaStyle>
          {item.video_url ? (
            <StyledVideo
              controls
              data-recording_uuid={item.uuid}
              style={{ height: 180 }}
              poster={item.poster_url ? item.poster_url : ""}
              preload="metadata"
            >
              <source
                src={baseURL + item.video_url}
                type={getVideoType(item.video_url)}
              />
            </StyledVideo>
          ) : (
            <>
              <Modal
                keepMounted
                open={showModal}
                onClose={handleClose}
                aria-labelledby="keep-mounted-modal-title"
                aria-describedby="keep-mounted-modal-description"
              >
                <Box className="modalImageContainer">
                  <TransformWrapper
                    initialScale={1}
                    initialPositionX={200}
                    initialPositionY={100}
                    centerOnInit={true}
                    centerZoomedOut={true}
                  >
                    {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                      <>
                        <div className="modalTopBar">
                          <Typography
                            sx={{
                              color: "white",
                            }}
                          >
                            {item.file_name}
                          </Typography>
                          <div className="iconsContainer iconsContainerPosition">
                            <div className="iconsContainer">
                              <IconButton
                                className="modalIcon"
                                onClick={() => {
                                  rotate();
                                  resetTransform();
                                }}
                              >
                                <CropRotateIcon className="icon" />
                              </IconButton>
                              <IconButton
                                className="modalIcon"
                                sx={{ marginLeft: 2 }}
                                onClick={() => {
                                  zoomIn();
                                }}
                              >
                                <AddIcon className="icon" />
                              </IconButton>
                              <IconButton
                                className="modalIcon"
                                sx={{ marginRight: 2 }}
                                onClick={() => {
                                  zoomOut();
                                }}
                              >
                                <RemoveIcon className="icon" />
                              </IconButton>
                            </div>
                            <IconButton
                              className="modalIcon"
                              onClick={() => {
                                handleClose();
                                resetTransform();
                                setRotation(0);
                              }}
                            >
                              <CloseIcon className="icon" />
                            </IconButton>
                          </div>
                        </div>
                        <TransformComponent
                          contentStyle={{
                            justifyContent: "center",
                            height: rotateCondition ? "auto" : "100%",
                          }}
                        >
                          <img
                            src={baseURL + item.screenshot_url}
                            alt={item.file_name}
                            className={
                              rotateCondition
                                ? "imageNormalAngle"
                                : "imageSideAngle"
                            }
                            style={{
                              height: rotateCondition ? "95%" : "65%",
                              width: rotateCondition ? "75%" : "80%",
                              justifySelf: "center",
                              transform: `rotate(${rotation}deg)`,
                            }}
                          />
                        </TransformComponent>
                      </>
                    )}
                  </TransformWrapper>
                </Box>
              </Modal>
              <StyledImageCard style={{ height: 180 }} onClick={handleOpen}>
                <img
                  className="image"
                  src={baseURL + item.screenshot_url}
                  alt={item.file_name}
                />
                <div className="overlay"></div>
                <CropFreeIcon className="modalShowIcon" />
              </StyledImageCard>
            </>
          )}
        </CardMediaStyle>

        <CardContentStyle>
          <MainWrapper>
            <FlexyCenter>
              {/* <PrimaryBadge />   */}
              <InboxText variant="lightCaption">
                {videoCount > 1 ? `${videoCount - 1} more` : ""}
              </InboxText>
            </FlexyCenter>

            <FlexyCenter>
              <SuccessBadge />
              <InboxText variant="lightCaption">
                {item.is_new ? item.human_created : item.date_created}
              </InboxText>
            </FlexyCenter>
          </MainWrapper>

          {/* <FlexyBetween my={1.5}>
            <Flexy>
              <UserAvatar
                src={'https://randomuser.me/api/portraits/women/4.jpg'}
              />
              <UserName variant="boldCallout">Dianne Russell</UserName>
            </Flexy>

            <BasicSwitch
              checked={switchChecked}
              onChange={() => {
                setSwitchChecked(!switchChecked);
              }}
            />
          </FlexyBetween> */}

          {/* <Collapse in={switchChecked}>
            {switchChecked === true ? (
              <>
                <CollapseBox>
                  <Typography variant="lightSmall">
                    Anyone with this link can access this video
                  </Typography>
                  <ItemBox sx={{}}>
                    <Typography variant="lightCaption">
                      https://screenjar.com/share/a608a80c/
                    </Typography>
                  </ItemBox>
                </CollapseBox>
              </>
            ) : (
              ''
            )}
          </Collapse> */}

          <FlexyCenterItem pt={1.5}>
            <DetailsButton
              component={RouterLink}
              to={`/app/details/${item.slug}/${item.uuid}${
                params ? "?on_root=true" : ""
              }`}
            >
              <Typography variant="boldFootnote">Details</Typography>
            </DetailsButton>

            {/* Copy */}
            <Tooltip TransitionComponent={Zoom} title={`Click to copy url`}>
              <Box
                onClick={() => handleCopyLinkClick(dispatch, item.record_link)}
              >
                <CopyIcon />
              </Box>
            </Tooltip>

            {/* Download */}
            <Tooltip TransitionComponent={Zoom} title=" Download">
              <Box
                onClick={() => {
                  if (item.video_url) {
                    downloadFile(baseURL + item.video_url, item.file_name);
                  } else {
                    downloadFile(baseURL + item.screenshot_url, item.file_name);
                  }
                }}
              >
                <DownloadIcon />
              </Box>
            </Tooltip>

            {/* Delete */}
            <ConfirmDialog
              key={"dashboard-confirm-" + item.id}
              description={
                item.video_url
                  ? "Are you sure you want to delete this video?"
                  : "Are you sure you want to delete this image?"
              }
              onConfirm={() => deleteVideo(item.uuid, pageNumber)}
            >
              <Tooltip TransitionComponent={Zoom} title=" Delete">
                <Box>
                  <DeleteIcon />
                </Box>
              </Tooltip>
            </ConfirmDialog>
          </FlexyCenterItem>
        </CardContentStyle>
      </StyledCardMd>
    </Grid>
  );
}

InboxCard.defaultProps = {
  params: false,
};
