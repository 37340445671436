import {
  Avatar,
  Box,
  Button,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { ReactComponent as ComputerIconSvg } from "../../../images/Icon/computer-speaker.svg";
import { ReactComponent as DownloadIconSvg } from "../../../images/Icon/download.svg";
import { ReactComponent as CopyIconSvg } from "../../../images/Icon/link-horizontal.svg";
import { ReactComponent as LocationIconSvg } from "../../../images/Icon/location-dot.svg";
import Collapse from "@mui/material/Collapse";
import { ReactComponent as Plus } from "../../../images/Icon/plus.svg";

export const CopyIcon = styled(CopyIconSvg)(({ theme }) => ({
  width: 22,
  height: 22,
  cursor: "pointer",
  path: {
    fill: theme.palette.secondary.main,
  },
}));

export const DownloadIcon = styled(DownloadIconSvg)(({ theme }) => ({
  width: 16,
  height: 16,
  path: {
    fill: theme.palette.primary.main,
  },
}));

export const LocationIcon = styled(LocationIconSvg)(({ theme }) => ({
  width: 18,
  height: 24,
  margin: theme.spacing(0, 1),

  path: {
    fill: theme.palette.grey.main,
  },
}));

export const ComputerIcon = styled(ComputerIconSvg)(({ theme }) => ({
  width: 24,
  height: 16.8,
  margin: theme.spacing(0, 1),
  path: {
    fill: theme.palette.grey.main,
  },
}));

export const CardMediaStyle = styled(Box)(({ theme }) => ({
  padding: theme.spacing(1),
  width: "100%",
  height: "100%",
  overflow: "hidden",
}));

export const DownloadButton = styled(IconButton)(({ theme }) => ({
  boxShadow: "none",
  padding: theme.spacing(1),
  marginRight: theme.spacing(1.5),
  display: "flex",
  borderRadius: theme.spacing(1),
  color: theme.palette.primary.main,
  lineHight: 0,
  backgroundColor: theme.palette.primary.trans5,
  "&:hover": {
    backgroundColor: theme.palette.primary.trans5,
    boxShadow: "none",
  },
  [theme.breakpoints.up("md")]: {
    margin: theme.spacing(0, 1),
  },
}));

export const ContentItem = styled(Box)(({ theme }) => ({
  display: "flex",
  flexWrap: "wrap",
  justifyContent: "flex-start",
  alignItems: "flex-start",
  flexDirection: "column",

  [theme.breakpoints.up("md")]: {
    justifyContent: "space-between",
    alignItems: "center",
    flexDirection: "row",
  },
}));

export const UserBox = styled(Box)(({ theme }) => ({
  ...theme.mixins.flexyBetween,
}));

export const UserAvatar = styled(Avatar)({
  width: "54px",
  height: "54px",
});

export const UserName = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.main,
  cursor: "pointer",
}));

export const UserMail = styled(Typography)(({ theme }) => ({
  color: theme.palette.dark.main,
}));

export const ActionBox = styled(Box)(({ theme }) => ({
  display: "flex",
  flexWrap: "wrap",
  flexDirection: "column",
  justifyContent: "flex-start",
  alignItems: "flex-start",

  [theme.breakpoints.up("md")]: {
    justifyContent: "space-between",
    alignItems: "center",
    flexDirection: "row",
  },
}));

export const BrowserBox = styled(Box)(({ theme }) => ({
  ...theme.mixins.flexyCenter,
  marginTop: theme.spacing(2.5),
  [theme.breakpoints.up("md")]: {
    margin: theme.spacing(0, 3.75),
  },
}));

export const LocationBox = styled(Box)(({ theme }) => ({
  ...theme.mixins.flexyCenter,
  marginRight: theme.spacing(2.5),
}));

export const OtherBox = styled(Box)(({ theme }) => ({
  ...theme.mixins.flexyCenter,
  marginTop: theme.spacing(1.5),

  [theme.breakpoints.up("md")]: {
    margin: theme.spacing(0),
  },
}));

export const StyledVideo = styled("video")({
  width: "100%",
  height: "100%",
  borderRadius: "16px",
  display: "block",
});

export const ExpandCollapse = styled(Collapse)(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    width: "100%",
  },
}));

export const TitleWrapperMoreVideos = styled(Box)({
  marginTop: "54px",
  marginBottom: "49px",
});

export const TitleWrapperActivity = styled(Box)({
  marginTop: "50px",
  marginBottom: "25px",
});

export const CollapseBox = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  background: theme.palette.grey.lighter,
  borderRadius: theme.spacing(1),
  padding: theme.spacing(1),
  position: "relative",
  lineHeight: 1.2,
}));

export const ItemBox = styled(Box)(({ theme }) => ({
  background: theme.palette.common.white,
  borderRadius: theme.spacing(1),
  padding: theme.spacing(1, 1.25),
  margin: theme.spacing(1, 1, 0, 1),
  position: "relative",
  ...theme.mixins.flexyBetween,
}));

export const LinkWrapper = styled(Box)(({ theme }) => ({
  ...theme.mixins.flexyCenterItem,
  margin: theme.spacing(2.5, 0),
  flexWrap: "wrap",
}));

export const SharingWrapper = styled(Box)(({ theme }) => ({
  ...theme.mixins.flexyCenterItem,
  marginBottom: theme.spacing(2),
}));

export const NoteTextAreaStyle = styled(Box)(({ theme }) => ({
  backgroundColor: "#f4f4f4",
}));

export const EditNoteButton = styled(Button)(({ theme }) => ({
  padding: theme.spacing(1, 4),
  textTransform: "initial",
  "&:disabled": {
    backgroundColor: theme.palette.secondary.lighter,
  },
}));

export const ShareVideo = styled(Grid)(({ theme }) => ({
  width: "100%",
}));

export const AddIcon = styled(Plus)(({ theme }) => ({
  width: 16,
  height: 16,
  marginRight: theme.spacing(1.5),
  path: {
    fill: theme.palette.primary.main,
  },
}));

export const AddButton = styled(IconButton)(({ theme }) => ({
  boxShadow: "none",
  // padding: theme.spacing(1),
  marginRight: theme.spacing(1),
  display: "flex",
  borderRadius: theme.spacing(1),
  color: theme.palette.primary.main,
  lineHight: 0,
  backgroundColor: theme.palette.primary.trans5,
  "&:hover": {
    backgroundColor: theme.palette.primary.trans5,
    boxShadow: "none",
  },
  [theme.breakpoints.up("md")]: {
    margin: theme.spacing(0, 0),
  },
}));

export const MessageWrapper = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(2),
  ...theme.mixins.flexyCol,
}));

// export const EditNoteTextArea = styled(TextareaAutosize)(({ theme }) => ({
//   boxShadow: 'none',
//   padding: theme.spacing(1),
//   borderRadius: theme.spacing(1),
//   color: theme.palette.common.black,
//   backgroundColor: theme.palette.grey.lighter,
//   borderColor:theme.palette.grey.lighter,
//   width:'100%',
//   resize: 'vertical',
//   ...theme.typography.lightSubTitle

// }));
