import React, { useState } from 'react';
import { Stack, TextField, Button, Box, Typography } from '@mui/material';
import {
    LeftSectionStyle,
    ContentStyle,
    RootStyle,
    FormStyle,
    LogoBox,
    FromWrapper,
    SubTitle,
    StyledTypography,
    StyledLink,
    MainTitle,
} from '../../sign-up/components/SignUpStyle';
import { Link as RouterLink } from 'react-router-dom';
import Logo from '../../../images/LogoWhite.svg';
import { ResetPasswordAPI } from '../PasswordResetAPI';


export function SendResetEmail() {

    const [errorMessage, setErrorMessage] = useState('');
    const [email, setEmail] = useState('');
    const [emailSent, setEmailSent] = useState(false)
    const search = window.location.search;
    const paramss = new URLSearchParams(search);
    const source_data = paramss.get('source_z');
    let LOGIN_USER_REDIRECT = "/app/login";
    let CREATE_USER_REDIRECT = `/app/signup`;

    if (source_data) {
        const source_arr = source_data.split(";");
        let source_z = source_arr[0];
        if (source_z === 'zendesk' || source_z === 'intercom' || source_z === 'freshchat') {
            LOGIN_USER_REDIRECT = LOGIN_USER_REDIRECT + `?source_z=` + source_data;
            CREATE_USER_REDIRECT = CREATE_USER_REDIRECT + `?source_z=` + source_data;
        }
    }
    const handleChange = (e) => {
        setEmail(e.target.value);
    };

    const handleResend = (e) => {
        e.preventDefault();
        setEmailSent(false);
    }

    const handleSubmit = (e) => {
        e.preventDefault();

        if (email === '' || email === null) {
            setErrorMessage('Please enter your email')
        }
        const emailid = new FormData();
        emailid.append('email', email);
        ResetPasswordAPI(emailid).then((res) => {
            if (res.data.status === 'success') {
                setEmailSent(true);
            }
            if (res.data.status === 'error') {
                if ("email" in res.data) {
                    setErrorMessage(res.data.email);
                }
            }
        }).catch(err => {
            let errorobj = err.response.data;
            if ("email" in errorobj) {
                setErrorMessage(errorobj.email);
            }
            else {
                setErrorMessage("Something went wrong. Please try again later.");
            }
        });

    };
    return (
        <RootStyle>
            <FromWrapper>
                <Box>
                    <LogoBox component="img" alt="register" src={Logo} />
                    <LeftSectionStyle>
                        <MainTitle variant="boldH3">
                            Skip the customer support back-and-forth
                        </MainTitle>
                        <SubTitle variant="lightSubtitle">
                            Skip the custoMake it ridiculously easy for customers to send you
                            video recordings of their screen. No download/install required.mer
                            support back-and-forth
                        </SubTitle>
                    </LeftSectionStyle>
                </Box>

                <ContentStyle>
                    <FormStyle component="form" autoComplete="off" >
                        <Stack spacing={3}>
                            {/* <SignUpWrapper> */}
                            <StyledTypography variant="boldH2">Reset your password</StyledTypography>
                            {/* </SignUpWrapper> */}
                            <StyledTypography variant="lightCallout">
                                Already have an account? &nbsp;
                                <StyledLink
                                    underline="none"
                                    variant="boldCallout"
                                    component={RouterLink}
                                    to={LOGIN_USER_REDIRECT}
                                >
                                    Sign In
                                </StyledLink>
                            </StyledTypography>
                            {emailSent ? (
                                <React.Fragment>
                                    <SubTitle variant="lightCallout" style={{ color: 'black' }} >
                                        The link with reset password is sent to email address provided by you.
                                        Kindly follow the instructions in the email to reset your password
                                    </SubTitle>

                                    <Button
                                        size="large"
                                        type="submit"
                                        variant="contained"
                                        style={{ width: '100%' }}
                                        onClick={handleResend}
                                    >
                                        <Typography variant="boldBody">Resend</Typography>
                                    </Button>
                                </React.Fragment>
                            ) : (
                                <React.Fragment>
                                    <SubTitle variant="lightCallout" style={{ color: 'black' }} >
                                        Enter your email address to get started with your password
                                        reset. If you don't find the reset email, please get in touch
                                        and we'll get this all sorted for you.
                                    </SubTitle>

                                    <StyledTypography variant="lightCallout" style={{ color: 'red' }} >
                                        {errorMessage && <p className="error" > {errorMessage} </p>}
                                    </StyledTypography>

                                    <TextField
                                        variant="filled"
                                        placeholder="Enter your email id "
                                        required
                                        inputProps={{ 'aria-label': 'Without label' }}
                                        hiddenLabel
                                        fullWidth
                                        id="filled-hidden-label-normal"
                                        name="email"
                                        onChange={handleChange}
                                        error={errorMessage}
                                        helperText={errorMessage ? errorMessage : ""}

                                    />

                                    <Button
                                        size="large"
                                        type="submit"
                                        variant="contained"
                                        style={{ width: '100%' }}
                                        onClick={handleSubmit}
                                    >
                                        <Typography variant="boldBody">Reset password</Typography>
                                    </Button>
                                </React.Fragment>)}
                            <StyledTypography variant="lightCallout" mt={2.5}>
                                New User? &nbsp;
                                <StyledLink
                                    underline="none"
                                    variant="boldCallout"
                                    component={RouterLink}
                                    to={CREATE_USER_REDIRECT}
                                >
                                    Create an account
                                </StyledLink>
                            </StyledTypography>
                        </Stack>
                    </FormStyle>
                </ContentStyle>
            </FromWrapper>

        </RootStyle>
    );
}
