import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Stack, TextField, Button, Box, Typography } from "@mui/material";
import {
  LeftSectionStyle,
  ContentStyle,
  RootStyle,
  FormStyle,
  LogoBox,
  FromWrapper,
  SubTitle,
  StyledTypography,
  MainTitle,
  SignUpWrapper,
} from "../../sign-up/components/SignUpStyle";
import Logo from "../../../images/LogoWhite.svg";
import { ResetPasswordConfirmView } from "../PasswordResetAPI";
import { showNotification } from "../../../shared_modules/notification_bar/NotificationBarSlice";
import { useDispatch } from "react-redux";

export function PasswordReset() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const initialFormData = Object.freeze({
    new_password1: "",
    new_password2: "",
  });

  const [formData, updateFormData] = useState(initialFormData);
  const [errorMessage, setErrorMessage] = useState(<div></div>);
  const [password1Err, setPassword1Err] = useState("");
  const [password2Err, setPassword2Err] = useState("");
  const params = useParams();

  const handleChange = (e) => {
    updateFormData({
      ...formData,
      [e.target.name]: e.target.value.trim(),
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (formData.new_password1 === "" || formData.new_password1 === null) {
      setPassword1Err("This field cannot be blank");
    }
    if (formData.new_password2 === "" || formData.new_password2 === null) {
      setPassword2Err("This field cannot be blank");
    }
    if (formData.new_password1 !== formData.new_password2) {
      setPassword2Err("New password and confirm password do not match");
    }

    if (Object.keys(params).length > 0) {
      ResetPasswordConfirmView(
        params.token,
        params.uidb64,
        formData.new_password1,
        formData.new_password2
      )
        .then((res) => {
          dispatch(
            showNotification({
              message: `Password reset was successful`,
            })
          );
          navigate("/app/login");
        })
        .catch((error) => {
          let errorobj = error.response.data;
          if ("new_password1" in errorobj) {
            setPassword1Err(errorobj.new_password1);
          }
          if ("new_password2" in errorobj) {
            setPassword2Err(errorobj.new_password2);
          }
          if ("token" in errorobj) {
            // console.log(errorobj.token);
            setErrorMessage(<div>Link is expired, <a href="/app/send-pwd-link">click here</a> to generate new link</div>);
          } else {
            console.log(error);
            setErrorMessage("Something went wrong. Please try again later.");
          }
        });
    }
  };
  return (
    <RootStyle>
      <FromWrapper>
        <Box>
          <LogoBox component="img" alt="register" src={Logo} />
          <LeftSectionStyle>
            <MainTitle variant="boldH3">
              Skip the customer support back-and-forth
            </MainTitle>
            <SubTitle variant="lightSubtitle">
              Skip the custoMake it ridiculously easy for customers to send you
              video recordings of their screen. No download/install required.mer
              support back-and-forth
            </SubTitle>
          </LeftSectionStyle>
        </Box>

        <ContentStyle>
          <FormStyle
            component="form"
            autoComplete="off"
            onSubmit={handleSubmit}
          >
            <Stack spacing={3}>
              <SignUpWrapper>
                <StyledTypography variant="boldH2">
                  Enter new password
                </StyledTypography>
              </SignUpWrapper>

              <StyledTypography variant="lightCallout" style={{ color: "red" }}>
                {errorMessage && <p className="error"> {errorMessage} </p>}
              </StyledTypography>

              <TextField
                variant="filled"
                autoComplete="current-password"
                type="password"
                label="New password"
                name="new_password1"
                onChange={handleChange}
                error={password1Err}
                helperText={password1Err ? password1Err : ""}
              />
              <TextField
                variant="filled"
                autoComplete="current-password"
                type="password"
                label="Confirm password"
                name="new_password2"
                onChange={handleChange}
                error={password2Err}
                helperText={password2Err ? password2Err : ""}
              />

              <Button
                size="large"
                type="submit"
                variant="contained"
                style={{ width: "100%" }}
              >
                <Typography variant="boldBody">Save</Typography>
              </Button>
            </Stack>
          </FormStyle>
        </ContentStyle>
      </FromWrapper>
    </RootStyle>
  );
}
