// ----------------------------------------------------------------------
function px(value) {
  return `${value}px`;
}

function responsiveFontSizes({ sm, md, lg }) {
  return {
    '@media (min-width:600px)': {
      fontSize: px(sm),
    },
    '@media (min-width:900px)': {
      fontSize: px(md),
    },
    '@media (min-width:1200px)': {
      fontSize: px(lg),
    },
  };
}

const FONT_PRIMARY = 'Inter';

const typography = {
  fontFamily: FONT_PRIMARY,
  fontWeightMedium: 500,
  fontWeightBold: 600,

  lightH1: {
    fontSize: '80px',
    fontWeight: '500',
    lineHeight: '102px',
    letterSpacing: '-1.76px',
    ...responsiveFontSizes({ sm: 52, md: 58, lg: 64 }),
  },

  lightH2: {
    fontWeight: '500',
    lineHeight: '56px',
    letterSpacing: '-0.88px',
    fontSize: px(40),
    ...responsiveFontSizes({ sm: 52, md: 58, lg: 64 }),
  },

  lightH3: {
    fontSize: '32px',
    fontWeight: '500',
    lineHeight: '44px',
    letterSpacing: '-0.704px',
  },

  lightH4: {
    fontWeight: '500',
    lineHeight: '32px',
    letterSpacing: '-0.45px',
    fontSize: px(20),
    ...responsiveFontSizes({ sm: 20, md: 24, lg: 24 }),
  },

  lightH5: {
    fontSize: '22px',
    fontWeight: '500',
    lineHeight: '30px',
    letterSpacing: '-0.4px',
  },

  lightH6: {
    fontSize: '20px',
    fontWeight: '500',
    lineHeight: '28px',
    letterSpacing: '-0.34px',
  },

  boldH1: {
    fontSize: '80px',
    fontWeight: '600',
    lineHeight: '102px',
    letterSpacing: '-1.76px',
  },

  boldH2: {
    fontSize: '40px',
    fontWeight: '600',
    lineHeight: '56px',
    letterSpacing: '-0.88px',
  },

  boldH3: {
    fontSize: '32px',
    fontWeight: '600',
    lineHeight: '44px',
    letterSpacing: '-0.704px',
  },

  boldH4: {
    fontWeight: '600',
    lineHeight: '32px',
    letterSpacing: '-0.45px',
    fontSize: px(20),
    ...responsiveFontSizes({ sm: 20, md: 24, lg: 24 }),
  },

  boldH5: {
    fontSize: '22px',
    fontWeight: '600',
    lineHeight: '30px',
    letterSpacing: '-0.4px',
  },

  boldH6: {
    fontSize: '20px',
    fontWeight: '600',
    lineHeight: '28px',
    letterSpacing: '-0.34px',
  },

  lightSmall: {
    fontSize: '10px',
    fontWeight: '400',
    lineHeight: '14px',
    letterSpacing: '0.1px',
  },

  boldSmall: {
    fontSize: '10px',
    fontWeight: '600',
    lineHeight: '14px',
    letterSpacing: '0.1px',
  },

  lightCaption: {
    fontSize: '12px',
    fontWeight: '500',
    lineHeight: '18px',
  },

  blodCaption: {
    fontSize: '12px',
    fontWeight: '600',
    lineHeight: '18px',
  },

  lightFootnote: {
    fontSize: '13px',
    fontWeight: '500',
    lineHeight: '18px',
    letterSpacing: '-0.03px',
  },

  boldFootnote: {
    fontSize: '13px',
    fontWeight: '600',
    lineHeight: '18px',
    letterSpacing: '-0.03px',
  },

  lightSubhead: {
    fontWeight: '500',
    lineHeight: '22px',
    letterSpacing: '-0.12px',
    fontSize: px(13),
    ...responsiveFontSizes({ sm: 13, md: 15, lg: 15 }),
  },

  boldSubhead: {
    fontWeight: '600',
    lineHeight: '22px',
    letterSpacing: '-0.12px',
    fontSize: px(13),
    ...responsiveFontSizes({ sm: 13, md: 15, lg: 15 }),
  },

  lightCallout: {
    fontWeight: '500',
    lineHeight: '22px',
    letterSpacing: '-0.25px',
    fontSize: px(14),
    ...responsiveFontSizes({ sm: 14, md: 16, lg: 16 }),
  },

  boldCallout: {
    fontWeight: '600',
    lineHeight: '22px',
    letterSpacing: '-0.25px',
    fontSize: px(14),
    ...responsiveFontSizes({ sm: 14, md: 16, lg: 16 }),
  },

  lightBody: {
    fontWeight: '500',
    lineHeight: '24px',
    letterSpacing: '-0.22px',
    fontSize: px(14),
    ...responsiveFontSizes({ sm: 15, md: 17, lg: 17 }),
  },

  boldBody: {
    fontWeight: '600',
    lineHeight: '24px',
    letterSpacing: '-0.22px',
    fontSize: px(14),
    ...responsiveFontSizes({ sm: 15, md: 17, lg: 17 }),
  },

  lightSubTitle: {
    fontWeight: '500',
    lineHeight: '24px',
    letterSpacing: '-0.25px',
    fontSize: px(16),
    ...responsiveFontSizes({ sm: 16, md: 18, lg: 18 }),
  },

  boldSubTitle: {
    fontWeight: '600',
    lineHeight: '24px',
    letterSpacing: '-0.25px',
    fontSize: px(16),
    ...responsiveFontSizes({ sm: 16, md: 18, lg: 18 }),
  },
};

export default typography;
