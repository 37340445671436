// ----------------------------------------------------------------------

export default function Button(theme) {
  return {
    MuiButton: {
      styleOverrides: {
        root: {
          backgroundColor: theme.palette.secondary.main,
          borderRadius: 16,
          '&:hover': {
            backgroundColor: theme.palette.secondary.main,
            boxShadow: 'none',
          },
        },
        sizeLarge: {
          height: 56,
          width: 'max-content',
          textTransform: 'initial',
        },
        containedInherit: {
          color: theme.palette.grey.dark2,
          boxShadow: 'none',
          '&:hover': {
            backgroundColor: theme.palette.grey.light2,
          },
        },
        containedPrimary: {
          boxShadow: 'none',
        },
        containedSecondary: {
          boxShadow: 'none',
        },
        outlinedInherit: {
          border: `1px solid ${theme.palette.grey.trans40}`,
          '&:hover': {
            // backgroundColor: theme.palette.grey.lighter,
          },
        },
        textInherit: {
          '&:hover': {
            // backgroundColor: theme.palette.grey.lighter,
          },
        },
      },
    },
  };
}
