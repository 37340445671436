import React from 'react';
import { PageTitle } from '../../../shared_modules/common/StyledClass';
import { Wrapper } from '../../../shared_modules/common/Wrapper';
import Tabs from '../../../shared_modules/tabs/Tabs';
import BillingAndPayments from './BillingAndPayments';
import ChromeExtension from './ChromeExtension';
import Integrations from './Integrations';
import RequestMessaging from './RequestMessaging';
import { selectSessionState } from '../../../shared_modules/layout/SessionSlice';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

const TAB_ID_BILLING_PAYMENTS = 0;
const TAB_ID_REQUEST_MESSAGING = 1;
const TAB_ID_INTEGRATIONS = 2;
const TAB_ID_CHROME_EXTENTION = 3;

const TAB_PARAM_BILLING_PAYMENTS = 'billing';
const TAB_PARAM_REQUEST_MESSAGING = 'request-messages';
const TAB_PARAM_INTEGRATIONS = 'integrations';
const TAB_PARAM_CHROME_EXTENTION = 'chrome';

export const SUB_TAB_PARAM_HELPSCOUT = 'helpscout';
export const SUB_TAB_PARAM_ZENDESK = 'zendesk';
export const SUB_TAB_PARAM_INTERCOM = 'intercom';
export const SUB_TAB_PARAM_FRESHCHAT = 'freshchat';

const USER_ADMIN = 'Admin';

const getTabId = (tabName, perm) => {
  let tabIdInUrl = (perm === USER_ADMIN) ? TAB_ID_BILLING_PAYMENTS : TAB_ID_REQUEST_MESSAGING;
  if (perm === USER_ADMIN && tabName === TAB_PARAM_BILLING_PAYMENTS) {
    tabIdInUrl = TAB_ID_BILLING_PAYMENTS;
  }
  if (tabName === TAB_PARAM_REQUEST_MESSAGING) tabIdInUrl = TAB_ID_REQUEST_MESSAGING;
  if (tabName === TAB_PARAM_INTEGRATIONS) tabIdInUrl = TAB_ID_INTEGRATIONS;
  if (tabName === TAB_PARAM_CHROME_EXTENTION) tabIdInUrl = TAB_ID_CHROME_EXTENTION;

  //reduce index by 1 in case its not admin
  if (perm !== USER_ADMIN) {
    tabIdInUrl = tabIdInUrl - 1;
  }
  return tabIdInUrl;
}
const Setting = () => {
  const { tabName } = useParams();
  const { user } = useSelector(selectSessionState);
  const perm = user.company_permissions;
  let idx = 0;
  let tabs = [
    ...(perm === USER_ADMIN) ? [
      {
        label: 'Billing & payments',
        Component: <BillingAndPayments />,
        index: idx++,
        href: '/app/settings/' + TAB_PARAM_BILLING_PAYMENTS,
      }] : [],
    {
      label: 'Request messaging',
      Component: <RequestMessaging />,
      index: idx++,
      href: '/app/settings/' + TAB_PARAM_REQUEST_MESSAGING,
    },
    {
      label: 'Integrations',
      Component: <Integrations />,
      index: idx++,
      href: '/app/settings/' + TAB_PARAM_INTEGRATIONS,
    },
    {
      label: 'Chrome extension ',
      Component: <ChromeExtension />,
      index: idx++,
      href: '/app/settings/' + TAB_PARAM_CHROME_EXTENTION,
    },
  ];
  const tabIdInUrl = getTabId(tabName, perm);

  return (
    <Wrapper>
      <PageTitle>Settings </PageTitle>
      <Tabs tabs={tabs} selectedTab={tabIdInUrl} />
    </Wrapper>
  );
};

export default Setting;
