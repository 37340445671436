import {
  Box,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import PropTypes from 'prop-types';
import {
  matchPath,
  NavLink as RouterLink,
  useLocation,
} from 'react-router-dom';
import Record from '../../pages/record';

// ----------------------------------------------------------------------

const ListItemStyle = styled((props) => (
  <ListItemButton disableGutters {...props} />
))(({ theme }) => ({
  ...theme.typography.boldSubhead,
  position: 'relative',
  textTransform: 'initial',
  padding: theme.spacing(2, 2.5),
  margin: theme.spacing(0.5, 2.5),
  color: theme.palette.grey.main,
  borderRadius: '12px',
  maxHeight: 56,
}));

const ListItemIconStyle = styled(ListItemIcon)(({ theme }) => ({
  path: {
    fill: theme.palette.grey.main,
  },
  width: 24,
  height: 24,
  minWidth: 0,
  marginRight: 16,
}));

const ListItemIconStyleRight = styled(ListItemIcon)(({ theme }) => ({
  path: {
    fill: theme.palette.grey.main,
  },
  width: 24,
  height: 24,
  minWidth: 0,
  marginRight: 0,
}));

// ----------------------------------------------------------------------

NavItem.propTypes = {
  item: PropTypes.object,
  active: PropTypes.func,
};

function NavItem({ item, active }) {
  const theme = useTheme();
  const isActiveRoot = active(item.path);
  const { title, path, icon, info, additionalIcon } = item;

  const activeRootStyle = {
    color: 'white',
    fontWeight: 'fontWeightMedium',
    bgcolor: 'secondary.main',
    borderRadius: '12px',
    '&:before': { display: 'block' },
    '&:hover': {
      backgroundColor: theme.palette.secondary.main,
    },
  };

  const ListItemIconStyleActive = {
    path: {
      fill: '#fff',
    },
  };

  const list_item = (
    <>
      <ListItemIconStyle sx={{ ...(isActiveRoot && ListItemIconStyleActive) }}>
        {icon && icon}
      </ListItemIconStyle>
      <ListItemText disableTypography primary={title} />
      {info && info}
      <ListItemIconStyleRight
        sx={{ ...(isActiveRoot && ListItemIconStyleActive) }}
      >
        {additionalIcon && additionalIcon}
      </ListItemIconStyleRight>
    </>
  );

  return (
    <ListItemStyle
      component={RouterLink}
      to={path}
      onClick={(e) => item.is_modal_item && e.preventDefault()}
      sx={{ ...(isActiveRoot && activeRootStyle) }}
    >
      {item.is_modal_item ? <Record listItem={list_item} /> : list_item}
    </ListItemStyle>
  );
}

NavSection.propTypes = {
  navConfig: PropTypes.array,
};

export default function NavSection({ navConfig, ...other }) {
  const { pathname } = useLocation();
  const match = (path) =>
    path ? !!matchPath({ path, end: false }, pathname) : false;

  return (
    <Box {...other}>
      <List sx={{ mt: 5 }}>
        {navConfig.map((item) => (
          <NavItem key={item.title} item={item} active={match} />
        ))}
      </List>
    </Box>
  );
}
