import { Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
// ----------------------------------------------------------------------

export const FlexyCenter = styled(Box)(({ theme }) => ({
  ...theme.mixins.flexyCenter,
}));

export const FlexyCenterItem = styled(Box)(({ theme }) => ({
  ...theme.mixins.flexyCenterItem,
}));

export const Flexy = styled(Box)(({ theme }) => ({
  ...theme.mixins.flexy,
}));

export const FlexyCol = styled(Box)(({ theme }) => ({
  ...theme.mixins.flexyCol,
}));

export const FlexyBetween = styled(Box)(({ theme }) => ({
  ...theme.mixins.flexyBetween,
}));

export const FlexyCenterCol = styled(Box)(({ theme }) => ({
  ...theme.mixins.flexyCenterCol,
}));

export const LabelTypography = styled(Typography)(({ theme }) => ({
  margin: theme.spacing(0, 0, 0.5, 0),
}));

export const PageTitle = styled(Typography)(({ theme }) => ({
  ...theme.typography.boldH4,
  margin: theme.spacing(2, 0, 3, 0),
}));

export const SpaceTop1 = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(1),
}));

export const SpaceTop2 = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(2),
}));

export const SpaceTop3 = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(3),
}));

export const SpaceBottom1 = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(1),
}));

export const SpaceBottom2 = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(2),
}));

export const SpaceBottom3 = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(3),
}));
