import axiosInstance from "../../common/axios";

export function addToLibraryAPI(form_data) {
  return axiosInstance.post("api/library/add/", form_data, {
    headers: {
      "content-type": "multipart/form-data",
    },
  });
}

export function fetchVideosApi(pageNumber, sorttype) {
  return axiosInstance.get(
    "api/library?page=" + pageNumber + "&sortby=" + sorttype
  );
}

export function deleteVideoLibraryAPI(uuid) {
  return axiosInstance.delete(`api/library/delete/${uuid}`);
}

export function fetchVideoDetailsAPI(uuid, slug) {
  return axiosInstance.get(
    "api/fetch-video-details?uuid=" + uuid + "&slug=" + slug
  );
}
export function fetchVideoAPI(slug) {
  return axiosInstance.get("api/fetch-video-details?slug=" + slug);
}
export function updateVideoDetailsAPI(form_data) {
  return axiosInstance.post("api/library/update/", form_data, {
    headers: {
      "content-type": "multipart/form-data",
    },
  });
}