import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

// ----------------------------------------------------------------------

export const PrimaryBadge = styled(Box)(({ theme }) => ({
  height: '6px',
  width: '6px',
  background: theme.palette.primary.main,
  borderRadius: '50%',
  display: 'inline-block',
  margin: '0 4px 0 0',
}));

export const SuccessBadge = styled(Box)(({ theme }) => ({
  height: '6px',
  width: '6px',
  background: theme.palette.success.main,
  borderRadius: '50%',
  display: 'inline-block',
  margin: '0 4px 0 0',
}));

export const PrimaryBadge1 = styled(Box)(({ theme }) => ({
  height: '8px',
  width: '8px',
  background: theme.palette.primary.main,
  borderRadius: '50%',
  display: 'inline-block',
  margin: '0 4px 0 0',
}));

export const GreyBadge1 = styled(Box)(({ theme }) => ({
  height: '8px',
  width: '8px',
  background: theme.palette.grey.main,
  borderRadius: '50%',
  display: 'inline-block',
  margin: '0 4px 0 0',
}));
