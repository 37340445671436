import { createSlice } from '@reduxjs/toolkit';

const initialState = {
};

export const loginSlice = createSlice({
  name: 'login',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {

  },

});


export const logout = (navigate) => {

  // the inside "thunk function"
  return async (dispatch, getState) => {
    try {
      localStorage.setItem('access_token', null);
      localStorage.setItem('refresh_token', null);
      sessionStorage.clear();
      navigate('/app/login');
    } catch (err) {
      // If something went wrong, handle it here
    }
  }
}

export default loginSlice.reducer;
