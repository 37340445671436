import { Button, Grid, Stack, Typography } from '@mui/material';
import React from 'react';
import { NavLink as RouterLink, useParams } from 'react-router-dom';
import HelpScoutLogoTxt from '../../../images/Icon/helpScout.svg';
import HelpScoutLogo from '../../../images/Icon/helpScoutLogo.svg';
import JiraLogo from '../../../images/Icon/logo-gradient-blue-jira.svg'
import ZendeskLogoText from '../../../images/Icon/zendesk-logo.svg'
import IntercomLogoWithText from '../../../images/Icon/IntercomLogoWithName.png'
import freshchat from '../../../images/Icon/freshchat.png'
import {
  SpaceBottom3,
} from '../../../shared_modules/common/StyledClass';
import HelpScout from './HelpScout';
import { SUB_TAB_PARAM_HELPSCOUT, SUB_TAB_PARAM_ZENDESK, SUB_TAB_PARAM_INTERCOM, SUB_TAB_PARAM_FRESHCHAT } from './Setting';
import {
  HelpScoutImg,
  HelpScoutLogoImg,
  HelpSection,
  InstructionTitle,
  IntegrationWrapper,
  JiraImg
} from './SettingStyle';
import Zendesk from './Zendesk';
import Intercom from './Intercom';
import Freshchat from './Freshchat';

const Integrations = () => {
  const { subTabName } = useParams();
  console.log(subTabName);
  if (subTabName === SUB_TAB_PARAM_HELPSCOUT) {
    return HelpScout();
  }
  if (subTabName === SUB_TAB_PARAM_ZENDESK) {
    return Zendesk();
  }
  if (subTabName === SUB_TAB_PARAM_INTERCOM) {
    return Intercom();
  }
  if (subTabName === SUB_TAB_PARAM_FRESHCHAT) {
    return Freshchat();
  }
  return (
    <>

      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 2 }}>
        <Grid item md={6}>
          <IntegrationWrapper>
            <Stack direction={{ md: 'row' }}>
              <HelpSection>
                <Stack direction="row" spacing={1.25}>
                  <HelpScoutLogoImg component="img" src={HelpScoutLogo} alt="" />
                  <HelpScoutImg component="img" src={HelpScoutLogoTxt} alt="" />
                </Stack>

                <InstructionTitle>
                  <Typography variant="boldH4">
                    Follow these instructions
                  </Typography>
                </InstructionTitle>

                <SpaceBottom3>
                  <Typography variant="lightCallout">
                    To connect Screenjar to your Help Scout account. It only takes a
                    minute to set up and allows you to request and view screen
                    recordings right from your Help Scout mailbox
                    <br />
                  </Typography>
                </SpaceBottom3>

                <Button size="large" variant="contained" component={RouterLink} to="/app/settings/integrations/helpscout">
                  <Typography variant="boldBody">Connect Help Scout</Typography>
                </Button>
              </HelpSection>
            </Stack>
          </IntegrationWrapper>
        </Grid>
        <Grid item md={6}>
          <IntegrationWrapper>
            <Stack direction={{ md: 'row' }}>
              <HelpSection>
                <Stack direction="row" spacing={1.25}>
                  <JiraImg component="img" src={JiraLogo} alt="" />
                </Stack>

                <InstructionTitle>
                  <Typography variant="boldH4">
                    Try Jira app free
                  </Typography>
                </InstructionTitle>

                <SpaceBottom3>
                  <Typography variant="lightCallout">
                    Record screen & attach videos directly to Jira issues & JSM tickets. No installation, no user management, no hassles. Screenjar account not required for this as its pricing is managed from the Atlassian marketplace
                  </Typography>
                </SpaceBottom3>
                <Button size="large" variant="contained" href="https://marketplace.atlassian.com/apps/1225798/screenjar-screen-recorder-for-jira?tab=overview&hosting=cloud">
                  <Typography variant="boldBody">Try it free</Typography>
                </Button>
              </HelpSection>
            </Stack>
          </IntegrationWrapper>
        </Grid>
        <Grid item md={6}>
          <IntegrationWrapper>
            <Stack direction={{ md: 'row' }}>
              <HelpSection>
                <Stack direction="row" spacing={1.25}>
                  {/* <HelpScoutLogoImg component="img" src={ZendeskLogo} alt="" /> */}
                  <HelpScoutImg component="img" src={ZendeskLogoText} alt="" style={{ maxWidth: '68%' }} />
                </Stack>

                <InstructionTitle>
                  <Typography variant="boldH4">
                    Follow these instructions
                  </Typography>
                </InstructionTitle>

                <SpaceBottom3>
                  <Typography variant="lightCallout">
                    To connect Screenjar to your Zendesk account. It only takes a minute to set up and allows you to request and view screen recordings right from your Zendesk Mailbox.
                    <br />
                  </Typography>
                </SpaceBottom3>

                <Button size="large" variant="contained" component={RouterLink} to="/app/settings/integrations/zendesk">
                  <Typography variant="boldBody">Connect to Zendesk</Typography>
                </Button>
              </HelpSection>
            </Stack>
          </IntegrationWrapper>
        </Grid>
        <Grid item md={6}>
          <IntegrationWrapper>
            <Stack direction={{ md: 'row' }}>
              <HelpSection>
                <Stack direction="row" spacing={1.25}>
                  <HelpScoutImg component="img" src={IntercomLogoWithText} alt="" style={{ maxWidth: '68%' }} />
                </Stack>

                <InstructionTitle>
                  <Typography variant="boldH4">
                    Follow these instructions
                  </Typography>
                </InstructionTitle>

                <SpaceBottom3>
                  <Typography variant="lightCallout">
                    It only takes a minute to set up and allows you to request and view screen recordings right from your Intercom tickets.                    <br />
                  </Typography>
                </SpaceBottom3>

                <Button size="large" variant="contained" component={RouterLink} to="/app/settings/integrations/intercom">
                  <Typography variant="boldBody">Connect to Intercom</Typography>
                </Button>
              </HelpSection>
            </Stack>
          </IntegrationWrapper>
        </Grid>
        <Grid item md={6}>
          <IntegrationWrapper>
            <Stack direction={{ md: 'row' }}>
              <HelpSection>
                <Stack direction="row" spacing={1.25}>
                  <HelpScoutImg component="img" src={freshchat} alt="" style={{ maxWidth: '68%' }} />
                </Stack>

                <InstructionTitle>
                  <Typography variant="boldH4">
                    Follow these instructions
                  </Typography>
                </InstructionTitle>

                <SpaceBottom3>
                  <Typography variant="lightCallout">
                    It only takes a minute to set up and allows you to request and view screen recordings right from your Freshchat.                    <br />
                  </Typography>
                </SpaceBottom3>

                <Button size="large" variant="contained" component={RouterLink} to="/app/settings/integrations/freshchat">
                  <Typography variant="boldBody">Connect to Freshchat</Typography>
                </Button>
              </HelpSection>
            </Stack>
          </IntegrationWrapper>
        </Grid>
        <Grid item md={6}>
          <IntegrationWrapper>
            <Stack direction={{ md: 'row' }}>
              <HelpSection>
                <Typography variant="lightCallout">
                  More coming soon. <br /> Looking for an integration in
                  particular?{' '}
                  <Typography
                    variant="lightCallout"
                    component={RouterLink}
                    to="/app/contact"
                    color="secondary.main"
                  >
                    Let us know
                  </Typography>
                </Typography>
              </HelpSection>
            </Stack>
          </IntegrationWrapper>
        </Grid>
      </Grid>
      {/* </Stack> */}

    </>
  );
};

export default Integrations;
