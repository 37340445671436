import axiosInstance from "../../common/axios";

export function UpdateCompanyDetailsAPI(form_data) {

    return axiosInstance.post(`api/company-update/`, form_data, {
        headers: {
            'content-type': 'multipart/form-data'
        }
    });
}
export function CompanyViewAPI() {
    return axiosInstance.get(`api/company-view`);
}


