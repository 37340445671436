import styled from '@emotion/styled';
import { Badge, IconButton, Tooltip, Zoom } from '@mui/material';
import { Component } from 'react';
// import { ReactComponent as NotificationIconSvg } from '../../images/Icon/bell.svg';
// import { ReactComponent as NotificationIconSvg } from '../../images/Icon/megaphone.svg';
import { ReactComponent as NotificationIconSvg } from '../../images/Icon/bullhorn.svg';


// ----------------------------------------------------------------------

const NotificationIcon = styled(NotificationIconSvg)(({ theme }) => ({
  width: 21,
  height: 24,
  path: {
    // opacity: 0.4,
    fill: '#A5A6B6', //theme.palette.grey.main,
  },
}));

// ----------------------------------------------------------------------
export class Notification extends Component {

  componentDidMount() {
    this.btn.addEventListener("click", this.onButtonClick);
  }
  componentWillUnmount() {
    this.btn.removeEventListener("click", this.onButtonClick);
  }
  onButtonClick() {
    var item = document.getElementsByClassName('arn-widget-btn')[0];
    item.click();
  }
  render() {
    return (
      <>
        <Tooltip TransitionComponent={Zoom} title={`What's new?`}>
          <IconButton size="medium" ref={arnbutton => (this.btn = arnbutton)} id="arn-widget-launcher">
            <NotificationIcon />
            <Badge color="error"></Badge>
          </IconButton>
        </Tooltip>
      </>
    );
  }
}
export default Notification;