import React from 'react';
import { Card, Typography } from '@mui/material';
import { Wrapper } from '../../shared_modules/common/Wrapper';

export function LinkExpired() {

    return (
        <Wrapper>
            <Card style={{ padding: '2%' }}>
                <Typography variant="lightCallout">
                    The recording link is expired. <br />
                </Typography>
            </Card>
        </Wrapper>
    );
}
