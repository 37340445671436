import axiosInstance from "../../common/axios";

export function getSubscriptionAPI(interval) {
  return axiosInstance.get(`api/billing/${interval}`);
}

export function updateRequestMessagesForUser(messages) {
  return axiosInstance.put(`api/settings/update-default-messaging/`, messages)
}

export function getHelpScoutIntegrationAPI() {
  return axiosInstance.get(`api/settings/integrations/helpscout`);
}

export function updateHelpScoutIntegrationAPI() {
  return axiosInstance.post(`api/settings/integrations/helpscout`);
}