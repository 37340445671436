import { Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  GreyBadge1,
  PrimaryBadge1,
} from "../../../shared_modules/common/RoundIconBadge";
import {
  FlexyCenter,
  FlexyCol,
  LabelTypography,
  SpaceTop3,
} from "../../../shared_modules/common/StyledClass";
import BasicSwitch from "../../../shared_modules/common/Switch";
import Textarea from "../../../shared_modules/common/Textarea";
import { fetchSessionApi } from "../../../shared_modules/layout/SessionAPI";
import { selectSessionState } from "../../../shared_modules/layout/SessionSlice";
import { showNotification } from "../../../shared_modules/notification_bar/NotificationBarSlice";
import RecordScreenCard from "../../../shared_modules/record_screen_card/RecordScreenCard";
import {
  ActionRecordButtonWrapper,
  CardDescription,
  LinkButton,
  LockIcon,
  MainTitleWrapper,
  RecordButton,
  RecordDescription,
  RecordIcon,
  StopRecordButton,
  SwitchWrapper,
  TitleWrapper,
  RedoButton,
  RedoIcon,
} from "../../record/components/RecordScreenStyle";
import { CustomMessageWrapper } from "../../record/components/RecordStyle";
import { StyledVideo } from "../../video_details/components/ExpandVideoStyle";
import { updateRequestMessagesForUser } from "../SettingsAPI";
import {
  InputWrapper,
  MessagingTitleWrapper,
  MessagingWrapper,
  PreviewWrapper,
  SaveButton,
} from "./SettingStyle";
const INTRO_NOTE = "intro_note";
const RECORDING_NOTE = "recording_note";
const THANKS_NOTE = "thanks_note";

const RequestMessaging = () => {
  const [focusedElement, setFocusedElement] = useState(INTRO_NOTE);
  const { user } = useSelector(selectSessionState);
  const initialFormData = Object.freeze({
    intro_note: user.intro_note,
    recording_note: user.recording_note,
    thanks_note: user.thanks_note,
  });
  const [formData, updateFormData] = useState(initialFormData);
  const dispatch = useDispatch();

  useEffect(() => {
    async function getUserData() {
      const response = await fetchSessionApi();
      updateFormData({
        intro_note: response.data.intro_note,
        recording_note: response.data.recording_note,
        thanks_note: response.data.thanks_note,
      });
    }
    getUserData();
  }, []);

  const handleChange = (e) => {
    updateFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };
  const handleFocus = (e) => {
    setFocusedElement(e.target.name);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    updateRequestMessagesForUser(formData)
      .then((response) => {
        dispatch(
          showNotification({
            message: "Messages updated successfully",
          })
        );
      })
      .catch((error) => {
        dispatch(
          showNotification({
            severity: "error",
            message:
              "Error occured while updating messages. please try again later",
          })
        );
      });
  };
  return (
    <>
      <MessagingWrapper>
        <Stack
          direction={{ xs: "column", md: "row" }}
          spacing={3.75}
          justifyContent="space-between"
          alignItems="center"
        >
          <InputWrapper spacing={3}>
            <MessagingTitleWrapper>
              <Typography variant="boldH4">Customer messaging</Typography>
            </MessagingTitleWrapper>

            <FlexyCol>
              <Typography variant="lightCallout">
                Optionally add messages to your recording requests to help guide
                your customers or provide extra information
              </Typography>
            </FlexyCol>

            <FlexyCol>
              <LabelTypography variant="lightFootnote" color="common.black">
                Introduction message (optional)
              </LabelTypography>

              <Textarea
                name={INTRO_NOTE}
                minRows={4}
                placeholder="e.g. Recording your screen helps us understand your problem and solve it quicker. Close anything private, then click the record button to start recording your screen"
                value={formData.intro_note}
                onChange={handleChange}
                onFocus={handleFocus}
              ></Textarea>
            </FlexyCol>

            <FlexyCol>
              <LabelTypography variant="lightFootnote" color="common.black">
                Recording message (optional)
              </LabelTypography>

              <Textarea
                name={RECORDING_NOTE}
                minRows={3}
                placeholder={`Shown once the customer starts recording - any links added to this message will automatically be made "clickable" `}
                value={formData.recording_note}
                onChange={handleChange}
                onFocus={handleFocus}
              />
            </FlexyCol>

            <FlexyCol>
              <LabelTypography variant="lightFootnote" color="common.black">
                "Thank you" message (optional)
              </LabelTypography>

              <Textarea
                name={THANKS_NOTE}
                minRows={3}
                placeholder="message here..."
                value={formData.thanks_note}
                onChange={handleChange}
                onFocus={handleFocus}
              />
            </FlexyCol>

            <SaveButton size="large" variant="contained" onClick={handleSubmit}>
              <Typography variant="boldBody">Save</Typography>
            </SaveButton>
          </InputWrapper>

          <PreviewWrapper>
            <Typography variant="boldH4">Preview</Typography>
            <Typography variant="lightCallout" my={2.5}>
              This is a preview of what your customers see when you send them a
              screen recording request
            </Typography>

            <RecordScreenCard user={user}>
              <CardDescription>
                {focusedElement === INTRO_NOTE &&
                  renderRecordingStart(user, formData)}
                {focusedElement === RECORDING_NOTE &&
                  renderRecordingActive(user, formData)}
                {focusedElement === THANKS_NOTE &&
                  renderRecordingUploadSuccess(user, formData)}
                {showBottomNav(focusedElement)}
              </CardDescription>
            </RecordScreenCard>
          </PreviewWrapper>
        </Stack>
      </MessagingWrapper>
    </>
  );
};
const showBottomNav = (focusedElement) => (
  <SpaceTop3>
    <FlexyCenter>
      {focusedElement === INTRO_NOTE && (
        <>
          <PrimaryBadge1 />
          <GreyBadge1 />
          <GreyBadge1 />
        </>
      )}
      {focusedElement === RECORDING_NOTE && (
        <>
          <PrimaryBadge1 />
          <PrimaryBadge1 />
          <GreyBadge1 />
        </>
      )}

      {focusedElement === THANKS_NOTE && (
        <>
          <PrimaryBadge1 />
          <PrimaryBadge1 />
          <PrimaryBadge1 />
        </>
      )}
    </FlexyCenter>
  </SpaceTop3>
);

const showUserCustomMessage = (msg) => (
  <CustomMessageWrapper>
    <Typography variant="lightCallout" my={2.5}>
      {msg}
    </Typography>
  </CustomMessageWrapper>
);

const renderRecordingStart = (user, formData) => {
  return (
    <>
      <TitleWrapper variant="lightSubTitle">
        <TitleWrapper variant="boldSubTitle">{user.name} </TitleWrapper>
        {user.company.name && (
          <>
            from{" "}
            <TitleWrapper variant="boldSubTitle">
              {user.company.name}{" "}
            </TitleWrapper>
          </>
        )}
        <br />
        is requesting a screen recording from you
      </TitleWrapper>
      {formData.intro_note && showUserCustomMessage(formData.intro_note)}
      <SwitchWrapper>
        <BasicSwitch
          disabled
          // checked={switchChecked}
        />
        <Typography variant="lightBody">Microphone audio</Typography>
      </SwitchWrapper>
      <RecordButton
        size="large"
        variant="contained"
        style={{ cursor: "not-allowed" }}
      >
        <Typography variant="boldBody">Start recording screen</Typography>
        <RecordIcon />
      </RecordButton>

      {recordingFooter(user)}
    </>
  );
};
const renderRecordingActive = (user, formData) => {
  return (
    <>
      <Typography textAlign="center" variant="boldSubTitle">
        Your screen is now being recorded When you’re done, come back to this
        page to stop recording and submit your video
      </Typography>
      {formData.recording_note &&
        showUserCustomMessage(formData.recording_note)}

      <ActionRecordButtonWrapper>
        <StopRecordButton
          size="large"
          variant="contained"
          style={{ cursor: "not-allowed" }}
        >
          <Typography variant="boldBody">Stop recording</Typography>
          <RecordIcon className="record-icon" />
        </StopRecordButton>
        <RedoButton
          size="large"
          variant="contained"
          width = "100%"
          style={{ cursor: "not-allowed" }}
        >
          <Typography variant="boldBody">Try again</Typography>
          <RedoIcon />
        </RedoButton>
        {/* {tryAgainButton(null, true)} */}
      </ActionRecordButtonWrapper>

      {recordingFooter(user)}
    </>
  );
};

const renderRecordingUploadSuccess = (user, formData) => {
  return (
    <>
      <Typography variant="boldSubTitle">Here's the video</Typography>
      <MainTitleWrapper>
        <TitleWrapper variant="lightSubTitle">
          <TitleWrapper variant="boldSubTitle">
            Your video has been sent to {user.name}{" "}
            {user.company.name ? `from ${user.company.name}` : ""}
          </TitleWrapper>
        </TitleWrapper>
      </MainTitleWrapper>
      {formData.thanks_note && showUserCustomMessage(formData.thanks_note)}
      <SpaceTop3>
        <StyledVideo controls>
          <source src="" type="video/mp4" />
        </StyledVideo>
      </SpaceTop3>

      <RecordDescription>
        <Typography variant="lightCallout">
          Does the video clearly show what you wanted it to? If not, &nbsp;
          <TitleWrapper variant="lightCallout">
            <LinkButton style={{ cursor: "not-allowed" }}>
              record another video
            </LinkButton>
          </TitleWrapper>
        </Typography>
      </RecordDescription>
    </>
  );
};

const recordingFooter = (user) => {
  return (
    <RecordDescription>
      <LockIcon />
      <Typography variant="lightCallout">
        Your screen recording will be privately sent to {user.name}{" "}
        {user.company.name ? `from ${user.company.name} ` : ""}to review.{" "}
      </Typography>
    </RecordDescription>
  );
};

export default RequestMessaging;
