import { IconButton } from '@mui/material';
import { styled } from '@mui/system';
import React from 'react';
import { ReactComponent as DownloadIconSvg } from '../../images/Icon/download.svg';

const DownloadIconBtn = styled(DownloadIconSvg)(({ theme }) => ({
  width: 16,
  height: 16,
  path: {
    fill: theme.palette.primary.main,
  },
}));

const DownloadButton = styled(IconButton)(({ theme }) => ({
  boxShadow: 'none',
  padding: '8px',
  margin: '0 8px',
  display: 'flex',
  borderRadius: '8px',
  lineHight: 0,
  backgroundColor: theme.palette.primary.trans5,
  '&:hover': {
    backgroundColor: theme.palette.primary.trans5,
    boxShadow: 'none',
  },
}));

const DownloadIcon = (props) => {
  return (
    <>
      <DownloadButton onClick={props.onClick}>
        <DownloadIconBtn />
      </DownloadButton>
    </>
  );
};

export default DownloadIcon;
