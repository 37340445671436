import { configureStore } from "@reduxjs/toolkit";
import dashboardReducer from "../pages/dashboard/DashboardSlice";
import loginReducer from "../pages/login/loginSlice";
import recordSlice from "../pages/record/RecordSlice";
import sessionReducer from "../shared_modules/layout/SessionSlice";
import NotificationBarReducer from "../shared_modules/notification_bar/NotificationBarSlice";
import inviteReducer from "../pages/teammates/TeamSlice";
import companySlice from "../pages/company/CompanySlice";
import profileSlice from "../pages/profile/ProfileSlice";
import TabsSlice from "../shared_modules/tabs/TabsSlice";
import SettingsSlice from "../pages/settings/SettingsSlice";
import LinkHistorySlice from "../pages/link-history/LinkHistorySlice";
import videoLibrarySlice from "../pages/video_library/VideoLibrarySlice";

export const store = configureStore({
  reducer: {
    login: loginReducer,
    dashboard: dashboardReducer,
    notificationBar: NotificationBarReducer,
    session: sessionReducer,
    invites: inviteReducer,
    record: recordSlice,
    company: companySlice,
    profile: profileSlice,
    tabs: TabsSlice,
    settings: SettingsSlice,
    linkHistory: LinkHistorySlice,
    videoLibrary: videoLibrarySlice,
  },
});
