import { styled } from "@mui/material/styles";

export const ShareStyledVideo = styled("video")({
  width: "100%",
  height: "100%",
  display: "block",
  marginBottom: 10,
});

export const ShareVideoWrapper = styled("div")({
  maxWidth: "900px",
  marginLeft: "auto",
  marginRight: "auto",
  marginTop: "50px",
});

export const ShareVideoDownloadBtn = styled("button")(({ theme }) => ({
  backgroundColor: "white",
  color: theme.palette.secondary.main,
  border: 0,
  cursor: "pointer",
  fontWeight: 500,
}));
