import { Stack, Typography } from '@mui/material';
import React from 'react';
import zenImg1 from '../../../images/zen1.png';
import zenImg2 from '../../../images/zen2.png';
import zenImg4 from '../../../images/zen4.png';
import zenImg5 from '../../../images/zen5.png';
import zenImg6 from '../../../images/zen6.png';
import zenImg7 from '../../../images/zen7.png';
import zenImg9 from '../../../images/zen9.png';
import zenImg10 from '../../../images/zen10.png';
import zenImg11 from '../../../images/zen11.png';
import zenImg12 from '../../../images/zen12.png';


import {
    SpaceBottom3,
} from '../../../shared_modules/common/StyledClass';
import {
    HelpFullSection,
    HelpScoutImg,
    InstructionTitle,
    IntegrationWrapper,
} from './SettingStyle';
import ZendeskLogoText from '../../../images/Icon/zendesk-logo.svg'

export default function Zendesk() {

    return (
        <>
            <IntegrationWrapper>
                {/* <Stack direction={{ xs: 'column-reverse', md: 'row' }}> */}
                <HelpFullSection>
                    <Stack direction="row" spacing={1.25}>
                        {/* <HelpScoutLogoImg component="img" src={HelpScoutLogo} alt="" /> */}
                        <HelpScoutImg component="img" src={ZendeskLogoText} alt="" style={{ width: '40%' }} />
                    </Stack>

                    <InstructionTitle>
                        <Typography variant="boldH4">
                            Follow these instructions
                        </Typography>
                    </InstructionTitle>

                    <SpaceBottom3>
                        <Typography variant="lightCallout">
                            It only takes a minute to set up and allows you to request and view screen recordings right from yoour zendesk tickets.

                        </Typography>
                    </SpaceBottom3>

                    <SpaceBottom3>
                        <Typography variant="lightSubtitle" color="dark.main">
                            <Typography variant="boldH4">1. </Typography><b>Install Screenjar from zendesk app marketplace</b>
                        </Typography>
                    </SpaceBottom3>
                    <SpaceBottom3>
                        <Typography variant="lightSubtitle" color="dark.main">
                            Go to the Zendesk marketplace at <a href="https://www.zendesk.com/marketplace/apps/support/853815/screenjar-screen-recorder/" target="blank">https://www.zendesk.com/marketplace/apps/support/853815/screenjar-screen-recorder/</a>
                            &nbsp; and install screenjar
                        </Typography>
                    </SpaceBottom3>

                    <img src={zenImg1} alt="Zendesk integration" />
                    <SpaceBottom3></SpaceBottom3>
                    <SpaceBottom3>
                        <Typography variant="lightSubtitle" color="dark.main">
                            Choose Account and click "Install"
                        </Typography>
                    </SpaceBottom3>
                    <img src={zenImg2} alt="Zendesk integration" style={{ width: '50%', height: '50%' }} />
                    <SpaceBottom3></SpaceBottom3>
                    <SpaceBottom3>
                        <Typography variant="lightSubtitle" color="dark.main">
                            Once installed Screenjar app will come in the list of your installed apps.
                        </Typography>
                    </SpaceBottom3>

                    <SpaceBottom3></SpaceBottom3>
                    {/* <img src={zenImg3} alt="Zendesk integration" /> */}

                    <SpaceBottom3>
                        <Typography variant="lightSubtitle" color="dark.main">
                            <Typography variant="boldH4">2. </Typography><b>Connect to Screenjar account</b>
                        </Typography>
                    </SpaceBottom3>
                    <SpaceBottom3>
                        <Typography variant="lightSubtitle" color="dark.main">
                            The Screenjar screen recorder will appear in the left apps bar section on tickets screen. Click on "Sign in"
                        </Typography>
                    </SpaceBottom3>
                    <SpaceBottom3>
                        <img src={zenImg4} alt="Zendesk integration" />
                    </SpaceBottom3>
                    <SpaceBottom3>
                        <Typography variant="lightSubtitle" color="dark.main">
                            After you click sign in, a new window / tab will appear asking you to login into Screenjar account. Please enter your email and password to login or create a fresh new account with Screenjar if you don’t have it already.
                        </Typography>
                    </SpaceBottom3>
                    <img src={zenImg5} alt="Zendesk integration" />
                    <SpaceBottom3></SpaceBottom3>
                    <SpaceBottom3>
                        Once logged in you will be taken to the authorization page. Please click on “Allow”
                    </SpaceBottom3>
                    <SpaceBottom3>
                        <img src={zenImg6} alt="Zendesk integration" style={{ width: '50%', height: '50%' }} />
                    </SpaceBottom3>
                    <Typography variant="lightSubtitle" color="dark.main">
                        Once you allow screenjar to connect to your zendesk account, it will display confirmation message as displayed below. Close this window
                    </Typography>
                    <SpaceBottom3></SpaceBottom3>
                    <img src={zenImg7} alt="Zendesk integration" />
                    <SpaceBottom3></SpaceBottom3><SpaceBottom3></SpaceBottom3>
                    <SpaceBottom3>
                        <Typography variant="boldH4">3. </Typography><b>Send recording request to customer</b>
                    </SpaceBottom3>
                    <Typography variant="lightSubtitle" color="dark.main">
                        Go back to the Zendesk tickets page and refresh page.
                        You can use Screenjar from left panel apps section or using shortcut icon just below the message box as highlighted below.
                        Click on the "New Recording Request" button and a link will appear in the message box. Hit send to send it to the customer.
                    </Typography>
                    <SpaceBottom3></SpaceBottom3>
                    {/* <img src={zenImg8} alt="Zendesk integration" /> */}
                    {/* <SpaceBottom3></SpaceBottom3> */}
                    {/* <SpaceBottom3>
                        <Typography variant="lightSubtitle" color="dark.main">
                            <Typography variant="boldH4">3.1</Typography>Send screen recording request
                        </Typography>
                    </SpaceBottom3> */}
                    {/* <SpaceBottom3>

                    </SpaceBottom3> */}
                    <img src={zenImg9} alt="Zendesk integration" />
                    <SpaceBottom3></SpaceBottom3>
                    <SpaceBottom3>
                        <Typography variant="boldH4">4. </Typography><b>Customer records screen</b>
                    </SpaceBottom3>

                    <Typography variant="lightSubtitle" color="dark.main">
                        Customers will receive this link in messenger.
                    </Typography>
                    <SpaceBottom3></SpaceBottom3>
                    <img src={zenImg10} alt="Zendesk integration" />
                    <SpaceBottom3></SpaceBottom3>
                    <SpaceBottom3>
                        <Typography variant="lightSubtitle" color="dark.main">
                            Recording screen will appear as customer clicks on recording link.
                            Customer needs to give permission to record screen and use microphone if required.
                            Once recording is complete. Customer can review the recording and click on send button to share recording with support
                        </Typography>
                    </SpaceBottom3>

                    <Typography variant="lightSubtitle" color="dark.main">
                        <img src={zenImg11} alt="Zendesk integration" />
                    </Typography>
                    <SpaceBottom3></SpaceBottom3>
                    <SpaceBottom3>
                        <Typography variant="boldH4">5. </Typography><b>Watch recorded video</b>
                    </SpaceBottom3>
                    <SpaceBottom3>
                        <Typography variant="lightSubtitle" color="dark.main">
                            You will receive the recorded video in the same ticket as highlighted below
                        </Typography>
                    </SpaceBottom3>
                    <img src={zenImg12} alt="Zendesk integration" />


                </HelpFullSection>
            </IntegrationWrapper>
        </>
    )
}
