/*global chrome*/

//id for chrome extension
const extensionId = 'eannaomdbmcakllbmhhaiaekahjdhffa';

export const sendTokenToChromeExtension = ({ jwt }) => {
    chrome.runtime.sendMessage(extensionId, { jwt }, response => {
        if (!response.success) {
            console.log('error sending message', response);
            return response;
        }
        console.log('Sucesss ::: ', response.message)
    });
}