import { Box, InputAdornment, Tooltip, Zoom } from "@mui/material";
import React, { useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { showNotification } from "../../../shared_modules/notification_bar/NotificationBarSlice";
import { videoNameEditApi } from "../RecordAPI";
import {
  ActionWrapper,
  EditField,
  EditIcon,
  SubmitIcon,
} from "./RecordScreenStyle";
//
const EditVideoName = (props) => {
  const [isReadOnly, setReadOnly] = useState(true);
  const [videoName, setVideoName] = useState(props.videoName);
  const [err, setErr] = useState("");
  const dispatch = useDispatch();
  var hasError = false;

  const handleChange = (e) => {
    hasError = false;
    setErr("");
    setVideoName(e.target.value);
    if (videoName && videoName.length > 255) {
      setErr("File name exceeds allowed max length");
      hasError = true;
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // var regx =
    if (videoName === null || videoName === "" || videoName.trim() === "") {
      setErr("Please enter file name");
      hasError = true;
    }
    if (/[`!@#$%^&*()+\=\[\]{};':"\\|,<>\/?~]/.test(videoName)) {
      setErr("Please enter valid file name");
      hasError = true;
    }
    if (videoName && videoName.length > 255) {
      setErr("File name exceeds allowed max length");
      hasError = true;
    }
    if (!hasError && err === "") {
      setReadOnly(true);
      videoNameEditApi(videoName.trim(), props.uuid).then((res) => {
        dispatch(
          showNotification({
            message: `File name is updated successfully`,
          })
        );
      });
      // e.textInput.current.blur();
      props.fileName(videoName);
    }
  };

  let textInput = useRef(null);

  return (
    <EditField
      fullWidth
      id="outlined-basic-url"
      variant="outlined"
      value={videoName}
      sx={{
        borderColor: "whitesmoke !important",
        marginTop: "25px",
        // "&:hover fieldset": { borderColor: "whitesmoke !important" },
      }}
      onChange={handleChange}
      onKeyPress={(e) => {
        if (!isReadOnly) {
          if (e.key === "Enter") {
            handleSubmit(e);
            textInput.current.blur();
          }
        }
      }}
      error={err !== "" ? true : false}
      helperText={err !== "" ? err : ""}
      InputProps={{
        readOnly: isReadOnly,
        endAdornment: (
          <InputAdornment position="end">
            {isReadOnly ? (
              <ActionWrapper>
                <Tooltip TransitionComponent={Zoom} title="Edit">
                  <Box onClick={() => setReadOnly(false)}>
                    <EditIcon
                      onClick={() => {
                        setTimeout(() => {
                          textInput.current.focus();
                        }, 0);
                      }}
                    />
                  </Box>
                </Tooltip>
              </ActionWrapper>
            ) : (
              <ActionWrapper>
                <Tooltip TransitionComponent={Zoom} title="Update">
                  <Box>
                    <SubmitIcon onClick={(e) => handleSubmit(e)} />
                  </Box>
                </Tooltip>
              </ActionWrapper>
            )}
          </InputAdornment>
        ),
      }}
      inputRef={textInput}
    />
  );
};

export default EditVideoName;
