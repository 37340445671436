import { createSlice } from "@reduxjs/toolkit";
import {
  STATE_FAIL,
  STATE_LOADING,
  STATE_SUCCESS,
} from "../../common/Constants";
import {
  RECORDING_AUDIO_STARTED,
  STATE_RECORDING_ACTIVE,
  STATE_RECORDING_COMPLETE,
  STATE_RECORDING_SUBMITTED,
  STATE_RECORDING_SUBMITTED_ERROR,
} from "./components/RecordingUtil";
import { fetchLinkDetailsApi } from "./RecordAPI";

const initialState = {
  linkDetail: null,
  status: STATE_LOADING,
  browserNotSupported: false,
  recordingNotSupported: false,
  microphoneBlocked: false,
  switchChecked: false,
  recordingState: "",
  audioRecordingState: "",
  saveProgressPercent: 0,
  videoLink: "",
  imageLink: "",
  editedImgLink: "",
  videoBlobLink: "",
  canWebm: false,
  blob: null,
  isUploadStuck: false,
  jiraUploadStatus: { status: "" },
  zendeskUploadStatus: { status: "" },
  intercomUploadStatus: { status: "" },
  freshchatUploadStatus: { status: "" },
  mondayUploadStatus: { status: "" },
  videoName: "",
  imageName: "",
  slug: "",
  instructions: true,
  markerImageState: null,
  note: null,
  uuid: "",
};

export const recordSlice = createSlice({
  name: "record",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    fetchLinkDetailsStart: (state) => {
      state.status = STATE_LOADING;
      state.linkDetail = null;
      state.browserNotSupported = false;
      state.recordingNotSupported = false;
    },
    fetchLinkDetailsSuccess: (state, action) => {
      state.status = STATE_SUCCESS;
      state.linkDetail = action.payload;
    },
    fetchLinkDetailsFail: (state) => {
      state.status = STATE_FAIL;
      state.linkDetail = null;
    },
    setBrowserNotSupported: (state) => {
      state.browserNotSupported = true;
      state.recordingNotSupported = false;
    },
    setRecordingNotSupported: (state) => {
      state.browserNotSupported = false;
      state.recordingNotSupported = true;
    },
    setSwitchChecked: (state, action) => {
      state.switchChecked = action.payload;
      if (action.payload === true) {
        state.microphoneBlocked = false;
      }
    },
    setMicrophoneBlocked: (state, action) => {
      state.microphoneBlocked = action.payload;
      state.switchChecked = false;
    },
    updateRecordingState: (state, action) => {
      state.recordingState = action.payload;
    },
    updateScreenshotState: (state, action) => {
      state.recordingState = action.payload.recordingState;
    },
    updateInstructions: (state, action) => {
      if (state.instructions) {
        state.instructions = false;
      } else {
        state.instructions = true;
      }
    },
    setMarkerImageState: (state, action) => {
      state.markerImageState = action.payload.markerImageState;
    },
    setScreenShotImage: (state, action) => {
      if (action.payload.editedImgLink) {
        state.editedImgLink = action.payload.editedImgLink;
      } else {
        state.imageName = action.payload.imageName;
        state.imageLink = action.payload.imageLink;
        state.recordingState = action.payload.recordingState;
      }
    },
    setScreenShotName: (state, action) => {
      state.imageName = action.payload.imageName;
      state.uuid = action.payload.uuid;
      state.note = action.payload.note;
    },
    updateRecordingStateWithData: (state, action) => {
      let rState = action.payload.recordingState;
      state.recordingState = rState;
      if (
        rState === STATE_RECORDING_ACTIVE &&
        RECORDING_AUDIO_STARTED === action.payload.audioRecordingState
      ) {
        state.audioRecordingState = action.payload.audioRecordingState;
      }
      if (rState === STATE_RECORDING_SUBMITTED) {
        state.isUploadStuck = false;
        state.videoName = action.payload.videoName;
        state.uuid = action.payload.uuid;
      }
      if (rState === STATE_RECORDING_COMPLETE) {
        state.videoLink = action.payload.videoLink;
        state.canWebm = action.payload.canWebm;
        state.videoName = action.payload.videoName;
        state.uuid = action.payload.uuid;
      }
      if (rState === STATE_RECORDING_SUBMITTED_ERROR) {
        state.videoBlobLink = action.payload.videoBlobLink;
        state.blob = action.payload.blob;
        state.videoName = action.payload.videoName;
        state.uuid = action.payload.uuid;
      }
      if (rState === "") {
        state.audioRecordingState = "";
        state.saveProgressPercent = 0;
        state.videoLink = "";
        state.videoBlobLink = "";
        state.blob = null;
        state.isUploadStuck = false;
        state.jiraUploadStatus = { status: "" };
        state.mondayUploadStatus = { status: "" };
      }
    },
    updateVideoEdit: (state, action) => {
      state.videoBlobLink = action.payload.videoBlobLink;
      state.blob = action.payload.blob;
    },
    updateRecordingUploadStuck: (state, action) => {
      state.isUploadStuck = true;
      state.videoBlobLink = action.payload.videoBlobLink;
      state.blob = action.payload.blob;
    },
    updateSaveProgress: (state, action) => {
      state.saveProgressPercent = action.payload;
    },
    updateJiraUploadStatus: (state, action) => {
      state.jiraUploadStatus = action.payload;
    },
    updateZendeskUploadStatus: (state, action) => {
      state.zendeskUploadStatus = action.payload;
    },
    updateIntercomUploadStatus: (state, action) => {
      state.intercomUploadStatus = action.payload;
    },
    updateFreshchatUploadStatus: (state, action) => {
      state.freshchatUploadStatus = action.payload;
    },
    updateMondayUploadStatus: (state, action) => {
      state.mondayUploadStatus = action.payload;
    },
  },
});

export const fetchLinkDetails = (linkId) => {
  // the inside "thunk function"
  return async (dispatch, getState) => {
    try {
      dispatch(fetchLinkDetailsStart());
      const response = await fetchLinkDetailsApi(linkId);
      if (response.data.status === "trial-expired") {
        window.location.replace("/trial-expired.html/");
      } else {
        dispatch(fetchLinkDetailsSuccess(response.data));
      }
    } catch (err) {
      // If something went wrong, handle it here
    }
  };
};

export const selectRecordingState = (state) => state.record;
export const {
  fetchLinkDetailsStart,
  fetchLinkDetailsSuccess,
  setBrowserNotSupported,
  setRecordingNotSupported,
  setSwitchChecked,
  setMicrophoneBlocked,
  updateRecordingState,
  updateScreenshotState,
  setScreenShotImage,
  setScreenShotName,
  setMarkerImageState,
  updateRecordingStateWithData,
  updateSaveProgress,
  updateVideoEdit,
  updateRecordingUploadStuck,
  updateJiraUploadStatus,
  updateZendeskUploadStatus,
  updateIntercomUploadStatus,
  updateFreshchatUploadStatus,
  updateInstructions,
  updateMondayUploadStatus,
} = recordSlice.actions;

export default recordSlice.reducer;
