import {
  Autocomplete,
  Box,
  CircularProgress,
  FormHelperText,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { LabelTypography } from "../../../shared_modules/common/StyledClass";
import { fetchSession } from "../../../shared_modules/layout/SessionSlice";
import { showNotification } from "../../../shared_modules/notification_bar/NotificationBarSlice";
import {
  FormWrapper,
  SaveButton,
} from "../../teammates/components/TeammatsStyle";
import { subscribeToPlanAPI } from "../PaymentAPI";
import "./checkout.css";

const CheckoutForm = ({ planDetails, countries }) => {
  const { planId } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [errors, setErrors] = useState({
    billing_name: "",
    billing_address_line1: "",
    billing_address_line2: "",
    billing_address_city: "",
    billing_address_state: "",
    billing_country: "",
    billing_address_pin: "",
  });
  const [loader, setLoader] = useState(false);
  const [formValues, setFormValues] = useState({
    billing_name: "",
    billing_address_line1: "",
    billing_address_line2: "",
    billing_address_city: "",
    billing_address_state: "",
    billing_country: "GB",
    billing_address_pin: "",
  });

  const stripe = useStripe();
  const elements = useElements();

  const onChangeHandler = (e) => {
    const target = e.target;
    setFormValues({
      ...formValues,
      [target.name]: target.value,
    });
  };

  const handleValidaion = () => {
    let invalidate = false;
    if (formValues.billing_name === "") {
      setErrors((prev) => ({
        ...prev,
        billing_name: "Name can't be empty",
      }));
      invalidate = true;
    } else {
      setErrors((prev) => ({
        ...prev,
        billing_name: "",
      }));
    }
    if (formValues.billing_address_line1 === "") {
      setErrors((prev) => ({
        ...prev,
        billing_address_line1: "Address can't be empty",
      }));
      invalidate = true;
    } else {
      setErrors((prev) => ({
        ...prev,
        billing_address_line1: "",
      }));
    }
    if (formValues.billing_address_line2 === "") {
      setErrors((prev) => ({
        ...prev,
        billing_address_line2: "Address can't be empty",
      }));
      invalidate = true;
    } else {
      setErrors((prev) => ({
        ...prev,
        billing_address_line2: "",
      }));
    }
    if (formValues.billing_address_city === "") {
      setErrors((prev) => ({
        ...prev,
        billing_address_city: "City can't be empty",
      }));
      invalidate = true;
    } else {
      setErrors((prev) => ({
        ...prev,
        billing_address_city: "",
      }));
    }
    if (formValues.billing_address_state === "") {
      setErrors((prev) => ({
        ...prev,
        billing_address_state: "State can't be empty",
      }));
      invalidate = true;
    } else {
      setErrors((prev) => ({
        ...prev,
        billing_address_state: "",
      }));
    }
    if (invalidate) {
      return false;
    } else {
      return true;
    }
  };

  const handleSubmit = async (ev) => {
    ev.preventDefault();
    const isValid = handleValidaion();
    if (isValid) {
      const cardDetails = elements.getElement(CardElement);
      const payload = await stripe.createSource(cardDetails, {
        amount: planDetails.amount.split(".")[0],
      });
      if (payload.source) {
        setLoader(true);
        const res = await subscribeToPlanAPI(planId, {
          ...formValues,
          stripe_source: payload.source.id,
        });
        if (res.data) {
          setLoader(false);
          dispatch(fetchSession());
          dispatch(
            showNotification({
              message: `Payment Completed`,
            })
          );
          navigate("/app/settings");
        } else setLoader(false);
      } else {
        setLoader(false);
      }
    }
  };

  const cardStyle = {
    style: {
      base: {
        color: "#000",
        fontSmoothing: "Inter",
        borderBottom: "red",
        fontSize: "18px",
        "::placeholder": {
          color: "#ccc",
          fontWeight: 400,
        },
      },
    },
  };

  return (
    <FormWrapper style={{ marginLeft: "auto", marginRight: "auto" }}>
      {loader ? (
        <Box textAlign={"center"} p={4}>
          <CircularProgress
            sx={{
              maxHeight: "70px",
              maxWidth: "70px",
            }}
          />
        </Box>
      ) : (
        <form onSubmit={handleSubmit}>
          <div>
            <LabelTypography variant="lightFootnote">
              Card Number<span style={{ color: "red" }}>*</span>
            </LabelTypography>
            <CardElement id="card-element" options={cardStyle} />
          </div>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <LabelTypography variant="lightFootnote">
                Country<span style={{ color: "red" }}>*</span>
              </LabelTypography>
              <Autocomplete
                disablePortal
                name="billing_country"
                onChange={(e, value) =>
                  setFormValues({
                    ...formValues,
                    billing_country: value.value,
                  })
                }
                defaultValue={{
                  label: "United Kingdom",
                  value: "GB",
                }}
                getOptionLabel={(option) => option.label}
                options={Object.values(countries)}
                renderInput={(params) => (
                  <TextField
                    hiddenLabel
                    fullWidth
                    variant="filled"
                    {...params}
                  />
                )}
              />
              {errors.billing_country && (
                <FormHelperText className="Mui-error">
                  {errors.billing_country}
                </FormHelperText>
              )}
            </Grid>
            <Grid item xs={12}>
              <LabelTypography variant="lightFootnote">
                Name<span style={{ color: "red" }}>*</span>
              </LabelTypography>
              <TextField
                type="name"
                inputProps={{ "aria-label": "Without label" }}
                hiddenLabel
                variant="filled"
                placeholder="Your name"
                name="billing_name"
                value={formValues.billing_name}
                onChange={onChangeHandler}
                error={errors.billing_name ? true : false}
                fullWidth
              />
              {errors.billing_name && (
                <FormHelperText className="Mui-error">
                  {errors.billing_name}
                </FormHelperText>
              )}
            </Grid>
            <Grid item xs={12}>
              <LabelTypography variant="lightFootnote">
                Address<span style={{ color: "red" }}>*</span>
              </LabelTypography>
              <TextField
                type="text"
                inputProps={{ "aria-label": "Without label" }}
                hiddenLabel
                variant="filled"
                placeholder="Street address"
                name="billing_address_line1"
                value={formValues.billing_address_line1}
                onChange={onChangeHandler}
                error={errors.billing_address_line1 ? true : false}
                fullWidth
              />
              {errors.billing_address_line1 && (
                <FormHelperText className="Mui-error">
                  {errors.billing_address_line1}
                </FormHelperText>
              )}
            </Grid>
            <Grid item xs={12}>
              <TextField
                type="text"
                inputProps={{ "aria-label": "Without label" }}
                hiddenLabel
                variant="filled"
                placeholder="Apartment, building, floor etc"
                name="billing_address_line2"
                value={formValues.billing_address_line2}
                onChange={onChangeHandler}
                error={errors.billing_address_line2 ? true : false}
                fullWidth
              />
              {errors.billing_address_line2 && (
                <FormHelperText className="Mui-error">
                  {errors.billing_address_line2}
                </FormHelperText>
              )}
            </Grid>
            <Grid item xs={4}>
              <LabelTypography variant="lightFootnote">
                City<span style={{ color: "red" }}>*</span>
              </LabelTypography>
              <TextField
                type="text"
                inputProps={{ "aria-label": "Without label" }}
                hiddenLabel
                variant="filled"
                name="billing_address_city"
                value={formValues.billing_address_city}
                onChange={onChangeHandler}
                error={errors.billing_address_city ? true : false}
                fullWidth
              />
              {errors.billing_address_city && (
                <FormHelperText className="Mui-error">
                  {errors.billing_address_city}
                </FormHelperText>
              )}
            </Grid>
            <Grid item xs={4}>
              <LabelTypography variant="lightFootnote">
                State<span style={{ color: "red" }}>*</span>
              </LabelTypography>
              <TextField
                type="text"
                inputProps={{ "aria-label": "Without label" }}
                hiddenLabel
                variant="filled"
                name="billing_address_state"
                value={formValues.billing_address_state}
                onChange={onChangeHandler}
                error={errors.billing_address_state ? true : false}
                fullWidth
              />
              {errors.billing_address_state && (
                <FormHelperText className="Mui-error">
                  {errors.billing_address_state}
                </FormHelperText>
              )}
            </Grid>
            <Grid item xs={4}>
              <LabelTypography variant="lightFootnote">
                Postal Code
              </LabelTypography>
              <TextField
                type="text"
                inputProps={{ "aria-label": "Without label" }}
                hiddenLabel
                variant="filled"
                name="billing_address_pin"
                value={formValues.billing_address_pin}
                onChange={onChangeHandler}
                error={errors.billing_address_pin ? true : false}
                fullWidth
              />
              {errors.billing_address_pin && (
                <FormHelperText className="Mui-error">
                  {errors.billing_address_pin}
                </FormHelperText>
              )}
            </Grid>
          </Grid>
          <SaveButton
            size="large"
            variant="contained"
            type="submit"
            style={{ marginTop: 20 }}
          >
            <Typography variant="boldBody">
              Pay $
              {planDetails?.amount ? planDetails?.amount.split(".")[0] : ""}
            </Typography>
          </SaveButton>
        </form>
      )}
    </FormWrapper>
  );
};

export default CheckoutForm;
