import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  open: false,
  severity: '',
  message: ''
};


export const NotificationBarSlice = createSlice({
  name: 'notificationBar',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    showNotification: (state, action) => {
      state.open = true;
      state.severity = action.payload.severity;
      state.message = action.payload.message;
    },
    hideNotification: (state, action) => {
      state.open = false;
      state.severity = '';
      state.message = '';
    },
  },
});

export const selectNotificationState = state => state.notificationBar;
export const { showNotification, hideNotification } = NotificationBarSlice.actions;

export default NotificationBarSlice.reducer;
