import React, { useEffect } from "react";
import { onSwitchInstructionClick, handlePaste } from "../RecordingUtil";
import { ContentSection } from "../RecordStyle";
import { Typography } from "@mui/material";
import { TitleWrapper } from "../RecordScreenStyle";
import "./ScreenShot.css";

function ScreenshotInstructions({
  dispatch,
  instructions,
  imageName,
  imageLink,
}) {
  document.addEventListener("paste", (e) => {
    e.preventDefault();
    handlePaste(e, dispatch, imageName, imageLink);
  });

  useEffect(() => {
    if (navigator.userAgentData.platform !== "Windows") {
      onSwitchInstructionClick(dispatch);
    }
  }, []);

  return (
    <ContentSection
      onPaste={(e) => {
        handlePaste(e, dispatch, imageName, imageLink);
      }}
    >
      <Typography textAlign="center" variant="boldSubTitle">
        Follow below instructions to capture screenshot
      </Typography>
      <ContentSection className="instructionsContainer">
        <TitleWrapper variant="lightSubTitle">
          1.Go to the screen you want to capture.
        </TitleWrapper>
        {instructions ? (
          <TitleWrapper variant="lightSubTitle">
            2.Press Alt + Print Screen.
          </TitleWrapper>
        ) : (
          <TitleWrapper variant="lightSubTitle">
            2.Press Shift + Ctrl + Cmd + 3.
          </TitleWrapper>
        )}
        <TitleWrapper variant="lightSubTitle">
          3.Return back to this page.
        </TitleWrapper>
        {instructions ? (
          <TitleWrapper variant="lightSubTitle">
            4.Press Ctrl + V to upload the image.
          </TitleWrapper>
        ) : (
          <TitleWrapper variant="lightSubTitle">
            4.Press Cmd + V to upload the image.
          </TitleWrapper>
        )}
      </ContentSection>
      <ContentSection
        className="instructionsChange"
        onClick={() => {
          onSwitchInstructionClick(dispatch);
        }}
      >
        {!instructions ? (
          <Typography>>>Instructions for Windows</Typography>
        ) : (
          <Typography>>>Instructions for Mac</Typography>
        )}
      </ContentSection>
    </ContentSection>
  );
}

export default ScreenshotInstructions;
