import axiosInstance from "../../common/axios";
import { getMetaTag, META_TAG_BASE_URL } from "../../common/MetaTagReader";

export const zendeskAuth = (origin) => {
    try {
        const data = { 'origin': origin }

        axiosInstance.post('zendesk/redirect', data).then((res) => {
            let auth_id = res.data.id;
            let path = `${getMetaTag(META_TAG_BASE_URL)}/zendesk/close`;
            path = path + "?auth_id=" + auth_id;
            window.location.replace(path);
        });


    } catch (err) {
        console.log("fetch issue");
    }
}

export const intercomAuth = (origin) => {
    try {
        const data = { 'origin': origin }
        axiosInstance.post(`${getMetaTag(META_TAG_BASE_URL)}/intercom/redirect`, data).then((res) => {
            let status = res.data.status;
            let path = ""
            if (status === true) {
                path = "https://app.intercom.io/appstore/redirect?install_success=true";
            }
            if (status === false) {
                path = "https://app.intercom.io/appstore/redirect?error_message=Please login with admin email id";
            }
            window.location.replace(path);
        });
    } catch (err) {
        console.log("fetch issue");
    }
}

export const freshchatAuth = (origin) => {
    try {
        const data = { 'origin': origin }
        axiosInstance.post(`${getMetaTag(META_TAG_BASE_URL)}/freshchat/redirect`, data).then((res) => {
            let status = res.data.status;
            let path = ""
            if (status === true) {
                path = `${getMetaTag(META_TAG_BASE_URL)}/freshchat/close`;
            }
            if (status === false) {
                path = `${getMetaTag(META_TAG_BASE_URL)}/freshchat/close?status=false`;
            }
            window.location.replace(path);
        });
    } catch (err) {
        console.log("fetch issue");
    }
}