import { TextField, Typography, Modal, Box } from "@mui/material";
import React, { useRef, useState, useEffect } from "react";
import {
  FieldWrapper,
  LabelWrapper,
} from "../../../company/components/CompanyStyle";
import { TeamButton } from "../../../teammates/components/TeammatsStyle";
import { setScreenShotImage } from "../../RecordSlice";
import { ContentSection } from "../RecordStyle";
import { axiosPlainInstance } from "../../../../common/axios";
import { getCookie } from "../RecordingUtil";
import "./ScreenShot.css";

export const ScreenshotEdit = ({
  dispatch,
  imageName,
  imageLink,
  editedImgLink,
  showMarkerArea,
  markerImageState,
  onScreenshotClick,
  sentState,
  screenshotNameHandler,
  linkDetail,
  uuid,
}) => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth - 10)
  const [windowHeight, setWindowHeight] = useState(window.innerHeight - 10)
  const setWindowDimensions = () => {
    setWindowWidth(windowWidth)
    setWindowHeight(windowHeight)
  }

  useEffect(() => {
    window.addEventListener('resize', setWindowDimensions);
    return () => {
      window.removeEventListener('resize', setWindowDimensions);
    }
  }, [])
  const [name, setName] = useState(imageName);
  const [message, setMessage] = useState("");
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);

  const handleClose = () => setOpen(false);
  const imgRef = useRef();
  const underImgRef = useRef();

  const sendScreenshot = (url = null) => {
    let uploadUrl = `/api/s/${linkDetail.slug}/`;
    const formData = new FormData();
    if (url) {
      editedImgLink = url;
    }

    fetch(editedImgLink)
      .then((res) => res.blob())
      .then((blob) => {
        const file = new File([blob], name, { type: blob.type });
        formData.append("type", "image");
        formData.append("video", file);
        formData.append("note", message);
        axiosPlainInstance
          .post(uploadUrl, formData, {
            credentials: "same-origin",
            processData: false,
            contentType: false,
            timeout: 500000,
            headers: {
              "X-CSRFToken": getCookie("csrftoken"),
            },
          })
          .then((res) => {
            screenshotNameHandler(
              dispatch,
              res.data.name,
              res.data.uuid,
              res.data.note
            );
            onScreenshotClick(dispatch, sentState);
          })
          .catch((error) => {
            console.log(error);
          });
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    dispatch(
      setScreenShotImage({
        editedImgLink: imageLink,
      })
    );
    setTimeout(() => {
      showMarkerArea(
        dispatch,
        imgRef,
        underImgRef,
        markerImageState,
        handleOpen,
        linkDetail,
        sendScreenshot
      );
    }, 50);
  }, []);

  return (
    <>
      <div>
        <Modal
          keepMounted
          open={open}
          aria-labelledby="keep-mounted-modal-title"
          aria-describedby="keep-mounted-modal-description"
        >
          <Box className="modalContainer">
            <FieldWrapper>
              <LabelWrapper>
                <Typography variant="lightCallout">File name</Typography>
              </LabelWrapper>
              <TextField
                inputProps={{ "aria-label": "Without label" }}
                hiddenLabel
                fullWidth
                id="filled-hidden-label-normal"
                variant="filled"
                placeholder="Enter Image name"
                name="name"
                onChange={(e) => {
                  setName(e.target.value);
                }}
                value={name}
              />
            </FieldWrapper>
            <FieldWrapper>
              <LabelWrapper>
                <Typography variant="lightCallout">
                  Message(Optional)
                </Typography>
              </LabelWrapper>
              <TextField
                inputProps={{ "aria-label": "Without label" }}
                hiddenLabel
                fullWidth
                id="filled-hidden-label-normal"
                variant="filled"
                placeholder="Add an optional message to image"
                multiline
                rows={4}
                name="message"
                onChange={(e) => {
                  setMessage(e.target.value);
                }}
                value={message}
              />
            </FieldWrapper>
            <ContentSection className="modalbuttonsContainer">
              <TeamButton
                size="large"
                variant="contained"
                onClick={() => {
                  sendScreenshot();
                }}
              >
                <Typography ml={1} variant="boldBody">
                  Send screenshot
                </Typography>
              </TeamButton>
              <TeamButton
                size="large"
                variant="contained"
                className="modalCancelbtn"
                onClick={() => {
                  handleClose();
                  showMarkerArea(
                    dispatch,
                    imgRef,
                    underImgRef,
                    markerImageState,
                    handleOpen,
                    linkDetail,
                    sendScreenshot
                  );
                }}
              >
                <Typography ml={1} variant="boldBody">
                  Cancel
                </Typography>
              </TeamButton>
            </ContentSection>
          </Box>
        </Modal>
      </div>
      <ContentSection className="imageEditContainer" >
        <img
          ref={underImgRef}
          src={imageLink}
          alt="Screenshot"
          crossOrigin="anonymous"
          height="100%"
          width="100%"

        />
        <img
          ref={imgRef}
          src={imageLink}
          alt="Screenshot"
          crossOrigin="anonymous"
          height="100%"
          width="100%"


          style={{ position: "absolute" }}
          onClick={() =>
            showMarkerArea(
              dispatch,
              imgRef,
              underImgRef,
              markerImageState,
              handleOpen
            )
          }
        />
      </ContentSection>
    </>
  );
};
