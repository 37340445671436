import axiosInstance, { axiosPlainInstance } from "../../common/axios";

export function generateLinkApi(note, user_slug, is_profile_link = false) {
  return axiosInstance.post(`api/new-recording-request`, {
    note: note,
    is_profile_link: is_profile_link,
    user_slug: user_slug,
  });
}
export function fetchLinkDetailsApi(linkId, flag = "None") {
  // we are using axios plain instance here as this api
  // is getting called from recording page as anonymous user
  return axiosPlainInstance.get(`api/s/${linkId}/${flag}/`);
}

export function videoNameEditApi(videoName, uuid) {
  return axiosInstance.post(`api/edit-video-name`, {
    videoName: videoName,
    uuid: uuid,
  });
}
