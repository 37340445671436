import { ReactComponent as Company } from "../../images/Icon/building.svg";
import { ReactComponent as Settings } from "../../images/Icon/gear.svg";
import { ReactComponent as InboxIcon } from "../../images/Icon/inboxes.svg";
import { ReactComponent as Plus } from "../../images/Icon/square-plus.svg";
import { ReactComponent as Teammats } from "../../images/Icon/users.svg";
import { ReactComponent as Video } from "../../images/Icon/video.svg";
import { ReactComponent as VideoLibrary } from "../../images/Icon/video_library.svg";

import React from "react";
import { selectSessionState } from "../../shared_modules/layout/SessionSlice";
import { useSelector } from "react-redux";

// ----------------------------------------------------------------------

export function SidebarConfig() {
  const { user } = useSelector(selectSessionState);
  const perm = user.company_permissions;
  const admin = "Admin";
  const sidebarCon = [
    {
      title: "Inbox",
      path: "/app/inbox",
      icon: <InboxIcon />,
      additionalIcon: "",
    },
    {
      title: "Record video",
      path: "#",
      icon: <Video />,
      additionalIcon: <Plus />,
      is_modal_item: true,
    },
    {
      title: "Video library",
      path: "/app/video_library",
      icon: <VideoLibrary />,
      // additionalIcon: <Plus />,
      // is_modal_item: true,
    },
    ...(perm === admin
      ? [
        {
          title: "Company",
          path: "/app/company",
          icon: <Company />,
          additionalIcon: "",
        },
      ]
      : []),

    {
      title: "Teammates",
      path: "/app/teammates",
      icon: <Teammats />,
      additionalIcon: "",
    },
    {
      title: "Settings",
      path: "/app/settings",
      icon: <Settings />,
      additionalIcon: "",
    },
  ];
  return sidebarCon;
}

// export { SidebarConfig };
// export  sidebarCon ;
