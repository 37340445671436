import axiosInstance from "../../common/axios";

export function fetchVideosApi(pageNumber, sorttype) {
    return axiosInstance.get("api/my-videos?page=" + pageNumber + "&sortby=" + sorttype)
}

export function deleteVideoAPI(uuid) {
    return axiosInstance.delete(`api/recording/${uuid}`);
}

export function fetchVideoDetailsAPI(videoId, uuid, params) {
    return axiosInstance.get(`api/detail/${videoId}/${uuid}${params ? "?on_root=true" : ''}`)
}
export function fetchAllLinkVideosAPI(videoId, pageNumber) {
    return axiosInstance.get(`api/detail/${videoId}?page=` + pageNumber)
}
export function fetchRecentActivityUsers(videoId, pageNumber) {
    return axiosInstance.get(`api/detail/activity/${videoId}?page=` + pageNumber)
}

export function deleteRecordingAPI(uuid) {
    return axiosInstance.delete(`api/delete-recordings/${uuid}/`)
}

export function updateVideoRequestAPI(slug, values) {
    return axiosInstance.put(`api/updateVideoRequest/${slug}/`, values)
}

export function toggleSharingEnabledAPI(uuid) {
    return axiosInstance.post(`api/toggles-sharing-enabled/${uuid}/`)
}