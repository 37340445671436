import { Avatar, Box, Button, Card, Typography, Chip } from "@mui/material";
import { ReactComponent as TeammateAddIcon } from "../../../images/Icon/UserAdd.svg";
import { styled } from "@mui/material/styles";
// import Chip from "@mui/material/Chip";

export const MainWrapper = styled(Card)(({ theme }) => ({
  padding: theme.spacing(3),
  display: "flex",
  flexDirection: "column-reverse",
  alignItems: "center",

  [theme.breakpoints.up("md")]: {
    padding: theme.spacing(6),
    borderRadius: theme.spacing(5),
    flexDirection: "row",
    alignItems: "start",
  },
}));

export const FormWrapper = styled(Box)(({ theme }) => ({
  ...theme.mixins.flexyCol,

  [theme.breakpoints.up("md")]: {
    width: "45%",
  },
}));

export const SaveButton = styled(Button)(({ theme }) => ({
  width: "100%",
  textTransform: "initial",
  marginTop: theme.spacing(3),
}));

export const TeamButton = styled(Button)(({ theme }) => ({
  textTransform: "initial",
}));

export const CancelButton = styled(Button)(({ theme }) => ({
  textTransform: "initial",
  background: theme.palette.secondary.trans20,
  color: theme.palette.secondary.main,
  "&:hover": {
    background: theme.palette.secondary.trans20,
  },
}));

export const ImageWrapper = styled(Box)(({ theme }) => ({
  width: "100%",
  ...theme.mixins.flexyCenter,
  flex: "1",
  marginBottom: theme.spacing(2),

  [theme.breakpoints.up("md")]: {
    width: "45%",
  },
}));

export const TeammatesSection = styled(Box)(({ theme }) => ({
  ...theme.mixins.flexyCol,
  marginTop: theme.spacing(6.75),
  position: "relative",

  [theme.breakpoints.up("md")]: {},
}));

export const InviteButtonWrapper = styled(Box)(({ theme }) => ({
  display: "none",
  [theme.breakpoints.up("md")]: {
    position: "absolute",
    right: 0,
    display: "block",
  },
}));

export const TitleWrapper = styled(Box)(({ theme }) => ({
  display: "none",
  [theme.breakpoints.down("md")]: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
}));

export const TeammatesCardWrapper = styled(Box)(({ theme }) => ({
  padding: theme.spacing(1, 2.25, 1, 2),
  ...theme.mixins.flexyBetween,
}));

export const TeammateAvatar = styled(Avatar)(({ theme }) => ({
  height: theme.spacing(8.5),
  width: theme.spacing(8.5),
  marginRight: theme.spacing(2),
  borderRadius: theme.spacing(3),
}));

export const TeammateName = styled(Typography)(({ theme }) => ({
  color: theme.palette.secondary.main,
  cursor: "pointer",
  marginBottom: theme.spacing(0.5),

  "&.inactive": {
    color: theme.palette.grey.main,
  },
}));

export const TeammateMail = styled(Typography)(({ theme }) => ({
  color: theme.palette.common.black,
  marginTop: theme.spacing(0.5),
  marginBottom: theme.spacing(1),
  "&.inactive": {
    color: theme.palette.grey.main,
  },
}));

export const MessageWrapper = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(2),
  ...theme.mixins.flexyCol,
}));

export const EmailWrapper = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(3.25),
  ...theme.mixins.flexyCol,
}));

export const DescriptionWrapper = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(2),
}));

//
export const TeammateAdd = styled(TeammateAddIcon)(({ theme }) => ({
  path: {
    fill: theme.palette.common.white,
  },
}));

export const ChipComponent = styled(Chip)(({ theme }) => ({
  width: theme.spacing(9),
}));
