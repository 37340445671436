import { Typography } from '@mui/material';
import React from 'react';
import {
    FlexyCenter, SpaceTop3,
} from '../../../shared_modules/common/StyledClass';
import {
    RedoButton,
    RedoIcon,
} from './RecordScreenStyle';
import { staticPath } from '../../../shared_modules/common/Utils';
import { updateRecordingStateWithData } from '../RecordSlice';
import { OrderedLi, OrderedOl, OrderedP } from './RecordStyle';
import { onStopRecordClick } from './RecordingUtil';

export const renderRecordingErrored = (dispatch) => <div>
    <Typography textAlign="left" variant="lightSubtitle">
        There was an error while trying to record your screen
    </Typography>
    <SpaceTop3>
        <Typography textAlign="left" variant="lightSubtitle">
            This is likely because we're not getting permission to record your screen from your browser or your operating system
        </Typography>
    </SpaceTop3>
    <SpaceTop3>
        <Typography textAlign="left" variant="lightSubtitle">
            Please try enabling screen recording permissions and try again
        </Typography>
    </SpaceTop3>
    {tryAgainButtonContainer(dispatch)}
</div>;

const renderRecordingErroredSystemPermissionsCommon = (dispatch, imgSrc) => <div>
    <Typography textAlign="left" variant="lightSubtitle">
        We couldn't start recording your screen
    </Typography>
    <SpaceTop3>
        <Typography textAlign="left" variant="lightSubtitle">
            Please follow the instructions below to enable screen recording and try again
        </Typography>
    </SpaceTop3>
    <OrderedOl>
        <OrderedLi>
            <OrderedP>Open your system preferences</OrderedP>
            <img src={`${staticPath}/website/images/sysPrefIcon.png`} alt="System preferences icon" />
        </OrderedLi>
        <OrderedLi>
            <OrderedP>Go to "Security & Privacy"</OrderedP>
            <img src={`${staticPath}/website/images/sysPref1.jpg`} alt="System preferences screenshot with system & privacy circled" />
        </OrderedLi>
        <OrderedLi>
            <OrderedP>Go to the "Privacy" tab at the top, then the "Screen Recording" section from the left column</OrderedP>
            <img src={`${staticPath}/website/images/sysPref2.jpg`} alt="System preferences Privacy screenshot" />
        </OrderedLi>
        <OrderedLi>
            <OrderedP>Select your browser from the list of applications in the "Screen Recording section." When a dialog appears, click "Quit now" to close your browser now, or "Later" if you want to come back to this process later</OrderedP>
            <img src={`${staticPath}${imgSrc}`} alt="System preferences Privacy screenshot" />
        </OrderedLi>
        <OrderedLi>
            <OrderedP>Once your browser has screen recording enabled, come back to this link and try again</OrderedP>
        </OrderedLi>
    </OrderedOl>
    {tryAgainButtonContainer(dispatch)}
</div>;


export const renderRecordingErroredSystemPermissionsFF = (dispatch) =>
    renderRecordingErroredSystemPermissionsCommon(dispatch, '/website/images/sysPref3FF.jpg');
export const renderRecordingErroredSystemPermissionsEdge = (dispatch) =>
    renderRecordingErroredSystemPermissionsCommon(dispatch, '/website/images/sysPref3Edge.jpg');
export const renderRecordingErroredSystemPermissions = (dispatch) =>
    renderRecordingErroredSystemPermissionsCommon(dispatch, '/website/images/sysPref3.jpg');


export const renderRecordingErroredChromeOrEdgePermission = (dispatch, imgSrc, altTxt) => <div>
    <Typography textAlign="left" variant="lightSubtitle">
        We couldn't start recording your screen.
    </Typography>
    <SpaceTop3>
        <Typography textAlign="left" variant="lightSubtitle">
            Please refresh this page and try again. When prompted, click the screen preview, then click "Share"
        </Typography>
    </SpaceTop3>
    <SpaceTop3>
        <img src={`${staticPath}${imgSrc}`} alt={altTxt} />
    </SpaceTop3>
    <SpaceTop3>
        <Typography textAlign="left" variant="lightSubtitle">
            If you want to record a specific window or tab instead of your whole screen, select the window or tab from the sections at the top of the prompt.
        </Typography>
    </SpaceTop3>
    {tryAgainButtonContainer(dispatch)}

</div>;
export const renderRecordingErroredChromePermission = (dispatch) =>
    renderRecordingErroredChromeOrEdgePermission(dispatch, 'website/images/enableChrome.jpg', 'Chrome screen recording permission request')
export const renderRecordingErroredEdgePermission = (dispatch) =>
    renderRecordingErroredChromeOrEdgePermission(dispatch, 'website/images/enableEdge.jpg', 'Edge screen recording permission request')

export const renderRecordingErroredFFPermission = (dispatch) => <div>
    <Typography textAlign="left" variant="lightSubtitle">
        We couldn't start recording your screen.
    </Typography>
    <SpaceTop3>
        <Typography textAlign="left" variant="lightSubtitle">
            Please refresh this page and try again. When prompted, select the window you want to record from the dropdown, then click "Allow"
        </Typography>
    </SpaceTop3>
    <SpaceTop3>
        <img src={`${staticPath}/website/images/enableFF3.jpg`} alt="Firefox screen recording permission request" />
    </SpaceTop3>
</div>;

export const renderRecordingErroredSafariPermission = (dispatch) => <div>
    <Typography textAlign="left" variant="lightSubtitle">
        We couldn't start recording your screen.
    </Typography>
    <SpaceTop3>
        <Typography textAlign="left" variant="lightSubtitle">
            Please try again and click the "Allow" button when prompted
        </Typography>
    </SpaceTop3>
    <SpaceTop3>
        <img src={`${staticPath}/website/images/enableSafari.jpg`} alt="Safari screen recording permission request" />
    </SpaceTop3>
</div>;

function tryAgainButtonContainer(dispatch) {
    return <SpaceTop3>
        <FlexyCenter>
            {tryAgainButton(dispatch)}
        </FlexyCenter>
    </SpaceTop3>;
}

export const tryAgainButton = (dispatch, cancelRecording) => {
    return <RedoButton
        size="large"
        variant="contained"
        width="50%"

        onClick={() => {
            if (dispatch !== null) { //dispatch can be null on record screen messages
                onStopRecordClick(dispatch, cancelRecording);
                dispatch(updateRecordingStateWithData({ "recordingState": '' }));
            }
        }}
    >
        <Typography variant="boldBody">Redo</Typography>
        <RedoIcon />
    </RedoButton>
}
