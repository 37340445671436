import { alpha, Grid, Tooltip, Typography, Zoom } from "@mui/material";
import { useDispatch } from "react-redux";
import { useRef, useState } from "react";
import { getMetaTag, META_TAG_BASE_URL } from "../../common/MetaTagReader";
import ConfirmDialog from "../common/ConfirmDialog";
import { StyledCardMd } from "../common/StyledCard";
import { FlexyCenter } from "../common/StyledClass";
import { downloadFile, getVideoType } from "../common/Utils";
import { ReactComponent as DotsMenu } from "../../images/Icon/dots-menu.svg";
import { showNotification } from "../notification_bar/NotificationBarSlice";
import {
  CardContentStyle,
  CardMediaStyle,
  MainWrapper,
  StyledVideo,
  MenuItemStyled,
  MenuItemWrapper,
  DotsButton,
} from "./VideoLibraryStyle";
import MenuPopover from "../common/MenuPopover";
import AddToLibrary from "../../pages/video_library/components/AddToLibrary";
import { handleCopyLinkClick } from "../../pages/record/components/RecordingUtil";
// ----------------------------------------------------------------------

export default function VideoLibraryCard({ item, deleteVideo, pageNumber }) {
  const baseURL = `${getMetaTag(META_TAG_BASE_URL)}`;
  const dispatch = useDispatch();
  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Grid item xs={12} sm={6} md={4}>
      <StyledCardMd>
        <CardMediaStyle>
          <StyledVideo
            controls
            data-recording_uuid={item.uuid}
            style={{ height: 180 }}
            poster={item.poster ? item.poster : ""}
            preload="metadata"
          >
            <source
              src={baseURL + item.video}
              type={getVideoType(item.video)}
            />
          </StyledVideo>
        </CardMediaStyle>

        <CardContentStyle>
          <MainWrapper>
            <Tooltip TransitionComponent={Zoom} title={item.title}>
              <Typography
                variant="boldSubhead"
                display="block"
                noWrap
                maxWidth={"200px"}
              >
                {item.title}
              </Typography>
            </Tooltip>

            <FlexyCenter>
              <DotsButton
                ref={anchorRef}
                onClick={handleOpen}
                sx={{
                  cursor: "pointer",
                  ...(open && {
                    "&:before": {
                      zIndex: 1,
                      content: "''",
                      position: "absolute",
                      bgcolor: (theme) => alpha(theme.palette.grey[300], 0.12),
                    },
                  }),
                }}
              >
                <DotsMenu style={{ paddingBottom: "3.5px" }} />
              </DotsButton>
              <MenuPopover
                open={open}
                onClose={handleClose}
                anchorEl={anchorRef.current}
                sx={{ width: 150 }}
              >
                <MenuItemStyled>
                  <MenuItemWrapper>
                    <AddToLibrary
                      id={null}
                      slug={item.slug}
                      closeOnRequest={handleClose}
                      pageNumber={pageNumber}
                    />
                    {/* <VideoDetailsPopup slug={item.slug} /> */}
                  </MenuItemWrapper>
                </MenuItemStyled>

                <MenuItemStyled
                  onClick={() => {
                    handleCopyLinkClick(dispatch, item.sharing_link);
                    handleClose();
                  }}
                >
                  <MenuItemWrapper>
                    <Typography>Copy link</Typography>
                  </MenuItemWrapper>
                </MenuItemStyled>

                <ConfirmDialog
                  key={"dashboard-confirm-" + item.id}
                  description="Are you sure you want to delete this video?"
                  onConfirm={() => {
                    deleteVideo(item.uuid, pageNumber);
                  }}
                  closeOnRequest={handleClose}
                >
                  <MenuItemStyled onClose={handleClose}>
                    <MenuItemWrapper>
                      <Typography>Delete</Typography>
                    </MenuItemWrapper>
                  </MenuItemStyled>
                </ConfirmDialog>

                <MenuItemStyled
                  onClick={() => {
                    downloadFile(baseURL + item.video, item.file_name);
                    handleClose();
                  }}
                >
                  <MenuItemWrapper>
                    <Typography>Download</Typography>
                  </MenuItemWrapper>
                </MenuItemStyled>
              </MenuPopover>
            </FlexyCenter>
          </MainWrapper>
          <Tooltip TransitionComponent={Zoom} title={item.description}>
            <Typography
              variant="lightCaption"
              display="-webkit-box"
              sx={{
                overflow: "hidden",
                textOverflow: "ellipsis",
                display: "-webkit-box",
                WebkitLineClamp: "2",
                WebkitBoxOrient: "vertical",
              }}
            >
              {item.description}
            </Typography>
          </Tooltip>
        </CardContentStyle>
      </StyledCardMd>
    </Grid>
  );
}

VideoLibraryCard.defaultProps = {
  params: false,
};
