import { CircularProgress, Typography } from "@mui/material";

import React, {
  useEffect,
} from "react";
import {
  GreyBadge1,
  PrimaryBadge1,
} from "../../../shared_modules/common/RoundIconBadge";
import {
  FlexyCenter,
  SpaceTop3,
} from "../../../shared_modules/common/StyledClass";
import BasicSwitch from "../../../shared_modules/common/Switch";
import RecordScreenCard from "../../../shared_modules/record_screen_card/RecordScreenCard";
import {
  CardDescription,
  LockIcon,
  MainWrapper,
  RecordButton,
  RecordDescription,
  RecordIcon,
  TitleWrapper,
  SwitchWrapper,
  ActionRecordButtonWrapper,
  StopRecordButton,
  ProgressWrapper,
  ProgressPercent,
  LinkButton,
  RedoButton,
  DownloadIconLarge,
  MainTitleWrapper,
  DownloadLargeButton,
  IssueLink,
  CardScreenshotDescription,
  InfoWwrapper,
  InfoWwrapperHead,
} from "./RecordScreenStyle";
import { useParams } from "react-router-dom";
import {
  fetchLinkDetails,
  selectRecordingState,
  setSwitchChecked,
  updateRecordingStateWithData,
  updateFreshchatUploadStatus,
  updateIntercomUploadStatus,
  updateZendeskUploadStatus,
  updateMondayUploadStatus,
} from "../RecordSlice";
import { useDispatch, useSelector } from "react-redux";
import {
  STATE_FAIL,
  STATE_LOADING,
  STATE_SUCCESS,
} from "../../../common/Constants";
import {
  initRecordingScreen,
  UPLOAD_STATUS_STARTED,
  UPLOAD_STATUS_SUCCESS,
  UPLOAD_STATUS_ERROR,
  ZENDESK_SOURCE,
  INTERCOM_SOURCE,
  FRESHCHAT_SOURCE,
  onchangeAudioToggle,
  onRecordClick,
  onStopRecordClick,
  RECORDING_AUDIO_STARTED,
  STATE_RECORDING_ACTIVE,
  STATE_SCREENSHOT_INSTRUCTION_ACTIVE,
  STATE_SCREENSHOT_CAPUTURED,
  STATE_SCREENSHOT_SENT,
  STATE_RECORDING_COMPLETE,
  STATE_RECORDING_ERRORED,
  STATE_RECORDING_ERRORED_CHROME_PERMISSIONS,
  STATE_RECORDING_ERRORED_EDGE_PERMISSIONS,
  STATE_RECORDING_ERRORED_FIREFOX_PERMISSIONS,
  STATE_RECORDING_ERRORED_SAFARI_PERMISSIONS,
  STATE_RECORDING_ERRORED_SYSTEM_PERMISSIONS,
  STATE_RECORDING_ERRORED_SYSTEM_PERMISSIONS_EDGE,
  STATE_RECORDING_ERRORED_SYSTEM_PERMISSIONS_FF,
  STATE_RECORDING_SUBMITTED,
  STATE_RECORDING_SUBMITTED_ERROR,
  STATE_RECORDING_TRIGGERED,
  submitVideo,
  uploadToJiraHanlder,
  uploadToVideo,
  onScreenshotClick,
  screenshotNameHandler,
  showMarkerArea,
  MONDAYCOM_SOURCE,
  JIRA_SOURCE
} from "./RecordingUtil";
import {
  renderRecordingErrored,
  renderRecordingErroredChromePermission,
  renderRecordingErroredEdgePermission,
  renderRecordingErroredFFPermission,
  renderRecordingErroredSafariPermission,
  renderRecordingErroredSystemPermissions,
  renderRecordingErroredSystemPermissionsEdge,
  renderRecordingErroredSystemPermissionsFF,
  tryAgainButton,
} from "./RecordingErrors";
import "../record.css";
import { StyledVideo } from "../../video_details/components/ExpandVideoStyle";
import { downloadFile } from "../../../shared_modules/common/Utils";
import { getMetaTag, META_TAG_BASE_URL } from "../../../common/MetaTagReader";
import {
  CustomMessageWrapper,
} from "./RecordStyle";
import EditVideoName from "./EditVideoName";
import { ScreenshotEdit } from "./Screenshot/ScreenshotEdit";
import ScreenshotUploadSuccess from "./Screenshot/ScreenshotUploadSuccess";
import ScreenshotInstructions from "./Screenshot/ScreenshotInstructions";
import { useStopwatch } from 'react-timer-hook';
import { selectSessionState } from "../../../shared_modules/layout/SessionSlice";

const RecordScreen = (props) => {
  const { user, isGuest } = useSelector(selectSessionState);
  const {
    linkDetail,
    status,
    browserNotSupported,
    recordingNotSupported,
    switchChecked,
    microphoneBlocked,
    recordingState,
    instructions,
    imageName,
    imageLink,
    editedImgLink,
    markerImageState,
    note,
    audioRecordingState,
    saveProgressPercent,
    videoLink,
    canWebm,
    blob,
    videoBlobLink,
    isUploadStuck,
    jiraUploadStatus,
    zendeskUploadStatus,
    intercomUploadStatus,
    freshchatUploadStatus,
    mondayUploadStatus,
    videoName,
    uuid,
  } = useSelector(selectRecordingState);
  let { linkId, flag, audio } = useParams();

  if (props.requestSlug) {
    linkId = props.requestSlug;
  }
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchLinkDetails(linkId));
  }, [dispatch, linkId]);

  useEffect(() => {
    if (audio && (audio === true || audio === "true")) {
      dispatch(setSwitchChecked(true));
    }
  }, [dispatch, audio]);

  useEffect(() => {
    if (flag && linkDetail != null && (user !== null || isGuest) &&
      isjiraLink(linkDetail) && !linkDetail.external_issue_info.uploaded) {

      if (flag === 'record') { onRecordClick(dispatch, switchChecked); }
      if (flag === 'screenshot') { onScreenshotClick(dispatch, STATE_SCREENSHOT_INSTRUCTION_ACTIVE); }
    }
  }, [dispatch, linkDetail, user, isGuest, flag, switchChecked]);

  useEffect(() => {
    // start an activity recorder when status changes to success
    if (status === STATE_SUCCESS) {
      initRecordingScreen(dispatch, linkDetail);
    }
  }, [dispatch, status, linkDetail]);

  useEffect(() => {
    onchangeAudioToggle(dispatch, switchChecked);
  }, [dispatch, switchChecked]);

  var logo = "";
  if (
    linkDetail &&
    isjiraLink(linkDetail) &&
    linkDetail?.external_issue_info?.logo
  ) {
    logo = linkDetail.external_issue_info.logo;

  }
  return (
    <>
      {recordingState === STATE_SCREENSHOT_CAPUTURED ||
        recordingState === STATE_SCREENSHOT_SENT ? (
        <CardScreenshotDescription>
          {status === STATE_SUCCESS &&
            !browserNotSupported &&
            !recordingNotSupported &&
            renderRecordingStates(
              dispatch,
              linkDetail,
              recordingState,
              instructions,
              imageName,
              imageLink,
              editedImgLink,
              markerImageState,
              note,
              audioRecordingState,
              switchChecked,
              microphoneBlocked,
              saveProgressPercent,
              videoLink,
              canWebm,
              blob,
              videoBlobLink,
              isUploadStuck,
              jiraUploadStatus,
              zendeskUploadStatus,
              intercomUploadStatus,
              freshchatUploadStatus,
              mondayUploadStatus,
              videoName,
              uuid
            )}
        </CardScreenshotDescription>
      ) : (
        <MainWrapper>
          {status === STATE_SUCCESS && (
            <RecordScreenCard
              user={linkDetail ? linkDetail.user : null}
              jiraLogo={logo}
            >
              <CardDescription>
                {status === STATE_LOADING && <div>Please wait...</div>}

                {status === STATE_FAIL && (
                  <div>
                    Something went wrong. Please refresh page or try after some
                    time
                  </div>
                )}

                {status === STATE_SUCCESS &&
                  !browserNotSupported &&
                  !recordingNotSupported &&
                  renderRecordingStates(
                    dispatch,
                    linkDetail,
                    recordingState,
                    instructions,
                    imageName,
                    imageLink,
                    editedImgLink,
                    markerImageState,
                    note,
                    audioRecordingState,
                    switchChecked,
                    microphoneBlocked,
                    saveProgressPercent,
                    videoLink,
                    canWebm,
                    blob,
                    videoBlobLink,
                    isUploadStuck,
                    jiraUploadStatus,
                    zendeskUploadStatus,
                    intercomUploadStatus,
                    freshchatUploadStatus,
                    mondayUploadStatus,
                    videoName,
                    uuid
                  )}
                {(browserNotSupported || recordingNotSupported) &&
                  renderRecordingNotSupported(
                    browserNotSupported,
                    recordingNotSupported
                  )}

                {showBottomNav(recordingState)}
              </CardDescription>
            </RecordScreenCard>
          )}
        </MainWrapper>
      )}
    </>
  );
};
const showBottomNav = (recordingState) => (
  <SpaceTop3>
    <FlexyCenter>
      {recordingState === "" && (
        <>
          <PrimaryBadge1 />
          <GreyBadge1 />
          <GreyBadge1 />
        </>
      )}
      {recordingState === STATE_RECORDING_ACTIVE ||
        recordingState === STATE_RECORDING_SUBMITTED ||
        (recordingState === STATE_SCREENSHOT_INSTRUCTION_ACTIVE && (
          <>
            <PrimaryBadge1 />
            <PrimaryBadge1 />
            <GreyBadge1 />
          </>
        ))}

      {(recordingState === STATE_RECORDING_COMPLETE ||
        recordingState === STATE_RECORDING_SUBMITTED_ERROR) && (
          <>
            <PrimaryBadge1 />
            <PrimaryBadge1 />
            <PrimaryBadge1 />
          </>
        )}
    </FlexyCenter>
  </SpaceTop3>
);

const renderRecordingStates = (
  dispatch,
  linkDetail,
  recordingState,
  instructions,
  imageName,
  imageLink,
  editedImgLink,
  markerImageState,
  note,
  audioRecordingState,
  switchChecked,
  microphoneBlocked,
  saveProgressPercent,
  videoLink,
  canWebm,
  blob,
  videoBlobLink,
  isUploadStuck,
  jiraUploadStatus,
  zendeskUploadStatus,
  intercomUploadStatus,
  freshchatUploadStatus,
  mondayUploadStatus,
  videoName,
  uuid
) => {
  if (isjiraLink(linkDetail) && linkDetail.external_issue_info.uploaded) {
    return renderRecordingLinkAlreadyUsed();
  }
  if (isMondayLink(linkDetail) && linkDetail.external_issue_info.uploaded) {
    return renderMondayRecordingLinkAlreadyUsed();
  }
  setFileName(videoName);

  return (
    <>
      {recordingState === "" &&
        renderRecordingStart(
          dispatch,
          linkDetail,
          switchChecked,
          microphoneBlocked
        )}
      {recordingState === STATE_RECORDING_TRIGGERED && (
        <Typography textAlign="center" variant="boldSubTitle">
          You'll now be prompted to grant access to your screen recorder. Once
          granted, you screen will start being recorded
        </Typography>
      )}
      {recordingState === STATE_RECORDING_ERRORED_CHROME_PERMISSIONS &&
        renderRecordingErroredChromePermission(dispatch)}
      {recordingState === STATE_RECORDING_ERRORED_EDGE_PERMISSIONS &&
        renderRecordingErroredEdgePermission(dispatch)}
      {recordingState === STATE_RECORDING_ERRORED_FIREFOX_PERMISSIONS &&
        renderRecordingErroredFFPermission(dispatch)}
      {recordingState === STATE_RECORDING_ERRORED_SAFARI_PERMISSIONS &&
        renderRecordingErroredSafariPermission(dispatch)}
      {recordingState === STATE_RECORDING_ERRORED &&
        renderRecordingErrored(dispatch)}
      {recordingState === STATE_RECORDING_ERRORED_SYSTEM_PERMISSIONS_FF &&
        renderRecordingErroredSystemPermissionsFF(dispatch)}
      {recordingState === STATE_RECORDING_ERRORED_SYSTEM_PERMISSIONS_EDGE &&
        renderRecordingErroredSystemPermissionsEdge(dispatch)}
      {recordingState === STATE_RECORDING_ERRORED_SYSTEM_PERMISSIONS &&
        renderRecordingErroredSystemPermissions(dispatch)}
      {recordingState === STATE_RECORDING_ACTIVE &&
        renderRecordingActive(dispatch, linkDetail, audioRecordingState, microphoneBlocked)}
      {recordingState === STATE_SCREENSHOT_INSTRUCTION_ACTIVE && (
        <ScreenshotInstructions
          dispatch={dispatch}
          imageName={imageName}
          instructions={instructions}
          imageLink={imageLink}
        />
      )}
      {recordingState === STATE_SCREENSHOT_CAPUTURED && (
        <ScreenshotEdit
          imageName={imageName}
          imageLink={imageLink}
          editedImgLink={editedImgLink}
          showMarkerArea={showMarkerArea}
          markerImageState={markerImageState}
          dispatch={dispatch}
          onScreenshotClick={onScreenshotClick}
          sentState={STATE_SCREENSHOT_SENT}
          screenshotNameHandler={screenshotNameHandler}
          linkDetail={linkDetail}
          uuid={uuid}
        />
      )}
      {recordingState === STATE_SCREENSHOT_SENT && (
        <ScreenshotUploadSuccess
          dispatch={dispatch}
          imageName={imageName}
          editedImgLink={editedImgLink}
          linkDetail={linkDetail}
          uuid={uuid}
          note={note}
          zendeskUploadStatus={zendeskUploadStatus}
          intercomUploadStatus={intercomUploadStatus}
          freshchatUploadStatus={freshchatUploadStatus}
          jiraUploadStatus={jiraUploadStatus}
          mondayUploadStatus={mondayUploadStatus}
        />
      )}
      {recordingState === STATE_RECORDING_SUBMITTED &&
        renderRecordingUploadInProgress(
          dispatch,
          linkDetail,
          audioRecordingState,
          saveProgressPercent,
          isUploadStuck,
          videoBlobLink,
          blob,
          videoName
        )}
      {recordingState === STATE_RECORDING_COMPLETE &&
        renderRecordingUploadSuccess(
          dispatch,
          linkDetail,
          videoLink,
          canWebm,
          jiraUploadStatus,
          zendeskUploadStatus,
          intercomUploadStatus,
          freshchatUploadStatus,
          mondayUploadStatus,
          videoName,
          uuid
        )}
      {recordingState === STATE_RECORDING_SUBMITTED_ERROR &&
        renderRecordingUploadError(
          dispatch,
          linkDetail,
          videoBlobLink,
          blob,
          videoName
        )}
    </>
  );
};

const renderRecordingLinkAlreadyUsed = () => {
  return (
    <>
      <Typography textAlign="center" variant="boldSubTitle">
        This link is already used to upload video, please generate new link from
        Jira issue to upload new video recording
      </Typography>
    </>
  );
};

const renderMondayRecordingLinkAlreadyUsed = () => {
  return (
    <>
      <Typography textAlign="center" variant="boldSubTitle">
        This link is already used to upload video, please generate new link from
        your monday.com account to upload new video recording
      </Typography>
    </>
  );
};

let file_name = "";

const setFileName = (name) => {
  file_name = name;
};
const renderRecordingUploadError = (
  dispatch,
  linkDetail,
  videoBlobLink,
  blob,
  videoName
) => {
  if (file_name === "") {
    setFileName(videoName);
  }
  return (
    <>
      <Typography textAlign="center" variant="boldSubTitle">
        There was an error while uploading your video. We're super sorry about
        this and are now looking into it!
      </Typography>

      <ActionRecordButtonWrapper>
        <StopRecordButton
          size="large"
          variant="contained"
          onClick={() => submitVideo(blob, dispatch)}
        >
          <Typography variant="boldBody">Try upload again</Typography>
        </StopRecordButton>
        <RedoButton
          size="large"
          variant="contained"
          width="100%"
          onClick={() => {
            downloadFile(videoBlobLink, file_name);
          }}
        >
          <DownloadIconLarge />
          <Typography variant="boldBody">Download video</Typography>
        </RedoButton>
      </ActionRecordButtonWrapper>
      {recordingFooter(linkDetail)}
    </>
  );
};
const showUserCustomMessage = (msg) => (
  <CustomMessageWrapper>
    <Typography variant="lightCallout" my={2.5}>
      {msg}
    </Typography>
  </CustomMessageWrapper>
);

const renderRecordingUploadSuccess = (
  dispatch,
  linkDetail,
  videoLink,
  canWebm,
  jiraUploadStatus,
  zendeskUploadStatus,
  intercomUploadStatus,
  freshchatUploadStatus,
  mondayUploadStatus,
  videoName,
  uuid
) => {
  if (file_name === "") {
    setFileName(videoName);
  }
  return (
    <>
      <Typography variant="boldSubTitle">Here's the video</Typography>
      <MainTitleWrapper>
        <TitleWrapper variant="lightSubTitle">
          {isjiraLink(linkDetail) && (
            <TitleWrapper variant="boldSubTitle">
              {jiraUploadStatus.status !== UPLOAD_STATUS_SUCCESS && (
                <>
                  Thank you! Your video is ready to be attached to Jira
                  issue&nbsp;
                </>
              )}
              {jiraUploadStatus.status === UPLOAD_STATUS_SUCCESS && (
                <>
                  Your video is queued to upload and shortly it will be attached
                  to Jira issue &nbsp;
                </>
              )}
              <IssueLink
                href={`${linkDetail.external_issue_info.jiraBaseUrl}/browse/${linkDetail.external_issue_info.issueKey}`}
                target="_blank"
              >
                {linkDetail.external_issue_info.issueKey}
              </IssueLink>
            </TitleWrapper>
          )}
          {!isjiraLink(linkDetail) &&
            linkDetail.jsm_id === null &&
            linkDetail.source !== ZENDESK_SOURCE &&
            linkDetail.source !== INTERCOM_SOURCE &&
            linkDetail.source !== FRESHCHAT_SOURCE &&
            linkDetail.source !== MONDAYCOM_SOURCE && (
              <TitleWrapper variant="boldSubTitle">
                Your video has been sent to {linkDetail.user.name}{" "}
                {linkDetail.user.company.name
                  ? `from ${linkDetail.user.company.name}`
                  : ""}
              </TitleWrapper>
            )}
          {!isjiraLink(linkDetail) && linkDetail.jsm_id && (
            <TitleWrapper variant="boldSubTitle">
              Your video will be automatically attached to the Jira issue once
              it is created.
            </TitleWrapper>
          )}
          {linkDetail.source === ZENDESK_SOURCE && (
            //here zendesk
            <TitleWrapper variant="boldSubTitle">
              {zendeskUploadStatus.status !== UPLOAD_STATUS_SUCCESS && (
                <>
                  Thank you! Your video is ready. You can send it{" "}
                  {linkDetail.user.company.name
                    ? `to ${linkDetail.user.company.name}.`
                    : "now."}{" "}
                  &nbsp;
                </>
              )}
              {zendeskUploadStatus.status === UPLOAD_STATUS_SUCCESS && (
                <>
                  Your video has been sent
                  {linkDetail.user.company.name
                    ? ` to ${linkDetail.user.company.name}.`
                    : "."}{" "}
                  You can close this window now.&nbsp;
                </>
              )}
            </TitleWrapper>
          )}
          {linkDetail.source === INTERCOM_SOURCE && (
            //here zendesk
            <TitleWrapper variant="boldSubTitle">
              {intercomUploadStatus.status !==
                UPLOAD_STATUS_SUCCESS && (
                  <>
                    Thank you! Your video is ready. You can send it{" "}
                    {linkDetail.user.company.name
                      ? `to ${linkDetail.user.company.name}.`
                      : "now."}{" "}
                    &nbsp;
                  </>
                )}
              {intercomUploadStatus.status ===
                UPLOAD_STATUS_SUCCESS && (
                  <>
                    Your video has been sent
                    {linkDetail.user.company.name
                      ? ` to ${linkDetail.user.company.name}.`
                      : "."}{" "}
                    You can close this window now.&nbsp;
                  </>
                )}
            </TitleWrapper>
          )}
          {linkDetail.source === FRESHCHAT_SOURCE && (
            //here zendesk
            <TitleWrapper variant="boldSubTitle">
              {freshchatUploadStatus.status !==
                UPLOAD_STATUS_SUCCESS && (
                  <>
                    Thank you! Your video is ready. You can send it{" "}
                    {linkDetail.user.company.name
                      ? `to ${linkDetail.user.company.name}.`
                      : "now."}{" "}
                    &nbsp;
                  </>
                )}
              {freshchatUploadStatus.status ===
                UPLOAD_STATUS_SUCCESS && (
                  <>
                    Your video has been sent
                    {linkDetail.user.company.name
                      ? ` to ${linkDetail.user.company.name}.`
                      : "."}{" "}
                    You can close this window now.&nbsp;
                  </>
                )}
            </TitleWrapper>
          )}
          {isMondayLink(linkDetail) && (
            <TitleWrapper variant="boldSubTitle">
              {mondayUploadStatus.status !== UPLOAD_STATUS_SUCCESS && (
                <>
                  Thank you! Your video is ready to be attached to monday.com&nbsp;
                </>
              )}
              {mondayUploadStatus.status === UPLOAD_STATUS_SUCCESS && (
                <>
                  Your video is queued to upload and shortly it will be attached to monday.com&nbsp;
                </>
              )}
              <IssueLink
                href={`${linkDetail.external_issue_info.baseUrl}/boards/${linkDetail.external_issue_info.boardId}/pulses/${linkDetail.external_issue_info.itemId}`}
                target="_blank"
              >
                item
              </IssueLink>
            </TitleWrapper>
          )}
        </TitleWrapper>
      </MainTitleWrapper>

      {isjiraLink(linkDetail) && linkDetail.external_issue_info.thanks
        ? showUserCustomMessage(linkDetail.external_issue_info.thanks)
        : linkDetail.user?.thanks_note &&
        showUserCustomMessage(linkDetail.user.thanks_note)}

      {canWebm && (
        <SpaceTop3>
          <StyledVideo
            controls
            preload="auto"
            autoplay="true"
            src={videoLink}
          />
        </SpaceTop3>
      )}

      <EditVideoName
        videoName={videoName}
        uuid={uuid}
        fileName={(name) => setFileName(name)}
      />
      {((isjiraLink(linkDetail) &&
        jiraUploadStatus.status === UPLOAD_STATUS_SUCCESS) ||
        (isMondayLink(linkDetail) && mondayUploadStatus.status === UPLOAD_STATUS_SUCCESS)) && (
          <SpaceTop3>
            <TitleWrapper>
              <InfoWwrapperHead variant="lightSubhead">
                Please wait !! &nbsp;
              </InfoWwrapperHead>
              <InfoWwrapper variant="lightSubhead">
                It may take few minutes depending on the size of recording.
                Usually it takes approximately 1 min to attach the 2 minute long
                recording.
              </InfoWwrapper>
            </TitleWrapper>
          </SpaceTop3>
        )}
      <ActionRecordButtonWrapper>
        <DownloadLargeButton
          size="large"
          variant="contained"
          onClick={() => {
            downloadFile(
              `${getMetaTag(META_TAG_BASE_URL)}${videoLink}`,
              file_name
            );
          }}
        >
          <DownloadIconLarge />
          <Typography variant="boldBody">Download</Typography>
        </DownloadLargeButton>
        {isjiraLink(linkDetail) &&
          jiraUploadStatus.status !== UPLOAD_STATUS_SUCCESS && (
            <RedoButton
              size="large"
              variant="contained"
              width="100%"
              onClick={() => {
                uploadToJiraHanlder(linkDetail, dispatch, videoName);
              }}
              disabled={jiraUploadStatus.status === UPLOAD_STATUS_STARTED}
            >
              <Typography variant="boldBody">
                {jiraUploadStatus.status === UPLOAD_STATUS_STARTED && (
                  <>Uploading to Jira...</>
                )}
                {jiraUploadStatus.status !== UPLOAD_STATUS_STARTED && (
                  <>Upload to Jira issue</>
                )}
              </Typography>
            </RedoButton>
          )}

        {linkDetail &&
          linkDetail.source === ZENDESK_SOURCE &&
          zendeskUploadStatus.status !== UPLOAD_STATUS_SUCCESS && (
            <DownloadLargeButton
              size="large"
              variant="contained"
              onClick={() => {
                uploadToVideo(
                  linkDetail,
                  dispatch,
                  "zendesk",
                  updateZendeskUploadStatus,
                  UPLOAD_STATUS_ERROR
                );
              }}
            >
              <Typography variant="boldBody">Send Video</Typography>
            </DownloadLargeButton>
          )}

        {linkDetail &&
          linkDetail.source === INTERCOM_SOURCE &&
          intercomUploadStatus.status !== UPLOAD_STATUS_SUCCESS && (
            <DownloadLargeButton
              size="large"
              variant="contained"
              onClick={() => {
                uploadToVideo(
                  linkDetail,
                  dispatch,
                  "intercom",
                  updateIntercomUploadStatus,
                  UPLOAD_STATUS_ERROR
                );
              }}
            >
              <Typography variant="boldBody">Send Video</Typography>
            </DownloadLargeButton>
          )}
        {linkDetail &&
          linkDetail.source === FRESHCHAT_SOURCE &&
          freshchatUploadStatus.status !== UPLOAD_STATUS_SUCCESS && (
            <DownloadLargeButton
              size="large"
              variant="contained"
              onClick={() => {
                uploadToVideo(
                  linkDetail,
                  dispatch,
                  "freshchat",
                  updateFreshchatUploadStatus,
                  UPLOAD_STATUS_ERROR
                );
              }}
            >
              <Typography variant="boldBody">Send Video</Typography>
            </DownloadLargeButton>
          )}
        {linkDetail &&
          linkDetail.source === MONDAYCOM_SOURCE &&
          mondayUploadStatus.status !== UPLOAD_STATUS_SUCCESS && (
            <DownloadLargeButton
              size="large"
              variant="contained"
              onClick={() => {
                uploadToVideo(
                  linkDetail,
                  dispatch,
                  "mondaycom",
                  updateMondayUploadStatus,
                  UPLOAD_STATUS_ERROR
                );
              }}
              disabled={mondayUploadStatus.status === UPLOAD_STATUS_STARTED}
            >
              <Typography variant="boldBody">
                {mondayUploadStatus.status === UPLOAD_STATUS_STARTED && (
                  <>Uploading to monday.com...</>
                )}
                {mondayUploadStatus.status !== UPLOAD_STATUS_STARTED && (
                  <>Upload to monday.com</>
                )}
              </Typography>
            </DownloadLargeButton>
          )}
      </ActionRecordButtonWrapper>
      {isjiraLink(linkDetail) &&
        jiraUploadStatus.status === UPLOAD_STATUS_SUCCESS ? (
        <></>
      ) : (
        <RecordDescription>
          <Typography variant="lightCallout">
            Does the video clearly show what you wanted it to? If not, &nbsp;
            <TitleWrapper
              variant="lightCallout"
              onClick={() => {
                dispatch(updateRecordingStateWithData({ recordingState: "" }))
                dispatch(setSwitchChecked(false))
              }
              }
            >
              <LinkButton>record another video</LinkButton>
            </TitleWrapper>
          </Typography>
        </RecordDescription>
      )}
    </>
  );
};

const renderRecordingUploadInProgress = (
  dispatch,
  linkDetail,
  audioRecordingState,
  saveProgressPercent,
  isUploadStuck,
  videoBlobLink,
  blob,
  videoName
) => {
  if (file_name === "") {
    setFileName(videoName);
  }
  return (
    <>
      <Typography textAlign="center" variant="boldSubTitle">
        Uploading your video
      </Typography>

      <ProgressWrapper>
        <CircularProgress variant="determinate" value={saveProgressPercent} />
        <ProgressPercent>
          <Typography variant="lightCaption">Saving video</Typography>
          <Typography variant="boldH4">
            {`${Math.round(saveProgressPercent)}%`}
          </Typography>
        </ProgressPercent>
      </ProgressWrapper>
      {isUploadStuck && (
        <>
          <SpaceTop3>
            <Typography textAlign="center" variant="boldSubTitle">
              Trouble saving video?
            </Typography>
          </SpaceTop3>
          <ActionRecordButtonWrapper>
            <StopRecordButton
              size="large"
              variant="contained"
              onClick={() => submitVideo(blob, dispatch)}
            >
              <Typography variant="boldBody">Try upload again</Typography>
            </StopRecordButton>
            <RedoButton
              size="large"
              variant="contained"
              width="100%"
              onClick={() => {
                downloadFile(videoBlobLink, file_name);
              }}
            >
              <DownloadIconLarge />
              <Typography variant="boldBody">Download video</Typography>
            </RedoButton>
          </ActionRecordButtonWrapper>
        </>
      )}
      {recordingFooter(linkDetail)}
    </>
  );
};
const Stopwatch = () => {
  const {
    seconds,
    minutes
  } = useStopwatch({ autoStart: true });

  return (
    <>
      <span> ({minutes}</span>:<span>{seconds})</span>
    </>
  );
};

const renderRecordingActive = (dispatch, linkDetail, audioRecordingState, microphoneBlocked) => {
  return (
    <>
      <Typography textAlign="center" variant="boldSubTitle">
        Your screen is now being recorded When you’re done, come back to this
        page to stop recording and submit your video
      </Typography>
      {isjiraLink(linkDetail) && linkDetail.external_issue_info.rec
        ? showUserCustomMessage(linkDetail.external_issue_info.rec)
        : linkDetail.user?.recording_note &&
        showUserCustomMessage(linkDetail.user.recording_note)}
      {microphoneBlocked && (
        <Typography variant="lightFootnote">
          <br />
          There was a problem getting permission to record your audio - you are recording your screen without audio
        </Typography>
      )}
      {audioRecordingState === RECORDING_AUDIO_STARTED && (
        <SwitchWrapper>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            height="20"
            width="20"
            viewBox="0 0 24 24"
          >
            <path
              fill="#666"
              d="M12 1a4 4 0 0 1 4 4v6a4 4 0 1 1-8 0V5a4 4 0 0 1 4-4z"
            />
            <path
              fill="#666"
              d="M13 18.94V21h3a1 1 0 0 1 0 2H8a1 1 0 0 1 0-2h3v-2.06A8 8 0 0 1 4 11a1 1 0 0 1 2 0 6 6 0 1 0 12 0 1 1 0 0 1 2 0 8 8 0 0 1-7 7.94z"
            />
          </svg>
          <canvas id="meter" width="150" height="20"></canvas>
        </SwitchWrapper>
      )}
      <ActionRecordButtonWrapper>
        {/* <Wrapper> */}
        <StopRecordButton
          size="large"
          variant="contained"
          width='100%'
          onClick={() => onStopRecordClick(dispatch, false)}
        >
          <Typography variant="boldBody">
            Stop recording
            <Stopwatch />
          </Typography>
          <RecordIcon className="record-icon" />
        </StopRecordButton>
        {tryAgainButton(dispatch, true)}
      </ActionRecordButtonWrapper>

      {recordingFooter(linkDetail)}
    </>
  );
};
export const isjiraLink = (linkDetail) =>
  Object.keys((linkDetail.source === JIRA_SOURCE && linkDetail.external_issue_info) ? linkDetail.external_issue_info : {})
    .length > 0;

export const isMondayLink = (linkDetail) =>
  Object.keys((linkDetail.source === MONDAYCOM_SOURCE && linkDetail.external_issue_info) ? linkDetail.external_issue_info : {})
    .length > 0;

const recordingFooter = (linkDetail) => {
  return (
    <RecordDescription>
      <LockIcon />
      <Typography variant="lightCallout">
        {!isjiraLink(linkDetail) &&
          !isMondayLink(linkDetail) &&
          !linkDetail.jsm_id &&
          linkDetail.source !== ZENDESK_SOURCE &&
          linkDetail.source !== INTERCOM_SOURCE && (
            <>
              Your screen recording will be privately sent to{" "}
              {linkDetail.user.name}{" "}
              {linkDetail.user.company.name
                ? `from ${linkDetail.user.company.name} `
                : ""}
              to review.{" "}
            </>
          )}
        {isjiraLink(linkDetail) && (
          <>
            Once completed, you will be able to review & securely upload the
            recording to Jira issue.
          </>
        )}

        {!isjiraLink(linkDetail) && linkDetail.jsm_id && (
          <>
            Once completed, your recording will be automatically uploaded to
            Jira issue.
          </>
        )}

        {(linkDetail.source === ZENDESK_SOURCE ||
          linkDetail.source === INTERCOM_SOURCE) && (
            <>
              Once completed you can review and send this recording
              {linkDetail.user.company.name
                ? ` to ${linkDetail.user.company.name}. `
                : "."}
            </>
          )}

        {isMondayLink(linkDetail) && (
          <>
            Once completed, you will be able to review & securely upload the
            recording to item files.
          </>
        )}
      </Typography>
    </RecordDescription>
  );
};
const renderRecordingStart = (
  dispatch,
  linkDetail,
  switchChecked,
  microphoneBlocked
) => {
  return (
    <>
      <TitleWrapper variant="lightSubTitle">
        {isjiraLink(linkDetail) && (
          <TitleWrapper variant="boldSubTitle">
            You are going to record your screen for Jira issue&nbsp;
            <IssueLink
              href={`${linkDetail.external_issue_info.jiraBaseUrl}/browse/${linkDetail.external_issue_info.issueKey}`}
              target="_blank"
            >
              {linkDetail.external_issue_info.issueKey}
            </IssueLink>
          </TitleWrapper>
        )}
        {!isjiraLink(linkDetail) && linkDetail.jsm_id && (
          <TitleWrapper variant="boldSubTitle">
            You are going to record your screen for Jira issue.
          </TitleWrapper>
        )}
        {!isjiraLink(linkDetail) &&
          linkDetail.jsm_id === null &&
          linkDetail.source !== ZENDESK_SOURCE &&
          linkDetail.source !== INTERCOM_SOURCE &&
          linkDetail.source !== MONDAYCOM_SOURCE && (
            <>
              <TitleWrapper variant="boldSubTitle">
                {linkDetail.user.name}{" "}
              </TitleWrapper>
              {linkDetail.user.company.name && (
                <>
                  from{" "}
                  <TitleWrapper variant="boldSubTitle">
                    {linkDetail.user.company.name}{" "}
                  </TitleWrapper>
                </>
              )}
              <br />
              is requesting a screen recording from you
            </>
          )}

        {(linkDetail.source === ZENDESK_SOURCE ||
          linkDetail.source === INTERCOM_SOURCE) && (
            <>
              {linkDetail.user.company.name && (
                <>
                  <TitleWrapper variant="boldSubTitle">
                    {linkDetail.user.company.name}{" "}
                  </TitleWrapper>
                  <br />
                  is requesting a screen recording from you
                </>
              )}
            </>
          )}
        {isMondayLink(linkDetail) && (
          <TitleWrapper variant="boldSubTitle">
            You are going to record your screen for monday.com&nbsp;
            <IssueLink
              href={`${linkDetail.external_issue_info.baseUrl}/boards/${linkDetail.external_issue_info.boardId}/pulses/${linkDetail.external_issue_info.itemId}`}
              target="_blank"
            >
              item
            </IssueLink>
          </TitleWrapper>
        )}
      </TitleWrapper>
      {isjiraLink(linkDetail) && linkDetail.external_issue_info.intro
        ? showUserCustomMessage(linkDetail.external_issue_info.intro)
        : linkDetail.user?.intro_note &&
        showUserCustomMessage(linkDetail.user.intro_note)}
      <SwitchWrapper>
        <BasicSwitch
          checked={switchChecked}
          onChange={() => {
            dispatch(setSwitchChecked(!switchChecked));
          }}
        />
        <Typography variant="lightBody">Microphone audio</Typography>
      </SwitchWrapper>
      {microphoneBlocked && (
        <Typography variant="lightFootnote">
          <br />
          There was a problem getting permission to record your audio - try
          recording your screen without audio
        </Typography>
      )}
      <RecordButton
        size="large"
        variant="contained"
        // component={RouterLink}
        // to="/app/record-ongoing"
        onClick={() => onRecordClick(dispatch, switchChecked)}
      >
        <Typography variant="boldBody">Start recording screen</Typography>
        <RecordIcon />
      </RecordButton>
      <TitleWrapper variant="lightSubTitle" sx={{ mt: 2 }}>
        You can also take a screenshot
      </TitleWrapper>
      <RecordButton
        size="large"
        variant="contained"
        style={{ marginTop: 2 }}
        onClick={() =>
          onScreenshotClick(dispatch, STATE_SCREENSHOT_INSTRUCTION_ACTIVE)
        }
      >
        <Typography variant="boldBody">Take a Screenshot</Typography>
      </RecordButton>
      {recordingFooter(linkDetail)}
    </>
  );
};
const renderRecordingNotSupported = (
  browserNotSupported,
  recordingNotSupported
) => {
  return (
    <>
      <RecordDescription>
        {browserNotSupported && (
          <Typography variant="lightCallout">
            Please open this on a laptop or desktop to record your screen
          </Typography>
        )}
        {recordingNotSupported && (
          <>
            <Typography variant="lightCallout">
              Screen recording isn't supported in this browser.
              <br />
              Please open this in a recent version of{" "}
              <a
                href="https://www.google.com/chrome/"
                target="_blank"
                rel="noreferrer"
              >
                Chrome
              </a>
              ,{" "}
              <a
                href="https://www.mozilla.org/"
                target="_blank"
                rel="noreferrer"
              >
                Firefox
              </a>
              , or{" "}
              <a
                href="https://www.microsoft.com/en-us/edge"
                target="_blank"
                rel="noreferrer"
              >
                Edge
              </a>
            </Typography>
          </>
        )}
      </RecordDescription>
    </>
  );
};

export default RecordScreen;
