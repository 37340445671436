import { Box, CircularProgress } from "@mui/material";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getMetaTag, META_TAG_BASE_URL } from "../../common/MetaTagReader";
import { downloadFile, getVideoType } from "../../shared_modules/common/Utils";
import {
  ShareStyledVideo,
  ShareVideoDownloadBtn,
  ShareVideoWrapper,
} from "./Style";

const ShareLibraryVideo = () => {
  const { slug } = useParams();
  const baseURL = `${getMetaTag(META_TAG_BASE_URL)}`;
  const [shareVideourl, setShareVideourl] = useState("");

  useEffect(() => {
    const fetchShareVideo = async () => {
      const url = `${baseURL}/api/library/share/${slug}/`;
      const options = {
        method: "GET",
        "Content-Type": "application/json",
      };
      const res = await fetch(url, options);
      const data = await res.json();
      if (data) {
        setShareVideourl(data);
      }
    };
    fetchShareVideo();
  }, [slug]);


  return (
    <ShareVideoWrapper>
      {shareVideourl?.video ? (
        <>
          <ShareStyledVideo controls>
            <source
              src={baseURL + shareVideourl?.video}
              type={getVideoType(shareVideourl?.video)}
            />
          </ShareStyledVideo>
          <ShareVideoDownloadBtn
            onClick={() =>
              downloadFile(
                baseURL + shareVideourl?.video,
                shareVideourl?.file_name
              )
            }
          >
            Download video
          </ShareVideoDownloadBtn>
        </>
      ) : (
        <Box textAlign="center">
          <CircularProgress
            sx={{
              maxHeight: "70px",
              maxWidth: "70px",
            }}
          />
        </Box>
      )}
    </ShareVideoWrapper>
  );
};

export default ShareLibraryVideo;
