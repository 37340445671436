// ----------------------------------------------------------------------

export default function FormControl(theme) {
  return {
    MuiInputBase: {
      styleOverrides: {
        root: {
          borderRadius: 8,
          minHeight: 56,
          padding: theme.spacing(0, 2),

          '&.Mui-disabled': {
            '& svg': { color: theme.palette.primary.main },
          },
        },
        input: {
          '&::placeholder': {
            opacity: 1,
            color: theme.palette.grey.light,
            ...theme.typography.lightSubTitle,
          },
        },
      },
    },
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          '&.MuiSelect-icon': {
            fontSize: 30,
            opacity: '0.4',
            fill: theme.palette.secondary.main,
          },
        },
      },
    },
    MuiInput: {
      styleOverrides: {
        underline: {
          '&:before': {
            borderBottomColor: 'red',
          },
        },
      },
    },

    MuiFilledInput: {
      styleOverrides: {
        root: {
          borderRadius: 8,
          minHeight: 56,
          ...theme.typography.boldH6,
          backgroundColor: theme.palette.grey.trans5,
          '&:hover': {
            backgroundColor: theme.palette.grey.lighter,
          },
          '&.Mui-focused': {
            backgroundColor: theme.palette.grey.lighter,
          },
          '&.Mui-disabled': {
            backgroundColor: theme.palette.action.disabledBackground,
          },
        },
        input: {
          '&:focus': {
            backgroundColor: 'transparent',
          },
        },
        underline: {
          '&:before': {
            borderBottomColor: 'transparent',
          },
          '&:after': {
            borderBottomColor: 'transparent',
          },
          '&:hover:not(.Mui-disabled):before': {
            borderBottomColor: 'transparent',
          },
        },
      },
    },

    MuiFormLabel: {
      styleOverrides: {
        root: {
          color: theme.palette.grey.main,
          fontSize: '18px',
          fontWeight: '500',
          lineHeight: '24px',
          letterSpacing: '-0.25px',
        },
      },
    },

    MuiFormControlLabel: {
      styleOverrides: {
        root: {},
        label: {
          fontSize: '17px',
          fontWeight: '500',
          lineHeight: '24px',
          letterSpacing: '-0.22px',
        },
      },
    },

    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: theme.palette.grey.lighter,
          },
          '&.Mui-disabled': {
            '& .MuiOutlinedInput-notchedOutline': {
              borderColor: theme.palette.grey.lighter,
            },
          },
        },
      },
    },
  };
}
