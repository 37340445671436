import { Box, Button, Card, Stack } from '@mui/material';
import { styled } from '@mui/material/styles';
import {
  StyledCardLg,
  StyledCardMd,
} from '../../../shared_modules/common/StyledCard';

import { ReactComponent as CopyIconSvg } from '../../../images/Icon/link-horizontal.svg';
import { ReactComponent as CopyKeyIconSvg } from '../../../images/Icon/clone.svg';
// Billing And Payments
export const StyledWrapper = styled(Box)(({ theme }) => ({
  ...theme.mixins.flexyCenterCol,
  marginTop: theme.spacing(9.5),
}));

export const CommonCard = styled(StyledCardMd)(({ theme }) => ({
  width: '100%',
  padding: theme.spacing(5, 3.25),
  ...theme.mixins.flexyColBetween,

  [theme.breakpoints.up('md')]: {
    width: '33.33%',
  },
}));

export const PricingCard = styled(CommonCard)(({ theme }) => ({
  color: theme.palette.dark.main,
}));

export const GrowthPricingCard = styled(CommonCard)(({ theme }) => ({
  background: theme.palette.primary.main,
  boxShadow: '0px 0px 43px 1px rgb(218,218,218)',
  color: theme.palette.common.white,
}));

export const PricingButton = styled(Button)(({ theme }) => ({
  width: '100%',
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.common.white,
  marginTop: theme.spacing(5),

  '&:hover': {
    backgroundColor: theme.palette.primary.main,
  },
}));

export const GrowthPricingButton = styled(Button)(({ theme }) => ({
  width: '100%',
  backgroundColor: theme.palette.common.white,
  color: theme.palette.primary.main,
  marginTop: theme.spacing(5),

  '&:hover': {
    backgroundColor: theme.palette.common.white,
  },
}));

export const SubTitleWrapper = styled(Box)(({ theme }) => ({
  margin: theme.spacing(1.25, 0, 5, 0),
}));

export const TitleWrapper = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(5),
}));

// Request Messaging
export const MessagingWrapper = styled(StyledCardLg)(({ theme }) => ({
  width: '100%',
  padding: theme.spacing(2.75),
  marginTop: theme.spacing(6.625),
}));

export const InputWrapper = styled(Stack)(({ theme }) => ({
  ...theme.mixins.flexyCol,
}));

export const MessagingTitleWrapper = styled(Box)(({ theme }) => ({
  margin: theme.spacing(0, 0, -1, 0),
}));

export const PreviewWrapper = styled(Card)(({ theme }) => ({
  position: 'relative',
  background: theme.palette.grey.lighter,
  borderRadius: theme.spacing(2),
  padding: theme.spacing(2),
  boxShadow: 'none',
  ...theme.mixins.flexyCol,
}));

export const SaveButton = styled(Button)({
  width: '100%',
});

// Integrations
export const IntegrationWrapper = styled(StyledCardLg)(({ theme }) => ({
  width: '100%',
  marginTop: theme.spacing(6.625),
  padding: theme.spacing(2),
}));

export const HelpSection = styled(Box)(({ theme }) => ({
  width: '100%',
  margin: theme.spacing(2, 0),
  paddingRight: theme.spacing(0),
  ...theme.mixins.flexyCol,

  [theme.breakpoints.up('md')]: {
    width: '100%',
    paddingLeft: theme.spacing(3.8),
    margin: theme.spacing(5, 0),
  },
}));

export const HelpFullSection = styled(Box)(({ theme }) => ({
  width: '100%',
  margin: theme.spacing(2, 0),
  // paddingRight: theme.spacing(0),
  padding: theme.spacing(3.8),
  ...theme.mixins.flexyCol,

}));

export const HelpDeskSection = styled(Box)(({ theme }) => ({
  width: '100%',
  paddingRight: theme.spacing(0),
  ...theme.mixins.flexyCenter,

  [theme.breakpoints.up('md')]: {
    margin: theme.spacing(6, 0),
    paddingRight: theme.spacing(5.8),
    width: '50%',
  },
}));

export const HelpScoutLogoImg = styled(Box)(({ theme }) => ({
  height: '50px',
  width: '60px',

  [theme.breakpoints.up('md')]: {
    height: 'auto',
    width: 'auto',
  },
}));
export const HelpScoutImg = styled(Box)(({ theme }) => ({
  height: '50px',
  width: '200px',

  [theme.breakpoints.up('md')]: {
    height: 'auto',
    width: 'auto',
  },
}));
export const JiraImg = styled(Box)(({ theme }) => ({
  height: '55px',
  width: '150px',

  // [theme.breakpoints.up('md')]: {
  //   height: 'auto',
  //   width: 'auto',
  // },
}));

export const HelpDeskImage = styled(Box)({
  height: '400px',
  width: '400px',
});

export const InstructionTitle = styled(Box)(({ theme }) => ({
  margin: theme.spacing(3, 0, 0.5, 0),
}));

// Chrome Extension
export const ExtensionWrapper = styled(StyledCardLg)(({ theme }) => ({
  width: '100%',
  padding: theme.spacing(4.3),
  marginTop: theme.spacing(6.625),
}));

export const InfoWrapper = styled(Box)(({ theme }) => ({
  width: '100%',
  ...theme.mixins.flexyCol,

  [theme.breakpoints.up('md')]: {
    width: '50%',
  },
}));

export const CopyIcon = styled(CopyKeyIconSvg)(({ theme }) => ({
  width: 22,
  height: 22,
  cursor: 'pointer',
  path: {
    fill: theme.palette.secondary.main,
  },
}));

export const ManageSubsButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.common.white,
  marginTop: theme.spacing(5),

  '&:hover': {
    backgroundColor: theme.palette.primary.main,
  },
}));

