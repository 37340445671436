import axios from 'axios';
import { META_TAG_BASE_URL, getMetaTag } from './MetaTagReader';
import { sendTokenToChromeExtension } from './chromeExtension';

const baseURL = `${getMetaTag(META_TAG_BASE_URL)}/`;

function getAxiosInstance() {
	return axios.create({
		baseURL: baseURL,
		headers: {
			Authorization: localStorage.getItem('access_token')
				? 'JWT ' + localStorage.getItem('access_token')
				: null,
			'Content-Type': 'application/json',
			accept: 'application/json',
		},
	});
}
function getAxiosNoAuthInstance() {
	return axios.create({
		baseURL: baseURL,
		headers: {
			'Content-Type': 'application/json',
			accept: 'application/json',
		},
	});
}
const MetaTag = window.location.pathname;
const axiosInstance = getAxiosInstance();
export const axiosPlainInstance = getAxiosInstance();
export const axiosNoAuthInstance = getAxiosNoAuthInstance();

axiosInstance.interceptors.response.use(
	(response) => {
		return response;
	},
	async function (error) {
		console.log(error.response);
		const originalRequest = error.config;

		if (typeof error.response === 'undefined') {
			alert(
				'A server/network error occurred. ' +
				'Looks like CORS might be the problem. ' +
				'Sorry about this - we will get it fixed shortly.'
			);
			return Promise.reject(error);
		}

		if (
			error.response.status === 401 &&
			originalRequest.url === 'api/token/refresh/'
		) {
			window.location.href = '/app/login/';
			return Promise.reject(error);
		}

		if (
			(error.response.data.code === 'token_not_valid' &&
				error.response.status === 401 &&
				error.response.statusText === 'Unauthorized') ||
			(error.response.data.code === 'token_not_valid' &&
				error.response.status === 403) ||
			(error.response.status === 401 &&
				originalRequest.url === 'api/session')
		) {

			const refreshToken = localStorage.getItem('refresh_token');
			if (refreshToken && refreshToken !== 'null' && refreshToken !== '' && refreshToken !== 'undefined') {
				const tokenParts = JSON.parse(atob(refreshToken.split('.')[1]));
				// exp date in token is expressed in seconds, while now() returns milliseconds:
				const now = Math.ceil(Date.now() / 1000);

				if (tokenParts.exp > now) {
					return axiosInstance
						.post('api/token/refresh/', { refresh: refreshToken })
						.then((response) => {
							localStorage.setItem('access_token', response.data.access);
							localStorage.setItem('refresh_token', response.data.refresh);

							axiosInstance.defaults.headers['Authorization'] =
								'JWT ' + response.data.access;
							originalRequest.headers['Authorization'] =
								'JWT ' + response.data.access;
							try {
								//send token to chrome extension
								sendTokenToChromeExtension({ jwt: response.data })
							} catch (err) {
								console.log(err);
							}

							return axiosInstance(originalRequest);
						})
						.catch((err) => {
							console.log(err);
						});
				} else {
					console.log('Refresh token is expired', tokenParts.exp, now);
					window.location.href = '/app/login/';
				}
			} else {
				// get  redirect link and pass to login
				let redirectLink = MetaTag;
				console.log('Refresh token not available.');
				window.location.href = `/app/login?redirect=${redirectLink}`;
			}
		} else {
			if(error.response.status === 401 ){
					window.location.href = '/app/login/';
			}
			// commenting this code as each bad request it's redirecting to login
			// window.location.href = '/app/login/';
		}

		// specific error handling done elsewhere
		return Promise.reject(error);
	}
);

export default axiosInstance;