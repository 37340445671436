import { createSlice } from '@reduxjs/toolkit';
import { fetchSessionApi } from './SessionAPI';

const initialState = {
  user: null,
  isGuest: false,
};


export const sessionSlice = createSlice({
  name: 'session',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    fetchSessionStart: (state) => {
      state.user = null;
      state.isGuest = false;
    },
    fetchSessionSuccess: (state, action) => {
      state.user = action.payload;
      state.isGuest = false;
    },
    fetchSessionFail: (state) => {
      state.isGuest = true;
      state.user = null;
    }
  },
});


export const fetchSession = (linkId) => {
  // the inside "thunk function"
  return async (dispatch, getState) => {
    try {
      dispatch(fetchSessionStart());
      const response = await fetchSessionApi(linkId)
      dispatch(fetchSessionSuccess(response.data));
    } catch (err) {
      dispatch(fetchSessionFail());
      // If something went wrong, handle it here
    }
  }
}

export const selectSessionState = state => state.session;
export const { fetchSessionStart, fetchSessionSuccess, fetchSessionFail } = sessionSlice.actions;

export default sessionSlice.reducer;
