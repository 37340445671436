import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { Box } from '@mui/material';
import MainLogo from '../../images/LogoMain.svg';
// ----------------------------------------------------------------------

Logo.propTypes = {
  sx: PropTypes.object,
};

export default function Logo({ sx }) {
  return (
    <RouterLink to="/app/">
      <Box component="img" src={MainLogo} alt="Screen" sx={{ ...sx }} />
    </RouterLink>
  );
}
