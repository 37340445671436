import styled from "@emotion/styled";
import { Box, Button, MenuItem } from "@mui/material";
import { StyledCardLg } from "../../../shared_modules/common/StyledCard";
import { EditIcon } from "../../profile/components/ProfileStyle";
// ----------------------------------------------------------------------

export const ImgWrapper = styled(Box)(({ theme }) => ({
  position: "relative",
  ...theme.mixins.flexyCenterCol,
  width: "fit-content",
  margin: "0 auto",
}));

export const EditWrapper = styled(Box)(({ theme }) => ({
  position: "absolute",
  right: 0,
  top: 0,
  padding: theme.spacing(2.5),
}));

export const BannerEditIcon = styled(EditIcon)(({ theme }) => ({
  cursor: "pointer",
  path: {
    fill: theme.palette.common.white,
  },
}));

export const BannerWrapper = styled(Box)(({ theme }) => ({
  width: "1056px",
  height: "250px",
  borderRadius: theme.spacing(3),
  backgroundColor: '#f5f5f5', //#ffffff

  [theme.breakpoints.up("md")]: {
    height: "400px",
  },
}));

export const CompanyForm = styled(Box)(({ theme }) => ({
  ...theme.mixins.flexyCenter,
  transform: "translateY(-10%)",

  [theme.breakpoints.up("md")]: {
    transform: "translateY(-30%)",
  },
}));

export const FormInnerWrapper = styled(StyledCardLg)(({ theme }) => ({
  width: "565px",
  boxShadow: "0px 10px 10px 1px rgb(240,243,246)",
  padding: theme.spacing(6, 3, 6, 3),

  [theme.breakpoints.up("md")]: {
    padding: theme.spacing(6, 6.25, 7, 5),
    width: "765px",
  },
}));

export const FormInfo = styled(Box)(({ theme }) => ({
  ...theme.mixins.flexyCenterCol,

  [theme.breakpoints.up("md")]: {
    flexDirection: "row",
    alignItems: "end",
  },
}));

export const LogoEditIcon = styled(EditIcon)(({ theme }) => ({
  cursor: "pointer",
  path: {
    fill: theme.palette.grey.main,
  },
}));

export const CompanyLogo = styled(Box)(({ theme }) => ({
  width: "140px",
  height: "140px",
  objectFit: "cover",

  [theme.breakpoints.up("md")]: {
    width: "180px",
    height: "180px",
  },
}));

export const InfoBox = styled(Box)(({ theme }) => ({
  marginLeft: theme.spacing(0),
  width: "100%",

  [theme.breakpoints.up("md")]: {
    marginLeft: theme.spacing(3),
    flex: 1,
  },
}));

export const FieldWrapper = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(2.25),
  marginTop: theme.spacing(2.25),

  [theme.breakpoints.up("md")]: {
    marginBottom: theme.spacing(2.25),
    marginTop: theme.spacing(0),
  },
}));

export const LabelWrapper = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(0.5),
}));

export const SaveButtonWrapper = styled(Box)(({ theme }) => ({
  margin: theme.spacing(4, 0, 0, 0),

  [theme.breakpoints.up("md")]: {
    margin: theme.spacing(5, 10, 0, 10),
  },
}));

export const SaveButton = styled(Button)({
  width: "100%",
});

export const BannerMenu = styled(MenuItem)({
  backgroundColor: "#ffffff",
  '&:hover': {
    backgroundColor: "#ffffff",
  },
})
