import { FormHelperText, Grid, TextField, Typography } from "@mui/material";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import {
  LabelTypography,
  PageTitle
} from "../../shared_modules/common/StyledClass";
import Textarea from "../../shared_modules/common/Textarea";
import { Wrapper } from "../../shared_modules/common/Wrapper";
import { showNotification } from "../../shared_modules/notification_bar/NotificationBarSlice";
import { SaveButton } from "../settings/components/SettingStyle";
import {
  FormWrapper,
  MessageWrapper
} from "../teammates/components/TeammatsStyle";
import { contactAPI } from "./ContactAPI";

const Contact = () => {
  const [formValues, setFormValues] = useState({
    name: "",
    email: "",
    message: "",
  });
  const [error, setErrors] = useState({
    name: "",
    email: "",
    message: "",
  });
  const [touched, setTouched] = useState(false);
  const dispatch = useDispatch();

  const onChangeHandler = (e) => {
    const target = e.target;
    setFormValues({
      ...formValues,
      [target.name]: target.value,
    });
  };

  const handleValidation = () => {
    let invalidate = false;
    const emailRegex =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (formValues.name === "") {
      setErrors((prev) => ({
        ...prev,
        name: "Name can't be empty",
      }));
      invalidate = true;
    } else {
      setErrors((prev) => ({
        ...prev,
        name: "",
      }));
    }
    if (formValues.message === "") {
      setErrors((prev) => ({
        ...prev,
        message: "Message can't be empty",
      }));
      invalidate = true;
    } else {
      setErrors((prev) => ({
        ...prev,
        message: "",
      }));
    }
    if (formValues.email === "") {
      setErrors((prev) => ({
        ...prev,
        email: "Email can't be empty",
      }));
      invalidate = true;
    }
    if (!emailRegex.test(formValues.email)) {
      setErrors((prev) => ({
        ...prev,
        email: "Email is not valid",
      }));
      invalidate = true;
    } else {
      setErrors((prev) => ({
        ...prev,
        email: "",
      }));
      invalidate = false;
    }
    if (invalidate) {
      return false;
    } else {
      return true;
    }
  };

  const onSubmitHandler = async (e) => {
    e.preventDefault();
    const isValid = handleValidation();
    if (isValid) {
      const res = await contactAPI(formValues);
      if (res.data) {
        setTouched(true);
        dispatch(
          showNotification({
            message: `Thanks for contacting us, we will get back to you shortly`,
          })
        );
        setFormValues({
          name: "",
          email: "",
          message: "",
        });
        setTouched(false);
      }
    }
  };
  return (
    <Wrapper>
      <PageTitle>Contact</PageTitle>
      <FormWrapper >
        <form onSubmit={onSubmitHandler} noValidate>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <LabelTypography variant="lightFootnote">Name</LabelTypography>
              <TextField
                type="name"
                inputProps={{ "aria-label": "Without label" }}
                hiddenLabel
                variant="filled"
                placeholder="Your name"
                name="name"
                value={formValues.name}
                onChange={onChangeHandler}
                error={error.name ? true : false}
                fullWidth
              />
              {error.name && (
                <FormHelperText className="Mui-error">
                  {error.name}
                </FormHelperText>
              )}
            </Grid>
            <Grid item xs={12}>
              <LabelTypography variant="lightFootnote">Email</LabelTypography>
              <TextField
                type="email"
                inputProps={{ "aria-label": "Without label" }}
                hiddenLabel
                variant="filled"
                placeholder="Your email"
                name="email"
                fullWidth
                value={formValues.email}
                onChange={onChangeHandler}
                error={error.email ? true : false}
              />
              {error.email && (
                <FormHelperText className="Mui-error">
                  {error.email}
                </FormHelperText>
              )}
            </Grid>
            <Grid item xs={12}>
              <MessageWrapper style={{ marginTop: 0 }}>
                <LabelTypography variant="lightFootnote">
                  Message
                </LabelTypography>

                <Textarea
                  minRows={3}
                  placeholder="Your suggestion or message"
                  name="message"
                  value={formValues.message}
                  onChange={onChangeHandler}
                />
                {error.message && (
                  <FormHelperText className="Mui-error">
                    {error.message}
                  </FormHelperText>
                )}
              </MessageWrapper>
            </Grid>
          </Grid>
          <SaveButton
            size="large"
            variant="contained"
            type="submit"
            disabled={touched}
            style={{ marginTop: 20 }}
          >
            <Typography variant="boldBody">Send message</Typography>
          </SaveButton>
        </form>
      </FormWrapper>
    </Wrapper>
  );
};

export default Contact;
