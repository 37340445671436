import { Navigate, useRoutes } from "react-router-dom";
import Company from "./pages/company";
import Dashboard from "./pages/dashboard";
import { Login } from "./pages/login/login";
import Payment from "./pages/payment";
import Profile from "./pages/profile";
import { PasswordReset } from "./pages/password_reset/components/PasswordReset";
// import ReadyVideo from "./pages/record/components/ReadyVideo";
// import RecordOnGoing from "./pages/record/components/RecordOnGoing";
import RecordScreen from "./pages/record/components/RecordScreen";
import Setting from "./pages/settings";
// import SaveVideo from "./pages/record/components/SaveVideo";
import SignUp from "./pages/sign-up";
import TeammatsInvite from "./pages/teammates";
import ExpandVideo from "./pages/video_details/components/ExpandVideo";
import { HomeLayout } from "./shared_modules/layout/HomeLayout";
import { SendResetEmail } from "./pages/password_reset/components/SendResetEmail";
// import { LoginLayout } from "./shared_modules/layout/LoginLayout";
import ShareVideo from "./pages/share-video";
import Contact from "./pages/contact";
import LinkHistory from "./pages/link-history";
import { ChromeLogin } from "./pages/extension/chrome_login_success";
import { ExtensionUninstalled } from "./pages/extension/extensionUninstalled";
import { PageNotFound } from "./pages/error/PageNotFound";
import { LinkExpired } from "./pages/error/LinkExpired";
import VideoLibrary from "./pages/video_library";
import ShareLibraryVideo from "./pages/share-video/ShareLibraryVideo";
// ----------------------------------------------------------------------

export default function Router() {
  return useRoutes([
    {
      path: "/app/",
      element: <HomeLayout />,
      children: [
        { path: "inbox", element: <Dashboard /> },
        { path: "", element: <Navigate to="/app/inbox" replace /> },
        { path: "details/:videoId/:uuid", element: <ExpandVideo /> },
        { path: "profile", element: <Profile /> },
        { path: "teammates", element: <TeammatsInvite /> },
        { path: "teammates/:tabName", element: <TeammatsInvite /> },
        { path: "company", element: <Company /> },
        { path: "payment/:planId", element: <Payment /> },
        { path: "s/:linkId", element: <RecordScreen /> },
        { path: "s/:linkId/:flag", element: <RecordScreen /> },
        { path: "s/:linkId/:flag/:audio", element: <RecordScreen /> },
        { path: "settings", element: <Setting /> },
        { path: "settings/:tabName", element: <Setting /> },
        { path: "settings/:tabName/:subTabName", element: <Setting /> },
        { path: "contact", element: <Contact /> },
        { path: "link-history", element: <LinkHistory /> },
        { path: "chrome_logged_in", element: <ChromeLogin /> },
        { path: "extension-uninstalled", element: <ExtensionUninstalled /> },
        { path: "video_library", element: <VideoLibrary /> },

        // { path: "record-screen", element: <RecordScreen /> },
        // { path: "record-ongoing", element: <RecordOnGoing /> },
        // { path: "save-video", element: <SaveVideo /> },
        // { path: "ready-video", element: <ReadyVideo /> },
      ],
    },
    {
      path: "/app/",
      children: [
        { path: "login", element: <Login /> },
        { path: "signup", element: <SignUp /> },
        { path: "signup/:uuid/:token", element: <SignUp /> },

        { path: "pass-reset/:uidb64/:token", element: <PasswordReset /> },
        { path: "send-pwd-link", element: <SendResetEmail /> },
        { path: "page-not-found", element: <PageNotFound /> },
        { path: "link-expired", element: <LinkExpired /> },
      ],
    },
    {
      path: "/app/",
      children: [
        { path: "share/:slug", element: <ShareVideo /> },
        { path: "library/share/:slug", element: <ShareLibraryVideo /> },
      ],
    },
    {
      path: "/app/",
      // element: <LoginLayout />,
      children: [{ path: "company-details/:onboarding", element: <Company /> }],
    },
    { path: "*", element: <Navigate to="/app/login" replace /> },
  ]);
}
