import {
  // Avatar,
  Box,
  // Divider,
  Drawer,
  // Stack,
  // Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Logo from "../common/Logo";
import NavSection from "./NavSection";
import Scrollbar from "../common/Scrollbar";
import useResponsive from "../../hooks/useResponsive";
// import { TEAMMATS } from '../../_mockData_/teammates';
import { SidebarConfig } from "./SidebarConfig";
// import { ReactComponent as SignOutSvg } from '../../images/Icon/power-off.svg';
import { useDispatch } from "react-redux";
// import { logout } from '../../pages/login/loginSlice';

// ----------------------------------------------------------------------

const DRAWER_WIDTH = 256;

const RootStyle = styled("div")(({ theme }) => ({
  [theme.breakpoints.up("lg")]: {
    flexShrink: 0,
    width: DRAWER_WIDTH,
  },
}));

// const SignOut = styled(SignOutSvg)(({ theme }) => ({
//   width: 24,
//   height: 24,
//   path: {
//     fill: theme.palette.grey.main,
//   },
// }));
// ----------------------------------------------------------------------

Sidebar.propTypes = {
  isOpenSidebar: PropTypes.bool,
  onCloseSidebar: PropTypes.func,
};

export default function Sidebar({ isOpenSidebar, onCloseSidebar }) {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const isDesktop = useResponsive("up", "lg");
  var sidebarCon = SidebarConfig();
  const dispatch = useDispatch();

  // const handleSignout = (e) => {
  //   dispatch(logout(navigate));
  // };

  useEffect(() => {
    if (isOpenSidebar) {
      onCloseSidebar();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const renderContent = (
    <>
      <Scrollbar
        sx={{
          height: 1,
          "& .simplebar-content": {
            height: 1,
            display: "flex",
            flexDirection: "column",
          },
        }}
      >
        <Box
          sx={{
            px: 2.5,
            py: 3,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Logo />
        </Box>

        <NavSection navConfig={sidebarCon} />

        {/* <Box sx={{ px: 5, my: 3 }}>
          <Divider sx={{ border: '1px solid #F0F3F6' }} />
        </Box> */}

        {/* <Box sx={{ px: 5 }}>
          <Box>
            <Typography
              variant="lightCaption"
              sx={{
                color: theme.palette.grey.main,
              }}
            >
              Teammates
            </Typography>

            <Scrollbar
              sx={{
                height: 230,
                marginTop: 2,

                '& .simplebar-content': {
                  height: 1,
                  display: 'flex',
                  flexDirection: 'column',
                },
              }}
            >
              <Stack>
                {TEAMMATS.map((e) => (
                  <>
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        marginBottom: 2,
                        cursor: 'pointer',
                      }}
                    >
                      <Avatar
                        sx={{ width: 24, height: 24, marginRight: 3 }}
                        src={e.image}
                        alt="image"
                      />
                      <Typography
                        variant="boldSmall"
                        sx={{
                          color: theme.palette.grey.main,
                        }}
                      >
                        {e.teammates}
                      </Typography>
                    </Box>
                  </>
                ))}
              </Stack>
            </Scrollbar>
          </Box>
        </Box> */}

        <Box sx={{ flexGrow: 1 }} />

        {/* <Box sx={{ px: 5, my: 3 }}>
          <Divider sx={{ border: '1px solid #F0F3F6' }} />
        </Box> */}

        {/* <Box
          sx={{
            display: 'flex',
            px: 5,
            py: 4,
            cursor: 'pointer',
          }}
          onClick={handleSignout}
        >
          <SignOut
            sx={{
              mr: 3,
            }}
          />
          <Typography
            variant="boldSubhead"
            sx={{ color: theme.palette.grey.main }}
          >
            Sign Out
          </Typography>
        </Box> */}
      </Scrollbar>
    </>
  );

  return (
    <RootStyle>
      {!isDesktop && (
        <Drawer
          open={isOpenSidebar}
          onClose={onCloseSidebar}
          PaperProps={{
            sx: { width: DRAWER_WIDTH },
          }}
        >
          {renderContent}
        </Drawer>
      )}

      {isDesktop && (
        <Drawer
          open
          variant='persistent'
          PaperProps={{
            sx: {
              top: "auto",
              // position: "fixed",
              width: DRAWER_WIDTH,
              bgcolor: "#fff",
              borderRightStyle: "none",
              borderRadius: 0,
              boxShadow: "inset -1px 0px 0px #E4E4E4",
            },
          }}
        >
          {renderContent}
        </Drawer>
      )}
    </RootStyle>
  );
}
