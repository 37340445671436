import React from "react";
import { Box, CircularProgress } from "@mui/material";
import { styled } from "@mui/material/styles";
//

const CircularProgressWrapper = styled(Box)(({ theme }) => ({
  position: "fixed",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
}));

//

const Loader = () => {
  return (
    <CircularProgressWrapper>
      <CircularProgress
        sx={{
          maxHeight: "70px",
          maxWidth: "70px",
        }}
      />
    </CircularProgressWrapper>
  );
};

export default Loader;
