import {
  Box,
  Pagination,
  Tooltip,
  Typography,
  Zoom,
} from "@mui/material";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ConfirmDialog from "../../shared_modules/common/ConfirmDialog";
import { StyledCardMd } from "../../shared_modules/common/StyledCard";
import { PageTitle } from "../../shared_modules/common/StyledClass";
import BasicSwitch from "../../shared_modules/common/Switch";
import { Wrapper } from "../../shared_modules/common/Wrapper";
import DeleteIcon from "../../shared_modules/icon_with_button/DeleteIcon";
import {
  CopyIcon,
  SharingWrapper,
} from "../video_details/components/ExpandVideoStyle";
import { deleteLinksApi, fetchLinksApi } from "./LinkHistoryAPI";
import {
  onHistoryLinks,
  onHistoryPageChange,
  unusedLinkHandle,
} from "./LinkHistorySlice";
import {
  LinkItem,
  RecordLink,
  UserHistoryText,
  UserName,
  UserHistoryWrapper,
  RecordLinkWrapper,
  ActionButtonWrapper,
  VideoCountWrapper,
  CounterStack,
  CountBox,
  VideoCountTypography,
  LinkItemWrapper,
  ActionLinkWrapperBox,
} from "./LinkHistoryStyle";
import { handleCopyLinkClick } from "../record/components/RecordingUtil";

const LinkHistory = () => {
  const { historyLinks, historyLinksPage, unusedLinkCheck } = useSelector(
    (state) => state.linkHistory
  );
  const [isDeleted, setIsDeleted] = useState(false);
  const dispatch = useDispatch();

  const deleteHistoryLink = async (uuid) => {
    const res = await deleteLinksApi(uuid);
    if (res.status === 200) {
      setIsDeleted(!isDeleted);
    }
  };

  useEffect(() => {
    const fetchAllLinks = async () => {
      const res = await fetchLinksApi(historyLinksPage, unusedLinkCheck);
      if (res.data) {
        dispatch(onHistoryLinks(res.data));
      }
    };
    fetchAllLinks();
  }, [dispatch, historyLinksPage, unusedLinkCheck, isDeleted]);

  return (
    <>
      <Wrapper>
        <PageTitle>Link history</PageTitle>
        <SharingWrapper>
          <BasicSwitch
            checked={unusedLinkCheck}
            onChange={(e) => {
              dispatch(unusedLinkHandle(e.target.checked));
            }}
          />
          <Typography variant="lightBody">
            {/* {unusedLinkCheck ? "Show all links" : "Show only unused links"} */}
            Show only unused links
          </Typography>
        </SharingWrapper>
        <Box sx={{ flexGrow: 1 }} pt={3}>
          {historyLinks.results.map((link) => {
            var videoDetailUrl = "";
            if (link.first_recording) {
              videoDetailUrl = `/app/details/${link.slug}/${link.first_recording.uuid}?on_root=true`;
            }
            return (
              <>
                <LinkItemWrapper>
                  <StyledCardMd>
                    <LinkItem key={link.uuid}>
                      {/*  */}
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "flex-start",
                        }}
                      >
                        <RecordLinkWrapper>
                          <ActionLinkWrapperBox>
                            <RecordLink
                              target="_blank"
                              href={link.record_link}
                            >
                              {link.record_link}
                            </RecordLink>

                            <ActionButtonWrapper>
                              <Box>
                                <Tooltip
                                  TransitionComponent={Zoom}
                                  title="Copy"
                                >
                                  <Box
                                    onClick={() =>
                                      handleCopyLinkClick(
                                        dispatch,
                                        link.record_link
                                      )
                                    }
                                  >
                                    <CopyIcon />
                                  </Box>
                                </Tooltip>
                              </Box>
                              {/* Delete */}
                              {link.is_request_user || link.is_superuser ? (
                                <ConfirmDialog
                                  description={
                                    link.video_count > 0
                                      ? "This action will delete all the videos associated with the video request link.. Do you want to proceed?"
                                      : "Are you sure you want to delete this link?"
                                  }
                                  onConfirm={() => {
                                    deleteHistoryLink(link.uuid);
                                  }}
                                >
                                  <Tooltip
                                    TransitionComponent={Zoom}
                                    title="Delete"
                                  >
                                    <Box>
                                      <DeleteIcon />
                                    </Box>
                                  </Tooltip>
                                </ConfirmDialog>
                              ) : (
                                ""
                              )}
                            </ActionButtonWrapper>
                          </ActionLinkWrapperBox>
                        </RecordLinkWrapper>
                        <UserHistoryWrapper>
                          <img
                            src={`https://ui-avatars.com/api/?size=24&color=fff&rounded=true&bold=true&background=random&name=${link.user.name}`}
                            alt=""
                            width="24"
                          />
                          <UserHistoryText>
                            <UserName>
                              {link.is_request_user ? "You" : link.user.name}{" "}
                            </UserName>
                            created this on{" "}
                            {moment(link.created).format("DD MMM, YYYY")}
                          </UserHistoryText>
                        </UserHistoryWrapper>
                      </Box>

                      {/*  */}
                      <VideoCountWrapper>
                        <CounterStack direction="column">
                          <CountBox
                            component={link.video_count > 0 ? "a" : "div"}
                            href={videoDetailUrl}
                            target="_blank"
                            color="inherit"
                          >
                            <VideoCountTypography
                              color={link.video_count > 0 ? "primary" : "black"}
                              mr={1}
                            >
                              {link.video_count}
                            </VideoCountTypography>
                            video / images
                          </CountBox>
                          {link.video_count > 0 && (
                            <Box fontSize={12} textAlign="center" color="dark">
                              Last video uploaded on{" "}
                              {moment(link.last_video_updated_on).format(
                                "DD MMM, YYYY"
                              )}
                            </Box>
                          )}
                        </CounterStack>
                      </VideoCountWrapper>
                    </LinkItem>
                  </StyledCardMd>
                </LinkItemWrapper>

                {/* <DividerWrapper>
                  <Divider />
                </DividerWrapper> */}
              </>
            );
          })}
        </Box>
        {historyLinks.results.length > 0 && (
          <Box
            mt={6}
            width="100%"
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Pagination
              color="secondary"
              size="large"
              count={historyLinks.pages}
              page={historyLinksPage}
              onChange={(e, val) => dispatch(onHistoryPageChange(val))}
            />
          </Box>
        )}
      </Wrapper>
    </>
  );
};

export default LinkHistory;
