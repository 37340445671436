// ----------------------------------------------------------------------

const GREY = {
  lighter: '#F4F4F6',
  lighter2: '#E3E3E8',
  light: '#CCCCD6',
  light2: '#B9B9C6',
  main: '#A5A6B6',
  main2: '#898A9F',
  dark: '#6E6F87',
  dark2: '#57586B',
  darker: '#40414F',
  trans5: 'rgba(165, 166, 182, 0.05)',
  trans20: 'rgba(165, 166, 182, 0.2)',
  trans40: 'rgba(165, 166, 182, 0.4)',
  trans60: 'rgba(165, 166, 182, 0.6)',
};

const DARK = {
  lighter: '#E2E4E7',
  lighter2: '#ADB2BB',
  light: '#767E8E',
  light2: '#494F5A',
  main: '#1B1D21',
  main2: '#1C1E22',
  dark: '#191B1F',
  dark2: '#191B1F',
  darker: '#17191C',
  trans5: 'rgba(27, 29, 33, 0.05)',
  trans20: 'rgba(27, 29, 33, 0.2)',
  trans40: 'rgba(27, 29, 33, 0.4)',
  trans60: 'rgba(27, 29, 33, 0.6)',
};

const PRIMARY = {
  lighter: '#CBDEFE',
  light: '#4D8DFE',
  main: '#004BCD',
  dark: '#002F80',
  darker: '#001333',
  trans5: 'rgba(0, 75, 205, 0.05)',
  trans20: 'rgba(0, 75, 205, 0.2)',
  trans40: 'rgba(0, 75, 205, 0.4)',
  trans60: 'rgba(0, 75, 205, 0.6)',
};
const SECONDARY = {
  lighter: '#DAD6F3',
  light: '#A39AE3',
  main: '#6C5DD2',
  dark: '#34268B',
  darker: '#0F0B28',
  trans5: 'rgba(108, 93, 210, 0.05)',
  trans20: 'rgba(108, 93, 210, 0.2)',
  trans40: 'rgba(108, 93, 210, 0.4)',
  trans60: 'rgba(108, 93, 210, 0.6)',
};

const SUCCESS = {
  lighter: '#DAF1E3',
  light: '#91D4AA',
  main: '#47B872',
  dark: '#2B6E44',
  darker: '#0F2517',
  trans5: 'rgba(71, 184, 114, 0.05)',
  trans20: 'rgba(71, 184, 114, 0.2)',
  trans40: 'rgba(71, 184, 114, 0.4)',
  trans60: 'rgba(71, 184, 114, 0.6)',
};

const DANGER = {
  lighter: '#F9CEC6',
  light: '#F28D7C',
  main: '#ED3E3E',
  dark: '#EB4B33',
  darker: '#CC2D14',
  trans5: 'rgba(237, 62, 62, 0.05)',
  trans20: 'rgba(237, 62, 62, 0.2)',
  trans40: 'rgba(237, 62, 62, 0.4)',
  trans60: 'rgba(237, 62, 62, 0.6)',
};

const palette = {
  common: { black: '#000', white: '#fff' },
  primary: { ...PRIMARY },
  secondary: { ...SECONDARY },
  danger: { ...DANGER },
  success: { ...SUCCESS },
  dark: { ...DARK },
  grey: GREY,
  text: { primary: '#000' },
    background: { paper: '#fff', default: GREY[100], neutral: GREY[200] },
};

export default palette;
