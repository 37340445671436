import { Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import BasicSwitch from "../../../shared_modules/common/Switch";
import { getSubscriptionAPI } from "../SettingsAPI";
import { changePlansInterval, companyDetails } from "../SettingsSlice";
import {
  GrowthPricingButton,
  GrowthPricingCard,
  PricingButton,
  PricingCard,
  StyledWrapper,
  SubTitleWrapper,
  TitleWrapper,
  ManageSubsButton
} from "./SettingStyle";
import moment from "moment"
import Loader from "../../../shared_modules/common/Loader";
import { selectSessionState } from "../../../shared_modules/layout/SessionSlice";

//
const BillingAndPayments = () => {
  const [plans, setPlans] = useState([]);
  const [stripe_portal_url, setStripeUrl] = useState([]);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { switchChecked, details, loader } = useSelector((state) => state.settings)
  const { user } = useSelector(selectSessionState);

  useEffect(() => {
    const getSubscriptions = async () => {
      const res = await getSubscriptionAPI(switchChecked ? "year" : "month");
      if (res.data) {
        setPlans(res.data?.plans);
        dispatch(companyDetails(res.data?.company));
        setStripeUrl(res.data?.stripe_portal_url);
      }
    };
    getSubscriptions();
  }, [switchChecked]);

  return (
    <>
      {!loader ?
        <StyledWrapper>

          {user.has_active_subscription ? (
            <>
              <Typography
                variant="lightSubtitle"
                textAlign="center"
                color="dark.main"
              >
                You are subscribed to the{" "}
                <b>{details?.active_subscription?.plan?.nickname} plan</b> which is set to
                renew on <b>{moment(details?.active_subscription?.current_period_end).format("DD MMM, YYYY, hh:mm")}</b>
              </Typography>

              <ManageSubsButton
                size="large"
                variant="contained"
                onClick={() => window.open(stripe_portal_url, '_blank')}
              >
                Manage Subscription
              </ManageSubsButton>
              <br />
              You will be redirected to Stripe portal where you can manage your account

            </>
          ) : (
            <>
              <Typography
                variant="lightSubtitle"
                textAlign="center"
                color="dark.main"
              >
                You currently have no active subscriptions. Select a plan below to
                subscribe
              </Typography>
              <Stack direction="row" alignItems="center" spacing={2} mt={3}>
                <Typography variant="lightBody" color="dark.main">
                  Monthly
                </Typography>
                <BasicSwitch
                  checked={switchChecked}
                  onChange={() => {
                    dispatch(changePlansInterval(!switchChecked));
                  }}
                />
                <Typography variant="lightBody">Yearly (2 months free)</Typography>
              </Stack>

              <Stack direction={{ xs: "column", md: "row" }} spacing={3.75} mt={4.5}>
                <PricingCard>
                  <TitleWrapper>
                    <Typography variant="boldH4">{plans[0]?.nickname}</Typography>
                  </TitleWrapper>

                  <Typography variant="boldH2">
                    ${plans[0]?.amount?.split(".")[0]}
                  </Typography>

                  <SubTitleWrapper>
                    <Typography variant="lightSubtitle" textTransform="capitalize">
                      {plans[0]?.interval}
                    </Typography>
                  </SubTitleWrapper>

                  <Typography variant="lightBody" textAlign="center">
                    Up to 3 teammates
                  </Typography>
                  <Typography variant="lightBody" textAlign="center">
                    Up to 50 videos per month
                  </Typography>
                  <Typography variant="lightBody" textAlign="center">
                    60 days video retention
                  </Typography>

                  <PricingButton size="large" variant="contained" onClick={() => navigate(`/app/payment/${plans[0].id}`)}>
                    Select plan
                  </PricingButton>
                </PricingCard>

                <GrowthPricingCard>
                  <TitleWrapper>
                    <Typography variant="boldH4">{plans[1]?.nickname}</Typography>
                  </TitleWrapper>

                  <Typography variant="boldH2">
                    ${plans[1]?.amount?.split(".")[0]}
                  </Typography>

                  <SubTitleWrapper>
                    <Typography variant="lightSubtitle" textTransform="capitalize">
                      {plans[1]?.interval}
                    </Typography>
                  </SubTitleWrapper>

                  <Typography variant="lightBody" textAlign="center">
                    Up to 10 teammates
                  </Typography>
                  <Typography variant="lightBody" textAlign="center">
                    Up to 150 videos per month
                  </Typography>
                  <Typography variant="lightBody" textAlign="center">
                    80 days video retention
                  </Typography>

                  <GrowthPricingButton size="large" variant="contained" onClick={() => navigate(`/app/payment/${plans[1].id}`)}>
                    Select plan
                  </GrowthPricingButton>
                </GrowthPricingCard>

                <PricingCard>
                  <TitleWrapper>
                    <Typography variant="boldH4">Enterprise</Typography>
                  </TitleWrapper>

                  <Typography variant="boldH3">Contact us</Typography>

                  <SubTitleWrapper>
                    <Typography variant="lightSubtitle">Enterprise</Typography>
                  </SubTitleWrapper>

                  <Typography variant="lightBody" textAlign="center">
                    Get in touch if you need more videos or enterprise features
                  </Typography>
                  <PricingButton
                    size="large"
                    variant="contained"
                    onClick={() => navigate("/app/contact")}
                  >
                    Contact
                  </PricingButton>
                </PricingCard>
              </Stack>
            </>
          )}

        </StyledWrapper> :

        <Loader />

      }
    </>
  );
};

export default BillingAndPayments;
