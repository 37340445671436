import { Popover, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import BannerImage from "../../../images/Banner.png";
import Logo from "../../../images/Logo.svg";
import { PageTitle } from "../../../shared_modules/common/StyledClass";
import { Wrapper } from "../../../shared_modules/common/Wrapper";
import {
  BannerEditIcon,
  BannerWrapper,
  CompanyForm,
  CompanyLogo,
  EditWrapper,
  FieldWrapper,
  FormInfo,
  FormInnerWrapper,
  ImgWrapper,
  InfoBox,
  LabelWrapper,
  LogoEditIcon,
  SaveButton,
  SaveButtonWrapper,
  BannerMenu,
} from "./CompanyStyle";
import { useDispatch, useSelector } from "react-redux";
import { getCompanyDetails, selectCompanyState } from "../CompanySlice";
import { UpdateCompanyDetailsAPI } from "../CompanyAPI";
import { showNotification } from "../../../shared_modules/notification_bar/NotificationBarSlice";
import { useNavigate, useParams } from "react-router-dom";
import { fetchSession } from "../../../shared_modules/layout/SessionSlice";
import {
  zendeskAuth,
  intercomAuth,
  freshchatAuth,
} from "../../login/LoginUtils";
// ----------------------------------------------------------------------

const Company = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const { company } = useSelector(selectCompanyState);
  const [formData, updateFormData] = useState("");
  const [logoImg, setLogo] = useState();
  const [isFilePicked, setFilePicked] = useState(false);
  const [websiteErr, setWebsiteErr] = useState("");
  const [nameErr, setNameErr] = useState("");
  const [errorMessage, setError] = useState("");
  const [onBoarding, setOnboarding] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [bannerData, setBannerData] = useState(null);

  var errBool = false;

  const search = window.location.search;
  const paramss = new URLSearchParams(search);
  const source_data = paramss.get("source_z");
  let source_z = "";
  let origin = "";
  if (source_data) {
    const source_arr = source_data.split(";");
    source_z = source_arr[0];
    origin = source_arr[1].split("=")[1];
  }
  // window.location.reload();

  useEffect(() => {
    dispatch(getCompanyDetails());
  }, []);

  useEffect(() => {
    updateFormData(company);
  }, [company]);


  useEffect(() => {
    if (
      Object.keys(params) === "onboarding" &&
      Object.values(params) === "on-boarding"
    ) {
      setOnboarding(true);
      dispatch(fetchSession());
    }
  }, []);



  useEffect(() => {
    if (!isFilePicked) {
      setLogo(formData.logo);
    }
  }, [formData.logo, isFilePicked]);

  const handleChange = (e) => {
    setNameErr("");
    setWebsiteErr("");
    updateFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
    if (formData.name && formData.name.length > 250) {
      setNameErr("Company name exceeds allowed max length");
      errBool = true;
    }
    if (formData.website && formData.website.length > 250) {
      setWebsiteErr("Website name exceeds allowed max length");
      errBool = true;
    }
  };

  const pictureChange = (e) => {
    // setLogo('');
    setFilePicked(true);
    if (e.target.files.length !== 0) {
      updateFormData({
        ...formData,
        [e.target.name]: e.target.files[0],
      });
      const file = e.target.files[0];
      setLogo(URL.createObjectURL(file));
    }
  };
  const bannerChange = (e) => {
    dispatch(
      showNotification({
        message: `Please save your changes`,
      })
    );
    if (!formData.banner) {
      updateFormData({
        ...formData,
        banner: true,
      });
    } else {
      updateFormData({
        ...formData,
        banner: false,
      });
    }
    handleClose(e);
  };

  const handleClick = (e) => {
    if (!formData.banner) {
      setBannerData("Set default background");
    } else {
      setBannerData("Remove background image");
    }
    setAnchorEl(e.currentTarget);
    // handleClose(e);
  };

  const handleClose = (e) => {
    setAnchorEl(null);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (formData.name === "" || formData.name === null) {
      setNameErr("Please enter company name");
      errBool = true;
    }

    if (!errBool) {
      const form_data = new FormData();
      form_data.append("name", formData.name);
      if (formData.website !== null) {
        form_data.append("website", formData.website);
      }
      if (isFilePicked) {
        form_data.append("logo", formData.logo);
      }
      form_data.append("banner", formData.banner);
      UpdateCompanyDetailsAPI(form_data)
        .then((res) => {
          dispatch(
            showNotification({
              message: `Company information is updated successfully`,
            })
          );

          if (Object.keys(params).length > 0) {
            if (source_data) {
              if (source_z === "freshchat") {
                freshchatAuth(origin);
              }
              if (source_z === "intercom") {
                intercomAuth(origin);
              }
              if (source_z === "zendesk") {
                zendeskAuth(origin);
              }
            } else {
              navigate("/app/inbox");
            }

          } else {
            dispatch(fetchSession());
          }
        })
        .catch((err) => {
          setError("Something went wrong. Please try again later.");
        });
    }

  };

  const open = Boolean(anchorEl);
  const id = open ? "banner-popover" : undefined;

  return (
    <>
      <Wrapper>
        {onBoarding ? (
          <PageTitle textAlign={"center"}>Company info </PageTitle>
        ) : (
          <PageTitle>Company info </PageTitle>
        )}
        <ImgWrapper>

          <EditWrapper>
            {!formData.banner ? (
              <LogoEditIcon
                aria-describedby={id}
                variant="contained"
                onClick={handleClick}
              />
            ) : (
              <BannerEditIcon
                aria-describedby={id}
                variant="contained"
                onClick={handleClick}
              />
            )}
            <Popover
              id={id}
              open={open}
              onClose={handleClose}
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
            >
              <BannerMenu onClick={bannerChange}>{bannerData}</BannerMenu>
            </Popover>
          </EditWrapper>
          {!formData.banner ? (
            <BannerWrapper style={{ width: 900, height: 200 }} />
          ) : (
            <BannerWrapper component="img" src={BannerImage} />
          )}
        </ImgWrapper>

        <CompanyForm component="form"
          autoComplete="off"
          onSubmit={e => handleSubmit(e)}>

          <FormInnerWrapper>
            <FormInfo>
              <ImgWrapper>
                <EditWrapper>
                  <input
                    accept="image/*"
                    style={{ display: "none" }}
                    id="raised-button-file-logo"
                    type="file"
                    name="logo"
                    onChange={pictureChange}
                  />
                  <label htmlFor="raised-button-file-logo">
                    <LogoEditIcon />
                  </label>
                </EditWrapper>
                {formData.logo ? (
                  <CompanyLogo
                    component="img"
                    src={logoImg}
                    alt={formData.logo.name}
                  />
                ) : (
                  <CompanyLogo component="img" src={Logo} alt="" />
                )}
              </ImgWrapper>

              <InfoBox>
                <FieldWrapper>
                  <LabelWrapper>
                    <Typography variant="lightFootnote">
                      Company name
                    </Typography>
                  </LabelWrapper>
                  <TextField
                    inputProps={{ "aria-label": "Without label" }}
                    hiddenLabel
                    fullWidth
                    id="filled-hidden-label-normal"
                    variant="filled"
                    placeholder="Enter company name"
                    name="name"
                    onChange={handleChange}
                    value={formData.name}
                    error={nameErr}
                    helperText={nameErr ? nameErr : ""}
                  />
                </FieldWrapper>

                <LabelWrapper>
                  <Typography variant="lightFootnote">
                    Company website
                  </Typography>
                </LabelWrapper>

                <TextField
                  inputProps={{ "aria-label": "Without label" }}
                  fullWidth
                  hiddenLabel
                  id="filled-hidden-label-normal"
                  variant="filled"
                  placeholder="Enter company website"
                  name="website"
                  onChange={handleChange}
                  value={formData.website}
                  error={websiteErr}
                  helperText={websiteErr ? websiteErr : ""}
                />
              </InfoBox>
            </FormInfo>
            <Typography variant="lightCallout" style={{ color: "red" }}>
              {errorMessage && <p className="error"> {errorMessage} </p>}
            </Typography>

            <SaveButtonWrapper>
              <SaveButton
                size="large"
                variant="contained"
                fullWidth
                type="submit"
              >
                <Typography variant="boldBody">Save</Typography>
              </SaveButton>
            </SaveButtonWrapper>
          </FormInnerWrapper>
        </CompanyForm>
      </Wrapper>
    </>
  );
};

export default Company;
