import { styled } from "@mui/material/styles";
import { FormControl, MenuItem, Select, Typography } from "@mui/material";
import PropTypes from "prop-types";
// ----------------------------------------------------------------------

InboxSort.propTypes = {
  options: PropTypes.array,
};

const StyledFormControl = styled(FormControl)({
  minWidth: "216px",
});

export default function InboxSort(props) {
  const { options, handleSort } = props;

  return (
    <>
      <StyledFormControl>
        <Select
          variant="filled"
          defaultValue={"recent"}
          // value={items}
          onChange={handleSort}
          inputProps={{ "aria-label": "Without label" }}
          hiddenLabel
          id="filled-hidden-label-normal"
        >
          {options.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              <Typography variant="boldSubhead">{option.label}</Typography>
            </MenuItem>
          ))}
        </Select>
      </StyledFormControl>
    </>
  );
}
