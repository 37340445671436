import { connect } from "react-redux";
import { VideoLibrary } from "../components/VideoLibrary";
import { deleteVideo, fetchNewVideos } from "../VideoLibrarySlice";

const mapStateToProps = (state) => ({
  videoListPage: state.videoLibrary.videoListPage,
  user: state.session.user,
});

const mapDispatchToProps = (dispatch) => ({
  fetchVideos: (pageNumber, sorttype) => {
    dispatch(fetchNewVideos(pageNumber, sorttype));
  },
  deleteVideo: (uuid, pageNumber) => {
    dispatch(deleteVideo(uuid, pageNumber));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(VideoLibrary);
