import { Button, Stack, Typography } from '@mui/material';
import React from 'react';
import {
  SpaceBottom2,
  SpaceBottom3,
  SpaceTop3,
} from '../../../shared_modules/common/StyledClass';
import { ExtensionWrapper, InfoWrapper } from './SettingStyle';


const ChromeExtension = () => {
  const openInNewTab = () => {
    window.open('https://chrome.google.com/webstore/detail/screenjar-for-chrome/eannaomdbmcakllbmhhaiaekahjdhffa/related?hl=en-US', '_blank', 'noopener,noreferrer');
  };
  return (
    <ExtensionWrapper>
      <Stack
        direction={{ xs: 'column', md: 'row' }}
        spacing={3.75}
        justifyContent="space-between"
        alignItems="center"
      >
        <InfoWrapper>
          <SpaceBottom2>
            <Typography variant="boldH3">
              Save time with Chrome Extension
            </Typography>
          </SpaceBottom2>

          <SpaceBottom3>
            <Typography variant="lightCallout">
              Install Chrome Extension to generate recording links
              <br />

            </Typography>
          </SpaceBottom3>

          <Button size="large" variant="contained" onClick={() => openInNewTab()}>
            <Typography variant="boldBody">Download chrome extension</Typography>
          </Button>

          <SpaceTop3>
            <Typography variant="lightCallout">
              Request your first video in 30 seconds or less
            </Typography>
          </SpaceTop3>
        </InfoWrapper>

      </Stack>
    </ExtensionWrapper>
  );
};

export default ChromeExtension;
