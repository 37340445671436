import { Box, Stack, TextField, Typography } from "@mui/material";
import { Link as RouterLink, useParams } from "react-router-dom";
import Logo from "../../../images/LogoWhite.svg";
import {
  BtnSignIn,
  ContentStyle,
  FormInfoBox,
  FormStyle,
  FromWrapper,
  LeftSectionStyle,
  LogoBox,
  MainTitle,
  RootStyle,
  SignUpWrapper,
  StyledLink,
  StyledTypography,
  SubTitle,
} from "./SignUpStyle";
import { useNavigate } from "react-router-dom";
import React, { useState, useEffect } from "react";
import {
  SignUpAPI,
  SignUpInviteAPI,
  GetInvitationDetails,
  LoginAPI,
} from "../SignUpAPI";
import { useDispatch } from "react-redux";
import { showNotification } from "../../../shared_modules/notification_bar/NotificationBarSlice";
import axiosInstance from "../../../common/axios";

// ----------------------------------------------------------------------

export default function SignUp() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const initialFormData = {
    name: "",
    email: "",
    password: "",
    name_err: "",
    email_err: "",
    pwd_err: "",
    general_err: "",
  };

  const initialInviteeData = {
    checkedToken: "",
    inviteeName: "",
    message: "",
    recipient: "",
  };

  const [inviteeData, setInviteeData] = useState(initialInviteeData);
  const [formData, updateFormData] = useState(initialFormData);
  const [isInvite, setInvite] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const params = useParams();
  var nav = "";
  const search = window.location.search;
  const paramss = new URLSearchParams(search);
  const source_data = paramss.get("source_z");
  let LOGIN_USER_REDIRECT = "/app/login";
  let intercom_email = "";

  if (source_data) {
    const source_arr = source_data.split(";");
    let source_z = source_arr[0];

    if (source_z === "zendesk" || source_z === "intercom" || source_z === "freshchat") {
      LOGIN_USER_REDIRECT = LOGIN_USER_REDIRECT + `?source_z=` + source_data;
    }

    if (source_z === "intercom") {
      intercom_email = source_arr[2].split("=")[1];
      formData.email = intercom_email;
    }
  }
  useEffect(() => {
    if (Object.keys(params).length > 0) {
      setInvite(true);
      GetInvitationDetails(params.uuid, params.token)
        .then((res) => {
          setInviteeData({
            checkedToken: res.data.context.checked_token,
            inviteeName: res.data.context.name,
            message: res.data.context.msg,
            recipient: res.data.context.recipient,
          });
        })
        .catch((err) => {
          setErrorMessage(
            "Your invitation link has expired. Please ask your Screenjar administrator for a new invitation."
          );
        });
    }
  }, []);
  useEffect(() => {
    updateFormData({
      ...formData,
      email: inviteeData.recipient,
    });
  }, [inviteeData]);

  const handleChange = (e) => {
    let n_err,
      e_err = "";
    if (formData.name.length > 255) {
      n_err = "Name exceeds allowed max length";
    }
    if (formData.email.length > 255) {
      e_err = "Email exceeds allowed max length";
    }
    updateFormData({
      ...formData,
      // email: inviteeData.recipient,
      [e.target.name]: e.target.value,
      name_err: n_err,
      email_err: e_err,
    });
  };

  const onError = (error) => {
    var n_err,
      e_err,
      p_err = "";
    const errorObj = error.response.data;
    if ("name" in errorObj) {
      n_err = errorObj.name;
    }
    if ("email" in errorObj) {
      if (errorObj.email[0] === "This field must be unique.") {
        e_err = "This email is already registered with us.";
      } else {
        e_err = errorObj.email;
      }
    }
    if ("password" in errorObj) {
      p_err = errorObj.password;
    }

    updateFormData({
      ...formData,
      name_err: n_err,
      pwd_err: p_err,
      email_err: e_err,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const form_data = new FormData();
    form_data.append("name", formData.name);
    form_data.append("email", formData.email);
    form_data.append("password", formData.password);

    if (Object.keys(params).length > 0) {
      SignUpInviteAPI(form_data, params.uuid, params.token)
        .then((res) => {
          nav = "/app/inbox";
          getLoginDetails(nav);
        })
        .catch((error) => {
          onError(error);
        });
    } else {
      SignUpAPI(form_data)
        .then((res) => {
          // let flag = 'on-boarding';
          nav = `/app/company-details/on-boarding`;
          getLoginDetails(nav);
        })
        .catch((error) => {
          onError(error);
        });
    }
  };

  const getLoginDetails = (nav) => {
    LoginAPI(formData.email, formData.password)
      .then((res) => {
        localStorage.setItem("access_token", res.data.access);
        localStorage.setItem("refresh_token", res.data.refresh);
        axiosInstance.defaults.headers["Authorization"] =
          "JWT " + localStorage.getItem("access_token");

        dispatch(
          showNotification({
            message: `Sign-up was successful`,
          })
        );
        if (source_data) {
          const source_arr = source_data.split(";");
          let source_z = source_arr[0];

          if (source_z === "zendesk" || source_z === "intercom" || source_z === "freshchat") {
            nav = nav + `?source_z=` + source_data;
            navigate(nav);
          }
        }
        navigate(nav);
      })
      .catch((error) => {
        setErrorMessage("Cannot sign-in with given credentials");
      });
  };

  return (
    <RootStyle>
      <FromWrapper>
        <Box>
          <LogoBox component="img" alt="register" src={Logo} />
          <LeftSectionStyle>
            <MainTitle variant="boldH3">
              Skip the customer support back-and-forth
            </MainTitle>
            <SubTitle variant="lightSubtitle">
              Make it ridiculously easy for customers to send you video
              recordings of their screen. No download/install required.
            </SubTitle>
          </LeftSectionStyle>
        </Box>

        <ContentStyle>
          <FormStyle
            component="form"
            autoComplete="off"
            onSubmit={handleSubmit}
          >
            <Stack spacing={3}>
              <FormInfoBox>
                <SignUpWrapper>
                  <StyledTypography variant="boldH2">Sign up</StyledTypography>
                </SignUpWrapper>

                <StyledTypography variant="lightCallout">
                  Already have an account? &nbsp;
                  <StyledLink
                    underline="none"
                    variant="boldCallout"
                    component={RouterLink}
                    to={LOGIN_USER_REDIRECT}
                  >
                    Sign In
                  </StyledLink>
                </StyledTypography>
              </FormInfoBox>
              {isInvite ? (
                <div>
                  <b>{inviteeData.inviteeName}</b> has invited you to join
                  Screenjar. <br />
                  <b>{inviteeData.message}</b>
                </div>
              ) : (
                <></>
              )}

              {/* <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}> */}
              <TextField
                variant="filled"
                label="Name"
                name="name"
                value={formData.name}
                onChange={handleChange}
                error={formData.name_err}
                helperText={formData.name_err ? formData.name_err : ""}
              // inputProps={{ maxLength: 20 }}
              />

              {/* 
                <TextField variant="filled" label="Last Name" /> */}
              {/* </Stack> */}

              <TextField
                variant="filled"
                autoComplete="username"
                type="email"
                name="email"
                value={formData.email}
                label="Email"
                onChange={handleChange}
                error={formData.email_err}
                helperText={formData.email_err ? formData.email_err : ""}
              />

              <TextField
                variant="filled"
                autoComplete="current-password"
                type="password"
                label="Password"
                name="password"
                value={formData.password}
                onChange={handleChange}
                error={formData.pwd_err}
                helperText={formData.pwd_err ? formData.pwd_err : ""}
              />

              <StyledTypography variant="lightCallout" style={{ color: "red" }}>
                {errorMessage && <p className="error"> {errorMessage} </p>}
              </StyledTypography>

              <BtnSignIn size="large" type="submit" variant="contained">
                <Typography variant="boldBody">Continue</Typography>
              </BtnSignIn>
            </Stack>
          </FormStyle>
        </ContentStyle>
      </FromWrapper>
    </RootStyle>
  );
}
