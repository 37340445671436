import {
  Dialog,
  DialogContentText,
  FormHelperText,
  Grid,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  LabelTypography,
  PageTitle,
} from "../../../shared_modules/common/StyledClass";
import Textarea from "../../../shared_modules/common/Textarea";
import { Wrapper } from "../../../shared_modules/common/Wrapper";
import { showNotification } from "../../../shared_modules/notification_bar/NotificationBarSlice";
import Tabs from "../../../shared_modules/tabs/Tabs";
import {
  ButtonWrapper,
  CancelButton,
  CustomDialogContent,
  CustomDialogTitle,
  RequestButton,
} from "../../record/components/RecordStyle";
import { pendingInvitesApi, sendInviteApi } from "../TeamAPI";
import { pendingInvites } from "../TeamSlice";
import { Pending, Teammates } from "./Teammates";
import {
  EmailWrapper,
  InviteButtonWrapper,
  MessageWrapper,
  TeamButton,
  TeammatesSection,
  TitleWrapper,
  TeammateAdd,
} from "./TeammatsStyle";

const TAB_ID_TEAM_MATES = 0;
const TAB_ID_PENDING_INVITES = 1;
const TAB_PARAM_TEAM_MATES = "teammates";
const TAB_PARAM_PENDING_INVITES = "invites";

const tabs = [
  {
    label: "Teammates",
    Component: (
      <Grid container spacing={3.75}>
        <Teammates />
      </Grid>
    ),
    index: TAB_ID_TEAM_MATES,
    href: "/app/teammates/" + TAB_PARAM_TEAM_MATES,
  },
  {
    label: "Pending invites",
    Component: (
      <Grid container spacing={3.75}>
        <Pending />
      </Grid>
    ),
    index: TAB_ID_PENDING_INVITES,
    href: "/app/teammates/" + TAB_PARAM_PENDING_INVITES,
  },
];
const getTabId = (tabName) => {
  let tabIdInUrl = TAB_ID_TEAM_MATES;
  if (tabName === TAB_PARAM_PENDING_INVITES)
    tabIdInUrl = TAB_ID_PENDING_INVITES;
  return tabIdInUrl;
};
const TeammatsInvite = () => {
  const dispatch = useDispatch();
  const { tabName } = useParams();
  const tabIdInUrl = getTabId(tabName);
  const { pendingInvitePage } = useSelector((state) => state.invites);
  const navigate = useNavigate();
  const [formValues, setFormValues] = useState({
    recipient: "",
    message: "",
  });
  const [error, setErrors] = useState("");
  const [touched, setTouched] = useState(false);
  const [open, setOpen] = React.useState(false);
  const theme = useTheme();

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setFormValues({
      recipient: "",
      message: "",
    });
  };

  const onChangeHandler = (e) => {
    const target = e.target;
    setFormValues({
      ...formValues,
      [target.name]: target.value,
    });
    if (target.type === "email" && touched) {
      handleValidation();
    }
  };

  const handleValidation = () => {
    const emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (formValues.recipient === "") {
      setErrors("Email can't be empty");
      return false;
    } else if (!emailRegex.test(formValues.recipient)) {
      setErrors("Email is not valid");
      return false;
    } else {
      setErrors("");
      return true;
    }
  };

  const onSendInvite = async (e) => {
    e.preventDefault();
    setTouched(true);
    const isValid = handleValidation();
    if (isValid) {
      const res = await sendInviteApi(formValues);
      if (res.data) {
        dispatch(
          showNotification({
            message: `Invitation sent successfully to ${formValues.recipient}`,
          })
        );
        handleClose();
        setFormValues({
          recipient: "",
          message: "",
        });
        setTouched(false);
        navigate("/app/teammates/invites");
        getInvites();
      }
    }
  };

  const getInvites = async () => {
    const res = await pendingInvitesApi(pendingInvitePage);
    dispatch(pendingInvites(res.data));
  };

  React.useEffect(() => {
    getInvites();
  }, [pendingInvitePage]);

  return (
    <>
      <Wrapper>
        <TitleWrapper>
          <PageTitle>Teammates</PageTitle>
          <TeamButton
            size='large'
            variant='contained'
            startIcon={<TeammateAdd />}
            onClick={handleOpen}
          >
            <Typography ml={1} variant='boldBody'>
              Invite teammate
            </Typography>
          </TeamButton>
        </TitleWrapper>
        <TeammatesSection>
          <Tabs tabs={tabs} selectedTab={tabIdInUrl} />
          <InviteButtonWrapper>
            <TeamButton
              size='large'
              variant='contained'
              startIcon={<TeammateAdd />}
              onClick={handleOpen}
            >
              <Typography ml={1} variant='boldBody'>
                Invite teammate
              </Typography>
            </TeamButton>
          </InviteButtonWrapper>
        </TeammatesSection>
      </Wrapper>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          sx: {
            bgcolor: theme.palette.common.white,
            borderRadius: theme.spacing(5),
          },
        }}
      >
        <CustomDialogTitle sx={{ paddingBottom: "5px" }}>
          <Typography variant='boldH4'>Invite your teammates</Typography>
        </CustomDialogTitle>
        <CustomDialogContent>
          <DialogContentText>
            If you have any questions or want to make any changes to your
            account/invites, please get in touch at screenjar@amoeboids.com
          </DialogContentText>
          <form onSubmit={onSendInvite} noValidate>
            <EmailWrapper>
              <LabelTypography variant='lightFootnote'>
                Email address
              </LabelTypography>
              <TextField
                type='email'
                inputProps={{ "aria-label": "Without label" }}
                hiddenLabel
                id='filled-hidden-label-normal'
                variant='filled'
                placeholder='Enter Email address'
                name='recipient'
                value={formValues.recipient}
                onChange={onChangeHandler}
                error={error ? true : false}
              />
              {error && (
                <FormHelperText className='Mui-error'>{error}</FormHelperText>
              )}
            </EmailWrapper>

            <MessageWrapper>
              <LabelTypography variant='lightFootnote'>
                Message (optional)
              </LabelTypography>

              <Textarea
                minRows={3}
                placeholder='Add an optional message to your invite'
                name='message'
                value={formValues.message}
                onChange={onChangeHandler}
              />
            </MessageWrapper>
            <ButtonWrapper direction={{ xs: "column", sm: "row" }} spacing={3}>
              <RequestButton size='large' variant='contained' type='submit'>
                <Typography variant='boldBody'>Send invite</Typography>
              </RequestButton>

              <CancelButton
                size='large'
                variant='contained'
                onClick={handleClose}
              >
                <Typography variant='boldBody'>Cancel</Typography>
              </CancelButton>
            </ButtonWrapper>
          </form>
        </CustomDialogContent>
      </Dialog>
    </>
  );
};

export default TeammatsInvite;
