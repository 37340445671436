import { createSlice } from '@reduxjs/toolkit';
import { ProfileAPI } from './ProfileAPI';

const initialState = {
    profile: {
        name: '',
        subscribed: false,
        is_profile_link: true,
    },
};


export const profileSlice = createSlice({
    name: 'profile',
    initialState,
    // The `reducers` field lets us define reducers and generate associated actions
    reducers: {
        getDetailsSuccess: (state, { payload }) => {
            state.profile = payload;
        },
    },
});


export const getUserDetails = () => {
    // the inside "thunk function"
    return async (dispatch) => {
        try {
            const response = await ProfileAPI();
            if (dispatch(getDetailsSuccess(response.data.user))) {

            };
        } catch (err) {
            // If something went wrong, handle it here
        }
    }
}


export const selectProfileState = state => state.profile;
export const { getDetailsSuccess } = profileSlice.actions;
export default profileSlice.reducer;
