import React, { useState } from "react";
import { Typography } from "@mui/material";
import { ContentSection } from "../RecordStyle";
import {
  RecordDescription,
  TitleWrapper,
  LinkButton,
  DownloadIconLarge,
  DownloadLargeButton,
  ActionRecordButtonWrapper,
} from "../RecordScreenStyle";
import {
  updateRecordingStateWithData,
  updateZendeskUploadStatus,
  updateFreshchatUploadStatus,
  updateIntercomUploadStatus,
  updateMondayUploadStatus,
} from "../../RecordSlice";
import { PrimaryBadge1 } from "../../../../shared_modules/common/RoundIconBadge";
import Logo from "../../../../images/Logo.svg";
import { downloadFile } from "../../../../shared_modules/common/Utils";
import { axiosPlainInstance } from "../../../../common/axios";
import {
  handleCopyLinkClick,
  onScreenshotClick,
  uploadToVideo,
  STATE_SCREENSHOT_CAPUTURED,
  ZENDESK_SOURCE,
  INTERCOM_SOURCE,
  FRESHCHAT_SOURCE,
  MONDAYCOM_SOURCE,
  uploadToJiraHanlder,
  UPLOAD_STATUS_SUCCESS,
  UPLOAD_STATUS_STARTED,
  UPLOAD_STATUS_ERROR,
} from "../RecordingUtil";
import EditVideoName from "../EditVideoName";
import "./ScreenShot.css";
import { isjiraLink } from "../RecordScreen";

function ScreenshotUploadSuccess({
  dispatch,
  imageName,
  editedImgLink,
  linkDetail,
  uuid,
  note,
  zendeskUploadStatus,
  intercomUploadStatus,
  freshchatUploadStatus,
  jiraUploadStatus,
  mondayUploadStatus,
}) {
  const [disabled, setDisabled] = useState(false);
  let file_name = "";
  const setFileName = (name) => {
    file_name = name;
  };
  if (file_name === "") {
    setFileName(imageName);
  }

  const getShareableLink = () => {
    setDisabled(true);
    axiosPlainInstance
      .get(`/api/sharelink/${linkDetail.slug}/`)
      .then((res) => {
        handleCopyLinkClick(dispatch, res.data.sharing_url);
        setDisabled(false);
      })
      .catch((err) => console.log(err));
  };

  var logoImg = "";
  if (
    linkDetail &&
    isjiraLink(linkDetail) &&
    linkDetail?.external_issue_info?.logo
  ) {
    logoImg = linkDetail.external_issue_info.logo;
  } else if (linkDetail.user.company.logo) {
    logoImg = linkDetail.user.company.logo;
  } else {
    logoImg = Logo;
  }
  return (
    <ContentSection className="imageSuccessPageMainContainer">
      <ContentSection className="successImageContainer ">
        {!(
          isjiraLink(linkDetail) &&
          jiraUploadStatus.status === UPLOAD_STATUS_SUCCESS
        ) && !(
          linkDetail.source === MONDAYCOM_SOURCE &&
          mondayUploadStatus.status === UPLOAD_STATUS_SUCCESS
        ) && (
            <DownloadLargeButton
              size="large"
              variant="contained"
              className="backButton"
              onClick={() =>
                onScreenshotClick(dispatch, STATE_SCREENSHOT_CAPUTURED)
              }
            >
              <Typography variant="boldBody">Go Back</Typography>
            </DownloadLargeButton>
          )}

        <img
          src={logoImg}
          crossOrigin="anonymous"
          alt="Logo"
          width="10%"
          height="10%"
          style={{ paddingBottom: 10 }}
        />
        <img
          src={editedImgLink}
          alt={imageName}
          crossOrigin="anonymous"
          width="90%"
          height="90%"
        />
      </ContentSection>
      <ContentSection className="successPageCardContainer">
        <Typography variant="boldSubTitle" sx={{ marginBottom: 2 }}>
          Here's the image
        </Typography>
        <TitleWrapper variant="boldSubTitle">
          {!isjiraLink(linkDetail) && linkDetail.jsm_id === null &&
            linkDetail.source !== ZENDESK_SOURCE &&
            linkDetail.source !== INTERCOM_SOURCE &&
            linkDetail.source !== FRESHCHAT_SOURCE &&
            linkDetail.source !== MONDAYCOM_SOURCE && (
              <TitleWrapper variant="boldSubTitle">
                Your screenshot has been sent to {linkDetail.user.name}{" "}
                {linkDetail.user.company.name
                  ? `from ${linkDetail.user.company.name}`
                  : ""}
              </TitleWrapper>
            )}
          {isjiraLink(linkDetail) &&
            jiraUploadStatus.status !== UPLOAD_STATUS_SUCCESS && (
              <TitleWrapper variant="boldSubTitle">
                Thank you! Your screenshot is ready to be attached to Jira issue{" "}
                <a
                  href={`${linkDetail.external_issue_info.jiraBaseUrl}/browse/${linkDetail.external_issue_info.issueKey}`}
                  target="_blank" rel="noreferrer"
                >
                  {linkDetail.external_issue_info.issueKey}
                </a>
              </TitleWrapper>
            )}
          {isjiraLink(linkDetail) &&
            jiraUploadStatus.status === UPLOAD_STATUS_SUCCESS && (
              <>
                Your screenshot is queued to upload and shortly it will be
                attached to Jira issue &nbsp;
                <a
                  href={`${linkDetail.external_issue_info.jiraBaseUrl}/browse/${linkDetail.external_issue_info.issueKey}`}
                  target="_blank" rel="noreferrer"
                >
                  {linkDetail.external_issue_info.issueKey}
                </a>
              </>
            )}
          {!isjiraLink(linkDetail) && linkDetail.jsm_id !== null && (
            <>
              Your screenshot will be automatically attached to the jira issue once
              {!isjiraLink(linkDetail) && linkDetail.jsm_id !== null && (
                <>
                  Your screenshot will be automatically attached to the jira issue once
                  it is created.
                </>
              )}
            </>
          )}
          {linkDetail.source === ZENDESK_SOURCE && (
            //here zendesk
            <TitleWrapper variant="boldSubTitle">
              {zendeskUploadStatus.status !== UPLOAD_STATUS_SUCCESS && (
                <>
                  Thank you! Your screenshot is ready. You can send it{" "}
                  {linkDetail.user.company.name
                    ? `to ${linkDetail.user.company.name}.`
                    : "now."}{" "}
                  &nbsp;
                </>
              )}
              {zendeskUploadStatus.status === UPLOAD_STATUS_SUCCESS && (
                <>
                  Your screenshot has been sent
                  {linkDetail.user.company.name
                    ? ` to ${linkDetail.user.company.name}.`
                    : "."}{" "}
                  You can close this window now.&nbsp;
                </>
              )}
            </TitleWrapper>
          )}
          {linkDetail.source === INTERCOM_SOURCE && (
            //here zendesk
            <TitleWrapper variant="boldSubTitle">
              {intercomUploadStatus.status !==
                UPLOAD_STATUS_SUCCESS && (
                  <>
                    Thank you! Your screenshot is ready. You can send it{" "}
                    {linkDetail.user.company.name
                      ? `to ${linkDetail.user.company.name}.`
                      : "now."}{" "}
                    &nbsp;
                  </>
                )}
              {intercomUploadStatus.status ===
                UPLOAD_STATUS_SUCCESS && (
                  <>
                    Your screenshot has been sent
                    {linkDetail.user.company.name
                      ? ` to ${linkDetail.user.company.name}.`
                      : "."}{" "}
                    You can close this window now.&nbsp;
                  </>
                )}
            </TitleWrapper>
          )}
          {linkDetail.source === FRESHCHAT_SOURCE && (
            //here zendesk
            <TitleWrapper variant="boldSubTitle">
              {freshchatUploadStatus.status !==
                UPLOAD_STATUS_SUCCESS && (
                  <>
                    Thank you! Your screenshot is ready. You can send it{" "}
                    {linkDetail.user.company.name
                      ? `to ${linkDetail.user.company.name}.`
                      : "now."}{" "}
                    &nbsp;
                  </>
                )}
              {freshchatUploadStatus.status ===
                UPLOAD_STATUS_SUCCESS && (
                  <>
                    Your screenshot has been sent
                    {linkDetail.user.company.name
                      ? ` to ${linkDetail.user.company.name}.`
                      : "."}{" "}
                    You can close this window now.&nbsp;
                  </>
                )}
            </TitleWrapper>
          )}
          {linkDetail.source === MONDAYCOM_SOURCE && (
            <TitleWrapper variant="boldSubTitle">
              {mondayUploadStatus.status !==
                UPLOAD_STATUS_SUCCESS && (
                  <>
                    Thank you! Your screenshot is ready to be attached to monday.com&nbsp;
                  </>
                )}
              {mondayUploadStatus.status ===
                UPLOAD_STATUS_SUCCESS && (
                  <>
                    Your screenshot is queued to upload and shortly it will be
                    attached to monday.com&nbsp;
                  </>
                )}
              <a
                href={`${linkDetail.external_issue_info.baseUrl}/boards/${linkDetail.external_issue_info.boardId}/pulses/${linkDetail.external_issue_info.itemId}`}
                target="_blank"
                rel="noreferrer"
              >item</a>
            </TitleWrapper>
          )}
        </TitleWrapper>
        <EditVideoName
          videoName={imageName}
          uuid={uuid}
          fileName={(value) => setFileName(value)}
        />
        {note ? (
          <Typography className="successCardDescription">{note}</Typography>
        ) : null}
        <ContentSection className="successCardButtonsContainer">
          <DownloadLargeButton
            size="large"
            variant="contained"
            onClick={() => {
              downloadFile(editedImgLink, file_name);
            }}
          >
            <DownloadIconLarge />
            <Typography variant="boldBody">Download</Typography>
          </DownloadLargeButton>
          {isjiraLink(linkDetail) &&
            jiraUploadStatus.status !== UPLOAD_STATUS_SUCCESS && (
              <DownloadLargeButton
                size="large"
                variant="contained"
                onClick={() => {
                  uploadToJiraHanlder(linkDetail, dispatch, file_name);
                }}
                disabled={jiraUploadStatus.status === UPLOAD_STATUS_STARTED}
              >
                <Typography variant="boldBody">
                  {jiraUploadStatus.status === UPLOAD_STATUS_STARTED && (
                    <>Uploading to Jira...</>
                  )}
                  {jiraUploadStatus.status !== UPLOAD_STATUS_STARTED && (
                    <>Upload to Jira issue</>
                  )}
                </Typography>
              </DownloadLargeButton>
            )}

          {!isjiraLink(linkDetail) && linkDetail.source !== MONDAYCOM_SOURCE && (
            <DownloadLargeButton
              size="large"
              variant="contained"
              disabled={disabled}
              onClick={() => {
                getShareableLink();
              }}
              style={{ margin: 0 }}
            >
              <Typography variant="boldBody">Copy shareable link</Typography>
            </DownloadLargeButton>
          )}

          {linkDetail &&
            linkDetail.source === MONDAYCOM_SOURCE &&
            mondayUploadStatus.status !== UPLOAD_STATUS_SUCCESS && (
              <DownloadLargeButton
                size="large"
                variant="contained"
                onClick={() => {
                  uploadToVideo(
                    linkDetail,
                    dispatch,
                    "mondaycom",
                    updateMondayUploadStatus,
                    UPLOAD_STATUS_ERROR
                  );
                }}
                disabled={mondayUploadStatus.status === UPLOAD_STATUS_STARTED}
              >
                <Typography variant="boldBody">
                  {mondayUploadStatus.status === UPLOAD_STATUS_STARTED && (
                    <>Uploading to monday.com...</>
                  )}
                  {mondayUploadStatus.status !== UPLOAD_STATUS_STARTED && (
                    <>Upload to monday.com</>
                  )}
                </Typography>
              </DownloadLargeButton>
            )}
        </ContentSection>
        <ActionRecordButtonWrapper>
          {linkDetail &&
            linkDetail.source === ZENDESK_SOURCE &&
            zendeskUploadStatus.status !== UPLOAD_STATUS_SUCCESS && (
              <DownloadLargeButton
                size="large"
                variant="contained"
                onClick={() => {
                  uploadToVideo(
                    linkDetail,
                    dispatch,
                    "zendesk",
                    updateZendeskUploadStatus,
                    UPLOAD_STATUS_ERROR
                  );
                }}
              >
                <Typography variant="boldBody">
                  Send screenshot to Zendesk
                </Typography>
              </DownloadLargeButton>
            )}

          {linkDetail &&
            linkDetail.source === INTERCOM_SOURCE &&
            intercomUploadStatus.status !== UPLOAD_STATUS_SUCCESS && (
              <DownloadLargeButton
                size="large"
                variant="contained"
                onClick={() => {
                  uploadToVideo(
                    linkDetail,
                    dispatch,
                    "intercom",
                    updateIntercomUploadStatus,
                    UPLOAD_STATUS_ERROR
                  );
                }}
              >
                <Typography variant="boldBody">
                  Send screenshot to Intercom
                </Typography>
              </DownloadLargeButton>
            )}
          {linkDetail &&
            linkDetail.source === FRESHCHAT_SOURCE &&
            freshchatUploadStatus.status !== UPLOAD_STATUS_SUCCESS && (
              <DownloadLargeButton
                size="large"
                variant="contained"
                onClick={() => {
                  uploadToVideo(
                    linkDetail,
                    dispatch,
                    "freshchat",
                    updateFreshchatUploadStatus,
                    UPLOAD_STATUS_ERROR
                  );
                }}
              >
                <Typography variant="boldBody">
                  Send screenshot to Freshchat
                </Typography>
              </DownloadLargeButton>
            )}
        </ActionRecordButtonWrapper>
        {!(
          (isjiraLink(linkDetail) &&
            jiraUploadStatus.status === UPLOAD_STATUS_SUCCESS) ||
          (linkDetail.source === MONDAYCOM_SOURCE &&
            mondayUploadStatus.status === UPLOAD_STATUS_SUCCESS)
        ) && (
            <RecordDescription>
              <Typography variant="lightCallout">
                Does the screenshot clearly show what you wanted it to? If not,
                <TitleWrapper
                  variant="lightCallout"
                  onClick={() =>
                    dispatch(updateRecordingStateWithData({ recordingState: "" }))
                  }
                >
                  <LinkButton> take another screenshot.</LinkButton>
                </TitleWrapper>
              </Typography>
            </RecordDescription>
          )}
        <ContentSection className="BadgeContainer">
          <PrimaryBadge1 />
          <PrimaryBadge1 />
          <PrimaryBadge1 />
        </ContentSection>
      </ContentSection>
    </ContentSection >
  );
}

export default ScreenshotUploadSuccess;
