import {
  Box,
  Button,
  Card,
  DialogContent,
  DialogTitle,
  Stack,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { ReactComponent as SaveIconSvg } from '../../../images/Icon/save.svg';

export const SaveIcon = styled(SaveIconSvg)(({ theme }) => ({
  width: 24,
  height: 24,
  cursor: 'pointer',
  marginLeft: theme.spacing(1),
  path: {
    fill: theme.palette.primary.main,
  },
}));

export const SaveItem = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.main,
  cursor: 'pointer',
  textDecoration: 'none',
}));

export const LinkNoUnderline = styled('a')(({ theme }) => ({
  textDecoration: 'none',
}));

export const ButtonWrapper = styled(Stack)(({ theme }) => ({
  width: '100%',
  marginTop: theme.spacing(3),
}));

export const TextareaWrapper = styled(Box)(({ theme }) => ({
  margin: theme.spacing(3.25, 0),
}));

export const RequestButton = styled(Button)(({ theme }) => ({
  color: theme.palette.common.white,
  textTransform: 'initial',
  width: '100%',

  [theme.breakpoints.up('sm')]: {
    width: '70%',
  },
}));

export const CancelButton = styled(Button)(({ theme }) => ({
  background: theme.palette.dark.lighter,
  color: theme.palette.common.black,
  textTransform: 'initial',
  width: '100%',

  '&:hover': {
    background: theme.palette.dark.lighter,
  },

  [theme.breakpoints.up('sm')]: {
    width: '30%',
  },
}));

export const BackButton = styled(CancelButton)(({ theme }) => ({
  [theme.breakpoints.up('sm')]: {
    width: '70%',
  },
}));

export const DialogButton = styled('div')({
  display: 'flex',
  alignItems: 'center',
  width: '100%',
});

export const CustomDialogContent = styled(DialogContent)(({ theme }) => ({
  padding: theme.spacing(3.75),
}));

export const CustomDialogTitle = styled(DialogTitle)(({ theme }) => ({
  padding: theme.spacing(5.25, 3.75, 2.63, 3.75),
}));

export const StyledNoteBox = styled('div')(({ theme }) => ({
  width: '100%',
  background: theme.palette.dark.trans5,
  border: 0,
  borderRadius: 8,
  font: 'inherit',
  fontWeight: '500',
  fontSize: '18px',
  lineHeight: '24px',
  letterSpacing: '-0.25px',
  margin: theme.spacing(3.125, 0, 3.25, 0),
  resize: 'vertical',
  padding: theme.spacing(2, 3),
}));

export const ContentSection = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(2),
}));
export const OrderedOl = styled('ol')(({ theme }) => ({
  marginLeft: theme.spacing(2),
}));
export const OrderedLi = styled('li')(({ theme }) => ({
  marginTop: theme.spacing(4),
}));
export const OrderedP = styled('p')(({ theme }) => ({
  marginBottom: theme.spacing(1),
}));

export const CustomMessageWrapper = styled(Card)(({ theme }) => ({
  position: 'relative',
  background: theme.palette.grey.lighter,
  borderRadius: theme.spacing(2),
  padding: theme.spacing(0, 2),
  boxShadow: 'none',
  width: '100%',
  marginTop: theme.spacing(3),
  ...theme.mixins.flexyCol,
}));