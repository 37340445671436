import { axiosPlainInstance } from "../../common/axios";

// for signup w/o invite
export function SignUpAPI(form_data) {
    return axiosPlainInstance.post(`api/sign-up-view/`, form_data, {
        headers: {
            'content-type': 'multipart/form-data'
        }
    });
}

// for signup with invite
export function SignUpInviteAPI(form_data, uuid, token) {
    return axiosPlainInstance.post(`api/sign-up-invite-view/${uuid}/${token}`, form_data, {
        headers: {
            'content-type': 'multipart/form-data'
        }
    });
}

//get invitee data
export function GetInvitationDetails(uuid, token) {
    return axiosPlainInstance.get(`api/sign-up-invite-view/${uuid}/${token}`);
}

export function LoginAPI(email, password) {
    return axiosPlainInstance.post(`api/token/`, { email, password });
}