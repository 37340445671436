import { getMetaTag, META_TAG_STATIC_PATH } from "../../common/MetaTagReader";

export const getQueryParameters = (url) => {
  const query = url.split("?")[1];
  const queryParams = {};
  const params = query ? query.split("&") : null;
  if (params) {
    params.forEach((param) => {
      const pair = param.split("=");
      queryParams[pair[0]] = decodeURIComponent(pair[1]);
    });
  }
  return queryParams;
};
export const downloadFile = async (fileUrl, name) => {
  fetch(fileUrl)
    .then((res) => res.blob())
    .then((blob) => {
      let url = window.URL.createObjectURL(blob);
      let a = document.createElement("a");
      a.href = url;
      if (!name || name === "" || name === null) {
        name = "screenjar";
      }
      a.download = name;
      a.click();
    });
};
export const getVideoType = (url) => {
  return url && !url.endsWith("mp4") ? "video/webm" : "video/mp4";
};
export function AddLibrary() {
  var s1 = document.createElement("script"),
    s0 = document.getElementsByTagName("script")[0];
  s1.async = true;
  s1.src = "https://embed.tawk.to/634686a137898912e96e31ae/1gf5nrq7d";
  s1.charset = "UTF-8";
  s1.setAttribute("crossorigin", "*");
  s0.parentNode.insertBefore(s1, s0);
}
export const staticPath = `${getMetaTag(META_TAG_STATIC_PATH)}`;
