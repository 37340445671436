import React from 'react';
import { Card } from '@mui/material';
import {
    StyledTypography,
    StyledLink,
} from '../sign-up/components/SignUpStyle';
import { Link as RouterLink } from 'react-router-dom';
import { Wrapper } from '../../shared_modules/common/Wrapper';

export function ChromeLogin() {

    return (
        <Wrapper>
            <Card style={{ padding: '2%' }}>
                <StyledTypography variant="lightCallout">
                    You're now signed in and can start using the browser extension. <br />
                    <StyledLink
                        underline="none"
                        variant="boldCallout"
                        component={RouterLink}
                        to="/app/inbox"
                    >
                        Go to inbox
                    </StyledLink>
                </StyledTypography>
            </Card>
        </Wrapper>
    );
}
